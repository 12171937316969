import { Button, Flex, Card, Col, Row, Statistic, theme, Typography, Tooltip, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';

const TitleWithToolTip = ({ title, tooltip }) => (
  <Flex justify="space-between" align="center">
    <Typography.Text type="secondary">{title}</Typography.Text>
    <Tooltip title={tooltip}>
      <QuestionCircleOutlined />
    </Tooltip>
  </Flex>
);

const LegacyCardLink = ({ link, state }) => (
  <Flex justify="space-between" align="center" vertical>
    <Link to={link} state={state}>
      <Button size="small" type="link">
        View
      </Button>
    </Link>
  </Flex>
);

const CardLink = ({ link, lastCrawledFilterActive, domain }) => {
  const search = new URLSearchParams();
  if (lastCrawledFilterActive) {
    const dateOfAmonthAgo = new Date();
    dateOfAmonthAgo.setMonth(dateOfAmonthAgo.getMonth() - 1);
    search.set('last_delivery_at', dateOfAmonthAgo.toISOString());
  }

  if (domain) {
    search.set('domain', domain);
  }

  return (
    <Flex justify="space-between" align="center" vertical>
      <Link to={`${link}?${search.toString()}`}>
        <Button size="small" type="link">
          View
        </Button>
      </Link>
    </Flex>
  );
};

export const AnalyticsStatisticCards = ({ isLoading = false, notCrawledPages, crawlerRequests, filters = {} }) => {
  const { token } = theme.useToken();

  return (
    <Row gutter={[token.marginMD, token.marginMD]}>
      <Col xs={12} xl={6}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic
              title={
                <TitleWithToolTip
                  title="Pages not crawled"
                  tooltip="Pages that have not been crawled in the past 30 days"
                />
              }
              value={notCrawledPages !== undefined ? notCrawledPages : '-'}
              suffix={notCrawledPages !== undefined ? 'pages' : ''}
              valueStyle={{ justifyContent: 'center', display: 'flex' }}
            />
            <CardLink link="/cache/cache-manager" lastCrawledFilterActive domain={filters.domain} />
          </Skeleton>
        </Card>
      </Col>

      <Col xs={12} xl={6}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic
              title="Crawler requests"
              value={crawlerRequests !== undefined ? crawlerRequests : '-'}
              valueStyle={{ justifyContent: 'center', display: 'flex' }}
            />
            <LegacyCardLink link="/crawl-stats" state={{ userAgent: filters.userAgent, domain: filters.domain }} />
          </Skeleton>
        </Card>
      </Col>

      <Col xs={12} xl={6}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic title="Avg. daily visits" value="In development" />
          </Skeleton>
        </Card>
      </Col>

      <Col xs={12} xl={6}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic title="Avg. page crawl rate" value="In development" />
          </Skeleton>
        </Card>
      </Col>
    </Row>
  );
};
