import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent < 0.2) {
    return null;
  }

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export function DashboardPieChart({ data, colors }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart width={400} height={400}>
        <Legend layout="vertical" verticalAlign="top" align="right" />

        <Pie
          data={data.length === 0 ? [{ name: 'No data', value: 1 }] : data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          // outerRadius={200}
          // outerRadius={120}
          outerRadius={'80%'}
          // fill="#8884d8"
          fill="#E3EBF6"
          dataKey="value"
        >
          {data.map((entry, index) => {
            let fill;
            if (Array.isArray(colors)) {
              fill = colors[index % colors.length];
            } else {
              fill = colors(entry);
            }

            return <Cell key={`cell-${index}`} fill={fill} />;
          })}
          {data.length === 0 && <Cell key={`cell-0`} fill={`#E3EBF6`} />}
        </Pie>
        {data.length > 0 && <Tooltip />}
      </PieChart>
    </ResponsiveContainer>
  );
}
