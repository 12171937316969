const USER_AGENT_NAMES = [
  {
    label: 'Googlebot',
    value: 'googlebot',
    iconName: 'google',
  },
  {
    label: 'Bingbot',
    value: 'bingbot',
    iconName: 'bing',
  },
  {
    label: 'Facebook',
    value: 'facebook',
    iconName: 'facebook',
  },
  {
    label: 'Twitter',
    value: 'twitter',
    iconName: 'twitter',
  },
  {
    label: 'Yandex',
    value: 'yandex',
    iconName: 'yandex',
  },
  {
    label: 'Baiduspider',
    value: 'baidu',
    iconName: 'baidu',
  },
  {
    label: 'AhrefsBot',
    value: 'ahrefs',
    iconName: 'ahrefs',
  },
  {
    label: 'WhatsApp',
    value: 'whatsapp',
    iconName: 'whatsapp',
  },
  {
    label: 'Pinterest',
    value: 'pinterest',
    iconName: 'pinterest',
  },
  {
    label: 'Applebot',
    value: 'applebot',
    iconName: 'apple',
  },
  {
    label: 'SemrushBot',
    value: 'semrush',
    iconName: 'semrush',
  },
];

export default USER_AGENT_NAMES;
