export const formatPagesCount = (count) => {
  const value = parseFloat(count);
  const formattedValue = Number.isNaN(value)
    ? '0'
    : value.toLocaleString(undefined, { useSeparator: true });
  return formattedValue.toString();
};

// 0.01234 -> '1.23%'
// 0       -> '0.00%
export const formatPercent = (n) => `${(n * 100).toFixed(2)}%`;
