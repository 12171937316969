import React from 'react';
import { Popconfirm, Tooltip, Button } from 'antd';
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';

import ButtonWithRole from './ButtonWithRole';
import USER_ROLES from '../features/auth/userRoles';
import { TOOLTIP_ROOT_ID } from './Tooltip';

export const ViewCachedHtmlButton = ({ disabled, item, tooltip }) => (
  <a
    type="button"
    className={disabled ? 'btn btn-sm btn-white ml-2 disabled' : 'btn btn-sm btn-white ml-2'}
    href={`/raw-html?id=${item.id}`}
    target="_blank"
    rel="noreferrer"
    data-tip={tooltip}
  >
    <i className="fe fe-eye"></i>
  </a>
);

export const DeletePageButton = ({ disabled, tooltip = 'Remove the URL from the cache', onClick }) => (
  <Popconfirm
    title="Are you sure you want to delete this URL?"
    placement="left"
    onConfirm={onClick}
    okText="Yes"
    cancelText="Cancel"
  >
    <div data-tooltip-id={TOOLTIP_ROOT_ID} data-tooltip-place="top" data-tooltip-content={tooltip}>
      <ButtonWithRole
        disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
        className="ml-2"
        danger
        disabled={disabled}
        data-tip={tooltip}
        icon={<DeleteOutlined />}
      ></ButtonWithRole>
    </div>
  </Popconfirm>
);

// This is for the ignored URLs
export const SaveButton = ({ disabled, tooltip, onClick }) => (
  <ButtonWithRole
    disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
    className="ml-2"
    type="primary"
    ghost
    danger={false}
    disabled={disabled}
    onClick={onClick}
    data-tip={tooltip}
    icon={<CheckOutlined />}
  ></ButtonWithRole>
);
