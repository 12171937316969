import { connect } from 'react-redux';
import { formatCentsToDollarLocale } from '../assets/currencies';
import { isFreePlan } from '../chargebee/chargebee';
import DataPointCard from './DataPointCard';

const cid = 'MyAccountPage';

const PlanPriceCards = ({ cm, isMeteredBilling, plan, inProgress, row = false }) => {
  const hasOverages = isMeteredBilling && !isFreePlan(plan) && plan.estimate.addonsPriceInCents > 0;
  const planTitle = isMeteredBilling ? cm.billing.costs.plan.title : cm.billing.costs.title;
  const width = row ? (hasOverages ? 'col-6' : 'col-12') : 'col-12';

  const planCost = (
    <DataPointCard
      width={width}
      title={planTitle}
      value={formatCentsToDollarLocale(plan.estimate.planPriceInCents)}
      icon={cm.billing.costs.icon}
      isLoading={inProgress}
    />
  );

  const overagesCost = hasOverages ? (
    <DataPointCard
      width={width}
      title={cm.billing.costs.overages.title}
      value={formatCentsToDollarLocale(plan.estimate.addonsPriceInCents)}
      icon={plan.suggested ? cm.billing.costs.overages.iconUpgrade : cm.billing.costs.overages.icon}
      iconToolTip={plan.suggested ? cm.billing.costs.upgradeToolTip : cm.billing.costs.toolTip}
      iconColor={plan.suggested ? 'text-info' : 'text-success'}
      toolTip={cm.billing.costs.overages.toolTip}
      isLoading={inProgress}
    />
  ) : null;

  return (
    <span>
      {row ? (
        <div className="row">
          {planCost} {overagesCost}
        </div>
      ) : (
        <span>
          {planCost} {overagesCost}
        </span>
      )}
    </span>
  );
};

function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    isMeteredBilling: state.prerenderUser.isMeteredBilling,
  };
}

export default connect(mapStateToProps, null)(PlanPriceCards);
