
import {
  GET_DOMAINS_IN_PROGRESS,
  GET_DOMAINS_SUCCESS
} from './types';
import { setLastErrorCode } from './pageActions';
import { getLocalErrorCode } from '../assets/lib';
import config from '../assets/config';
import internalApi from '../assets/internalApi';

const updateDomains = (dispatch, data) => {
  dispatch({ type: GET_DOMAINS_SUCCESS, payload: data });
};

export const getDomains = (searchQuery = null) => async (dispatch) => {
  try {
    dispatch({ type: GET_DOMAINS_IN_PROGRESS, payload: true });
    let requestUri = `${config.apiUrl.restServer}/v3/domains`;
    if (searchQuery && searchQuery.length > 2)
      requestUri += `?query=${encodeURIComponent(searchQuery)}`;
    const data = await internalApi('get', requestUri, null);
    updateDomains(dispatch, data);
  } catch (error) {
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  } finally {
    dispatch({ type: GET_DOMAINS_IN_PROGRESS, payload: false });
  }
};
