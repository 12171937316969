import { setupWorker } from 'msw/browser';
import { http, HttpResponse, delay } from 'msw';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

import { faker, fakerTH } from '@faker-js/faker';
import * as ms from 'ms';

const ENV = process.env.REACT_APP_STAGE;

const disableMocks = true;
const emptyState = false;

export const handlers = [
  http.get('http://localhost:3030/v3/aggregated-metrics/usage-overview', async ({ request }) => {
    // await delay(500);

    if (emptyState) {
      return HttpResponse.json({
        data: {
          averageDeliveryTime: 0,
          originalDeliveryTime: 0,
          crawlBudgetIncrease: 0,
        },
      });
    }

    return HttpResponse.json({
      data: {
        averageDeliveryTime: faker.number.float({ min: 1000, max: 2007 }),
        // averageDeliveryTime: 0.04,
        originalDeliveryTime: faker.number.int({ min: 2100, max: 3200 }),
        crawlBudgetIncrease: faker.number.int({ min: 50, max: 200 }),
      },
    });
  }),
  http.get('http://localhost:3030/v3/aggregated-metrics/page-delivered-user-agent/overview', async ({ request }) => {
    // await delay(500);

    if (emptyState) {
      return HttpResponse.json({
        data: {
          Googlebot: 0,
          Bingbot: 0,
          Pinterestbot: 0,
          Facebook: 0,
          Yandexbot: 0,
          Ahrefsbot: 0,
          Applebot: 0,
          AdsbotGoogle: 0,
          Semrushbot: 0,
          Other: 0,
        },
      });
    }

    return HttpResponse.json({
      data: {
        Googlebot: faker.number.int({ min: 2000, max: 4000 }),
        Bingbot: faker.number.int({ min: 150, max: 500 }),
        Pinterestbot: faker.number.int({ min: 1, max: 100 }),
        Facebook: faker.number.int({ min: 1, max: 100 }),
        Yandexbot: faker.number.int({ min: 1, max: 100 }),
        Ahrefsbot: faker.number.int({ min: 1, max: 100 }),
        Applebot: faker.number.int({ min: 1, max: 100 }),
        AdsbotGoogle: faker.number.int({ min: 1, max: 100 }),
        Semrushbot: faker.number.int({ min: 1, max: 100 }),
        Other: faker.number.int({ min: 200, max: 300 }),
      },
    });
  }),
  http.get('http://localhost:3030/v3/aggregated-metrics/page-delivered-user-agent/daily', async ({ request }) => {
    // return;
    // await delay(500);

    if (emptyState) {
      return HttpResponse.json({
        data: [],
      });
    }

    const days = [];

    const url = new URL(request.url);

    const from = url.searchParams.get('from');
    const fromMs = Math.abs(ms(from));
    const duration = dayjs.duration(fromMs).asDays();

    for (let i = 0; i < duration; i++) {
      const day = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
      days.push({
        Googlebot: faker.number.int({ min: 2000, max: 4000 }),
        Bingbot: faker.number.int({ min: 150, max: 500 }),
        Pinterestbot: faker.number.int({ min: 1, max: 100 }),
        Facebook: faker.number.int({ min: 1, max: 100 }),
        Yandexbot: faker.number.int({ min: 1, max: 100 }),
        Ahrefsbot: faker.number.int({ min: 1, max: 100 }),
        Applebot: faker.number.int({ min: 1, max: 100 }),
        AdsbotGoogle: faker.number.int({ min: 1, max: 100 }),
        Semrushbot: faker.number.int({ min: 1, max: 100 }),
        Other: faker.number.int({ min: 200, max: 300 }),
        day,
      });
    }

    return HttpResponse.json({
      data: days,
    });
  }),
  http.get('http://localhost:3030/v3/aggregated-metrics/page-delivered-status-code/overview', async ({ request }) => {
    // return;
    // await delay(500);

    if (emptyState) {
      return HttpResponse.json({
        data: {
          statusCode2xx: 0,
          statusCode301: 0,
          statusCode302: 0,
          statusCode401: 0,
          statusCode403: 0,
        },
      });
    }

    return HttpResponse.json({
      data: {
        statusCode2xx: faker.number.int({ min: 300, max: 1000 }),
        statusCode301: faker.number.int({ min: 1, max: 100 }),
        statusCode302: faker.number.int({ min: 1, max: 100 }),
        statusCode401: faker.number.int({ min: 1, max: 100 }),
        statusCode403: faker.number.int({ min: 1, max: 100 }),
      },
    });
  }),
  http.get('http://localhost:3030/v3/aggregated-metrics/page-delivered-status-code/daily', async ({ request }) => {
    // return;
    // await delay(500);

    if (emptyState) {
      return HttpResponse.json({
        data: [],
      });
    }

    const days = [];

    const url = new URL(request.url);

    const from = url.searchParams.get('from');
    const fromMs = Math.abs(ms(from));
    const duration = dayjs.duration(fromMs).asDays();

    for (let i = 0; i < duration; i++) {
      const day = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
      days.push({
        statusCode2xx: faker.number.int({ min: 300, max: 1000 }),
        statusCode301: faker.number.int({ min: 1, max: 100 }),
        statusCode302: faker.number.int({ min: 1, max: 100 }),
        statusCode401: faker.number.int({ min: 1, max: 100 }),
        statusCode403: faker.number.int({ min: 1, max: 100 }),
        day,
      });
    }

    return HttpResponse.json({
      data: days,
    });
  }),
  http.get('http://localhost:3030/v3/aggregated-metrics/page-delivered/daily', async ({ request }) => {
    // return;
    // await delay(500);

    if (emptyState) {
      return HttpResponse.json({
        data: [],
      });
    }

    const days = [];

    const url = new URL(request.url);

    const from = url.searchParams.get('from');
    const fromMs = Math.abs(ms(from));
    const duration = dayjs.duration(fromMs).asDays();

    for (let i = 0; i < duration; i++) {
      const day = dayjs().subtract(i, 'day').format('YYYY-MM-DD');
      days.push({
        cacheHit: faker.number.int({ min: 2000, max: 4000 }),
        cacheMiss: faker.number.int({ min: 150, max: 500 }),
        day,
      });
    }

    return HttpResponse.json({
      data: days,
    });
  }),
  http.get('http://localhost:8443/api/crawl-stats', async ({ request }) => {
    // return;
    // await delay(500);

    if (emptyState) {
      return HttpResponse.json([]);
    }

    const day = dayjs();
    const sometimeAgo = day.subtract(16, 'hours');

    const agents = [
      'Mozilla/5.0 (Linux; Android 6.0.1; Nexus 5X Build/MMB29P) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.6099.71 Mobile Safari/537.36 (compatible; Googlebot/2.1; +http://www.google.com/bot.html)',
      'facebookexternalhit/1.1 (+http://www.facebook.com/externalhit_uatext.php)',
      'Slackbot 1.0 (+https://api.slack.com/robots)',
      'Twitterbot/1.0',
    ];

    const faked = () => ({
      cache_hit: 1,
      domain: faker.internet.domainName(),
      render_time: faker.number.int({ min: 43, max: 27000 }),
      status_code: 200,
      time: faker.date.between({ from: sometimeAgo.toDate(), to: day.toDate() }),
      // url: faker.internet.url(),
      url: `${faker.internet.url()}/${faker.string.uuid()}}/${faker.string.uuid()}}/${faker.string.uuid()}}`,
      user_agent: faker.helpers.arrayElement(agents),
      user_id: 799,
      timedout: 0,
      adaptive_type: faker.helpers.arrayElement(['desktop', 'mobile']),
    });

    return HttpResponse.json([
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
    ]);
  }),
  http.get('http://localhost:8443/api/cached-pages', async ({ request }) => {
    // await delay(500);

    if (emptyState) {
      return HttpResponse.json([]);
    }

    const day = dayjs();
    const sometimeAgo = day.subtract(16, 'hours');

    const faked = () => ({
      domain: faker.internet.domainName(),
      // url: faker.internet.url(),
      url: `${faker.internet.url()}/${faker.string.uuid()}}/${faker.string.uuid()}}/${faker.string.uuid()}}`,
      cache_state: 'Cached',
      user_id: 799,
      last_refresh: faker.date.between({ from: sometimeAgo.toDate(), to: day.toDate() }),
      requested_recache_at: null,
      adaptive_type: faker.helpers.arrayElement(['desktop', 'mobile']),
      seo_score: faker.number.int({ min: -20, max: 20 }),
      created_at: null,
      id: faker.string.uuid(),
      last_delivery_at: null,
      source: null,
    });

    return HttpResponse.json([
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
      faked(),
    ]);
  }),
  http.get('http://localhost:8443/api/v2/dashboard-stats', async ({ request }) => {
    // await delay(500);

    if (emptyState) {
      return HttpResponse.json({
        result: {
          priorityQueueSize: `0`,
          recacheQueueSize: `0`,
          timeoutStatsResults: {
            allAnalytics: 0,
            timedoutAnalytics: 0,
            allRecacheAnalytics: 0,
            timedoutRecacheAnalytics: 0,
          },
        },
      });
    }

    return HttpResponse.json({
      result: {
        priorityQueueSize: `${faker.number.int({ min: 1000, max: 1500 })}`,
        recacheQueueSize: `${faker.number.int({ min: 50, max: 150 })}`,
        timeoutStatsResults: {
          allAnalytics: 0,
          timedoutAnalytics: 0,
          allRecacheAnalytics: 0,
          timedoutRecacheAnalytics: 0,
        },
      },
    });
  }),
];

export async function enableMockingOnDevelopment() {
  if (ENV === 'production') {
    return;
  }

  if (disableMocks) {
    console.info('Mocks are disabled');
    return;
  }

  const worker = setupWorker(...(disableMocks ? [] : handlers));

  return worker.start({
    // This is going to perform unhandled requests
    // but print no warning whatsoever when they happen.
    onUnhandledRequest: 'bypass',
  });
}
