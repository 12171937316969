function formatMinutes(amount) {
  return `${amount} minute${amount > 1 ? 's' : ''}`;
}

function formatHours(amount) {
  return `${amount} hour${amount > 1 ? 's' : ''}`;
}

function formatDays(amount) {
  return `${amount} day${amount > 1 ? 's' : ''}`;
}

export function getTimeLabel(hours) {
  if (hours >= 24) {
    const days = Math.floor(hours / 24);
    return formatDays(days);
  }

  return formatHours(hours);
}

export function formatCacheAge(minutes) {
  if (!minutes) return '-';

  const hours = minutes / 60;
  if (hours < 1) return formatMinutes(Math.floor(minutes));

  if (hours < 24) return formatHours(Math.floor(hours));

  const days = Math.floor(hours / 24);
  const outputString = formatDays(days);

  const remainingHours = hours % 24;
  if (remainingHours >= 1) return `${outputString}, ${remainingHours.toFixed(0)} h`;
  return outputString;
}
