import { Alert, Button, Divider, Steps, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import ButtonGroup from 'antd/lib/button/button-group';
import { Content } from 'antd/lib/layout/layout';
import React from 'react';
import { useRecoilState } from 'recoil';
import { onboardingAtom } from '../../atoms';
import IgnoredUrlsSettings from '../../features/cache-manager/components/IgnoredUrlsSettings';

export default function GettingStartedWrapper() {
  const [onboarding, setOnboarding] = useRecoilState(onboardingAtom);

  return (
    <>
      <PageHeader
        title="Getting Started!"
        subTitle={`Onboarding step at [${onboarding}]`}
        extra={[
          <Button key="help" type="ghost">
            Ask for Help
          </Button>,
          <Button key="cancel" danger>
            Skip Setup
          </Button>,
        ]}
        footer={
          <Steps current={onboarding} style={{ marginTop: '36px' }}>
            <Steps.Step title="Create Account" description="Smart Decision Made!" />
            <Steps.Step title="Your First Page" />
            <Steps.Step title="Optimize Your Region" />
            <Steps.Step title="Cache Preloading" />
            <Steps.Step title="Ignore Rules" />
            <Steps.Step title="Integration" />
            <Steps.Step title="Success!" description="End of the Journey!" />
          </Steps>
        }
      />

      <Content>
        <Divider />
        <div style={{ minHeight: 800, padding: 20, maxWidth: 1400 }} className="mx-auto">
          {onboarding === 4 && (
            <>
              <Alert
                type="info"
                message="Ignore Rules"
                description="We are now configuring URL patterns which does not need to be rendered, for example admin pages, login pages, etc. This will optimize your efficiency."
                showIcon
                action={
                  <Tooltip title="Your robots.txt usually already well configured for those rules, click here to automaticaly load and save it into your account">
                    <Button size="small" type="primary">
                      Auto Import robots.txt
                    </Button>
                  </Tooltip>
                }
              />
              <Divider />
              <IgnoredUrlsSettings />
            </>
          )}
        </div>

        <div style={{ textAlign: 'center' }}>
          <ButtonGroup>
            <Button onClick={() => setOnboarding((current) => Math.max(current - 1, 1))}>Prev (dev)</Button>
            <Button onClick={() => setOnboarding((current) => current + 1)}>Next (dev)</Button>
          </ButtonGroup>
        </div>
      </Content>
    </>
  );
}
