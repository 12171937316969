import React from 'react';
import { Form, AutoComplete, Modal, Alert, theme } from 'antd';
import { debounce } from 'lodash';

import DomainKeywordsEdit from './DomainKeywordsEdit';
import { useLazyGetAvailableDomainsQuery } from './keywordsDomainsApi';
import { useEvent } from '../events/hooks/useEvent';

function NewDomainModal({
  loading = false,
  open = false,
  onClose,
  onCreate,
  existingDomains,
  usedKeywords,
  maxKeywords,
  planId,
}) {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const { track } = useEvent();
  const [getAvailableDomains, availableDomainsResult] = useLazyGetAvailableDomainsQuery();

  const onFinish = ({ keywords, domain }) => {
    onCreate({
      domain,
      keywords: keywords.split('\n').filter((keyword) => keyword.trim() !== ''),
    });
    track('Rank Tracker: New Domain Added', {
      subscription_plan: planId,
      domain,
      keywords_total_count: maxKeywords,
      keywords_used_count: usedKeywords + keywords.split('\n').length,
    });
  };

  const onSearch = React.useCallback(
    debounce((value) => {
      if (value.length > 2) getAvailableDomains({ query: value });
    }, 250),
    []
  );

  React.useEffect(() => {
    if (!open) form.resetFields();
    else getAvailableDomains();
  }, [open]);

  const validateDomain = (rule, value) => {
    const domainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    if (!domainRegex.test(value)) {
      return Promise.reject('Please enter a valid domain name!');
    }
    return Promise.resolve();
  };

  return (
    <Modal
      title="Add new domain"
      open={open}
      onCancel={onClose}
      okButtonProps={{ htmlType: 'submit', form: 'newDomain', loading }}
      okText="Add Domain"
      destroyOnClose
    >
      {availableDomainsResult.isError && (
        <Alert
          message="Failed to fetch available domains"
          type="error"
          showIcon
          style={{ marginBottom: token.marginSM }}
        />
      )}
      <Form layout="vertical" name="newDomain" form={form} onFinish={onFinish} disabled={loading}>
        <Form.Item
          label="Domain"
          name="domain"
          rules={[
            {
              required: true,
              message: 'Please enter a domain',
            },
            {
              validator: validateDomain,
            },
          ]}
        >
          <AutoComplete
            showSearch
            loading={availableDomainsResult.isFetching}
            options={availableDomainsResult.data
              ?.filter(({ domain }) => !existingDomains.includes(domain))
              .map(({ domain }) => ({ label: domain, value: domain }))}
            onSearch={onSearch}
          ></AutoComplete>
        </Form.Item>
        <DomainKeywordsEdit maxKeywords={maxKeywords} usedKeywords={usedKeywords} />
      </Form>
    </Modal>
  );
}

export default NewDomainModal;
