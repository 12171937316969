import { restApi } from '../../api/restApiSlice';

export const integrationWizardApiSlice = restApi.injectEndpoints({
  tagTypes: ['integrationDomain'],
  endpoints: (builder) => ({
    getDomain: builder.query({
      query: () => '/integration-wizard/domain',
    }),
    setDomain: builder.mutation({
      query: (domain) => ({
        url: '/integration-wizard/domain',
        method: 'POST',
        body: { domain },
      }),
      invalidatesTags: ['integrationDomain'],
    }),
    getDocumentationByIntegrationMethod: builder.query({
      query: (method) => `/integration-wizard/documentation/${method}`,
    }),
    getDomainStats: builder.query({
      query: (domain) => `/integration-wizard/domain/${domain}/stats`,
    }),
    getDomainSitemaps: builder.query({
      query: (domain) => `/integration-wizard/domain/${domain}/sitemaps`,
    }),
    getPreview: builder.query({
      query: (domain) => `/integration-wizard/domain/${domain}/preview`,
    }),
  }),
});

export const {
  useGetDomainQuery,
  useSetDomainMutation,
  useGetDocumentationByIntegrationMethodQuery,
  useGetDomainStatsQuery,
  useGetDomainSitemapsQuery,
  useGetPreviewQuery,
} = integrationWizardApiSlice;
