import { restApi } from '../../api/restApiSlice';

export const sitemapApi = restApi.injectEndpoints({
  tagTypes: ['Sitemap'],
  endpoints: (builder) => ({
    getSitemaps: builder.query({
      query: (page) => `/sitemap?page=${page}`,
      providesTags: (result, error, arg) => [
        'Sitemap',
        ...(result?.data ? result.data.map(({ id }) => ({ type: 'Sitemap', id })) : []),
      ],
    }),
    getSitemap: builder.query({
      query: (sitemapId) => `/sitemap/${sitemapId}`,
      providesTags: (result, error, sitemapId) => [{ type: 'Sitemap', id: sitemapId }],
      async onQueryStarted(sitemapId, { dispatch, queryFulfilled }) {
        // Update the sitemap list with the new sitemap data
        try {
          const { data: sitemapWithDetails } = await queryFulfilled;
          dispatch(
            sitemapApi.util.updateQueryData('getSitemaps', undefined, (draft) => {
              Object.assign(
                draft.find((s) => s.id === sitemapWithDetails.id),
                sitemapWithDetails
              );
            })
          );
        } catch {
          // No-op
        }
      },
    }),
    addSitemap: builder.mutation({
      query: (initialSitemap) => ({
        url: '/sitemap',
        method: 'POST',
        body: initialSitemap,
      }),
      invalidatesTags: ['Sitemap'],
    }),
    editSitemap: builder.mutation({
      query: (sitemap) => {
        const { id, enabled, revisitInterval, adaptiveType } = sitemap;
        return {
          url: `/sitemap/${id}`,
          method: 'PATCH',
          body: {
            enabled,
            revisitInterval,
            adaptiveType,
          },
        };
      },
      invalidatesTags: (result, error, sitemap) => [{ type: 'Sitemap', id: sitemap.id }],
    }),
    toggleAllSitemaps: builder.mutation({
      query: (enabled) => {
        return {
          url: `/sitemap/toggle-all/${enabled}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Sitemap'],
    }),
    deleteSitemap: builder.mutation({
      query: (sitemapId) => {
        return {
          url: `/sitemap/${sitemapId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, sitemapId) => [{ type: 'Sitemap', id: sitemapId }],
    }),
    reCacheSitemap: builder.mutation({
      query: ({ sitemapId, adaptiveType }) => {
        return {
          url: `/sitemap/${sitemapId}/crawl`,
          method: 'POST',
          body: {
            adaptiveType,
          },
        };
      },
      invalidatesTags: (result, error, sitemapId) => [{ type: 'Sitemap', id: sitemapId }],
    }),
  }),
});

export const {
  useGetSitemapsQuery,
  useAddSitemapMutation,
  useEditSitemapMutation,
  useToggleAllSitemapsMutation,
  useDeleteSitemapMutation,
  useGetSitemapQuery,
  useReCacheSitemapMutation,
} = sitemapApi;
