import Card from './onboardingCard';
import Modal from './onboardingModal';
import { MODAL_CANCALLED_ACTION, INTEGRATION_VERIFICATION_DONE } from './onboardingActions';
import { clearContent } from './redux/onboardingActions';

export default {
  Card,
  Modal,
  actions: {
    MODAL_CANCALLED_ACTION,
    INTEGRATION_VERIFICATION_DONE,
    clearContent,
  },
};
