import React, { useEffect, useMemo } from 'react';
import { Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Layout, Tabs, Typography, message } from 'antd';

import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import EventHistoryTab from './tabs/EventHistoryTab';
import EventDescriptionTab from './tabs/EventDescriptionTab';
import { useGetEventsQuery } from './oversightApiSlice';
import { FeatureFlag } from '../../hooks/useFeatureFlag';

const META_TAGS = {
  title: 'Oversight - Prerender.io',
};
const { Content } = Layout;
const { Title, Paragraph } = Typography;

const PAGES = {
  eventHistory: 'event-history',
  eventDescription: 'event-description',
};

const OversightPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeTab = useMemo(() => location.pathname.split('/').pop(), [location.pathname]);
  const { data: eventTypes, error: eventTypesError, isFetching: isFetchingEventTypes } = useGetEventsQuery();

  useEffect(() => {
    if (eventTypesError) {
      message.error('Error fetching events');
    }
  }, [eventTypesError?.data?.error]);

  const onTabClick = (tabKey) => navigate(tabKey);

  const tabs = [
    {
      key: PAGES.eventHistory,
      label: 'Event History',
      Component: EventHistoryTab,
    },
    {
      key: PAGES.eventDescription,
      label: 'Event Descriptions',
      Component: EventDescriptionTab,
    },
  ];

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Content>
        <Title level={2}>Oversight</Title>
        <Paragraph type="secondary">
          This section allows you to monitor notifications and messages from our system.
        </Paragraph>
        <Tabs activeKey={activeTab} onTabClick={onTabClick} items={tabs} />
        <Routes>
          {tabs.map((tab) => (
            <Route
              key={tab.key}
              path={tab.key}
              element={
                <tab.Component
                  eventTypes={eventTypes}
                  eventTypesError={eventTypesError}
                  loadingEventTypes={isFetchingEventTypes}
                />
              }
            />
          ))}
          <Route path="" element={<Navigate to={PAGES.eventHistory} />}></Route>
        </Routes>
      </Content>
    </AdminTemplate>
  );
};

function isAuthorized({ featureFlags }) {
  return featureFlags.includes(FeatureFlag.Oversight);
}

export default requireAuth(OversightPage, isAuthorized);
