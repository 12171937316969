import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Field } from 'react-final-form';
import { getContent, setLastErrorCode } from '../actions/pageActions';
import { ModalWrapper, ModalHeader, ModalBodyScroller } from './ModalLib';
import { ControlButton } from './PageElementsLib';
import { TWrapper, TRow, TCell, TSectionHeader, TRowSpacer } from './TableLib';
import ErrorLoader from './ErrorLoader';
import { Alert } from './CssFrameworkComponents';

const cid = 'CachedPagesEditor';

class CachedPagesEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customMessage: null,
    };
    this.callParentEventHandler = props.onEvent;
  }

  componentDidMount() {
    const { cm, getContent: doGetContent, language } = this.props;
    if (!cm) doGetContent({ component: 'CachedPagesEditor', lang: language });
  }

  componentWillUnmount() {
    this.props.setLastErrorCode('');
  }

  render() {
    const { cm, apiErrTxt, lastErrorCode, selectedUrls = [], cachedPages, prerenderUser } = this.props;
    const { customMessage } = this.state;
    if (!cm) return null;
    return (
      <ModalWrapper maxHeight={selectedUrls.length === 1 ? '600px' : '500px'}>
        <Form
          onSubmit={() => {}}
          initialValues={{ url: selectedUrls[0].url }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <ModalHeader
                modalHeaderRight={
                  <ControlButton
                    text={cm.header.btnRight}
                    onclick={() => this.callParentEventHandler({ action: 'modalCancelled' })}
                  />
                }
                title={
                  selectedUrls.length === 1 ? cm.header.title1 : cm.header.title.replace('{{amt}}', selectedUrls.length)
                }
              />
              <ModalBodyScroller>
                <TWrapper
                  tableId="cuedit"
                  parentType="modal"
                  showTHead={false}
                  cols={[
                    { size: 'min', valign: 'middle' },
                    { size: 'flex', float: 'right', valign: 'middle', addClass: 'text-secondary pr-0' },
                    { size: 'min', float: 'right', valign: 'middle' },
                  ]}
                >
                  <ErrorLoader code={lastErrorCode} custom={customMessage} scrollTop>
                    <TRow>
                      <TCell colspan="3" addClass={'text-center p-0'}>
                        <Alert
                          modal={true}
                          content={apiErrTxt[lastErrorCode] ? { text: apiErrTxt[lastErrorCode] } : customMessage}
                        />
                      </TCell>
                    </TRow>
                  </ErrorLoader>
                  {selectedUrls.length === 1 && <TSectionHeader text="URL" />}
                  {selectedUrls.length === 1 && (
                    <TRow>
                      <TCell colspan="3">
                        <Field name="url">
                          {({ input }) => (
                            <input {...input} type="text" className="w-100 no-border" id="url" readOnly={true} />
                          )}
                        </Field>
                      </TCell>
                    </TRow>
                  )}
                  {selectedUrls.length !== 1 && <TSectionHeader text={cm.triggerRecache} />}
                  {selectedUrls.length === 1 && <TSectionHeader text="Options" />}
                  {selectedUrls.length === 1 && (
                    // View cached HTML
                    <TRow>
                      <TCell colspan="3">
                        <div className="d-flex justify-content-center">
                          <a
                            type="button"
                            className="p-0 btn btn-normal btn-link link-blue"
                            href={`/raw-html?id=${selectedUrls[0].id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {cm.showHtml}
                          </a>
                        </div>
                      </TCell>
                    </TRow>
                  )}
                  {/* Add to recache queue */}
                  <TRow>
                    <TCell colspan="3">
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className="p-0 btn btn-normal btn-link link-blue"
                          onClick={() =>
                            this.callParentEventHandler({ action: 'requestRecache', payload: selectedUrls })
                          }
                          disabled={cachedPages.inProgress || prerenderUser.inProgress}
                        >
                          {cm.recacheQueue}
                        </button>
                      </div>
                    </TCell>
                  </TRow>
                  {selectedUrls.length !== 1 && <TSectionHeader text={cm.deleteHeader} />}
                  {/* Remove from cache */}
                  <TRow>
                    <TCell colspan="3">
                      <div className="d-flex justify-content-center">
                        <button
                          type="button"
                          className="p-0 btn btn-normal btn-link text-danger"
                          onClick={() => this.callParentEventHandler({ action: 'deleteUrls', payload: selectedUrls })}
                          disabled={cachedPages.inProgress || prerenderUser.inProgress}
                        >
                          {selectedUrls.length === 1
                            ? cm.remove.replace('{{amt}} URLs ', '')
                            : cm.remove.replace('{{amt}}', selectedUrls.length)}
                        </button>
                      </div>
                    </TCell>
                  </TRow>
                  <TRowSpacer />
                  <TRowSpacer />
                </TWrapper>
              </ModalBodyScroller>
            </form>
          )}
        />
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.page.language,
    cm: state.page.contentData[cid] || null,
    apiErrTxt: state.page.contentData.apiError,
    lastErrorCode: state.page.lastErrorCode,
    cachedPages: state.cachedPages.all,
    prerenderUser: state.prerenderUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getContent, setLastErrorCode }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CachedPagesEditor);
