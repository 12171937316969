import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getHashParam, setHashParam } from '../assets/hashParams';

/****************
 TWrapper component

  STANDARD table - not custom:
    showTHead: true,
    cols: [
      { size: 'min', name: 'Src' },
      { size: 'flex', name: 'Hallo', float: 'right' },
    ]
****************/

let isScrolling = false;
let hasMore;

const formatPageNumbers = (stats) => {
  const from = stats.page * stats.amtpp + 1;
  const to = stats.page * stats.amtpp + stats.amt;

  return from <= to ? `Results ${from} - ${to}` : ''; // This can happen when stats.amt === 0, so the last page is empty
};

class TWrapperComp extends Component {
  static defaultProps = {
    stats: {
      total: 1,
      amt: 1,
      pages: 0,
      page: 0,
      amtpp: 1,
    },
    onPageChange: () => {},
  };

  handleEvent(event) {
    if (event.type === 'hashchange') this.onHashChanged();
  }

  componentDidMount() {
    this.startScrollEventCronJob();
    // Q: Why not this.onHashChanged ? A: 'this' needs to be bound correctly
    // Q: Why not a this.onHashChanged.bind(this)? A: We need to be able to remove it
    // Q: Why not a lambda? A: We need to be able to remove it
    window.addEventListener('hashchange', this);
    TWrapperComp.writePageToHash(this.props.stats.page);
  }

  static writePageToHash(pageIndex) {
    const page = pageIndex + 1;
    setHashParam('page', page);
  }

  componentDidUpdate() {
    this.startScrollEventCronJob();
  }

  componentWillUnmount() {
    if (this.scrollEventCronJob) {
      clearInterval(this.scrollEventCronJob);
    }
    // $('.modal-body-scroller').unbind('scroll');
    const mbs = document.getElementsByClassName('modal-body-scroller');
    if (mbs) window.removeEventListener('scroll', mbs);

    window.removeEventListener('hashchange', this);
    setHashParam('page');
  }

  startScrollEventCronJob() {
    if (this.props.hasMore) {
      if (this.props.parentType && this.props.parentType === 'modal') {
        const mbs = document.getElementsByClassName('modal-body-scroller');
        if (mbs)
          mbs.addEventListener('scroll', () => {
            isScrolling = true;
          });
        this.checkScrollEvent();
      }
    } else {
      hasMore = false;
    }
  }

  checkScrollEvent() {
    const { onLoadMore } = this.props;
    hasMore = this.props.hasMore;
    if (!this.scrollEventCronJob) {
      this.scrollEventCronJob = setInterval(() => {
        if (isScrolling && hasMore) {
          isScrolling = false;
          const mbs = document.getElementsByClassName('modal-body-scroller');
          if (mbs && mbs.scrollTop() + mbs.innerHeight() >= mbs[0].scrollHeight - 20) {
            onLoadMore();
          }
        }
      }, 500);
    }
  }

  paginatorClicked(dir) {
    const { stats, onPageChange } = this.props;
    if (dir === 'down' && stats.page > 0) {
      onPageChange({ action: 'changePage', payload: stats.page - 1 });
      TWrapperComp.writePageToHash(stats.page - 1);
    } else if (dir === 'up' && stats.page < stats.pages) {
      onPageChange({ action: 'changePage', payload: stats.page + 1 });
      TWrapperComp.writePageToHash(stats.page + 1);
    }
  }

  onHashChanged() {
    const { onPageChange } = this.props;
    const pageStr = getHashParam('page', null);
    const pageInt = parseInt(pageStr, 10);
    const page = Math.max(1, pageInt) || 1; // ||1  - in case of pageInt is NaN Math.max returns with NaN

    const pageIndex = page - 1;
    TWrapperComp.writePageToHash(pageIndex);
    onPageChange({ action: 'changePage', payload: pageIndex });
  }

  // eslint-disable-next-line class-methods-use-this
  getOrderArrowIcon(col, index) {
    if (col.sort) {
      if (col.sort.index === index) {
        if (col.sort.asc) {
          return <i className="fa fa-sort-up"></i>;
        } else {
          return <i className="fa fa-sort-down"></i>;
        }
      } else {
        return <i className="fa fa-sort"></i>;
      }
    }

    return null;
  }

  onTHeadClicked(col, index) {
    if (this.props.onSortChanged) this.props.onSortChanged(col, index);
  }

  renderTHeadColumns() {
    const { tableId, cols } = this.props;
    if (cols.length > 0) {
      return cols.map((col, i) => {
        let thClass = col.size ? `text-sm col-${col.size}` : 'text-sm col-flex';
        thClass += col.hidden ? (col.hidden === 'sm' ? ' d-none d-md-block' : '') : '';
        thClass += col.addClass ? ` ${col.addClass}` : '';
        thClass += col.sort ? ' cursor-pointer' : '';
        return (
          <th
            scope="col"
            key={`th${tableId}${i}`}
            className={thClass}
            onClick={() => {
              this.onTHeadClicked(col, i);
            }}
          >
            {!col.thContent && col.name}
            {col.thContent && col.thContent}
            {this.getOrderArrowIcon(col, i)}
          </th>
        );
      });
    }
    return null;
  }

  getColProps(rowId, colId) {
    // needs also be changed in TCell
    const { tableId, cols } = this.props;
    const colProps = cols[colId];
    const key = `${tableId}r${rowId}td${colId}`;
    let tdClass = colProps.size ? `col-${colProps.size}` : 'col-flex';
    tdClass += rowId === 0 ? ' no-border' : '';
    tdClass += colProps.valign === 'middle' ? ' valign-middle' : '';
    tdClass += colProps.text === 'small' ? ' text-sm' : '';
    tdClass += colProps.addClass ? ` ${colProps.addClass}` : '';
    tdClass += colProps.hidden ? (colProps.hidden === 'sm' ? ' d-none d-md-block' : '') : '';
    let divClass = colProps.type === 'number' || colProps.float === 'right' ? ' d-flex justify-content-end' : '';
    divClass += colProps.float === 'center' ? ' d-flex justify-content-center' : '';
    return { key, colProps, tdClass, divClass };
  }

  renderLoadingCols() {
    const { loadingCols, nextPage, cols, maxLoadingRows } = this.props;
    if (!loadingCols) {
      return (
        <tr className="bg-white">
          <td colSpan={cols.length}>
            <div className="d-flex justify-content-center">
              <div className="loader loader-sm"></div>
            </div>
          </td>
        </tr>
      );
    } else {
      const max = nextPage === 1 || !nextPage ? maxLoadingRows || 6 : 1;
      const rows = [];
      const colSpan = loadingCols.length === 1 ? cols.length : 1; // allows definition of single column loading row
      for (let i = 0; i < max; i++) {
        rows.push(
          <tr className="bg-white" key={`lr${i}`}>
            {loadingCols.map((loadingCol, colId) => {
              const { key, tdClass, divClass } = this.getColProps(10000, colId);
              return (
                <td key={key} colSpan={colSpan} className={tdClass}>
                  <div className={divClass}>{loadingCol}</div>
                </td>
              );
            })}
          </tr>
        );
      }
      return rows;
    }
  }

  render() {
    const {
      tableId,
      showTHead = true,
      cardHeaderLeft,
      cardHeaderRight,
      cardHeaderCenter,
      parentType,
      tclass,
      stats,
      onPageChange,
      hideRows,
      hover,
      scrolling,
      isLoading,
      rowCount,
      nowrap,
      minHeader,
      rootClassName,
    } = this.props;
    let divClass = parentType === 'card' || parentType === 'modal' ? 'table-responsive mb-0' : 'table p-3';
    if (!scrolling) divClass += ' no-scrolling';
    let tableClass = parentType === 'card' || parentType === 'modal' ? 'table card-table' : 'table';
    tableClass += tclass ? ` ${tclass}` : '';
    tableClass += hover ? ' table-hover' : '';
    tableClass += nowrap ? ' table-nowrap' : '';
    const tbodyClass = parentType === 'card' || parentType === 'modal' ? 'list' : '';
    let childIndex = 0;
    const children = React.Children.map(this.props.children, (child) => {
      if (React.isValidElement(child)) {
        const clone = React.cloneElement(child, {
          tableId: tableId || '',
          cols: this.props.cols,
          // eslint-disable-next-line no-plusplus
          rowIndex: childIndex++,
          rowCount: rowCount || null,
        });
        return clone;
      }

      return undefined;
    });
    const firstSectionHeader =
      children && children.length > 0 && children[0].type.name === 'TSectionHeader' && hideRows ? children[0] : null; // makes a TSectionHeader is shown during loading

    return (
      <div id={`div${tableId}`} className={rootClassName}>
        {minHeader && <div className="card-header" style={{ height: '0px', padding: '0px' }} />}
        {(parentType === 'card' || parentType === 'modal') &&
          (cardHeaderLeft || cardHeaderRight || cardHeaderCenter) && (
            <div className="card-header" style={{ minHeight: '3rem' }}>
              <div className="row align-items-center" style={{ marginLeft: '-19px', marginRight: '-19px' }}>
                {cardHeaderLeft && (
                  <div className="col">
                    <h4 className="card-header-title">{cardHeaderLeft}</h4>
                  </div>
                )}
                {cardHeaderCenter && <div className="col text-center">{cardHeaderCenter || <div />}</div>}
                {cardHeaderRight && <div className="col-auto">{cardHeaderRight}</div>}
              </div>
            </div>
          )}
        <div id="tbody" className={divClass}>
          <table id={tableId} className={tableClass}>
            {showTHead && (
              <thead>
                <tr>{this.renderTHeadColumns()}</tr>
              </thead>
            )}
            <tbody className={`bg-white ${tbodyClass}`}>
              {firstSectionHeader}
              {!hideRows && children}
              {(hideRows || isLoading) && this.renderLoadingCols()}
            </tbody>
          </table>
        </div>
        {onPageChange && stats.pages > 0 && !isLoading && (
          <div className="card-footer d-flex justify-content-between">
            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
              <li className="page-item">
                {stats.page > 0 && (
                  <button
                    className="page-link pl-0 pr-4 border-right text-secondary"
                    onClick={() => this.paginatorClicked('down')}
                  >
                    <i className="fe fe-arrow-left mr-1"></i> Prev
                  </button>
                )}
                {stats.page <= 0 && <div className="mx-5" />}
              </li>
            </ul>
            <div className="pagination card-pagination">
              <div className="text-secondary mt-1">{formatPageNumbers(stats)}</div>
            </div>
            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
              <li className="page-item">
                {stats.page < stats.pages && (
                  <button
                    className="page-link pl-4 pr-0 border-left text-secondary"
                    onClick={() => this.paginatorClicked('up')}
                  >
                    Next <i className="fe fe-arrow-right ml-1"></i>
                  </button>
                )}
                {stats.page >= stats.pages && <div className="mx-5" />}
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const TWrapper = connect(mapStateToProps, mapDispatchToProps)(TWrapperComp);

/****************
 * TCell
 ****************/
const TCell = ({ children, tableId, cols, colIndex = 0, rowIndex, colspan = '1', addClass, addStyle, onclick }) => {
  const colProps = cols[colIndex];
  const key = `${tableId}r${rowIndex}td${colIndex}`;
  const singleCell = cols.length === parseInt(colspan, 10); // this row only has one cell
  // needs also be changed in TWrapper
  let tdClass = colProps.size
    ? !singleCell && colspan === '1' // cell is allowed to get it's '-min' property if it's colspan === 1
      ? `col-${colProps.size}`
      : 'col-flex'
    : 'col-flex';
  tdClass += rowIndex === 0 ? ' no-border' : '';
  tdClass += colProps.valign === 'middle' ? ' valign-middle' : '';
  tdClass += colProps.text === 'small' ? ' text-sm' : '';
  tdClass += colProps.hidden ? (colProps.hidden === 'sm' ? ' d-none d-md-block' : '') : '';
  tdClass += addClass ? ` ${addClass}` : '';
  tdClass += colProps.addClass ? ` ${colProps.addClass}` : '';
  tdClass += onclick ? ' cursor-pointer' : '';
  const divStyle = addStyle || {};
  let divClass =
    (colProps.type === 'number' || colProps.float === 'right') && !singleCell ? ' d-flex justify-content-end' : '';
  divClass += colProps.float === 'center' ? ' d-flex justify-content-center' : '';
  if (onclick) {
    return (
      <td key={key} colSpan={colspan} className={tdClass} onClick={onclick}>
        {(divClass !== '' || addStyle) && (
          <div className={divClass} style={divStyle}>
            {children}
          </div>
        )}
        {divClass === '' && !addStyle && children}
      </td>
    );
  } else {
    return (
      <td key={key} colSpan={colspan} className={tdClass}>
        {(divClass !== '' || addStyle) && (
          <div className={divClass} style={divStyle}>
            {children}
          </div>
        )}
        {divClass === '' && !addStyle && children}
      </td>
    );
  }
};

/*****************
TRow component
receives from parent (TWrapper):
this.props = { tableId, cols, rowIndex }
*******************/

// const TRow : React.StatelessComponent<{}> = ({ children, onclick, addClass, tableId, rowIndex, rowCount, cols }) => {
const TRow = ({ children, onclick, addClass, tableId, rowIndex, rowCount, cols }) => {
  let childIndex = 0;
  const childrenElems = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      // eslint-disable-next-line no-plusplus
      const clone = React.cloneElement(child, { tableId, rowIndex, rowCount, cols, colIndex: childIndex++ });
      return clone;
    }

    return undefined;
  });
  let trClass = onclick ? 'cursor-pointer' : '';
  trClass += addClass || '';
  if (onclick) {
    return (
      <tr onClick={onclick} className={trClass}>
        {childrenElems}
      </tr>
    );
  } else if (trClass) {
    return <tr className={trClass}>{childrenElems}</tr>;
  } else {
    return <tr>{childrenElems}</tr>;
  }
};

/*****************
TSectionHeader component
*******************/

const TSectionHeader = ({ cols, text = '', option = '', onOption = null, noUpper = false, paddingTop = '2.5rem' }) => (
  <tr>
    <td
      className={`bg-light pb-${option ? '0' : '2'} text-secondary no-border`}
      style={{ paddingTop }}
      colSpan={cols.length}
    >
      <div className="d-flex justify-content-between">
        <div className={`mt-${option ? '2' : '1'}`}>
          <small>{noUpper ? text : text.toUpperCase()}</small>
        </div>
        {option && (
          <div className="mb-1">
            <button className="p-0 mt-0 mr-2 no-border btn btn-link btn-sm" onClick={() => onOption && onOption()}>
              <small>{option.toUpperCase()}</small>
            </button>
          </div>
        )}
      </div>
    </td>
  </tr>
);

/*******************
TRowSpacer component
*******************/

const TRowSpacer = ({ cols, text = '', addClass = 'text-secondary', height = null }) => {
  const tdAddClass = text ? `pt-1 pb-1 ${addClass}` : `pt-3 ${addClass}`;
  return (
    <tr>
      <td className={`bg-light no-border ${tdAddClass}`} colSpan={cols.length} style={height ? { height } : {}}>
        {text && <small>{text}</small>}
      </td>
    </tr>
  );
};

export { TWrapper, TCell, TRow, TSectionHeader, TRowSpacer };
