import { SET_SHOW_SEO_SCORE } from './actionTypes';

const initialState = {
  showSEOScoreURLsOnly: false,
};

const urlFilterModalReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_SHOW_SEO_SCORE:
      return {
        ...state,
        showSEOScoreURLsOnly: payload,
      };

    default: return state;
  }
};

export default urlFilterModalReducer;
