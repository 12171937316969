import { restApi } from '../../api/restApiSlice';

export const gscApi = restApi.injectEndpoints({
  tagTypes: ['GscIntegration'],
  endpoints: (builder) => ({
    getGscIntegrationsStatus: builder.query({
      query: () => '/gsc-integrations/status',
      providesTags: ['GscIntegration'],
    }),
    getGscSitemaps: builder.query({
      query: () => `/gsc-integrations/sitemaps`,
      providesTags: (result, error) => [
        'GscIntegration',
        {
          userErrors: result.data.userErrors,
          items: result.data.items.map(({ url }) => ({ type: 'GscIntegration', id: url })),
        },
      ],
    }),
    importGscSitemap: builder.mutation({
      query: (dto) => ({
        url: '/gsc-integrations/sitemaps/imports',
        method: 'POST',
        body: dto,
      }),
      invalidatesTags: ['GscIntegration'],
    }),
  }),
});

export const { useGetGscIntegrationsStatusQuery, useGetGscSitemapsQuery, useImportGscSitemapMutation } = gscApi;
