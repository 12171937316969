// Define action creators that dispatch actions:
import config from '../assets/config';
import internalApi from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';
import { setLastErrorCode, clearLastErrorCode } from './pageActions';

import {
  CRAWLERSTATS_OVERVIEW_SET,
  CRAWLERSTATS_OVERVIEW_IN_PROGRESS,
  CRAWLERSTATS_OVERVIEW_SET_PARAMS,
  CRAWLERSTATS_RESPONSE_TIMES_SET,
  CRAWLERSTATS_RESPONSE_TIMES_IN_PROGRESS,
  CRAWLERSTATS_RESPONSE_TIMES_SET_PARAMS,
} from './types';

const getFilter = (params) => {
  let filter = '';
  // filter+= params.query ? `&query=${params.query}` : '';
  filter += params.statusCodeLow ? `&statusCodeLow=${params.statusCodeLow}` : '';
  filter += params.statusCodeHigh ? `&statusCodeHigh=${params.statusCodeHigh}` : '';
  filter += params.statusCodeEq ? `&statusCode=${params.statusCodeEq}` : '';
  filter += params.responseTimeLow ? `&responseTimeLow=${params.responseTimeLow}` : '';
  filter += params.responseTimeHigh ? `&responseTimeHigh=${params.responseTimeHigh}` : '';
  filter += params.timedout ? `&timedout=${params.timedout}` : '';
  filter += params.domain ? `&domain=${params.domain}` : '';
  // filter+= params.timePeriod ? `&timePeriod=${params.timePeriod}` : '';
  // filter+= params.cacheStatus ? `&cacheStatus=${params.cacheStatus}` : '';
  filter += params.userAgent ? `&userAgent=${encodeURIComponent(params.userAgent)}` : '';
  filter += params.adaptive_type ? `&adaptive_type=${params.adaptive_type}` : '';

  return filter;
};

export const crawlerStatsOverviewSearch = (params) => async (dispatch) => {
  try {
    dispatch({ type: CRAWLERSTATS_OVERVIEW_IN_PROGRESS, payload: { value: true } });
    dispatch({ type: CRAWLERSTATS_OVERVIEW_SET_PARAMS, payload: { params } });
    const filter = getFilter(params);
    const requestUri = `${config.apiUrl.dataServer}/api/overview-stats?${filter}`;
    const data = await internalApi('get', requestUri, null);
    dispatch({ type: CRAWLERSTATS_OVERVIEW_SET, payload: { data, params } });
    dispatch(clearLastErrorCode());
  } catch (error) {
    console.log(error);
    dispatch({ type: CRAWLERSTATS_OVERVIEW_IN_PROGRESS, payload: { value: false } });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};
export const crawlerStatsResponseTimeSearch = (params) => async (dispatch) => {
  try {
    dispatch({ type: CRAWLERSTATS_RESPONSE_TIMES_IN_PROGRESS, payload: { value: true } });
    dispatch({ type: CRAWLERSTATS_RESPONSE_TIMES_SET_PARAMS, payload: { params } });
    const filter = getFilter(params);
    const requestUri = `${config.apiUrl.dataServer}/api/timing-stats?${filter}`;
    const data = await internalApi('get', requestUri, null);
    dispatch({ type: CRAWLERSTATS_RESPONSE_TIMES_SET, payload: { data, params } });
    dispatch(clearLastErrorCode());
  } catch (error) {
    console.log(error);
    dispatch({ type: CRAWLERSTATS_RESPONSE_TIMES_IN_PROGRESS, payload: { value: false } });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};
