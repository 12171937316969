import { useRef, useState } from 'react';
import { useEvent } from '../features/events/hooks/useEvent';
import { usePrerenderUser } from '../hooks/usePrerenderUser';

const CopiablePre = ({ className, props, children, copyText, onTextCopied }) => {
  const [showCopyButton, setShowCopyButton] = useState(false);
  const { track } = useEvent();
  const user = usePrerenderUser();

  const codeRef = useRef(null);

  const copy = () => {
    navigator.clipboard.writeText(codeRef.current.innerText);
    onTextCopied();
    track('Integration Code Copied', { subscription_plan: user.chargebeePlanId });
  };

  return (
    <div
      className="position-relative"
      onMouseOver={() => setShowCopyButton(true)}
      onMouseLeave={() => setShowCopyButton(false)}
    >
      <pre
        ref={codeRef}
        className={className}
        {...props}
        onCopy={() => {
          track('Integration Code Copied', { subscription_plan: user.chargebeePlanId });
        }}
      >
        {children}
      </pre>
      {showCopyButton && (
        <button
          type="button"
          className="btn btn-outline-info btn-sm position-absolute top-0 end-0"
          style={{ top: 0, right: 0 }}
          onClick={copy}
        >
          {copyText}
        </button>
      )}
    </div>
  );
};

export default CopiablePre;
