import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card } from '../../../components/CssFrameworkComponents';
import { createHtmlContentFromCmText } from '../../../assets/contentFormatter';
import { ToggleControl } from '../../../components/PageElementsLib';
import { setPauseRecache } from '../redux/settingsActions';

const DisableRecacheCard = ({ cm, settings, prerenderUser, setPauseRecache: doSetPauseRecache }) => {
  useEffect(() => {
    doSetPauseRecache(prerenderUser.userPausedRecaching);
  }, [doSetPauseRecache, prerenderUser.userPausedRecaching]);

  const toggleRecaching = () => {
    doSetPauseRecache(!settings.recachePaused);
  };

  return (
    <Card title="Recaching">
      <div className="col">
        <div className="row">
          {cm.descriptions.map((description, i) => (
            <p key={i}>{description.map(createHtmlContentFromCmText)}</p>
          ))}
        </div>
        <div className="row">
          <span>
            <ToggleControl
              checked={settings.recachePaused}
              onclick={toggleRecaching}
              disabled={settings.inProgress}
              labelText={settings.recachePaused ? cm.button.text.inActive : cm.button.text.active}
              small
              inputAddClass="switch-danger"
            />
          </span>
        </div>
      </div>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    cm: {
      button: {
        text: {
          active: 'Automatic re-caching is active',
          inActive: 'Automatic re-caching is inactive',
        },
      },
      descriptions: [
        [
          {
            type: 'text',
            // eslint-disable-next-line max-len
            text: 'Turn this on to temporarily pause automatic re-caching. This is useful if your website has problems or is temporarily unavailable.',
          },
        ],
        [
          {
            type: 'text',
            text: "Caution: leaving this on for too long can affect your site's search engine rankings. Only use it briefly when needed.",
          },
        ],
      ],
    },
    prerenderUser: state.prerenderUser,
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setPauseRecache }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DisableRecacheCard);
