// Define action creators that dispatch actions:
import config from '../assets/config';
import internalApi from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';
import { setLastErrorCode, clearLastErrorCode } from './pageActions';

import {
  URLPARAMS_SET,
  URLPARAMS_IN_PROGRESS,
} from './types';

export const urlParamsSearch = (params) => async (dispatch) => {
  try {
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: true });
    let filter = '';
    filter += params.page ? `&page=${params.page}` : '';
    filter += params.pageSize ? `&pageSize=${params.pageSize}` : '';
    filter += params.sort ? `&sort=${params.sort}` : '';
    filter += params.query ? `&query=${params.query}` : '';
    const requestUri = `${config.apiUrl.dataServer}/api/url-parameters?${filter}`;
    const data = await internalApi('get', requestUri, null);
    dispatch({ type: URLPARAMS_SET, payload: { data, params } });
    dispatch(clearLastErrorCode());
  } catch (error) {
    console.log(error);
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: false });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

export const setUrlParam = (item, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: true });
    const requestUri = `${config.apiUrl.dataServer}/api/url-parameters`;
    await internalApi('put', requestUri, item);
    dispatch(clearLastErrorCode());
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: false });
    if (onSuccess) onSuccess(item);
  } catch (error) {
    console.log(error);
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: false });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

export const deleteUrlParams = (ids, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: true });
    const requestUri = `${config.apiUrl.dataServer}/api/v2/url-parameters`;
    await internalApi('delete', requestUri, { ids });
    dispatch(clearLastErrorCode());
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: false });
    if (onSuccess) onSuccess();
  } catch (error) {
    console.log(error);
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: false });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

// {id, ignore, value}
export const updateUrlParams = (urlParams, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: true });
    const requestUri = `${config.apiUrl.dataServer}/api/v2/url-parameters`;
    await internalApi('put', requestUri, { urlParams });
    dispatch(clearLastErrorCode());
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: false });
    if (onSuccess) onSuccess();
  } catch (error) {
    console.log(error);
    dispatch({ type: URLPARAMS_IN_PROGRESS, payload: false });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};
