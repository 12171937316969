const convertCentsToDollar = (cents) => {
  const value = parseFloat(cents);
  return Number.isNaN(value) ? '0' : value / 100;
};

export const formatCentsToDollarLocale = (cents) =>
  `$${convertCentsToDollar(cents).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}`;

export const formatCentsToDollar = (cents) => `$${convertCentsToDollar(cents).toFixed(2)}`;

export const formatToDollar = (dollars) =>
  dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
