import { USAGE_STATISTICS_IN_PROGRESS, USAGE_STATISTICS_UPDATED } from './actionTypes';
import internalApi from '../../../assets/internalApi';
import config from '../../../assets/config';
import { clearLastErrorCode, setLastErrorCode } from '../../../actions/pageActions';
import { getLocalErrorCode } from '../../../assets/lib';

const dateRegExp = /(?<year>\d+)-(?<month>\d+)-(?<day>\d+)/;

const convertUsageDataToLocalData = (d) => {
  const parsedDay = dateRegExp.exec(d.day);
  const year = parseInt(parsedDay.groups.year, 10);
  const monthIndex = parseInt(parsedDay.groups.month, 10) - 1;
  const day = parseInt(parsedDay.groups.day, 10);
  const time = new Date(year, monthIndex, day).getTime();
  return {
    number: d.number,
    time,
  };
};

const fillMissingDaysWithZero = (stat) => {
  const now = new Date();
  const days = [];
  for (let i = 0; i < 7; i++) {
    const sevenDaysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0, 0);
    days.push(new Date(sevenDaysAgo.setDate(sevenDaysAgo.getDate() + i)).getTime());
  }
  return days.map(
    (d) => ({ time: d, number: stat.find((s) => s.time === d)?.number || 0 }),
  );
};

export const getUsageStatisticsData = () => async (dispatch) => {
  try {
    const timezoneOffset = new Date().getTimezoneOffset();
    dispatch({ type: USAGE_STATISTICS_IN_PROGRESS, payload: { inProgress: true } });
    const requestUri = `${config.apiUrl.dataServer}/api/v2/usage-stats?timezoneOffset=${timezoneOffset}`;
    const data = await internalApi('get', requestUri, null);
    const dataWithLocalTimestamps = {
      crawlerVisits: fillMissingDaysWithZero(data.crawlerVisits.map(convertUsageDataToLocalData)),
      renderingUsage: fillMissingDaysWithZero(data.renderingUsage.map(convertUsageDataToLocalData)),
    };
    dispatch({ type: USAGE_STATISTICS_UPDATED, payload: dataWithLocalTimestamps });
    dispatch(clearLastErrorCode());
  } catch (error) {
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};
