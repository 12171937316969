import {
  VALIDATE_INTEGRATION_IN_PROGRESS,
  VALIDATION_FINISHED,
  CLEAR_VALIDATION_RESULT,
  INTEGRATION_CONTENT_LOADING,
  INTEGRATION_CONTENT_LOADED,
  STEP_CHANGED,
} from './actionTypes';
import config from '../../../assets/config';
import internalApi, { THROW_ON_ERROR_V2 } from '../../../assets/internalApi';
import { clearLastErrorCode, setLastErrorCode } from '../../../actions/pageActions';
import { getLocalErrorCode } from '../../../assets/lib';

const CONTENT_PATH = '/assets/markdown/onboarding/integrationContents/';

export const validateIntegration = (url) => async (dispatch) => {
  try {
    dispatch({ type: VALIDATE_INTEGRATION_IN_PROGRESS, payload: true });
    const requestUri = `${config.apiUrl.dataServer}/api/v1/check-integration?url=${encodeURIComponent(url)}`;
    const result = await internalApi('get', requestUri, undefined, THROW_ON_ERROR_V2);
    dispatch(clearLastErrorCode());
    dispatch({ type: VALIDATION_FINISHED, payload: { validationResult: result, url } });
    return result;
  } catch (error) {
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  } finally {
    dispatch({ type: VALIDATE_INTEGRATION_IN_PROGRESS, payload: false });
  }
};

export const clearIntegrationResult = () => (dispatch) => {
  dispatch({ type: CLEAR_VALIDATION_RESULT });
};

const loadedContent = {};
export const fetchContent = (integration) => async (dispatch) => {
  dispatch({ type: INTEGRATION_CONTENT_LOADING });
  let integrationContent = null;
  if (integration) {
    integrationContent = loadedContent[integration];
    if (!integrationContent) {
      const response = await fetch(`${CONTENT_PATH + integration.toLowerCase()}.md`);
      integrationContent = await response.text();
      loadedContent[integration] = integrationContent;
    }
  }
  dispatch({ type: INTEGRATION_CONTENT_LOADED, payload: { integration, content: integrationContent } });
};
export const clearContent = () => ({ type: INTEGRATION_CONTENT_LOADED, payload: { integration: null, content: null } });

export const setStep = (step) => ({ type: STEP_CHANGED, payload: step });
