import {
  KEYCLOAK_AUTH_SUCCESS,
  KEYCLOAK_AUTH_ERROR,
  KEYCLOAK_IN_PROGRESS,
} from '../actions/types';

const initialState = {
  inProgress: false,
  error: null,
  loggedIn: false,
};

export default function keycloakReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case KEYCLOAK_IN_PROGRESS: return { ...state, inProgress: true, error: null };
    case KEYCLOAK_AUTH_SUCCESS: return { ...state, loggedIn: true, inProgress: false, error: null };
    case KEYCLOAK_AUTH_ERROR: return { ...state, loggedIn: false, inProgress: false, error: payload };
    default:
  }

  return state;
}
