import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SitemapListPage from './SitemapListPage';
import SitemapDetailsPage from './SitemapDetailsPage';

export default function SitemapRouter() {
  return (
    <Routes>
      <Route path="" element={<SitemapListPage />}></Route>
      <Route path={`map/:id`} element={<SitemapDetailsPage />}></Route>
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
}
