import { theme } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function CacheAgeChart({ lessOneDay, gOneLSevenDays, gSevenLThirtyDays, gThirtyDays }) {
  const { token } = theme.useToken();

  const data = [
    {
      name: '< 1 day',
      amount: lessOneDay,
    },
    {
      name: '1-7 days',
      amount: gOneLSevenDays,
    },
    {
      name: '7-30 days',
      amount: gSevenLThirtyDays,
    },
    {
      name: '> 30 days',
      amount: gThirtyDays,
    },
  ];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="4 4" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="amount" fill={token.colorPrimary} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default CacheAgeChart;
