import React, { useState } from 'react';
import { Modal, Select, Alert, Form, Flex, Input } from 'antd';

import { HOURS_IN_SECONDS, arrayToAdaptiveType, hoursToString } from '../sitemapUtil';
import DeviceTypeSelector from '../../../components/DeviceTypeSelector';

const REVISIT_INTERVALS = [168, 24, 8, 1].map((value) => ({
  value: value * HOURS_IN_SECONDS,
  label: hoursToString(value),
}));

const ImportSitemapModal = ({ open, onImport, onClose, errorMsg, confirmLoading, mobileAdaptive }) => {
  const [sitemapUrl, setSitemapUrl] = useState('');
  const [adaptiveTypes, setAdaptiveTypes] = useState([]);
  const [revisitInterval, setRevisitInterval] = useState(REVISIT_INTERVALS[0].value);

  const onSubmit = () => {
    onImport({
      sitemapUrl,
      adaptiveType: arrayToAdaptiveType(adaptiveTypes, mobileAdaptive),
      revisitInterval,
    });
  };

  return (
    <Modal
      closable
      title="Import sitemap"
      open={open}
      okText="Import"
      okButtonProps={{ htmlType: 'submit', form: 'importSitemap' }}
      onCancel={onClose}
      cancelText="Cancel"
      confirmLoading={confirmLoading}
    >
      <Form
        name="importSitemap"
        layout="vertical"
        onFinish={onSubmit}
        initialValues={{ revisitInterval: REVISIT_INTERVALS[0].value }}
      >
        <Form.Item
          name="sitemapUrl"
          label="Sitemap URL"
          tooltip="Please provide a valid url to a sitemap.xml file."
          rules={[
            {
              required: true,
              message: 'Please provide a valid url',
            },
          ]}
        >
          <Input
            placeholder="https://example.com/sitemap.xml"
            type="url"
            value={sitemapUrl}
            onChange={(event) => setSitemapUrl(event.target.value)}
          ></Input>
        </Form.Item>

        <Flex gap={32}>
          <Form.Item name="deviceType" label="Device type">
            <DeviceTypeSelector
              mobileAdaptive={mobileAdaptive}
              setAdaptiveTypes={setAdaptiveTypes}
            ></DeviceTypeSelector>
          </Form.Item>

          <Form.Item name="revisitInterval" label="Revisit interval">
            <Select options={REVISIT_INTERVALS} onChange={setRevisitInterval}></Select>
          </Form.Item>
        </Flex>

        {errorMsg && <Alert showIcon type="error" message={errorMsg} />}
      </Form>
    </Modal>
  );
};

export default ImportSitemapModal;
