import USER_ROLES from '../auth/userRoles';

export const AppUserRoles = {
  [USER_ROLES.SUPER_ADMIN]: 'Account Owner',
  [USER_ROLES.ADMIN]: 'Admin',
  [USER_ROLES.BILLING_MANAGER]: 'Billing Manager',
  [USER_ROLES.TEAM_MEMBER]: 'Team Member',
  [USER_ROLES.GUEST]: 'Guest',
};

export const userRoleOptions = Object.keys(AppUserRoles).map((role) => ({
  value: role,
  label: AppUserRoles[role],
}));

export function getLowerTierRoles(userRole) {
  switch (userRole) {
    // Super Admin can only create Account Owner, Admins, Billing Managers, Team Members and Guests
    case USER_ROLES.SUPER_ADMIN:
      return userRoleOptions;

    // Admin can only create Admins, Billing Managers, Team Members and Guests
    case USER_ROLES.ADMIN:
      return userRoleOptions.filter((role) => role.value !== USER_ROLES.SUPER_ADMIN);

    // Billing Manager can only create other Billing Managers and Guests
    case USER_ROLES.BILLING_MANAGER:
      return userRoleOptions.filter(
        (role) => role.value === USER_ROLES.GUEST || role.value === USER_ROLES.BILLING_MANAGER
      );

    // Team Member can only create other Team Members and Guests
    case USER_ROLES.TEAM_MEMBER:
      return userRoleOptions.filter((role) => role.value === USER_ROLES.GUEST || role.value === USER_ROLES.TEAM_MEMBER);

    // Guests can't create any other roles
    case USER_ROLES.GUEST:
    default:
      return [];
  }
}
