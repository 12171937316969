import { useState, useCallback } from 'react';
import { debounce } from 'lodash';

const useTableFilters = (filterKey, initialParams) => {
  const [tableParams, setTableParams] = useState(initialParams);
  const [searchInput, setSearchInput] = useState('');

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((oldParams) => ({
      ...oldParams,
      pagination: { ...oldParams.pagination, ...pagination },
      filters: { ...oldParams.filters, ...filters },
      sorter,
    }));
  };

  const debouncedSetTableParams = useCallback(
    debounce((newInput) => {
      setTableParams((oldParams) => ({
        ...oldParams,
        pagination: { ...oldParams.pagination, current: 1 },
        filters: { ...oldParams.filters, [filterKey]: [newInput] },
      }));
    }, 1500),
    [filterKey]
  );

  const onInputChange = (e) => {
    const newInput = e.target.value;
    setSearchInput(newInput);
    debouncedSetTableParams(newInput);
  };

  return {
    tableParams,
    searchInput,
    handleTableChange,
    onInputChange,
  };
};

export default useTableFilters;
