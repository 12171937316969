import { Flex, Typography, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph, Link } = Typography;

const SUCCESS_IMAGE = '/assets/img/onboarding/integrationSuccessful.svg';

const VerificationSuccessfulStep = ({ onGoNext }) => {
  const navigate = useNavigate();

  const onClickGoToDashboard = () => navigate('/');

  return (
    <Flex vertical align="center" justify="center" gap="middle" style={{ minHeight: 450 }}>
      <img src={SUCCESS_IMAGE} alt="success state" style={{ maxHeight: '75px', maxWidth: '75px' }} />
      <Title level={3} style={{ marginBottom: 0 }}>
        Success!
      </Title>
      <div style={{ maxWidth: 450, textAlign: 'center' }}>
        <Paragraph>
          Your Prerender integration is up and running. We're ready to start rendering your pages for search engine
          bots.
        </Paragraph>
        <Title level={4}>Unlock Your Website's Full Potential Now!</Title>
        <Paragraph>
          Your website can achieve more with Prerender. Unlock all the benefits you and your website deserve.
        </Paragraph>
      </div>
      <Button type="primary" onClick={onGoNext}>
        Upgrade Now
      </Button>
      <Link type="primary" onClick={onClickGoToDashboard}>
        Go to Dashboard
      </Link>
    </Flex>
  );
};

export default VerificationSuccessfulStep;
