import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContent, setLastErrorCode } from '../actions/pageActions';
import { ModalWrapper, ModalHeader } from './ModalLib';
import { TWrapper, TRow, TCell, TRowSpacer } from './TableLib';
import ErrorLoader from './ErrorLoader';
import { Button, Alert } from 'antd';

const cid = 'CachedPagesExport';

class CachedPagesExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customMessage: null,
    };
    this.callParentEventHandler = props.onEvent;
  }

  componentDidMount() {
    const { cm, getContent: doGetContent, language } = this.props;
    if (!cm) doGetContent({ component: cid, lang: language });
  }

  componentWillUnmount() {
    this.props.setLastErrorCode('');
  }

  render() {
    const { cm, apiErrTxt, lastErrorCode, selectedUrls = [], cachedPages, prerenderUser } = this.props;
    const { customMessage } = this.state;
    const amt = selectedUrls.length || 'all';
    if (!cm) return null;
    return (
      <ModalWrapper maxHeight={'380px'}>
        <ModalHeader
          modalHeaderLeft={
            <Button onClick={() => this.callParentEventHandler({ action: 'modalCancelled' })}>
              {cm.header.btnLeft}
            </Button>
          }
          modalHeaderRight={<div className="mx-4" />}
          title={selectedUrls.length === 1 ? cm.header.title1 : cm.header.title.replace('{{amt}}', amt)}
        />
        <TWrapper tableId="cuexport" parentType="modal" showTHead={false} cols={[{ size: 'flex', valign: 'middle' }]}>
          <ErrorLoader code={lastErrorCode} custom={customMessage} scrollTop>
            <TRow>
              <TCell colspan="3" addClass={'p-0'}>
                <Alert
                  style={{ margin: 8 }}
                  showIcon
                  type="error"
                  message={apiErrTxt[lastErrorCode] ? apiErrTxt[lastErrorCode] : customMessage?.text}
                />
              </TCell>
            </TRow>
          </ErrorLoader>

          <TRow>
            <TCell colspan="3">
              <p style={{ textAlign: 'center' }}>{cm.section.replace('{{amt}}', amt)}</p>
              <div className="d-flex justify-content-center">
                <Button
                  type="link"
                  className="p-0"
                  onClick={() => this.callParentEventHandler({ action: 'exportCsv', payload: selectedUrls })}
                  disabled={cachedPages.inProgress || prerenderUser.inProgress}
                >
                  {cm.csv}
                </Button>
              </div>
            </TCell>
          </TRow>
          <TRow>
            <TCell colspan="3">
              <div className="d-flex justify-content-center">
                <Button
                  type="link"
                  className="p-0"
                  onClick={() => this.callParentEventHandler({ action: 'exportSitemap', payload: selectedUrls })}
                  disabled={cachedPages.inProgress || prerenderUser.inProgress}
                >
                  {cm.sitemap}
                </Button>
              </div>
            </TCell>
          </TRow>
          <TRowSpacer height={'140px'} />
        </TWrapper>
      </ModalWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.page.language,
    cm: state.page.contentData[cid] || null,
    apiErrTxt: state.page.contentData.apiError,
    lastErrorCode: state.page.lastErrorCode,
    cachedPages: state.cachedPages.all,
    prerenderUser: state.prerenderUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getContent, setLastErrorCode }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CachedPagesExport);
