import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  LogarithmicScale,
  Title,
  Tooltip,
} from 'chart.js';

// public functions:
const lineChart = ({ color = 'success', data = [], yAxisID, label = null }) => {
  let c = {};
  switch (color) {
    case 'alert':
      c = { color: '#d16759', bg: '#d16759' };
      break;
    case 'warning':
      c = { color: '#feeb6b', bg: '#feeb6b' };
      break;
    case 'success':
    default:
      c = { color: '#4bc69a', bg: '#4bc69a' };
      break;
  }
  return {
    label: label || c.color, // acts a react key
    fill: false,
    lineTension: 0.1,
    backgroundColor: c.bg,
    borderColor: c.color,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: c.color,
    pointBackgroundColor: c.color,
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: c.color,
    pointHoverBorderColor: c.color,
    pointHoverBorderWidth: 0,
    pointRadius: 1,
    pointHitRadius: 10,
    data,
    yAxisID,
  };
};

const barChart = ({ color = 'success', data = [], label = null }) => {
  let c = {};
  switch (color) {
    case 'alert':
      c = { color: '#d16759', bg: '#f6e1de' };
      break;
    case 'warning':
      c = { color: '#feeb6b', bg: '#fffbe1' };
      break;
    case 'success':
    default:
      c = { color: '#4bc69a', bg: '#dbf4eb' };
      break;
  }
  return {
    label: label || c.color, // acts a react key
    backgroundColor: c.color,
    borderColor: c.color,
    borderDashOffset: 0.0,
    pointBorderColor: c.color,
    pointBackgroundColor: c.color,
    data,
  };
};

const diffInMonths = (d1, d2) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

const getOldestTimeStamp = (lines) => {
  // find oldest timestamp within all datasets:
  let oldestTs = null;
  for (let i = 0; i < lines.length; i++) {
    if (lines[i].source && lines[i].source.length > 0) {
      oldestTs = lines[i].source[0].time;
      break;
    }
  }
  if (oldestTs === null) return null;
  lines.forEach((line) => {
    const r0 = line.source[0];
    if (r0 && r0.time < oldestTs) oldestTs = r0.time;
  });
  return oldestTs;
};

const getLabelTimeStampsByPeriodStr = (oldestTimeStamp, periodStr) => {
  const now = new Date();
  const tsNow = Math.round(now.getTime());
  const labels = [];
  let interval = 'days';

  switch (periodStr) {
    case 'last24h':
      for (let i = 1; i < 25; i++) {
        const rawDate = new Date(tsNow - (24 - i) * 3600 * 1000);
        labels.push(
          new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), rawDate.getHours(), 0, 0, 0).getTime()
        );
      }
      interval = 'hours';
      break;
    case 'last48h':
      for (let i = 1; i < 49; i++) {
        const rawDate = new Date(tsNow - (48 - i) * 3600 * 1000);
        labels.push(
          new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), rawDate.getHours(), 0, 0, 0).getTime()
        );
      }
      interval = 'days+hours';
      break;
    case 'yesterday':
      for (let i = 0; i < 24; i++) {
        const yesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
        const rawDate = new Date(yesterday.setHours(yesterday.getHours() + i));
        labels.push(
          new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), rawDate.getHours(), 0, 0, 0).getTime()
        );
      }
      interval = 'hours';
      break;
    case 'last7d':
      for (let i = 0; i < 7; i++) {
        const sevenDaysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0, 0);
        const rawDate = new Date(sevenDaysAgo.setDate(sevenDaysAgo.getDate() + i));
        labels.push(
          new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), rawDate.getHours(), 0, 0, 0).getTime()
        );
      }
      break;
    case 'lastWeek':
      for (let i = 0; i < 7; i++) {
        const beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000);
        const day = beforeOneWeek.getDay();
        const diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1);
        const lastMonday = new Date(beforeOneWeek.setDate(diffToMonday));
        const rawDate = new Date(lastMonday.setDate(lastMonday.getDate() + i));
        labels.push(new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), 0, 0, 0, 0).getTime());
      }
      break;
    case 'last30d':
      for (let i = 0; i < 30; i++) {
        const rawDate = new Date(tsNow - (30 - i) * 24 * 3600 * 1000);
        labels.push(new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), 0, 0, 0, 0).getTime());
      }
      break;
    case 'currMonth': {
      const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
      for (let i = 0; i < lastDayOfMonth; i++) {
        const rawDate = new Date(now.getFullYear(), now.getMonth(), 1 + i);
        labels.push(new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), 0, 0, 0, 0).getTime());
      }
      break;
    }
    case 'lastMonth': {
      const lastDayOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0).getDate();
      for (let i = 0; i < lastDayOfLastMonth; i++) {
        const rawDate = new Date(now.getFullYear(), now.getMonth() - 1, 1 + i);
        labels.push(new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), 0, 0, 0, 0).getTime());
      }
      break;
    }
    default:
      break;
  }
  return [labels, interval];
};

const getLabelTimeStampsByData = (oldestTimeStamp) => {
  // no time period parameter provided -> calculate it. Start with oldest timestamp in dataset, end with current time:
  if (!oldestTimeStamp) return [[], []];
  const oldestDate = new Date(oldestTimeStamp);
  const diffInDays = Math.round((new Date().getTime() - oldestTimeStamp) / (60000 * 60 * 24));
  const interval = diffInDays > 31 ? 'months' : diffInDays > 1 ? 'days' : 'hours';
  const labels = [];
  switch (interval) {
    case 'months': {
      const months = diffInMonths(oldestDate, new Date());
      for (let i = 0; i <= months; i++) {
        const rawDate = new Date(oldestDate.getFullYear(), oldestDate.getMonth() + i);
        labels.push(new Date(rawDate.getFullYear(), rawDate.getMonth(), 1, 0, 0, 0, 0).getTime());
      }
      break;
    }
    case 'days':
      for (let i = 0; i <= diffInDays; i++) {
        const rawDate = new Date(oldestDate.getFullYear(), oldestDate.getMonth(), oldestDate.getDate() + i);
        labels.push(new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), 0, 0, 0, 0).getTime());
      }
      break;
    case 'hours':
      for (let i = 1; i < 25; i++) {
        const rawDate = new Date(new Date().getTime() - (24 - i) * 3600 * 1000);
        labels.push(
          new Date(rawDate.getFullYear(), rawDate.getMonth(), rawDate.getDate(), rawDate.getHours(), 0, 0, 0).getTime()
        );
      }
      break;
    default:
      break;
  }
  return [labels, interval];
};

const getDataSets = (labels = [], lines) => {
  const sets = lines.map((line) =>
    labels
      .map((label) =>
        line.source && line.source.find((r) => r.time === label) ? line.source.find((r) => r.time === label).number : 0
      )
      .map((item) => Math.round(item * 1000) / 1000)
  );
  return sets;
};

const getLabelStrings = (labelTimeStamps = [], interval) => {
  let format = {};
  switch (interval) {
    case 'hours':
      format = { hour: 'numeric', hour12: true };
      break;
    case 'days+hours':
      format = { hour: 'numeric', day: 'numeric', hour12: true };
      break;
    case 'days':
      format = { month: 'short', day: '2-digit' };
      break;
    case 'months':
      format = { month: 'short', year: 'numeric' };
      break;
    default:
      break;
  }
  return labelTimeStamps.map((ts) => new Date(ts).toLocaleString('en-US', format));
};

const mapDataSets = (chartType, datasets, dataPoints) => {
  switch (chartType) {
    case 'line-double-axis':
      return datasets.map((dataset, index) =>
        lineChart({ label: dataset.label, color: dataset.color, data: dataPoints[index], yAxisID: `y${index}` })
      );
    case 'line':
      return datasets.map((dataset, index) =>
        lineChart({ label: dataset.label, color: dataset.color, data: dataPoints[index] })
      );
    case 'area':
      return datasets.map((dataset, index) => {
        const data = lineChart({ label: dataset.label, color: dataset.color, data: dataPoints[index] });
        data.fill = true;

        return data;
      });
    default:
      return datasets.map((dataset, index) =>
        barChart({ label: dataset.label, color: dataset.color, data: dataPoints[index] })
      );
  }
};

const getChartData = (chartType, timePeriod, datasets) => {
  // dataSets = [{ source: Array of datapoints, 'label: 'Status Code 200', color: 'success' }]
  // each datasets entry contains the datapoints for one line- or bar diagram.
  const requestedTimePeriod = timePeriod || 'any';
  const oldestTimeStamp = getOldestTimeStamp(datasets);
  const [labelTimeStamps, interval] =
    requestedTimePeriod === 'any'
      ? getLabelTimeStampsByData(oldestTimeStamp)
      : getLabelTimeStampsByPeriodStr(oldestTimeStamp, requestedTimePeriod);
  const labels = getLabelStrings(labelTimeStamps, interval);
  const dataPoints = getDataSets(labelTimeStamps, datasets);

  return {
    labels,
    datasets: mapDataSets(chartType, datasets, dataPoints),
  };
};

export { lineChart, barChart, getChartData };

export function initChartLibrary() {
  ChartJS.register(
    BarElement, // Elements
    LineElement,
    PointElement,

    CategoryScale, // Scales
    LinearScale,
    LogarithmicScale,

    Title, // Other components
    Filler,
    Tooltip,
    Legend
  );
}
