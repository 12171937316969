import { restApi } from '../../api/restApiSlice';

const api = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getPhoneNumber: builder.query({
      query: () => ({
        url: 'my-account/my-phone',
        method: 'GET',
      }),
      transformErrorResponse: (response) => response.data,
      providesTags: ['UserPhoneNumber'],
    }),
    setAdminEmail: builder.mutation({
      query: ({ adminEmail, companyName }) => ({
        url: 'my-account/admin-email',
        method: 'POST',
        body: { adminEmail, companyName },
      }),
      transformErrorResponse: (response) => response.data,
    }),
    setUserDetails: builder.mutation({
      query: ({ email, firstName, lastName, phoneNumber }) => ({
        url: 'my-account/user-details',
        method: 'POST',
        body: { email, firstName, lastName, phoneNumber: phoneNumber?.length > 0 ? phoneNumber : null },
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: ['UserPhoneNumber'],
    }),
  }),
});

export const { useSetAdminEmailMutation, useSetUserDetailsMutation, useGetPhoneNumberQuery } = api;
