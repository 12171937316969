import { restApi } from '../api/restApiSlice';

const getStartedCheckerApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    setGetStartedProgress: builder.mutation({
      query: (body) => ({ url: `/getting-started/steps`, method: 'PATCH', body }),
    }),
  }),
});

export const { useSetGetStartedProgressMutation } = getStartedCheckerApiSlice;
export const { setGetStartedProgress } = getStartedCheckerApiSlice.endpoints;
