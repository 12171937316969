import {
  INVITATION_IN_PROGRESS,
  INVITATION_SUCCEEDED,
  INVITATION_FAILED,
  INVITATION_CLEAR,
} from '../actions/types';

const emptyState = {
  inProgress: false,
  invitationSentTo: null,
  invitationError: null,
};

const initialState = emptyState;

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case INVITATION_IN_PROGRESS:
      return {
        ...emptyState,
        inProgress: true,
      };
    case INVITATION_SUCCEEDED:
      return { ...state,
        inProgress: false,
        invitationSentTo: payload,
        invitationError: null,
      };
    case INVITATION_FAILED:
      return { ...state,
        inProgress: false,
        invitationSentTo: null,
        invitationError: payload,
      };
    case INVITATION_CLEAR:
      return initialState;
    default:
      break;
  }
  return state;
};
