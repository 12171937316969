import './RenderedHtmlStyle.css';

function stripStyles(html) {
  return html.replace(/\s*style="[^"]*"/g, '');
}

function RenderedHtml({ html }) {
  if (!html) return null;
  return <div id="html-from-server" dangerouslySetInnerHTML={{ __html: stripStyles(html) }} />;
}

export default RenderedHtml;
