// The old backend endpoints doesn't follow the new EXXXX format error codes.
// This function translates the error messages to the new error codes
const parseBackendError = (error) => {
  if (error && error.message) {
    switch (error.message) {
      // Cached pages
      case 'Please verify your email before you can add urls': return 'E0274';
      default: // Do nothing, give a chance to the other branch
    }
  }
  switch (error) {
    // Email change flow
    case 'You must supply a new email and your current password.': return 'E0004';
    case 'Unable to find your user. Please contact support@prerender.io': return 'E0245';
    case 'Invalid Password': return 'E0241';
    case 'That email address is already in use.': return 'E0220';
    case 'That email doesn\'t look like a valid email address.': return 'E0200';
    case 'Unable to update your email. Please contact support@prerender.io': return 'E0001';
    // Change API token
    // This is the same as the invalid password in email change flow but I leave it here because the change api token flow can emit it too
    // case 'Invalid Password': return 'E0241';
    case 'You must supply your current password.': return 'E0004';
    // Email verification
    case 'Wrong email verification token. Please contact support@prerender.io': return 'E0275';
    //
    default: return null;
  }
};

export default parseBackendError;
