import { restApi } from '../api/restApiSlice';

const customerSupportApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerSupport: builder.query({
      query: (id) => ({ url: `/csm-contacts/${id}`, method: 'GET' }),
      providesTags: ['customerSupport'],
    }),
    invalidatesTags: ['customerSupport'],
  }),
});

export const { useGetCustomerSupportQuery } = customerSupportApiSlice;
