import Cookies from 'universal-cookie';
import parseBackendError from './backend_errors_to_error_code';
import config from './config';

// public functions:
const isSet = (subject) => {
  // returns true if subject is !== null and !== undefined:
  if (typeof subject === 'undefined') return false;
  if (subject === null) return false;
  return true;
};

const isString = (data) => Object.prototype.toString.call(data) === '[object String]';

const clone = (nestedJson) => {
  // return a real copy of nestedJson
  if (!nestedJson) return null;
  return JSON.parse(JSON.stringify(nestedJson));
};

const getUrlValuesFromString = (urlString) => {
  const returnObj = {
    path: '',
    dir: [],
    query: '',
    param: {},
    hash: '',
  };
  if (!urlString) return returnObj;
  const [urlStr, hash] = urlString.split('#');
  if (hash) returnObj.hash = hash;
  const sr = urlStr.split('?');
  let pathName = sr.length > 0 ? sr[0] : '';
  const paramStr = sr.length > 1 ? sr[1] : '';
  if (pathName.substring(pathName.length - 1) === '/') {
    pathName = pathName.substring(0, pathName.length - 1); // remove trailing '/'
  }
  returnObj.uri = `${pathName}?${paramStr}`;
  returnObj.path = pathName;
  returnObj.query = paramStr;
  returnObj.dir = pathName.indexOf('/') === 0 ? pathName.substr(1).split('/') : pathName.split('/');
  // determine param list: ?show=account ==> param['show'] = 'account'
  if (paramStr) {
    const params = paramStr.split('&');
    params.forEach((item) => {
      const pair = item.split('=');
      // eslint-disable-next-line prefer-destructuring
      returnObj.param[pair[0]] = pair[1];
    });
  }
  return returnObj;
};

const getUrlValuesFromLocation = (location) =>
  getUrlValuesFromString(location.pathname + location.search + location.hash);

const removeTrailingString = (subject, trailer) => {
  if (
    subject.length >= trailer.length &&
    subject.substring(subject.length - trailer.length, subject.length) === trailer
  ) {
    return subject.substring(0, subject.length - trailer.length);
  }
  return subject;
};

const removeTrailingHttps = (url) => {
  return removeTrailingString(url.replace('https://', '').replace('http://', ''), '/');
};

const getLocalErrorCode = (error) => {
  if (error) {
    if (error.code) return error.code;
    if (error.error) return error.error.code;
    if (error.response && error.response.data && error.response.data.error) {
      return error.response.data.error.code;
    }

    if (
      error.response &&
      error.response.data &&
      isString(error.response.data) &&
      error.response.data.trim() === 'Please input your billing information'
    ) {
      // TODO: Ugly - update the backend
      return 'E1008';
    }
    if (error.message) {
      if (error.message.trim() === 'Network Error') return 'E0000';
      if (error.message.indexOf('401') > 0) return 'E0005';
    }
    const backendError = parseBackendError(error);
    if (backendError) return backendError;
  }
  return 'E0001';
};

const mergeArrays = (x, y) => {
  // merges two array, removes duplicates but doesn't sort.
  const d = [];
  x.concat(y).forEach((item) => {
    if (d.indexOf(item) === -1) d.push(item);
  });
  return d;
};

const setAssistingCookiesByAuthData = (data) => {
  // set language, cookie-consent and auth (=availabe authorizations e.g. 'admin') cookies after login.
  // dev.prerender.io is authorzied by auth.saas.group server, so token cookie needs to be set here on client-side, too.
  const cookies = new Cookies();
  if (data.auth) {
    const authStr = JSON.stringify(data.auth);
    cookies.set('auth', authStr, config.cookieOptions);
  }
  cookies.set('ca', true, config.cookieOptions);
  if (data.loginSession) {
    cookies.set('loginSession', data.loginSession, config.cookieOptions); // needs to be submitted in order to use auth.saas.group Auth process
    cookies.set('token', data.token, config.cookieOptions); // token is not sent via cookie automatically b/c of cross-origin limitation.
  }
  // set language cookie with user's preferred langauge. However, if a cookie exists don't overwrite it here:
  if (
    data.settings &&
    data.settings.language &&
    (data.settings.language === 'de' || data.settings.language === 'en') &&
    !cookies.get('lan')
  ) {
    cookies.set('lan', data.settings.language, config.cookieOptions);
  }
};

const targetAfterSigninCookie = ({ method = 'get', target = '/' } = { method: 'get' }) => {
  const cookies = new Cookies();
  if (method === 'get') return cookies.get('target') || '/';
  cookies.set('target', target, config.cookieOptions);

  return undefined;
};

const matchWith = (currentState, newState, _show = false) => {
  // currentState is often an emptyState.
  const resultingState = clone(newState);
  Object.keys(currentState).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(currentState, key)) {
      if (typeof newState[key] === 'undefined') {
        resultingState[key] = currentState[key];
      }
    }
  });
  return resultingState;
};

const objectsAreEqual = (obj1, obj2, ignoreKeys = []) => {
  // start with polyfill of object.keys for older browsers:
  if (!Object.keys) {
    // eslint-disable-next-line func-names
    Object.keys = (function () {
      const { hasOwnProperty } = Object.prototype;

      // eslint-disable-next-line no-prototype-builtins
      const hasDontEnumBug = !{ toString: null }.propertyIsEnumerable('toString');
      const dontEnums = [
        'toString',
        'toLocaleString',
        'valueOf',
        'hasOwnProperty',
        'isPrototypeOf',
        'propertyIsEnumerable',
        'constructor',
      ];
      const dontEnumsLength = dontEnums.length;

      return (obj) => {
        if (typeof obj !== 'function' && (typeof obj !== 'object' || obj === null)) {
          throw new TypeError('Object.keys called on non-object');
        }

        const result = [];
        let prop;
        let i;

        // eslint-disable-next-line no-restricted-syntax
        for (prop in obj) {
          if (hasOwnProperty.call(obj, prop)) {
            result.push(prop);
          }
        }

        if (hasDontEnumBug) {
          for (i = 0; i < dontEnumsLength; i++) {
            if (hasOwnProperty.call(obj, dontEnums[i])) {
              result.push(dontEnums[i]);
            }
          }
        }
        return result;
      };
    })();
  }
  // Get the obj1 type
  const type = Object.prototype.toString.call(obj1);
  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(obj2)) return false;
  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;
  // Compare the length of the length of the two items
  const obj1Len = type === '[object Array]' ? obj1.length : Object.keys(obj1).length;
  const obj2Len = type === '[object Array]' ? obj2.length : Object.keys(obj2).length;
  if (obj1Len !== obj2Len) return false;
  // Compare two items
  const compare = (item1, item2) => {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1);
    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!objectsAreEqual(item1, item2, ignoreKeys)) {
        return false;
      }
    } else {
      // Otherwise, do a simple comparison
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else if (item1 !== item2) return false;
    }

    return false;
  };
  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < obj1Len; i++) {
      if (compare(obj1[i], obj2[i]) === false) return false;
    }
  } else {
    Object.keys(obj1).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(obj1, key)) {
        if (ignoreKeys.indexOf(key) === -1) {
          if (compare(obj1[key], obj2[key]) === false) return false;
        }
      }

      return false;
    });
  }
  // If nothing failed, return true
  return true;
};

const wait = async (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const presentAsDownload = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

const countIsSet = (subjects) => {
  let counter = 0;
  // eslint-disable-next-line no-plusplus
  subjects.forEach((s) => {
    if (s) counter++;
  });
  return counter;
};

export {
  countIsSet,
  presentAsDownload,
  wait,
  objectsAreEqual,
  matchWith,
  targetAfterSigninCookie,
  setAssistingCookiesByAuthData,
  mergeArrays,
  removeTrailingString,
  removeTrailingHttps,
  getLocalErrorCode,
  getUrlValuesFromString,
  getUrlValuesFromLocation,
  isSet,
  clone,
};
