import { Typography, Space, Button, Flex } from 'antd';
import { RocketOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import RenderedHtml from './RenderedHtml';

/**
 * @param {Object} props
 * @param {Object} props.notification
 * @param {String} props.notification.description
 * @param {String} props.notification.createdAt - ISO date string
 * @param {Object} props.notification.metadata
 * @param {String=} props.notification.metadata.htmlContent - HTML content
 * @param {String=} props.notification.metadata.link - URL
 */
function NewUpdateNotification({ notification }) {
  const { metadata = {} } = notification;

  return (
    <Space direction="vertical" size="large">
      <Typography.Text strong>{new Date(notification.createdAt).toLocaleDateString()}</Typography.Text>
      <Typography.Title type="success" level={4} style={{ margin: 0 }}>
        <RocketOutlined /> New feature available
      </Typography.Title>
      <Typography.Title level={3}>{notification.description}</Typography.Title>

      <RenderedHtml html={metadata.htmlContent} />

      {metadata.link && (
        <Link to={metadata.link}>
          <Button type="primary">Check it out</Button>
        </Link>
      )}
    </Space>
  );
}

export default NewUpdateNotification;
