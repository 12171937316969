import { Fragment, useEffect, useState } from 'react';
import { useLazyGetCacheClearStatusQuery } from '../urls/api/urlsApiSlice';
import { Button, Alert, Input, Typography, Flex } from 'antd';

const CacheClearanceStatus = {
  QUEUED: 'QUEUED',
  NOT_QUEUED: 'NOT_QUEUED',
  ALREADY_EXISTS: 'ALREADY_EXISTS',
  PROCESSING: 'PROCESSING',
};

function ClearPartialCache({ onButtonClick }) {
  const [wildcard, setWildcard] = useState('');
  const [getStatus, statusData] = useLazyGetCacheClearStatusQuery();

  useEffect(() => {
    getStatus();
  }, []);

  const isNotQueued = statusData.data?.data?.status === CacheClearanceStatus.NOT_QUEUED;
  const showNotInQueuedAlert = statusData.isSuccess && !isNotQueued;

  const enableClearButton = wildcard != '' && isNotQueued;

  const onClearCacheButtonClick = async () => {
    if (isNotQueued && wildcard != '') {
      onButtonClick(wildcard);
    }
  };

  const onWildcardChange = (event) => {
    setWildcard(event.target.value);
  };

  return (
    <Fragment>
      <div className="container">
        {showNotInQueuedAlert && (
          <div className="row pt-2 pb-2">
            <Alert showIcon type="success" message="Clearing partial cache currently in progress." />
          </div>
        )}
        {statusData.isError && (
          <div className="row pt-2 pb-2">
            <Alert showIcon type="error" message="Sorry, something went wrong" />
          </div>
        )}
        <div className="row pt-2 pb-2">
          <Flex justify="center">
            <Typography.Text>
              To clear cache of specific URL(s), please type the <b>wildcard</b> to find URL(s) by.
            </Typography.Text>
          </Flex>
        </div>
        <div className="row pt-2 pb-2">
          <div className="col text-center">
            <Input
              type="text"
              id="delete-url-like"
              placeholder="for example: https://my-website.com/blog/*"
              value={wildcard}
              onChange={onWildcardChange}
            />
          </div>
          <div className="col-auto">
            <Button type="primary" onClick={() => onClearCacheButtonClick()} disabled={!enableClearButton}>
              Clear Partial Cache...
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export { ClearPartialCache };
