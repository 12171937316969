import React from 'react';
import { Row, Col, theme } from 'antd';
import { useParams } from 'react-router-dom';
import { AverageCacheAgeCard, FirstSeenAtCard, NotCrawledPagesCard, UniquePagesCard } from './components/StatsCards';

export function DomainStats({ isLoading, uniquePages, averageCacheMinutes, firstSeenAt, pagesNotCrawled }) {
  const { token } = theme.useToken();
  const { domain } = useParams();

  return (
    <Row gutter={[token.marginMD, token.marginMD]}>
      <Col xs={12} xl={4}>
        <UniquePagesCard isLoading={isLoading} uniquePages={uniquePages} />
      </Col>
      <Col xs={12} xl={6}>
        <AverageCacheAgeCard isLoading={isLoading} averageCacheMinutes={averageCacheMinutes} />
      </Col>

      <Col xs={12} xl={8}>
        <FirstSeenAtCard isLoading={isLoading} firstSeenAt={firstSeenAt} />
      </Col>

      <Col xs={12} xl={6}>
        <NotCrawledPagesCard isLoading={isLoading} pagesNotCrawled={pagesNotCrawled} domain={domain} />
      </Col>
    </Row>
  );
}

export default DomainStats;
