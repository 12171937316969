import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Radio, Space, Tooltip, message, Alert, Card, Row, Col, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { KNOWN_TRACKER_QUERY_PARAMS } from '@prerender/uri-lib';

import { getContent } from '../../../actions/pageActions';
import { getPrerenderUser } from '../../../actions/prerenderUserActions';
import { CheckListOptions } from '../../../components/PageElementsLib';
import { Pagination } from '../../../components/PaginationLib';
import { useSetQueryParamsSettingsMutation } from '../../api/environmentApiSlice';
import { useEvent } from '../../events/hooks/useEvent';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import UrlParametersSettingsTable from './UrlParametersSettingsTable';
import ButtonWithRole, { RadioGroupWithRole } from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';

const cid = 'SettingsPage';

const mapToRadioValue = (prerenderUser) => {
  if (prerenderUser.ignoreAllQueryParams) {
    return 'ignoreAllParams';
  } else if (prerenderUser.queryParamsWhitelist) {
    return 'useSpecificParams';
  } else {
    return 'ignoreSomeParams';
  }
};

const QueryParamsSettings = ({ prerenderUser, doGetPrerenderUser }) => {
  const { track } = useEvent();
  const user = usePrerenderUser();
  const [queryParamsSettingsState, setQueryParamsSettingsState] = useState(null);
  const [setQueryParamsSettings, { error: queryParamsSettingsError }] = useSetQueryParamsSettingsMutation();

  useEffect(() => {
    if (queryParamsSettingsError) {
      message.error('Error during change query parameter settings!');
    }
  }, [queryParamsSettingsError]);

  useEffect(() => {
    setQueryParamsSettingsState(mapToRadioValue(prerenderUser));
  }, [prerenderUser]);

  const onChange = (e) => {
    track('URL Parameters Settings Applied', {
      subscription_plan: user.chargebeePlanId,
      list_caching_rule: e.target.value,
    });
    setQueryParamsSettings({
      ignoreAllQueryParams: e.target.value === 'ignoreAllParams',
      queryParamsWhitelist: e.target.value === 'useSpecificParams',
    }).then(() => {
      doGetPrerenderUser();
    });
  };

  const urlQueryParamsToggles = [
    {
      label: 'Ignore specific query parameters',
      tooltip:
        "Add the query parameters that need to be ignored when caching. These need to be marked as 'Ignore when Caching'",
      value: 'ignoreSomeParams',
    },
    {
      label: 'Ignore all query parameters',
      tooltip: 'Ignore all query parameters when caching',
      value: 'ignoreAllParams',
    },
    {
      label: 'Only cache specific query parameters',
      tooltip:
        "Ignore all query parameters when caching with the exception of specific ones. These need to be marked as 'Use for Caching'",
      value: 'useSpecificParams',
    },
  ];

  return (
    <>
      <Space direction="vertical" className="mb-4">
        <RadioGroupWithRole
          disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
          value={queryParamsSettingsState}
          onChange={onChange}
        >
          {urlQueryParamsToggles.map((item, index) => (
            <Tooltip key={index} title={item.tooltip} placement="rightTop">
              <Radio value={item.value} className="mr-3 mb-2">
                {item.label}
              </Radio>
              <br />
            </Tooltip>
          ))}
        </RadioGroupWithRole>
      </Space>
    </>
  );
};

const UrlParamsSettings = ({
  cm,
  language,
  getContent: doGetContent,
  urlParams,
  selectedItems,
  querySearchInProgress,
  showCheckListOptions,
  didReceiveAction,
  setState,
  prerenderUser,
  getPrerenderUser: doGetPrerenderUser,
}) => {
  useEffect(() => {
    if (!cm) doGetContent({ groups: ['settings', 'default'], lang: language });
  }, [doGetContent, language, cm]);

  if (!cm) {
    return <div>Loading...</div>;
  }

  const tabCm = cm.urlParams;

  const isLoading = urlParams.inProgress || querySearchInProgress;

  const onAddParameterClick = () => didReceiveAction({ action: 'openUrlParamEditor', payload: { param: '' } });

  const onRowEditClick = (payload) => didReceiveAction({ action: 'openUrlParamEditor', payload });

  const onSelectedItemsChange = (latestSelectedItems) =>
    setState({
      selectedItems: latestSelectedItems,
      customMessage: null,
    });

  return (
    <div className="row">
      <Alert
        showIcon
        icon={<InfoCircleOutlined />}
        type="info"
        style={{ marginBottom: '22px' }}
        // eslint-disable-next-line max-len
        message="This feature allows you to choose which URLs containing query parameters, should be cached under the corresponding canonical URL in order to reduce the number of cached pages."
      />
      <QueryParamsSettings prerenderUser={prerenderUser} doGetPrerenderUser={doGetPrerenderUser} />
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={16}>
            <Card
              title="URL parameters"
              extra={
                <ButtonWithRole
                  disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                  type="primary"
                  size="small"
                  disabled={isLoading}
                  onClick={onAddParameterClick}
                >
                  {tabCm.card.btnRight}
                </ButtonWithRole>
              }
            >
              <UrlParametersSettingsTable
                isLoading={isLoading}
                tableLabels={tabCm.table}
                items={urlParams.items}
                hideIgnoreWhenCachingParams={prerenderUser.ignoreAllQueryParams || prerenderUser.queryParamsWhitelist}
                hideUseForCachingParams={prerenderUser.ignoreAllQueryParams || !prerenderUser.queryParamsWhitelist}
                onSelectedItemsChange={onSelectedItemsChange}
                onRowEditClick={onRowEditClick}
              />

              <Pagination
                onPageChange={(action) => didReceiveAction(action)}
                stats={{
                  total: urlParams.stats.total,
                  amt: urlParams.stats.amt,
                  pages: urlParams.stats.pages,
                  page: urlParams.params.page,
                  amtpp: urlParams.params.pageSize,
                }}
              ></Pagination>
            </Card>
          </Col>
          <Col xs={24} lg={8}>
            <Card title="Known Trackers" style={{ position: 'sticky', top: 10 }}>
              <Alert
                showIcon
                icon={<InfoCircleOutlined />}
                type="info"
                style={{ marginBottom: '22px' }}
                message="Known tracking parameters are managed by Prerender, and those are always ignored, to reduce the chance of URL duplications."
              />
              <Table
                bordered
                rowKey="name"
                columns={[
                  {
                    key: 'name',
                    dataIndex: 'name',
                  },
                ]}
                dataSource={KNOWN_TRACKER_QUERY_PARAMS.map((it) => ({ name: it }))}
                showHeader={false}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <div className="col-12 ml-2 mb-3 text-muted">{cm.urlParams.card.title}</div>
      <CheckListOptions
        show={showCheckListOptions}
        count={selectedItems ? selectedItems.length : 0}
        name="Parameter"
        actions={[
          { name: 'updateRuleIgnore', text: 'Ignore' },
          { name: 'updateRuleUse', text: 'Use' },
          { name: 'deleteParams', icon: 'trash-2' },
        ]}
        onsubmit={(action) => didReceiveAction({ action, payload: selectedItems })}
        isActive={!urlParams.inProgress}
        onclose={() => setState({ selectedItems: [] })}
      />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    language: state.page.language,
    urlParams: state.urlParams,
    prerenderUser: state.prerenderUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent,
      getPrerenderUser,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlParamsSettings);
