import { Content } from 'antd/lib/layout/layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContent } from '../../actions/pageActions';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { Container, Alert } from '../../components/CssFrameworkComponents';
import { ControlButton } from '../../components/PageElementsLib';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { TWrapper, TRow, TCell } from '../../components/TableLib';
import ErrorLoader from '../../components/ErrorLoader';
import { clearLastErrorCode } from '../../actions/pageActions';
import { validateIntegration, clearIntegrationResult } from '../onboarding/redux/onboardingActions';
import { isValidUrl } from '../validators/url';
import IntegrationVerificationResult from './components/IntegrationVerificationResult';
import { IntegrationSteps } from './components/IntegrationSteps';
import { useEvent } from '../events/hooks/useEvent';

const META_TAGS = {
  title: 'Onboarding - Prerender.io',
};
const contentId = 'Onboarding';
const SESSION_STORAGE_ONBOARDING_URL_KEY = 'onboardingTestUrl';

const IntegrationVerificationPage = ({
  cm,
  onboarding,
  getContent: doGetContent,
  validationInProgress,
  validateIntegration: doValidateIntegration,
  clearLastErrorCode: doClearLastErrorCode,
  clearIntegrationResult: doClearIntegrationResult,
  lastErrorCode,
  apiErrTxt,
  prerenderUser,
}) => {
  const { validationResult } = onboarding;
  const navigate = useNavigate();
  const params = useParams();
  const integration = params.name;
  const [url, setUrl] = useState(sessionStorage.getItem(SESSION_STORAGE_ONBOARDING_URL_KEY));
  const { track } = useEvent();

  const trackValidation = useCallback(
    (validationStatus) => {
      if (validationStatus.working === false) {
        track('Verification Failed', {
          integration_method: integration,
          subscription_plan: prerenderUser.chargebeePlanId,
        });
      } else if (validationStatus.working === true) {
        track('Verification Completed', {
          integration_method: integration,
          subscription_plan: prerenderUser.chargebeePlanId,
        });
      }
    },
    [integration, prerenderUser.chargebeePlanId]
  );

  const didReceiveAction = async (url) => {
    doClearLastErrorCode();
    await doValidateIntegration(url);
  };

  useEffect(() => {
    if (!cm) {
      doGetContent(contentId);
    }
  }, [doGetContent, cm]);

  useEffect(() => {
    if (validationResult) {
      trackValidation(validationResult);
    }
  }, [validationResult]);

  if (!cm) {
    return <h1>Loading...</h1>;
  }

  const mdCm = cm.modal.verification;

  if (validationResult) {
    return (
      <AdminTemplate metaTags={META_TAGS}>
        <Content>
          <Container fluid>
            <div className="row align-items-center m-4">
              <div className="row mt-2">
                <IntegrationSteps current={2} />
              </div>
              <div className="row mt-5">
                <IntegrationVerificationResult
                  cm={cm.modal.result}
                  prerenderUser={prerenderUser}
                  integration={integration}
                  validationResult={validationResult}
                  onEvent={doClearIntegrationResult}
                />
              </div>
            </div>
          </Container>
        </Content>
      </AdminTemplate>
    );
  }

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Content>
        <Container fluid>
          <div className="row align-items-center m-4">
            <div className="row mt-2">
              <IntegrationSteps current={2} />
            </div>
            <div className="row mt-5">
              <Form
                onSubmit={(v) => didReceiveAction(v.url)}
                render={({ handleSubmit }) => (
                  <Fragment>
                    <TWrapper tableId="cuadd" parentType="modal" showTHead={false} cols={[{ size: 'flex' }]}>
                      <ErrorLoader code={lastErrorCode} scrollTop>
                        <TRow>
                          <TCell colspan="3" addClass={'text-center p-0'}>
                            <div className="p-2">
                              <Alert
                                modal={true}
                                content={apiErrTxt[lastErrorCode] ? { text: apiErrTxt[lastErrorCode] } : null}
                              />
                            </div>
                          </TCell>
                        </TRow>
                      </ErrorLoader>
                    </TWrapper>
                    <form onSubmit={handleSubmit} className="pl-0">
                      <div>
                        <h1>{mdCm.body.title}</h1>
                        <h4>{mdCm.body.subtitle}</h4>
                        {mdCm.body.texts.map((text, index) => (
                          <p key={index}>{text}</p>
                        ))}
                      </div>
                      <div>
                        <Field
                          name="url"
                          initialValue={url}
                          render={({ input }) => (
                            <input
                              {...input}
                              onChange={(e) => {
                                setUrl(e.target.value);
                                input.onChange(e);
                              }}
                              type="text"
                              className="w-100 form-control"
                              id="url"
                              placeholder="https://example.com"
                              disabled={validationInProgress}
                            />
                          )}
                        ></Field>
                      </div>
                    </form>

                    <div className="row align-items-center mt-5 pr-0">
                      <div className="col pl-0">
                        {
                          <button
                            onClick={() => {
                              navigate(`/integration/${integration}`);
                            }}
                            className="btn btn-outline-primary btn-sm btn-rounded "
                            disabled={validationInProgress}
                          >
                            {mdCm.footer.btnLeft}
                          </button>
                        }
                      </div>
                      <div className="col text-center">
                        <h3 className="mb-0"></h3>
                      </div>
                      <div className="col-auto pr-0">
                        {
                          <ControlButton
                            text={mdCm.footer.btnRight}
                            color="success"
                            action="submit"
                            // external submit
                            onclick={(event) => {
                              handleSubmit(event);
                            }}
                            disabled={!url || !isValidUrl(url) || validationInProgress}
                            loading={validationInProgress ? { color: 'text-light' } : null}
                          />
                        }
                      </div>
                    </div>
                  </Fragment>
                )}
              />
            </div>
          </div>
        </Container>
      </Content>
      <Navigate to="/integration-wizard" />
    </AdminTemplate>
  );
};

const mapStateToProps = (state) => ({
  onboarding: state.onboarding,
  prerenderUser: state.prerenderUser,
  cm: state.page.contentData[contentId],
  validationInProgress: state.onboarding.inProgress,
  lastErrorCode: state.page.lastErrorCode,
  apiErrTxt: state.page.contentData.apiError,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContent,
      validateIntegration,
      clearLastErrorCode,
      clearIntegrationResult,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(IntegrationVerificationPage));
