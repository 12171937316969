import { restApi } from './restApiSlice';

export const seoApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getSeoScoreDetails: builder.query({
      query: (url) => ({
        url: '/seo/score/details',
        method: 'GET',
        params: { url: url?.url, adaptive_type: url?.adaptive_type },
      }),
    }),
  }),
});

export const { useGetSeoScoreDetailsQuery } = seoApiSlice;
