import React from 'react';
import { LoadingTextBar } from './PageElementsLib';

const DataPointCard = ({
  width = 'col-12',
  title = '',
  value = '',
  badge = '',
  badgeTheme = 'success',
  icon = '',
  toolTip = null,
  children = null,
  isLoading = true,
  iconColor = 'text-muted',
  iconToolTip = null }) => (
    <div className={width}>
      <div className="card">
        <div className="card-body pb-3">
          <div className="row align-items-center">
            <div className="col">
              <h6 className="text-uppercase text-muted mb-2">
                {title}
                {toolTip && <i className="ml-1 fe fe-info" data-tip={toolTip}></i>}
              </h6>
              { isLoading && <LoadingTextBar c="w-50"/> }
              { !isLoading && <span className="h2 mb-2 mr-2 d-inline-block">{value}</span> }
              { badge && !isLoading && <span className={`badge bg-${badgeTheme} mt-n1`}>{badge}</span> }
            </div>
            { (children || icon) && <div className="col-auto">
              { children }
              { icon && <span className={`h2 fe fe-${icon} ${iconColor}`} data-tip={iconToolTip}></span> }
            </div>}
          </div>
        </div>
      </div>
    </div>
);

export default DataPointCard;
