import { restApi } from './restApiSlice';

export const appUserApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    inviteUser: builder.mutation({
      query: ({ email, role, fullName }) => ({
        url: 'invite',
        method: 'POST',
        body: { email, role, fullName },
      }),
      transformErrorResponse: (response) => response.data,
      invalidatesTags: ['TeamInvites'],
    }),
    getInvitedLoginUsers: builder.query({
      query: () => ({
        url: 'invited-users',
        method: 'GET',
      }),
      transformResponse: (response) => response.result.users,
      providesTags: ['TeamInvites'],
    }),
    updateLoginUserRole: builder.mutation({
      query: ({ loginUserId, role }) => ({
        url: 'update-role',
        method: 'POST',
        body: { loginUserId, role },
      }),
      invalidatesTags: ['TeamInvites'],
    }),
  }),
});

export const { useInviteUserMutation, useGetInvitedLoginUsersQuery, useUpdateLoginUserRoleMutation } = appUserApi;
