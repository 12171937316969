export const NOTIFICATION_CATEGORIES = {
  product: 'product',
  billing: 'billing',
  reports: 'reports',
};

export const NOTIFICATION_TYPES = {
  update: 'update',
  pageReport: 'page-report',
  renderLimit75: 'render-limit-75',
  renderLimit100: 'render-limit-100',
  betterPlanFound: 'better-plan-found',
  text: 'text',
};
