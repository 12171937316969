import { DeleteOutlined } from '@ant-design/icons';
import { Flex, Table, Tooltip } from 'antd';
import { useState } from 'react';
import { NewRuleModal } from './NewRuleModal';
import { createUsNumberFormat } from '../../../utils/createUsNumberFormat';
import { getTimeLabel } from '../utils/getTimeLabel';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../../features/auth/userRoles';

export const SchedulerSettingsTable = ({
  schedulerSettings,
  isLoading,
  onDeleteSetting,
  onAddSetting,
  isAddSettingLoading,
}) => {
  const [showModal, setShowModal] = useState(false);

  const onAddRule = () => {
    setShowModal(true);
  };

  const onSubmit = (values) => {
    onAddSetting(values);
    setShowModal(false);
  };

  const isAddRuleDisabled = schedulerSettings?.length >= 2;

  return (
    <div>
      <NewRuleModal
        showModal={showModal}
        isAddSettingLoading={isAddSettingLoading}
        onSubmit={onSubmit}
        onCancel={() => setShowModal(false)}
      />
      <Flex justify="right" style={{ marginBottom: 16 }}>
        <Tooltip title={isAddRuleDisabled ? 'You have reached the maximum number of rules' : ''}>
          <ButtonWithRole
            disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
            type="primary"
            onClick={onAddRule}
            disabled={isAddRuleDisabled}
          >
            Add rule
          </ButtonWithRole>
        </Tooltip>
      </Flex>
      <Table dataSource={schedulerSettings} bordered pagination={false} isLoading={isLoading}>
        <Table.Column title="Rule" dataIndex="rule" key="rule" />
        <Table.Column
          title="Cache Expiration"
          dataIndex="cache_freshness_hours"
          key="cache_freshness_hours"
          render={(_rules, record) => getTimeLabel(record.cache_freshness_hours)}
        />
        <Table.Column
          title="Number of pages"
          dataIndex="affected_urls"
          key="affected_urls"
          render={(_rules, record) => createUsNumberFormat(record.affected_urls)}
        />
        <Table.Column
          title=""
          dataIndex="actions"
          key="actions"
          render={(_rules, record) => (
            <ButtonWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
              onClick={() => {
                onDeleteSetting(record.id);
              }}
              danger
            >
              <DeleteOutlined />
            </ButtonWithRole>
          )}
        />
      </Table>
    </div>
  );
};
