import ReactMarkdown from 'react-markdown';
import rehypePrism from '@mapbox/rehype-prism';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeAddClasses from 'rehype-add-classes';
import { useState } from 'react';
import CopiablePre from './CopiablePre';

const Markdown = ({ children, classOverride, cm }) => {
  const [copiedId, setCopiedId] = useState(null);
  let globalId = 0;

  return (<ReactMarkdown
  children={children}
  components={{
    pre({ className, children: preChildren, ...props }) {
      const id = globalId;
      globalId += 1;
      const onTextCopied = () => {
        setCopiedId(id);
      };
      const copyText = copiedId === id ? cm.copyButton.copied : cm.copyButton.copy;

      return (
        <CopiablePre className={className} props={props} children={preChildren} copyText={copyText} onTextCopied={onTextCopied}/>
      );
    },
  }}
  rehypePlugins={[rehypePrism, rehypeExternalLinks, [rehypeAddClasses, classOverride]]} />);
};

export default Markdown;
