import React from 'react';
import { Table, Button, ConfigProvider, Empty, Tag, Tooltip } from 'antd';
import { SearchOutlined, DesktopOutlined, MobileOutlined } from '@ant-design/icons';

function StatusTag({ status, isPartiallyDone }) {
  if (status === 'done' && isPartiallyDone) return <Tag color="orange">Partial Done</Tag>;

  switch (status) {
    case 'processing':
      return <Tag color="cyan">Processing</Tag>;
    case 'done':
      return <Tag color="green">Done</Tag>;
    case 'failed':
      return <Tag color="red">Failed</Tag>;
    case 'queued':
      return <Tag color="yellow">Queued</Tag>;
    default:
      return <Tag>Unknown</Tag>;
  }
}

function Device({ adaptiveType }) {
  let device = 'Mobile / Desktop';
  if (adaptiveType === 'mobile') device = 'Mobile';
  else if (adaptiveType === 'desktop') device = 'Desktop';

  const toolTipTitle = `Rendering was optimized for [${device}] crawlers`;

  return (
    <Tooltip title={toolTipTitle} placement="left">
      {(adaptiveType === 'desktop' || adaptiveType === 'all') && <DesktopOutlined />}
      {adaptiveType === 'all' && <span className="m-1">/</span>}
      {(adaptiveType === 'mobile' || adaptiveType === 'all') && <MobileOutlined />}
    </Tooltip>
  );
}

const getRuntimeString = ({ status, createdAt, runtime }) => {
  const computedRuntime = status === 'processing' ? new Date().getTime() - new Date(createdAt).getTime() : runtime;
  const seconds = computedRuntime / 1000;
  const intervalHours = Math.floor(seconds / 3600);
  const intervalMinutes = Math.floor((seconds - intervalHours * 3600) / 60);
  const intervalSeconds = seconds - intervalHours * 3600 - intervalMinutes * 60;

  const runtimeCacls = [];
  if (intervalHours >= 1) {
    runtimeCacls.push(`~${intervalHours.toFixed(0)} hours`);
  }
  if (intervalMinutes >= 1) {
    runtimeCacls.push(`~${intervalMinutes.toFixed(0)} minutes`);
  }
  if (intervalSeconds >= 1) {
    runtimeCacls.push(`~${intervalSeconds.toFixed(0)} seconds`);
  }
  if (!runtimeCacls.length) {
    runtimeCacls.push(`less than 1 second`);
  }
  const runtimeString = status === 'queued' ? '-' : runtimeCacls.join(', ');

  return runtimeString;
};

function SitemapCrawlsTable({ rows = [], isLoading = false, onFailedCrawlDetailsClick }) {
  const tableColumns = React.useMemo(
    () => [
      {
        title: 'Date',
        key: 'date',
        dataIndex: 'createdAt',
        className: 'col-min',
        render: (_text, item) => new Date(item.createdAt).toLocaleString(),
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        className: 'col-min',
        render: (_text, item) => {
          const isPartiallyDone = item.result?.urls_failed_to_cache > 0;
          return (
            <>
              <StatusTag status={item.status} isPartiallyDone={isPartiallyDone} />
              {(item.status === 'failed' || isPartiallyDone) && (
                <Button size="small" icon={<SearchOutlined />} onClick={() => onFailedCrawlDetailsClick(item)}>
                  See why
                </Button>
              )}
            </>
          );
        },
      },
      {
        title: 'Device',
        key: 'device',
        dataIndex: 'device',
        align: 'center',
        render: (_text, item) => <Device adaptiveType={item.adaptiveType} />,
      },
      {
        title: 'Runtime',
        key: 'runtime',
        dataIndex: 'runtime',
        className: 'col-min',
        render: (_text, item) => getRuntimeString(item),
      },
      {
        title: 'Total URLs found',
        key: 'totalUrlsFound',
        dataIndex: 'result.total_urls_found',
        className: 'col-min',
        render: (_text, item) => item.result?.total_urls_found,
      },
      {
        title: 'Skipped/failed to cache',
        key: 'urlsFailedToCache',
        dataIndex: 'result.total_urls_found',
        className: 'col-min',
        render: (_text, item) => {
          const skipped = item.result?.urls_skipped_cache || '-';
          const failed = item.result?.urls_failed_to_cache || '-';
          return `${skipped}/${failed}`;
        },
      },
      {
        title: 'Duplicate URLs',
        key: 'urlsDuplicates',
        dataIndex: 'result.urls_duplicates',
        className: 'col-min',
        render: (_text, item) => item.result?.urls_duplicates,
      },
      {
        title: 'Cache requested',
        key: 'urlsCached',
        dataIndex: 'result.urls_cached',
        className: 'col-min',
        render: (_text, item) => item.result?.urls_cached,
      },
    ],
    []
  );

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="We did not crawl this sitemap yet." />
      )}
    >
      <Table
        bordered={!!rows.length}
        rowKey="id"
        loading={isLoading}
        columns={tableColumns}
        dataSource={rows}
        pagination={false}
        showHeader={!!rows.length}
        scroll={{ x: true }}
      />
    </ConfigProvider>
  );
}

export default SitemapCrawlsTable;
