import { Card, Flex, Skeleton, Statistic, Tooltip, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatCacheAge } from '../utils/getTimeLabel';
import { QuestionCircleOutlined } from '@ant-design/icons';

const TitleWithToolTip = ({ title, tooltip }) => (
  <Flex justify="space-between" align="center" gap="small">
    <Typography.Text type="secondary">{title}</Typography.Text>
    <Tooltip title={tooltip}>
      <QuestionCircleOutlined />
    </Tooltip>
  </Flex>
);

export const UniquePagesCard = ({ isLoading, uniquePages }) => (
  <Card bordered size="small">
    <Skeleton loading={isLoading} active>
      <Statistic title="Unique Pages" value={uniquePages || '-'} />
    </Skeleton>
  </Card>
);

export const AverageCacheAgeCard = ({ isLoading, averageCacheMinutes }) => {
  const averageCacheAge = React.useMemo(() => formatCacheAge(averageCacheMinutes), [averageCacheMinutes]);

  return (
    <Card bordered size="small">
      <Skeleton loading={isLoading} active>
        <Statistic title="Average Cache Age" value={averageCacheAge} />
      </Skeleton>
    </Card>
  );
};

export const FirstSeenAtCard = ({ isLoading, firstSeenAt }) => (
  <Card bordered size="small">
    <Skeleton loading={isLoading} active>
      <Statistic
        title="Fist Seen At"
        value={firstSeenAt}
        formatter={(date) => (date ? new Date(date).toLocaleString() : '-')}
      />
    </Skeleton>
  </Card>
);

const LinkWithFilters = ({ link, lastCrawledFilterActive, domain }) => {
  const search = new URLSearchParams();
  if (lastCrawledFilterActive) {
    const dateOfAmonthAgo = new Date();
    dateOfAmonthAgo.setMonth(dateOfAmonthAgo.getMonth() - 1);
    search.set('last_delivery_at', dateOfAmonthAgo.toISOString());
  }

  if (domain) {
    search.set('domain', domain);
  }

  return <Link to={`${link}?${search.toString()}`}>View all pages</Link>;
};

export const NotCrawledPagesCard = ({ isLoading, pagesNotCrawled, domain }) => (
  <Card
    bordered
    size="small"
    actions={[<LinkWithFilters link="/cache/cache-manager" lastCrawledFilterActive domain={domain} />]}
  >
    <Skeleton loading={isLoading} active>
      <Statistic
        title={
          <TitleWithToolTip title="Pages not crawled" tooltip="Pages that have not been crawled in the past 30 days" />
        }
        value={pagesNotCrawled}
        suffix="pages"
      />
    </Skeleton>
  </Card>
);
