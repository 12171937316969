import React from 'react';
import { Link } from 'react-router-dom';
import { Tag, Space } from 'antd';
import { MenuItemType, MenuItemGroupType } from 'antd/es/menu/interface';

import {
  DashboardOutlined,
  ApiOutlined,
  GlobalOutlined,
  HistoryOutlined,
  PieChartOutlined,
  CloudOutlined,
  FileSyncOutlined,
  CheckSquareOutlined,
  GoogleOutlined,
  CreditCardOutlined,
  SettingOutlined,
  StockOutlined,
  LaptopOutlined,
  LockOutlined,
  EyeOutlined,
  TagOutlined,
  ScheduleOutlined,
  CompassOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';

export enum PAGE_KEY {
  Billing = 'billing',
  Security = 'security',
  Settings = 'settings',
  IntegrationWizard = 'integration-wizard',
  Dashboard = 'dashboard',
  RankTracker = 'rank-tracker',
  DomainAnalytics = 'domain-analytics',
  DeepInsight = 'deep-insight',
  CacheManager = 'cache-manager',
  DomainManager = 'domain-manager',
  CdnAnalytics = 'cdn-analytics',
  Sitemaps = 'sitemaps',
  ScheduledRendering = 'scheduled-rendering',
  RenderHistory = 'render-history',
  Domain404Checker = 'domain-404-checker',
  Oversight = 'oversight',
  Gsc = 'gsc',
  CustomDeal = 'custom-deal',
  Qa = 'qa',
  FeatureRequests = 'feature-requests',
}

// label: <LinkWithTag to="/page-url">test</LinkWithTag>;
type LinkWithTagProps = {
  to: string;
  tag?: string;
  color?: string;
};
function LinkWithTag({ to, children, tag = 'New', color = 'purple' }: React.PropsWithChildren<LinkWithTagProps>) {
  return (
    <Link to={to}>
      <Space size="small">
        <span>{children}</span>
        <Tag color={color}>{tag}</Tag>
      </Space>
    </Link>
  );
}

export type LeafMenuItem = MenuItemType & { target: string; visible: boolean };
type GroupMenuItem = Omit<MenuItemGroupType, 'children'> & { children: LeafMenuItem[] };
export type MenuItem = LeafMenuItem | GroupMenuItem;

const settingsItems: LeafMenuItem[] = [
  {
    key: PAGE_KEY.Billing,
    label: <Link to="/billing">Billing</Link>,
    icon: <CreditCardOutlined />,
    target: '/billing',
    visible: true,
  },
  {
    key: PAGE_KEY.Security,
    label: <Link to="/security">Security & Access</Link>,
    icon: <LockOutlined />,
    target: '/security',
    visible: true,
  },
  {
    key: PAGE_KEY.Settings,
    label: <Link to="/settings">Advanced Settings</Link>,
    icon: <SettingOutlined />,
    target: '/settings',
    visible: true,
  },
];

const menuItems: MenuItem[] = [
  {
    key: PAGE_KEY.IntegrationWizard,
    label: <Link to="/integration-wizard">Get Started</Link>,
    icon: <ApiOutlined />,
    target: '/integration-wizard',
    visible: false,
  },
  {
    key: PAGE_KEY.Dashboard,
    label: <Link to="/">Dashboard</Link>,
    icon: <DashboardOutlined />,
    target: '/',
    visible: true,
  },
  {
    key: PAGE_KEY.DomainAnalytics,
    label: (
      <LinkWithTag to="/analytics" tag="Beta">
        Analytics
      </LinkWithTag>
    ),
    icon: <ScheduleOutlined />,
    target: '/analytics',
    visible: false,
  },
  {
    key: PAGE_KEY.DeepInsight,
    label: <Link to="/insight">Deep Insight</Link>,
    icon: <PieChartOutlined />,
    target: '/insight',
    visible: true,
  },
  {
    key: PAGE_KEY.CacheManager,
    label: <Link to="/cache">Cache Manager</Link>,
    icon: <CloudOutlined />,
    target: '/cache',
    visible: true,
  },
  {
    key: PAGE_KEY.DomainManager,
    label: <Link to="/domain-manager">Domain Manager</Link>,
    icon: <LaptopOutlined />,
    target: '/domain-manager',
    visible: true,
  },
  {
    key: PAGE_KEY.CdnAnalytics,
    label: <Link to="/crawl-stats">CDN Analytics</Link>,
    icon: <GlobalOutlined />,
    target: '/crawl-stats',
    visible: true,
  },
  {
    key: PAGE_KEY.Sitemaps,
    label: <Link to="/sitemaps">Sitemaps</Link>,
    icon: <FileSyncOutlined />,
    target: '/sitemaps',
    visible: true,
  },
  {
    key: PAGE_KEY.ScheduledRendering,
    label: <Link to="/recache-queue">Scheduled Rendering</Link>,
    icon: <HistoryOutlined />,
    target: '/recache-queue',
    visible: true,
  },
  {
    key: PAGE_KEY.RenderHistory,
    label: <Link to="/recache-stats">Render History</Link>,
    icon: <StockOutlined />,
    target: '/recache-stats',
    visible: true,
  },
  {
    key: PAGE_KEY.Domain404Checker,
    label: <Link to="/domain-404-checker">404 Checker</Link>,
    icon: <CheckSquareOutlined />,
    target: '/domain-404-checker',
    visible: true,
  },
  {
    key: PAGE_KEY.Oversight,
    label: <Link to="/oversight">Oversight</Link>,
    icon: <EyeOutlined />,
    target: '/oversight',
    visible: false,
  },
  {
    key: PAGE_KEY.Gsc,
    label: <Link to="/gsc">Google Search Console</Link>,
    icon: <GoogleOutlined />,
    target: '/gsc',
    visible: true,
  },
  {
    key: PAGE_KEY.RankTracker,
    label: <Link to="/rank-tracker">Rank Tracker</Link>,
    icon: <TagOutlined />,
    target: '/rank-tracker',
    visible: true,
  },
  {
    key: PAGE_KEY.FeatureRequests,
    label: <Link to="/feature-requests">Feature Requests</Link>,
    icon: <CompassOutlined />,
    target: '/feature-requests',
    visible: true,
  },
  {
    key: PAGE_KEY.CustomDeal,
    label: <Link to="/custom-deal">Custom deal</Link>,
    icon: <DollarCircleOutlined />,
    target: '/custom-deal',
    visible: true,
  },
  // {
  //   key: 'qa',
  //   label: 'Quality Assurance',
  //   icon: <EyeOutlined />,
  //   target: '/qa',
  //   group: 'Experimental Features',
  //   visible: true,
  // },
  {
    type: 'group',
    key: 'settings',
    label: 'Settings',
    children: settingsItems,
  },
];

export default menuItems;
