import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, ConfigProvider, Empty, Switch, Tooltip, Space, Popconfirm, theme } from 'antd';
import {
  UserAddOutlined,
  DesktopOutlined,
  MobileOutlined,
  QuestionCircleOutlined,
  CheckCircleFilled,
  SearchOutlined,
  WarningOutlined,
  EyeOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import ButtonWithRole, { SwitchWithRole } from '../../../components/ButtonWithRole';
import USER_ROLES from '../../../features/auth/userRoles';

function SitemapListTable({ rows = [], isLoading = false, mobileAdaptive, onStatusToggle, onDeleteClick }) {
  const { token } = theme.useToken();

  const tableColumns = React.useMemo(
    () => [
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'enabled',
        render: (_text, item) => (
          <SwitchWithRole
            disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
            checked={item.enabled}
            onClick={() => onStatusToggle(item)}
          />
        ),
      },
      {
        title: 'Sitemap URL',
        key: 'sitemapUrl',
        dataIndex: 'sitemapUrl',
        width: '99%',
        className: 'text-truncate url-shortener',
        render: (_text, item) => <Link to={`map/${item.id}`}>{item.sitemap_url}</Link>,
      },
      {
        title: 'Created at',
        key: 'createdAt',
        dataIndex: 'created_at',
        className: 'col-min',
      },
      {
        title: 'Revisit interval',
        key: 'revisitInterval',
        dataIndex: 'interval',
        className: 'col-min',
      },
      {
        title: 'Device',
        key: 'device',
        dataIndex: 'device',
        className: 'col-min',
        align: 'center',
        render: (_text, item) => (
          <span>
            {(!mobileAdaptive || item.adaptiveType === 'desktop' || item.adaptiveType === 'all') && <DesktopOutlined />}
            {mobileAdaptive && item.adaptiveType === 'all' && <span className="m-1">/</span>}
            {mobileAdaptive && (item.adaptiveType === 'mobile' || item.adaptiveType === 'all') && <MobileOutlined />}
          </span>
        ),
      },
      {
        title: 'Last visited at',
        key: 'lastVisitedAt',
        dataIndex: 'last_checked_at',
        className: 'col-min',
      },
      {
        title: 'Next visit',
        key: 'nextVisit',
        dataIndex: 'next_visit',
        className: 'col-min',
      },
      {
        title: 'Origin',
        key: 'origin',
        dataIndex: 'origin',
        className: 'col-min',
        align: 'center',
        render: (_text, item) => {
          const type = item.type.toLowerCase();
          if (type.includes('user'))
            return (
              <Tooltip title="Created by you">
                <UserAddOutlined />
              </Tooltip>
            );

          if (type.includes('auto'))
            return (
              <Tooltip title="Automaticaly discovered">
                <SearchOutlined />
              </Tooltip>
            );

          return '';
        },
      },
      {
        title: 'Health',
        key: 'health',
        dataIndex: 'health',
        className: 'col-min',
        align: 'center',
        render: (_text, item) => {
          if (item.isHealthy === null)
            return (
              <Tooltip title="Unknown">
                <QuestionCircleOutlined />
              </Tooltip>
            );
          if (item.isHealthy === true)
            return (
              <Tooltip title="Healthy">
                <CheckCircleFilled style={{ color: token.colorSuccess }} />
              </Tooltip>
            );
          if (item.isHealthy === false)
            return (
              <Tooltip title="Unhealthy">
                <WarningOutlined style={{ color: token.colorError }} />
              </Tooltip>
            );
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_text, item) => (
          <Space>
            <Tooltip key="details" placement="left" title="Details">
              <Link to={`map/${item.id}`}>
                <Button icon={<EyeOutlined />} />
              </Link>
            </Tooltip>
            <Popconfirm
              key="delete"
              title="Delete this sitemap?"
              okText="Yes, delete"
              okType="danger"
              cancelText="Cancel"
              placement="left"
              onConfirm={() => onDeleteClick(item.id)}
            >
              <ButtonWithRole
                disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                disabled={isLoading}
                danger
                icon={<DeleteOutlined />}
              />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [mobileAdaptive, isLoading]
  );

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="We haven't discovered sitemaps for your account. We try every 24 hour, please check back later or use the import button."
        />
      )}
    >
      <Table
        bordered={!!rows.length}
        rowKey="id"
        loading={isLoading}
        columns={tableColumns}
        dataSource={rows}
        pagination={false}
        showHeader={!!rows.length}
        scroll={{ x: true }}
      />
    </ConfigProvider>
  );
}

export default SitemapListTable;
