import { Field, Form } from 'react-final-form';
import { ModalWrapper } from '../../components/ModalLib';
import { ClearPartialCache } from './clearPartialCache';
import { Button, Flex, Input, Typography } from 'antd';

const ClearCacheModal = ({ onEvent }) => {
  const openClearPartialCacheModal = (wildcard) => {
    onEvent({ action: 'openClearPartialCacheModal', actionArgs: { wildcard } });
  };

  return (
    <ModalWrapper maxWidth="750px">
      <Typography.Title level={5} style={{ margin: '0 auto', paddingTop: 16 }}>
        Clear Cache - Confirmation
      </Typography.Title>
      <div className="modal-body">
        <ClearPartialCache onButtonClick={openClearPartialCacheModal} />
        <hr className="mt-5" />
        <Form
          onSubmit={(values) => onEvent({ action: 'submitClearCache', payload: { values } })}
          validate={(values) => {
            const errors = {};
            if (values.keyword !== 'clear cache') {
              errors.keyword = 'keyword mismatch';
            }
            return errors;
          }}
        >
          {({ handleSubmit, submitting, valid }) => (
            <form onSubmit={handleSubmit} className="mt-5">
              <div>
                <div className="row pt-2 pb-2">
                  <Flex justify="center">
                    <Typography.Text>
                      To delete your entire cache, please type <u>clear cache</u> into the box below.
                    </Typography.Text>
                  </Flex>
                </div>
                <div className="row pt-2 pb-2">
                  <div className="col">
                    <Field name="keyword">
                      {({ input, meta }) => (
                        <Input
                          {...input}
                          type="text"
                          placeholder="clear cache"
                          className={`w-100
                ${meta.error && meta.touched ? 'border-danger' : ''}`}
                          id="keyword"
                        />
                      )}
                    </Field>
                  </div>
                </div>
                <Flex justify="space-between" style={{ paddingTop: 16 }}>
                  <Button onClick={() => onEvent({ action: 'closeClearCacheModal' })}>Close</Button>
                  <Button disabled={submitting || !valid} htmlType="submit" danger type="primary">
                    Yes, I understand the consequences
                  </Button>
                </Flex>
              </div>
            </form>
          )}
        </Form>
      </div>
    </ModalWrapper>
  );
};

export default ClearCacheModal;
