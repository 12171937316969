import { Flex, Typography, Layout, Form } from 'antd';
import NavigationButtons from '../../NavigationButtons';
import { useState } from 'react';
import { useInviteUserMutation } from '../../../../api/appUserApiSlice';
import InviteTeamMembersForm from './InviteTeamMembersForm';
import { useEvent } from '../../../../events/hooks/useEvent';
import { useCharbeePlanId } from '../../../../../hooks/usePrerenderUser';

const { Title, Text } = Typography;
const { Content } = Layout;

const InviteTeamStep = ({ onGoBack, onGoNext, currentStep }) => {
  const [disabledNext, setDisabledNext] = useState(true);
  const [addedUsers, setAddedUsers] = useState([]);
  const [userStatuses, setUserStatuses] = useState({});
  const [inviteUser] = useInviteUserMutation();
  const [form] = Form.useForm();
  const { track } = useEvent();
  const subscriptionPlan = useCharbeePlanId();

  const canGoBack = currentStep !== 0;

  const onExtraButtonClick = () => {
    track('Invite team step: Remind me later clicked', { subscription_plan: subscriptionPlan });
    onGoNext();
  };

  const handleSubmit = async (values) => {
    const usersToInvite = values.users.map((user) => ({
      email: user.email,
      role: user.role,
    }));

    let statuses = { ...userStatuses };
    let allSuccess = true;

    for (let [index, user] of usersToInvite.entries()) {
      try {
        await inviteUser(user).unwrap();
        statuses[user.email] = 'success';
      } catch (error) {
        statuses[user.email] = 'error';
        allSuccess = false;
        form.setFields([
          {
            name: ['users', index, 'email'],
            errors: [error.error ? error.error : 'An unexpected error occured, please try again'],
          },
        ]);
      }
    }
    setUserStatuses(statuses);

    if (allSuccess) {
      track('Invite team step: Team members invited');
      onGoNext();
    }
  };

  return (
    <Flex vertical gap="middle" style={{ minHeight: 520 }}>
      <Layout style={{ width: '100%', margingTop: 32 }}>
        <Flex align="center" style={{ minHeight: '74vh', maxWidth: 560, margin: '0 auto' }}>
          <Content>
            <Flex vertical align="center">
              <Title level={3}>Invite your team</Title>
              <Text>
                Bring on developers, billing managers, marketers, and more. Add unlimited users at no extra costs.
              </Text>
            </Flex>
            <InviteTeamMembersForm
              form={form}
              onFinish={handleSubmit}
              setAddedUsers={setAddedUsers}
              onInputChange={() => setDisabledNext(false)}
              userStatuses={userStatuses}
              addedUsers={addedUsers}
            />
          </Content>
        </Flex>
        <NavigationButtons
          onGoNext={form.submit}
          onGoBack={canGoBack ? onGoBack : null}
          nextBtnText="Invite team members"
          disabledNext={disabledNext}
          showExtraButton
          extraButtonText="Remind me later"
          onExtraButtonClick={onExtraButtonClick}
        />
      </Layout>
    </Flex>
  );
};

export default InviteTeamStep;
