import config from '../../../assets/config';
import { getLocalErrorCode } from '../../../assets/lib';
import { setLastErrorCode, clearLastErrorCode } from '../../../actions/pageActions';
import internalApi from '../../../assets/internalApi';

export const clearCache = (callback) => async (dispatch) => {
  try {
    const requestUri = `${config.apiUrl.cmsServer}/api/v2/cache-pages/`;
    const data = await internalApi('delete', requestUri, null);
    callback(null, data);
    clearLastErrorCode();
  } catch (error) {
    callback(error);
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};
