import React from 'react';
import { Button, Divider, Flex, Modal, Typography } from 'antd';

import { usePrerenderEmail, useCharbeePlanId } from '../../../hooks/usePrerenderUser';
import useQueryStringParameters from '../../../utils/useQueryStringParameters';
import { presentAsDownload } from '../../../assets/lib';
import { useEvent } from '../../events/hooks/useEvent';
import { useExportURLsAsCSVMutation, useExportURLsAsSitemapMutation, CachedCsvExportStatus } from '../CacheManagerApi';
import { CachedPagesQueryParameters, buildCachedPagesQueryParameters } from './QueryParameters';

type ExportCachedPagesModalProps = {
  visible: boolean;
  onCancel: () => void;
};

function transformCsvExportResponse(responseStatus: CachedCsvExportStatus, email: string) {
  switch (responseStatus) {
    case 'queued':
      return email
        ? `Your export is processing and will take a couple of minutes. We\'ll email it to you at "${email}" when it\'s ready.`
        : 'We have scheduled your export. You will get a download link in your email once the export is ready.';
    case 'not queued':
      return "Click start to export all your cached URLs into a CSV. Please note that exporting URLs happens in the background and you'll be notified by email once it's done.";
    case 'already exists':
      return 'CSV export has been already requested. Please try again later.';
    case 'limited':
      return 'Please upgrade your plan to use this feature.';
    default:
      return 'An error occurred while exporting URLs. Please try again later.';
  }
}

function ExportCachedPagesModal({ visible, onCancel }: ExportCachedPagesModalProps) {
  const { queryStrings } = useQueryStringParameters<CachedPagesQueryParameters>(buildCachedPagesQueryParameters);
  const [exportCsv, exportCsvResult] = useExportURLsAsCSVMutation();
  const [exportSitemap, exportSitemapResult] = useExportURLsAsSitemapMutation();

  const { track } = useEvent();
  const chargebeePlanId = useCharbeePlanId();
  const email = usePrerenderEmail();

  const closeConfirmationModal = () => {
    exportCsvResult.reset();
    onCancel();
  };

  const footerProp = exportCsvResult.isSuccess
    ? {
        footer: [
          <Button key="close" type="primary" onClick={closeConfirmationModal}>
            Close
          </Button>,
        ],
      }
    : { footer: null };

  const downloadCsv = () => {
    exportCsv(queryStrings);
  };

  const downloadSitemap = async () => {
    const sitemap = await exportSitemap().unwrap();
    presentAsDownload(sitemap, 'sitemap.xml');
    track('Cache URL List Exported', {
      subscription_plan: chargebeePlanId,
    });
    closeConfirmationModal();
  };

  return (
    <Modal open={visible} title="Export all URLs" onCancel={onCancel} {...footerProp}>
      {exportCsvResult.isSuccess ? (
        <Typography.Paragraph>{transformCsvExportResponse(exportCsvResult.data.status, email)}</Typography.Paragraph>
      ) : (
        <div>
          <Typography.Paragraph strong>You have selected all URLs</Typography.Paragraph>
          <Flex vertical>
            <Button
              type="primary"
              onClick={downloadCsv}
              loading={exportCsvResult.isLoading}
              disabled={exportSitemapResult.isLoading}
            >
              Export as CSV File
            </Button>
            <Divider plain> Or </Divider>
            <Button
              type="primary"
              onClick={downloadSitemap}
              loading={exportSitemapResult.isLoading}
              disabled={exportCsvResult.isLoading}
            >
              Download as sitemap.xml
            </Button>
          </Flex>
        </div>
      )}
    </Modal>
  );
}

export default ExportCachedPagesModal;
