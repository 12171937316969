import React from 'react';
import { Card, Flex, Typography } from 'antd';

const { Paragraph } = Typography;

const getTextForRange = (value) => {
  const ranges = [
    { label: 'Good', color: '#35B879', condition: (val) => val >= 20 },
    { label: 'Needs Improvement', color: '#F37F2C', condition: (val) => val >= -30 && val < 20 },
    { label: 'Poor', color: '#DB232D', condition: (val) => val < -30 },
  ];
  return ranges.find((range) => range.condition(value)) || { label: 'Unknown', color: 'black' };
};

const TextWithColor = ({ value }) => {
  const { label, color } = getTextForRange(value);

  return (
    <>
      <Paragraph strong style={{ color, fontSize: 20, marginBottom: 0 }}>
        {label}
      </Paragraph>
      <Paragraph strong style={{ color, fontSize: 24, marginBottom: 0 }}>
        {value}
      </Paragraph>
    </>
  );
};

export const ReportDetailsCard = ({ averageScore, totalPages }) => (
  <Card
    title={`Total cached pages: ${totalPages}`}
    styles={{ title: { display: 'flex', justifyContent: 'center' }, body: { padding: 16 } }}
  >
    <Flex style={{ width: '100%' }} align="center" vertical>
      <Paragraph type="secondary" style={{ marginBottom: 0 }}>
        Average SEO Score
      </Paragraph>
      <TextWithColor value={Math.round(averageScore)} />
    </Flex>
  </Card>
);
