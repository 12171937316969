export type SalesForceBucket = {
  bucketNumber: number;
  dealId: string;
  customerId: number;
  userIds: number[];
};

export const SALES_FORCE_BUCKETS: SalesForceBucket[] = [
  {
    bucketNumber: 1,
    dealId: 'fb581254-0c7a-4347-b0d3-800dca3e04e6',
    customerId: 123534,
    userIds: [123534, 618730, 619154],
  },
  {
    bucketNumber: 2,
    dealId: '54b39b97-d8da-4111-b98d-ae66ace409fc',
    customerId: 264998,
    userIds: [264998, 109209],
  },
];

export const SALES_FORCE_BUCKETS_MAP_WITH_CUSTOMER_ID_KEY: Map<string, SalesForceBucket> = new Map(
  SALES_FORCE_BUCKETS.map((bucket) => [bucket.dealId, bucket] as [string, SalesForceBucket])
);

export const SALES_FORCE_BUCKETS_MAP_WITH_USER_ID_KEY: Map<number, SalesForceBucket> = SALES_FORCE_BUCKETS.reduce(
  (map, bucket) => {
    bucket.userIds.forEach((userId) => {
      map.set(userId, bucket);
    });
    return map;
  },
  new Map<number, SalesForceBucket>()
);

export const SALES_FORCE_BUCKETS_DEAL_IDS: string[] = SALES_FORCE_BUCKETS.map((bucket) => bucket.dealId);
