import pageReducer from './pageReducer';
import prerenderUserReducer from './prerenderUserReducer';
import cachedPagesReducer from './cachedPagesReducer';
import crawlerActivityReducer from './crawlerActivityReducer';
import crawlerStatsReducer from './crawlerStatsReducer';
import recacheQueueReducer from './recacheQueueReducer';
import cachingActivityReducer from './cachingActivityReducer';
import cachingStatsReducer from './cachingStatsReducer';
import dashboardStatsReducer from './dashboardStatsReducer';
import urlParamsReducer from './urlParamsReducer';
import pricingPageReducer from './pricingPageReducer';
import emailVerificationReducer from './emailVerificationReducer';
import billingInfoReducer from './billingInfoReducer';
import onboardingReducer from '../features/onboarding/redux/onboardingReducer';
import ignoredUrlsReducer from './ignoredUrlsReducer';
import invitationReducer from './invitationReducer';
import usageStatisticsReducer from '../features/usage-statistics/redux/usageStatisticsReducer';
import urlFilterModalReducer from '../features/urlFilterModal/redux/urlFilterModalReducer';
import seoScoreModalReducer from '../features/seoScoreModal/redux/seoScoreModalReducer';
import keycloakReducer from './keycloakReducer';
import settingsReducer from '../features/settings/redux/settingsReducer';
import domainReducer from './domainReducer';

// combineReducers, gets called in store.js by configureStore
export default {
  page: pageReducer,
  prerenderUser: prerenderUserReducer,
  cachedPages: cachedPagesReducer,
  crawlerActivity: crawlerActivityReducer,
  crawlerStats: crawlerStatsReducer,
  recacheQueue: recacheQueueReducer,
  cachingActivity: cachingActivityReducer,
  cachingStats: cachingStatsReducer,
  dashboardStats: dashboardStatsReducer,
  urlParams: urlParamsReducer,
  ignoredUrls: ignoredUrlsReducer,
  invitation: invitationReducer,
  pricingPage: pricingPageReducer,
  emailVerification: emailVerificationReducer,
  billingInfo: billingInfoReducer,
  onboarding: onboardingReducer,
  usageStatistics: usageStatisticsReducer,
  urlFilterModal: urlFilterModalReducer,
  seoScoreModal: seoScoreModalReducer,
  keycloak: keycloakReducer,
  settings: settingsReducer,
  domains: domainReducer,
};
