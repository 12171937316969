import { Flex, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { memo } from 'react';
import { usePrerenderUser } from '../../../../../../hooks/usePrerenderUser';
import { useEvent } from '../../../../../events/hooks/useEvent';
import { formatIntegration } from '../../../../../../utils/formatIntegration';

export const defaultIntegrationMethods = [
  'cloudflare',
  'fastly',
  'node',
  'nginx',
  'apache',
  'magento-2',
  'ruby',
  'NextJS',
  'iis',
  'other',
];
const ONBOARDING_IMAGE_PATH = '/assets/img/onboarding/';
const GAP = 8;

const { Text } = Typography;

const Integrations = ({ selectedMethod, setSelectedMethod }) => {
  return (
    <Flex vertical gap={GAP}>
      {defaultIntegrationMethods.map((integration) => (
        <div key={integration} className="h-100">
          <button
            type="button"
            className="btn w-100"
            style={{
              background: selectedMethod === integration ? '#E8FAE5' : null,
              borderColor: '#2DA01D',
            }}
            onClick={() => {
              setSelectedMethod(integration);
            }}
          >
            <Flex>
              {selectedMethod === integration && (
                <div style={{ position: 'absolute', color: '#2DA01D', right: 28 }}>
                  <CheckCircleFilled />
                </div>
              )}
              <img
                src={`${ONBOARDING_IMAGE_PATH + integration.toLowerCase()}.svg`}
                width="24px"
                height="24px"
                alt={integration}
                style={{ marginRight: 8 }}
              />
              <Text>{formatIntegration(integration)}</Text>
            </Flex>
          </button>
        </div>
      ))}
    </Flex>
  );
};

export default memo(Integrations);
