import { Content } from 'antd/lib/layout/layout';
import { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';

const META_TAGS = {
  title: 'Quality Assurance - Prerender.io',
};
const QualityAssuranceRouter = lazy(() => import('./quality-assurance/QualityAssuranceRouter'));
const Loading = () => <h1>Loading...</h1>;

const QualityAssurancePage = ({ user }) => {
  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Content>
        <Suspense fallback={<Loading />}>{user ? <QualityAssuranceRouter user={user} /> : <Loading />}</Suspense>
      </Content>
    </AdminTemplate>
  );
};

const mapStateToProps = (state) => ({
  user: state.prerenderUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(QualityAssurancePage));
