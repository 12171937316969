import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Input, Table, ConfigProvider, Empty, Flex, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import {
  addIgnoredUrl,
  deleteIgnoredUrl,
  listIgnoredUrls,
  listIgnoredUrlsKeepErrorCode,
  updateIgnoredUrl,
} from '../../../actions/ignoredUrlsActions';
import { ruleConfigIntoRule } from '../../../assets/ignoreUrlsRules';
import { Card } from '../../../components/CssFrameworkComponents';
import ErrorLoader from '../../../components/ErrorLoader';
import { CheckListOptions } from '../../../components/PageElementsLib';
import { DeletePageButton, SaveButton } from '../../../components/PagesActionButtons';
import { useEvent } from '../../events/hooks/useEvent';
import { usePrerenderUser, useUserRole } from '../../../hooks/usePrerenderUser';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';

const defaultRuleType = 'CONTAINS';

const urlRuleDeletedEventName = 'URL Rule Deleted';

const tabCm = {
  card: {
    title: "Pages matching these rules won't be cached. 404 will be served instead",
    addedNotification:
      'New rule added. It may take an hour to take effect. Please also note that the URLs that are already cached stays in the cache. If you wish to delete them you need to do it manually',
    updatedNotification: 'Updated. It may take an hour to take effect',
    deletedNotification: 'Deleted. It may take an hour to take effect',
    youDontHaveAnyRulesYet: "You don't have any rules yet. You can add rules below",
  },
  contains: 'Contains',
  wildcard: 'Wildcard',
  table: {
    header: {
      pattern: 'Pattern',
      type: 'Type of match',
      test: 'Test',
      actions: 'Action',
    },
    containsPlaceholder: 'text',
    wildcardPlaceholder: 'pattern',
    noItems: 'You can tell prerender to not cache certain pages and send 404 to the crawlers',
    saveButtonTooltip: 'Update the rule',
    deleteButtonTooltip: 'Delete the rule',
    massOperationIgnoredUrls: 'Ignored URL',
  },
  saveTheNewRuleButton: 'Save the new rule',
  youCanTryTheNewRuleHere: 'You can try the new rule here',
  testUrlPlaceholder: 'Test URL',
  noteItMayTakeAnHour: 'Note: It may take an hour for the new rules to propagate to the rendering servers.',
  noteDeletedUrls:
    'Pages that are already in the cache and match the rules will stay in the cache. You need to delete them manually.',
  testMatch: 'Match',
  testNotMatch: 'Not match',
};

const MatchBadge = ({ tabCm, isMatch }) => (
  <span
    className={`badge ${isMatch ? 'ml-2 bg-soft-success' : 'ml-1 bg-warning'}`}
    style={{ fontSize: '90%', fontWeight: '500' }}
  >
    {isMatch ? tabCm.testMatch : tabCm.testNotMatch}
  </span>
);

const IgnoredUrlsSettings = (params) => {
  const { ignoredUrls, lastErrorCode } = params;
  const {
    listIgnoredUrls: doListIgnoredUrls,
    listIgnoredUrlsKeepErrorCode: doListIgnoredUrlsKeepErrorCode,
    addIgnoredUrl: doAddIgnoredUrl,
    updateIgnoredUrl: doUpdateIgnoredUrl,
    deleteIgnoredUrl: doDeleteIgnoredUrl,
  } = params;

  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState(ignoredUrls.items);
  const [newRuleType, setNewRuleType] = useState(defaultRuleType);
  const [newRulePattern, setNewRulePattern] = useState('');
  const [testUrl, setTestUrl] = useState('');
  // null: not doing matching because something is empty. true: match, false: no match
  const [testUrlMatch, setTestUrlMatch] = useState(null);
  const [userNotification, setUserNotification] = useState(null);
  const { track } = useEvent();
  const user = usePrerenderUser();
  const userRole = useUserRole();

  // This means the user is able to define this
  const isUserDefined = (type) => type === 'CONTAINS' || type === 'WILDCARD';

  const deselectAll = () => {
    setSelectedItems([]);
  };

  const changeUserNotification = (message) => {
    if (lastErrorCode) setUserNotification(null);
    else setUserNotification(message);
  };

  const hasRuleChanged = (rule) => {
    const originalItems = ignoredUrls.items;
    const currentRule = items.find((it) => it.id === rule.id);
    if (!currentRule) return false;

    const originalRule = originalItems.find((it) => it.id === rule.id);
    if (!originalRule) return false;

    return originalRule.pattern !== currentRule.pattern || originalRule.type !== currentRule.type; // add response when it's supported
  };

  const patternChanged = (ruleId, pattern) => {
    const newItems = items.reduce((acc, act) => {
      if (act.id === ruleId) {
        return [...acc, { ...act, pattern }];
      } else {
        return [...acc, act];
      }
    }, []);
    setItems(newItems);
  };

  const typeChanged = (ruleId, type) => {
    const newItems = items.reduce((acc, act) => {
      if (act.id === ruleId) {
        return [...acc, { ...act, type }];
      } else {
        return [...acc, act];
      }
    }, []);
    setItems(newItems);
  };

  const updateButtonClicked = (ruleId) => {
    const item = items.find((it) => it.id === ruleId);
    if (!item) return;
    doUpdateIgnoredUrl({ id: ruleId, type: item.type, pattern: item.pattern })
      .then(() => changeUserNotification(tabCm.card.updatedNotification))
      .then(doListIgnoredUrlsKeepErrorCode);
  };
  const deleteButtonClicked = (ruleId) => {
    doDeleteIgnoredUrl(ruleId)
      .then(() => {
        changeUserNotification(tabCm.card.deletedNotification);
        track(urlRuleDeletedEventName, {
          subscription_plan: user.chargebeePlanId,
          is_bulk_action: false,
        });
      })
      .then(doListIgnoredUrlsKeepErrorCode);
  };
  const deleteSelectedRulesClicked = () => {
    Promise.all(selectedItems.map((it) => doDeleteIgnoredUrl(it.id)))
      .then(deselectAll)
      .then(() => {
        changeUserNotification(tabCm.card.deletedNotification);
        track(urlRuleDeletedEventName, {
          subscription_plan: user.chargebeePlanId,
          is_bulk_action: selectedItems.length > 1,
        });
      })
      .then(doListIgnoredUrlsKeepErrorCode);
  };
  const saveNewUrlClicked = () => {
    const newItem = { type: newRuleType, pattern: newRulePattern };
    setNewRulePattern(''); // null won't delete the pattern it just won't change it
    setNewRuleType(defaultRuleType);
    setTestUrl(''); // null won't delete the url, it just won't change it
    doAddIgnoredUrl(newItem)
      .then(() => {
        changeUserNotification(tabCm.card.addedNotification);
        track('New Rule Created', { subscription_plan: user.chargebeePlanId });
      })
      .then(doListIgnoredUrlsKeepErrorCode);
  };

  const ruleTypeToNewRulePlaceholder = (type) => {
    switch (type) {
      case 'CONTAINS':
        return tabCm.table.containsPlaceholder;
      case 'WILDCARD':
        return tabCm.table.wildcardPlaceholder;
      default:
        return tabCm.table.wildcardPlaceholder;
    }
  };

  const ruleTypeToText = (type) => {
    switch (type) {
      case 'REGEX':
        return 'Regex';
      case 'CONTAINS':
        return 'Contains';
      case 'WILDCARD':
        return 'Wildcard';
      case 'REGEX_NOT_MATCH':
        return 'Regex not match';
      case 'NOT_CONTAINS':
        return 'Not contains';
      case 'WILDCARD_NOT_MATCH':
        return 'Wildcard not match';
      default:
        return type;
    }
  };

  useEffect(() => {
    doListIgnoredUrls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // I don't want to get the list again even if the getter function changes

  useEffect(() => {
    setItems(ignoredUrls.items);
  }, [ignoredUrls.items]);

  // Test the rule if something has changed
  useEffect(() => {
    if (!testUrl || !newRulePattern) {
      setTestUrlMatch(null);
    } else {
      const ruleConfig = { type: newRuleType, pattern: newRulePattern, response: { status: 404 } };
      const rule = ruleConfigIntoRule(ruleConfig);
      const isMatch = !!rule.test(testUrl);

      setTestUrlMatch(isMatch);
    }
  }, [newRuleType, newRulePattern, testUrl]);

  useEffect(() => {
    if (lastErrorCode) setUserNotification(null);
  }, [lastErrorCode]);

  const getColumns = () => {
    const { header } = tabCm.table;
    return [
      {
        title: header.type,
        key: 'type',
        dataIndex: 'param',
        className: 'col-min',
        render: (_text, item) => {
          const userDefined = isUserDefined(item.type);
          if (userDefined)
            return (
              <Select
                aria-label="code match"
                onChange={(value) => typeChanged(item.id, value)}
                defaultValue={item.type}
                size="large"
              >
                <Select.Option value="CONTAINS">{tabCm.contains}</Select.Option>
                <Select.Option value="WILDCARD">{tabCm.wildcard}</Select.Option>
              </Select>
            );

          return ruleTypeToText(item.type);
        },
      },
      {
        title: header.pattern,
        key: 'pattern',
        dataIndex: 'pattern',
        width: '99%',
        editable: true,
        render: (_text, item) => {
          const userDefined = isUserDefined(item.type);

          return (
            <div className="text-truncate">
              <Input
                size="large"
                placeholder={ruleTypeToNewRulePlaceholder(item.type)}
                disabled={!userDefined}
                value={item.pattern}
                onChange={(ev) => {
                  patternChanged(item.id, ev.target.value);
                }}
              />
            </div>
          );
        },
      },
      {
        title: header.actions,
        key: 'action',
        width: '1%',
        render: (_text, item) => (
          <Flex>
            <SaveButton
              onClick={() => updateButtonClicked(item.id)}
              tooltip={tabCm.table.saveButtonTooltip}
              disabled={ignoredUrls.inProgress || !hasRuleChanged(item)}
            />
            <DeletePageButton
              onClick={() => deleteButtonClicked(item.id)}
              tooltip={tabCm.table.deleteButtonTooltip}
              disabled={ignoredUrls.inProgress}
            />
          </Flex>
        ),
      },
    ];
  };

  return (
    <>
      <ErrorLoader code={null} custom={userNotification} scrollTop>
        <div className="mb-4">
          <Alert className="col-12" showIcon type="success" message={userNotification} />
        </div>
      </ErrorLoader>
      <Alert
        showIcon
        icon={<InfoCircleOutlined />}
        type="info"
        style={{ marginBottom: '22px' }}
        // eslint-disable-next-line max-len
        message="This feature is designed to prevent unwanted URLs matching a certain pattern, from getting cached. Our system will respond with a 404 status code for requests made for these URLs."
      />
      <Card title="Ignored URLs">
        <ConfigProvider
          renderEmpty={() => (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={tabCm.card.youDontHaveAnyRulesYet} />
          )}
        >
          <Table
            bordered={!!items.length}
            rowKey="id"
            loading={ignoredUrls.inProgress}
            rowSelection={{
              type: 'checkbox',
              onChange: (_selectedRowIds, selectedRows) => setSelectedItems(selectedRows),
              getCheckboxProps: (_record) => ({
                disabled: userRole === USER_ROLES.BILLING_MANAGER || userRole === USER_ROLES.GUEST,
              }),
            }}
            columns={getColumns()}
            dataSource={items}
            pagination={false}
            showHeader={!!items.length}
            scroll={{ x: true }}
          />
        </ConfigProvider>
      </Card>

      {/* Create new rule */}
      <div className="col-12 ml-2 mb-3 text-muted">Create a new rule</div>
      <div className="d-flex">
        <Select onChange={setNewRuleType} defaultValue={newRuleType} size="large">
          <Select.Option value="CONTAINS">{tabCm.contains}</Select.Option>
          <Select.Option value="WILDCARD">{tabCm.wildcard}</Select.Option>
        </Select>
        <div className="text-truncate w-100 mb-3">
          <Input
            size="large"
            placeholder={ruleTypeToNewRulePlaceholder(newRuleType)}
            disabled={false}
            value={newRulePattern}
            onChange={(ev) => setNewRulePattern(ev.target.value)}
          />
        </div>
      </div>
      <div className="col-12 ml-2 mb-3 text-muted">
        {tabCm.youCanTryTheNewRuleHere}{' '}
        {testUrlMatch === null ? null : <MatchBadge tabCm={tabCm} isMatch={testUrlMatch} />}
      </div>
      <div className="text-truncate w-100 mb-3">
        <Input
          size="large"
          placeholder={tabCm.testUrlPlaceholder}
          value={testUrl}
          onChange={(ev) => setTestUrl(ev.target.value)}
        />
      </div>
      <div className="col-12 mb-3 text-muted">
        <ButtonWithRole
          disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
          disabled={newRulePattern === ''}
          type="primary"
          onClick={saveNewUrlClicked}
        >
          {tabCm.saveTheNewRuleButton}
        </ButtonWithRole>
      </div>
      <div className="col-12 ml-2 mb-3">
        <p>{tabCm.noteItMayTakeAnHour}</p>
        <p>{tabCm.noteDeletedUrls}</p>
      </div>

      <CheckListOptions
        show={true}
        count={selectedItems.length}
        name={tabCm.table.massOperationIgnoredUrls}
        actions={[{ name: 'deleteParams', icon: 'trash-2' }]}
        onsubmit={deleteSelectedRulesClicked}
        isActive={!ignoredUrls.inProgress}
        onclose={() => deselectAll()}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    ignoredUrls: state.ignoredUrls,
    lastErrorCode: state.page.lastErrorCode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      listIgnoredUrls,
      listIgnoredUrlsKeepErrorCode,
      addIgnoredUrl,
      updateIgnoredUrl,
      deleteIgnoredUrl,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(IgnoredUrlsSettings);
