import { update } from 'lodash';
import { restApi } from '../api/restApiSlice';

const keywordsDomainsApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableDomains: builder.query({
      query: (params) => ({ url: `/domains`, method: 'GET', params }),
      transformResponse: (response) => response.domains,
      providesTags: ['availableDomains'],
    }),
    getRankedDomains: builder.query({
      query: (params) => ({ url: `/domain-keyword-rankings`, method: 'GET', params }),
      transformResponse: (response) => ({
        totalCount: response.totalCount,
        totalKeywords: response.totalKeywords,
        usedKeywords: response.usedKeywords,
        domains: response.data,
      }),
      providesTags: ['rankedDomains'],
    }),
    getRankedDomain: builder.query({
      query: (id) => ({ url: `/domain-keyword-rankings/${id}`, method: 'GET' }),
      transformResponse: (response) => ({ ...response, ...response.data }),
      providesTags: ['rankedDomains'],
    }),
    creteRankedDomain: builder.mutation({
      query: (body) => ({ url: `/domain-keyword-rankings`, method: 'POST', body }),
      invalidatesTags: ['rankedDomains'],
    }),
    updateRankedDomain: builder.mutation({
      query: ({ id, body }) => ({ url: `/domain-keyword-rankings/${id}`, method: 'PUT', body }),
      invalidatesTags: ['rankedDomains'],
    }),
    deleteRankedDomain: builder.mutation({
      query: (id) => ({ url: `/domain-keyword-rankings/${id}`, method: 'DELETE' }),
      invalidatesTags: ['rankedDomains'],
    }),
  }),
});

export const {
  useGetAvailableDomainsQuery,
  useLazyGetAvailableDomainsQuery,
  useGetRankedDomainQuery,
  useGetRankedDomainsQuery,
  useUpdateRankedDomainMutation,
  useCreteRankedDomainMutation,
  useDeleteRankedDomainMutation,
} = keywordsDomainsApi;
