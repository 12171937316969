import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Collapse, Flex, Progress, Space, Tooltip, Typography, theme } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEvent } from '../events/hooks/useEvent';
import { useEffect, useState } from 'react';

const GETTING_STARTED_STEPS = {
  REGISTRATION: 'registrationCompleted',
  INTEGRATION_WIZARD: 'integrationWizardCompleted',
  QUERY_PARAMETERS: 'queryParameters',
  SITEMAPS: 'reviewSitemaps',
  INVITE_TEAM_MEMBER: 'teamInvited',
};

const CollapseItemDescription = ({ children }) => {
  const { token } = theme.useToken();
  return <Typography.Text style={{ color: token.colorTextSecondary }}>{children}</Typography.Text>;
};

const getStartedCheckedItems = [
  {
    key: GETTING_STARTED_STEPS.REGISTRATION,
    title: 'Finish registration',
    buttonTitle: null,
    path: null,
  },
  {
    key: GETTING_STARTED_STEPS.INTEGRATION_WIZARD,
    title: 'Complete site integration',
    buttonTitle: 'Integrate Your Site',
    path: '/integration-wizard',
    event: 'Finish setup: Integrate your site clicked',
    description: 'This will forward crawler requests to our Prerender service, enabling us to serve your cached pages.',
  },
  {
    key: GETTING_STARTED_STEPS.QUERY_PARAMETERS,
    title: 'Identify query parameters for caching',
    buttonTitle: 'Set Up Query Parameters',
    path: '/cache#?page=1&tab=urlParams',
    event: 'Finish setup: Query parameters clicked',
    description:
      'Adjust the default caching and rendering behavior to ensure that the right parts of your site are cached and served.',
  },
  {
    key: GETTING_STARTED_STEPS.SITEMAPS,
    title: 'Review sitemaps',
    buttonTitle: 'Activate Your Sitemaps',
    path: '/sitemaps',
    event: 'Finish setup: Sitemaps clicked',
    description: 'Enable periodic sitemap crawling. This allows us to automatically render and cache any new pages.',
  },
  {
    key: GETTING_STARTED_STEPS.INVITE_TEAM_MEMBER,
    title: 'Invite Your Team',
    buttonTitle: 'Invite Team Members',
    path: '/security/team-members',
    event: 'Finish setup: Invite team members clicked',
    description: 'Share your dashboard with whoever needs it. Add unlimited users—no extra costs.',
  },
];

const CheckerSteps = ({ stepStatuses, onStepCompleted, closePopup }) => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const { track } = useEvent();
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (stepStatuses) {
      const completedSteps = Object.values(stepStatuses).filter(Boolean).length;
      const totalSteps = Object.keys(stepStatuses).length;
      setPercentage((completedSteps / totalSteps) * 100);
    }
  }, [stepStatuses]);

  const onCheckerStepClick = (item) => {
    if (
      item.key !== GETTING_STARTED_STEPS.INTEGRATION_WIZARD &&
      item.key !== GETTING_STARTED_STEPS.INVITE_TEAM_MEMBER &&
      !stepStatuses[item.key]
    ) {
      onStepCompleted(item.key);
    }
    track(item.event);
    closePopup();
    navigate(item.path);
  };

  const checkIntegrationIncomplete = (key) =>
    !stepStatuses[GETTING_STARTED_STEPS.INTEGRATION_WIZARD] &&
    (key === GETTING_STARTED_STEPS.QUERY_PARAMETERS || key === GETTING_STARTED_STEPS.SITEMAPS);

  return (
    <Space direction="vertical" style={{ width: '100%', minWidth: 350 }}>
      <Flex gap={8} justify="space-between">
        <Typography.Text strong>Get Started</Typography.Text>
        <Typography.Text type="secondary">{percentage}% completed</Typography.Text>
      </Flex>
      <Progress percent={percentage} showInfo={false} />
      {getStartedCheckedItems.map((item, index) => (
        <Collapse
          key={index}
          expandIconPosition="right"
          style={{ width: '100%', maxWidth: 350, marginBottom: 4, background: 'white', border: 'none' }}
        >
          <Collapse.Panel
            header={
              <Space align="center">
                {stepStatuses[item.key] ? (
                  <CheckCircleFilled style={{ color: token.colorPrimary, fontSize: 20 }} />
                ) : (
                  <CheckCircleOutlined style={{ color: 'rgba(38, 38, 38, 0.15)', fontSize: 20 }} />
                )}
                <Typography.Text style={{ paddingLeft: 12 }}>{item.title}</Typography.Text>
              </Space>
            }
            collapsible={item.key === GETTING_STARTED_STEPS.REGISTRATION ? 'disabled' : 'header'}
          >
            <CollapseItemDescription>{item.description}</CollapseItemDescription>
            <Tooltip title={checkIntegrationIncomplete(item.key) ? 'Complete integration before proceeding' : null}>
              {item.buttonTitle &&
                !(
                  item.key === GETTING_STARTED_STEPS.INTEGRATION_WIZARD &&
                  stepStatuses[GETTING_STARTED_STEPS.INTEGRATION_WIZARD]
                ) && (
                  <Button
                    type="default"
                    style={{ minWidth: 200, width: '100%', marginTop: 8 }}
                    onClick={() => onCheckerStepClick(item)}
                    disabled={checkIntegrationIncomplete(item.key)}
                  >
                    {item.buttonTitle}
                  </Button>
                )}
            </Tooltip>
          </Collapse.Panel>
        </Collapse>
      ))}
    </Space>
  );
};

export default CheckerSteps;
