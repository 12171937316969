import { useMemo } from 'react';
import { Spin, Button, Alert, Typography } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { usePaymentChargebeeInfo } from '../hooks/usePaymentChargebeeInfo';
import { InviteTeamMember } from '../../teams';
import { useUserRole } from '../../../hooks/usePrerenderUser';

export const BillingChangePlanConfirmModal = ({ pendingNewPlan, currentPlanIndex, onConfirmPlanChange, onClose }) => {
  const { isPaymentInfoValid, isLoadingIsPaymentInfoValid, onChangeBillingAddress, onChangePaymentMethod } =
    usePaymentChargebeeInfo();

  const userRole = useUserRole();

  const isSubmitButtonEnabled = useMemo(() => {
    return (
      isPaymentInfoValid?.data?.hasPaymentMethod === true &&
      isPaymentInfoValid?.data?.isAddressValid === true &&
      isLoadingIsPaymentInfoValid === false
    );
  }, [isPaymentInfoValid, isLoadingIsPaymentInfoValid]);

  const isUpgrading = pendingNewPlan.index > currentPlanIndex;

  const upgradeDowngradeText = isUpgrading ? 'upgrade' : 'downgrade';

  return (
    <div style={{ maxWidth: '600px', fontSize: '16px' }} className="mt-4">
      {isUpgrading ? (
        <Typography.Title level={5} strong>
          Thank you for choosing the “{pendingNewPlan.name}” plan! 
        </Typography.Title>
      ) : (
        <>
          <p className="font-weight-bold">{`You are about to ${upgradeDowngradeText} to the "${pendingNewPlan.name}" plan.`}</p>
          <p>{`Are you sure you want to ${upgradeDowngradeText} to "${pendingNewPlan.name}"?`}</p>
        </>
      )}

      <p>
        You will be charged the prorated amount now, along with the full monthly fee (plus overages) each payment
        period.
      </p>
      {pendingNewPlan.name === 'Free' && (
        <p>
          Please note that any renders above the amount of 1000 included in the Free plan will be responded to with a
          429 status code and will not include any overcharges.
        </p>
      )}
      <p>Want your billing team to handle it? Invite your coworkers.</p>
      <InviteTeamMember
        source="Billing Change Plan Confirm Modal"
        style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}
        selectRoles={true}
        userRole={userRole}
        buttonLabel="Invite Your Billing Manager"
      />
      <div className="mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className="card-text fw-light d-flex align-items-center mb-0">Payment Method</p>
          {!isLoadingIsPaymentInfoValid && (
            <>
              {!isPaymentInfoValid?.data?.hasPaymentMethod ? (
                <ExclamationCircleOutlined style={{ color: '#d16759' }} />
              ) : (
                <CheckCircleOutlined style={{ color: '#6FC49D' }} />
              )}
            </>
          )}
          {isLoadingIsPaymentInfoValid ? <Spin /> : null}
        </div>
        {!isLoadingIsPaymentInfoValid && (
          <div className="rounded border p-3 d-flex justify-content-between align-items-center">
            <div>
              {!isPaymentInfoValid?.data?.hasPaymentMethod ? (
                <p className="card-text fw-light text-danger">You need to add a payment method to proceed.</p>
              ) : (
                <p className="card-text fw-light">Your payment method is set.</p>
              )}
            </div>
            <Button onClick={onChangePaymentMethod} type="primary">
              {!isPaymentInfoValid?.data?.hasPaymentMethod ? 'Add' : 'Change'}
            </Button>
          </div>
        )}
      </div>
      <div className="mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <p className="card-text fw-light d-flex align-items-center mb-0">Billing Address</p>
          {!isLoadingIsPaymentInfoValid && (
            <>
              {!isPaymentInfoValid?.data?.isAddressValid ? (
                <ExclamationCircleOutlined style={{ color: '#d16759' }} />
              ) : (
                <CheckCircleOutlined style={{ color: '#6FC49D' }} />
              )}
            </>
          )}
          {isLoadingIsPaymentInfoValid ? <Spin /> : null}
        </div>
        {!isLoadingIsPaymentInfoValid && (
          <div className="rounded border p-3 d-flex justify-content-between align-items-center">
            <div>
              {!isPaymentInfoValid?.data?.isAddressValid ? (
                <p className="card-text fw-light text-danger">You need to add a billing address to proceed.</p>
              ) : (
                <p className="card-text fw-light">A billing address is set.</p>
              )}
            </div>
            <Button onClick={onChangeBillingAddress} type="primary">
              {!isPaymentInfoValid?.data?.isAddressValid ? 'Add' : 'Change'}
            </Button>
          </div>
        )}
      </div>
      <div className="mt-5 d-flex justify-content-between font-weight-bold">
        <span>Price</span> <span>{`$${pendingNewPlan.costInCents / 100}`}</span>
      </div>
      <hr />
      {!isUpgrading && (
        <Alert
          description="Please note, that canceling or downgrading your plan does not automatically adjust your add-on packages. If
          you wish to modify or remove packages, please do so separately."
          type="warning"
          showIcon
        />
      )}
      <div className="mt-5 d-flex justify-content-between pb-5">
        <Button style={{ fontSize: '16px' }} onClick={onClose} type="default">
          Cancel
        </Button>
        <Button
          onClick={() => onConfirmPlanChange(pendingNewPlan.id)}
          type="primary"
          className="ml-2"
          disabled={isSubmitButtonEnabled !== true}
        >
          {`${pendingNewPlan.index > currentPlanIndex ? 'Upgrade' : 'Downgrade'} to ${pendingNewPlan.name}`}
        </Button>
      </div>
    </div>
  );
};
