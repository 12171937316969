// Define action creators that dispatch actions:
import config from '../assets/config';
import internalApi, { THROW_ON_ERROR_V2 } from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';
import { setLastErrorCode, clearLastErrorCode } from './pageActions';

import {
  IGNORED_URLS_SET,
  IGNORED_URLS_IN_PROGRESS,
} from './types';

const defaultResponse = ({ status: 404 });

const requestUri = `${config.apiUrl.dataServer}/api/v2/ignored-urls`;
const requestUriWithId = (id) => `${config.apiUrl.dataServer}/api/v2/ignored-urls/${id}`;

const listIgnoredUrlsGeneric = (keepErrorCode) => (params) => async (dispatch) => {
  try {
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: true });
    const data = await internalApi('get', requestUri, null, THROW_ON_ERROR_V2);
    dispatch({ type: IGNORED_URLS_SET, payload: { data, params } });
    if (!keepErrorCode) dispatch(clearLastErrorCode());
  } catch (error) {
    console.log(error);
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: false });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

export const listIgnoredUrls = listIgnoredUrlsGeneric(false);
export const listIgnoredUrlsKeepErrorCode = listIgnoredUrlsGeneric(true);

export const addIgnoredUrl = (item, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: true });
    await internalApi('put', requestUri, { ...item, response: defaultResponse }, THROW_ON_ERROR_V2);
    dispatch(clearLastErrorCode());
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: false });
    if (onSuccess) onSuccess(item);
  } catch (error) {
    console.log(error);
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: false });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

export const updateIgnoredUrl = (ignoredUrl, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: true });
    await internalApi('put', requestUriWithId(ignoredUrl.id), { ...ignoredUrl, response: defaultResponse }, THROW_ON_ERROR_V2);
    dispatch(clearLastErrorCode());
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: false });
    if (onSuccess) onSuccess();
  } catch (error) {
    console.log(error);
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: false });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

export const deleteIgnoredUrl = (id, onSuccess) => async (dispatch) => {
  try {
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: true });
    await internalApi('delete', requestUriWithId(id), {}, THROW_ON_ERROR_V2);
    dispatch(clearLastErrorCode());
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: false });
    if (onSuccess) onSuccess();
  } catch (error) {
    console.log(error);
    dispatch({ type: IGNORED_URLS_IN_PROGRESS, payload: false });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};
