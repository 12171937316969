import { Route, Routes } from 'react-router-dom';

import requireAuth from '../components/hocs/requireAuth';
import { DomainsListPage, DomainDetailsPage } from '../features/RankTracker';

function RankTracker() {
  return (
    <Routes>
      <Route path="" element={<DomainsListPage />} />
      <Route path=":domainId" element={<DomainDetailsPage />} />
    </Routes>
  );
}

export default requireAuth(RankTracker);
