// imported by /reducers/index.js
// holds road search parameters and result

import { matchWith } from '../assets/lib';

import {
  CACHINGSTATS_SET,
  CACHINGSTATS_IN_PROGRESS,
  CACHINGSTATS_RESET,
  CACHINGSTATS_SET_PARAMS,
} from '../actions/types';

const emptyState = {
  inProgress: false,
  params: {
    query: '',
    statusCode: null,
    responseTime: null,
    timePeriod: null,
  },
  stats: {
    total: 0,
    amt: 0,
  },
  requests: [],
};

const initialState = {
  overview: emptyState,
  errorStats: emptyState,
};

export default function cachingStatsReducer(state = initialState, action) {
  const { payload } = action;
  const bucket = payload && payload.stateBucket ? payload.stateBucket : 'overview';
  switch (action.type) {
    case CACHINGSTATS_SET:
    {
      const dl = payload.data.length;
      const newState = {
        inProgress: false,
        params: matchWith(emptyState.params, payload.params),
        stats: { total: dl, amt: dl },
        requests: payload.data,
      };
      return { ...state,
        overview: bucket === 'overview' ? newState : state.overview,
        errorStats: bucket === 'errorStats' ? newState : state.errorStats,
      };
    }
    case CACHINGSTATS_IN_PROGRESS:
      return { ...state,
        overview: bucket === 'overview' ? { ...state.overview, inProgress: payload.value } : state.overview,
        errorStats: bucket === 'errorStats' ? { ...state.errorStats, inProgress: payload.value } : state.errorStats,
      };

    case CACHINGSTATS_SET_PARAMS:
      return { ...state,
        overview: bucket === 'overview' ? { ...state.overview, params: payload.params } : state.overview,
        errorStats: bucket === 'errorStats' ? { ...state.errorStats, params: payload.params } : state.errorStats,
      };

    case CACHINGSTATS_RESET:
      return initialState;
    default:
  }
  return state;
}
