import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContent, setLastErrorCode } from '../actions/pageActions';
import { Alert } from './CssFrameworkComponents';
import ErrorLoader from './ErrorLoader';
import ListPickerModal from './ListPickerModal';
import { ModalBodyScroller, ModalHeader, ModalWrapper } from './ModalLib';
import { ControlButton, IconMore } from './PageElementsLib';
import { TCell, TRow, TRowSpacer, TSectionHeader, TWrapper } from './TableLib';

const cid = 'ActivitySearchEditor';

const rangeCheckInitialState = (params) => !params.statusCodeEq && (params.statusCodeLow || params.statusCodeHigh);

const filterInputStyle = { maxWidth: '6em' };
const titleStyle = { width: '8em' };
const filterInputClasses = 'no-border text-right m-3 pt-1';
class ActivitySearchEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: 'overview',
      params: { ...props.activityData.params },
      customMessage: null,
      rangeChecked: rangeCheckInitialState(props.activityData.params),
    };
    this.callParentEventHandler = props.onEvent;
  }

  componentDidMount() {
    const { cm, getContent: doGetContent, language } = this.props;
    if (!cm) doGetContent({ component: 'ActivitySearchEditor', lang: language });
  }

  componentWillUnmount() {
    this.props.setLastErrorCode('');
  }

  saveForm(newParams) {
    const { statusCodeEq, statusCodeLow, statusCodeHigh, responseTimeLow, responseTimeHigh, timedout, userAgent } =
      newParams;
    this.callParentEventHandler({
      action: 'updateSearchParams',
      // payload: { query, statusCode, responseTime, timePeriod: params.timePeriod, cacheStatus: params.cacheStatus, userAgent }
      payload: { statusCodeEq, statusCodeLow, statusCodeHigh, responseTimeLow, responseTimeHigh, timedout, userAgent },
    });
  }

  switchViewMode(
    viewMode,
    { query, statusCodeEq, statusCodeLow, statusCodeHigh, responseTimeLow, responseTimeHigh, timedout, userAgent }
  ) {
    // also save existing from data in state:
    this.setState({
      viewMode,
      params: {
        ...this.state.params,
        query,
        statusCodeEq,
        statusCodeLow,
        statusCodeHigh,
        responseTimeLow,
        responseTimeHigh,
        timedout,
        userAgent,
      },
    });
  }

  didReceiveAction({ action, payload }) {
    const { params } = this.state;
    switch (action) {
      case 'showOverview':
        this.setState({ viewMode: 'overview' });
        break;
      // case 'changeTimePeriod':
      //   this.setState({ viewMode: 'overview', params: { ...params, timePeriod: payload[0] === 'any' ? null : payload[0] }});
      //   break;
      // case 'changeCacheStatus':
      //   this.setState({ viewMode: 'overview', params: { ...params, cacheStatus: payload[0] === 'any' ? null : payload[0] }});
      //   break;
      case 'changeUserAgent':
        this.setState({
          viewMode: 'overview',
          params: { ...params, userAgent: payload[0] === 'any' ? null : payload[0] },
        });
        break;
      default:
        console.warn(`Unknown action ${action}`); // TODO: handle this case
    }
  }

  statusCodeRangeFields() {
    const { cm } = this.props;
    return (
      <>
        <div>
          <Field name="statusCodeLow">
            {({ input }) => (
              <input
                {...input}
                type="text"
                placeholder={cm.statusPhLow}
                className={filterInputClasses}
                style={filterInputStyle}
                id="statusCodeLow"
              />
            )}
          </Field>
        </div>
        <div className="m-3 pt-1">and</div>
        <div>
          <Field name="statusCodeHigh">
            {({ input }) => (
              <input
                {...input}
                type="text"
                placeholder={cm.statusPhHigh}
                className={filterInputClasses}
                style={filterInputStyle}
                id="statusCodeHigh"
              />
            )}
          </Field>
        </div>
      </>
    );
  }

  statusCodeEqualField() {
    const { cm } = this.props;
    return (
      <Field name="statusCodeEq">
        {({ input }) => (
          <input
            {...input}
            type="text"
            placeholder={cm.statusPhEq}
            className={filterInputClasses}
            style={filterInputStyle}
            id="statusCodeEq"
          />
        )}
      </Field>
    );
  }

  timedoutToSelectOption = (timedout) => (timedout === null || timedout === undefined ? 'nothing' : timedout);

  selectedOptionToTimeout = (option) => (option === 'nothing' ? null : option);

  render() {
    const { cm, apiErrTxt, lastErrorCode, parent } = this.props;
    const { customMessage, viewMode, params, rangeChecked } = this.state;
    if (!cm) return null;
    switch (viewMode) {
      case 'overview':
        return (
          <ModalWrapper>
            <Form
              onSubmit={(values) =>
                this.saveForm({ ...values, timedout: this.selectedOptionToTimeout(values.timedout) })
              }
              initialValues={{
                statusCodeEq: params.statusCodeEq,
                statusCodeLow: params.statusCodeLow,
                statusCodeHigh: params.statusCodeHigh,
                responseTimeLow: params.responseTimeLow,
                responseTimeHigh: params.responseTimeHigh,
                timedout: this.timedoutToSelectOption(params.timedout),
                userAgent: params.userAgent,
              }}
              render={({ handleSubmit, form, values }) => (
                <form onSubmit={handleSubmit} className="m-0">
                  <ModalHeader
                    modalHeaderLeft={
                      <ControlButton
                        text={cm.header.btnLeft}
                        onclick={() => this.callParentEventHandler({ action: 'modalCancelled' })}
                      />
                    }
                    modalHeaderRight={<ControlButton text={cm.header.btnRight} action="submit" />}
                    title={cm.header.title}
                  />
                  <ModalBodyScroller>
                    <TWrapper
                      tableId="cuedit"
                      parentType="modal"
                      showTHead={false}
                      cols={[
                        { size: 'min', valign: 'middle' },
                        { size: 'flex', float: 'right', valign: 'middle', addClass: 'text-secondary pr-0' },
                        { size: 'min', float: 'right', valign: 'middle' },
                        { size: 'min', float: 'right', valign: 'middle' },
                      ]}
                    >
                      <ErrorLoader code={lastErrorCode} custom={customMessage} scrollTop>
                        <TRow>
                          <TCell colspan="4" addClass={'text-center p-0'}>
                            <Alert
                              modal={true}
                              content={apiErrTxt[lastErrorCode] ? { text: apiErrTxt[lastErrorCode] } : customMessage}
                            />
                          </TCell>
                        </TRow>
                      </ErrorLoader>
                      {parent === 'crawlerActivity' && <TSectionHeader text={cm.userAgents.title} />}
                      {/*
                        User agent fragment
                      { parent === 'crawlerActivity' &&
                      <TRow>
                        <TCell colspan="3">
                          <Field name="userAgent">
                            {({ input, meta }) => (
                              <input {...input} type="text" placeholder={cm.botPh}className="w-100 no-border" id="userAgent" /> )}
                          </Field>
                        </TCell>
                      </TRow>} */}
                      {parent === 'crawlerActivity' && (
                        <TRow onclick={() => this.switchViewMode('userAgentPicker', values)}>
                          <TCell colspan="2">
                            <div className="ml-3">{cm.userAgents.title}</div>
                          </TCell>
                          <TCell colspan="2" addClass="pr-3">
                            {params.userAgent !== null
                              ? cm.userAgents.options.find((o) => o.param === params.userAgent).name
                              : cm.userAgents.options[0].name}
                            &nbsp;
                            <IconMore />
                          </TCell>
                        </TRow>
                      )}
                      {/*
                      Filter by URL fragment
                      <TSectionHeader text={cm.urlFilter}/>
                      <TRow>
                        <TCell colspan="3">
                          <Field name="query">
                            {({ input, meta }) => ( <input {...input} type="text" placeholder={cm.urlPh} className="w-100 no-border" id="query" /> )}
                          </Field>
                        </TCell>
                      </TRow>
                       */}
                      <TSectionHeader text={cm.otherParams} />
                      {/* Status code */}
                      <TRow>
                        <TCell colspan="3">
                          <div>
                            <div className="pt-1">{cm.status}</div>
                            <div className="d-flex justify-content-start">
                              <div style={titleStyle} className="m-1">
                                <select
                                  className="form-select"
                                  aria-label="code match"
                                  onChange={() => this.setState({ rangeChecked: !rangeChecked })}
                                >
                                  <option value="Equals" defaultValue={!rangeChecked}>
                                    Equals
                                  </option>
                                  <option value="Between" defaultValue={rangeChecked}>
                                    Between
                                  </option>
                                </select>
                              </div>
                              {rangeChecked ? this.statusCodeRangeFields() : this.statusCodeEqualField()}
                            </div>
                          </div>
                        </TCell>
                      </TRow>
                      {/* Response time */}
                      <TRow>
                        <TCell colspan="3">
                          <div>
                            <div className="pt-1">{cm.response}</div>
                            <div className="d-flex justify-content-start">
                              <div className="pt-1 mt-3 pl-3 m-1" style={titleStyle}>
                                Between
                              </div>
                              <Field name="responseTimeLow">
                                {({ input }) => (
                                  <input
                                    {...input}
                                    type="text"
                                    placeholder={cm.responsePhLow}
                                    className={filterInputClasses}
                                    style={filterInputStyle}
                                    id="responseTimeLow"
                                  />
                                )}
                              </Field>
                              <div className="m-3 pt-1">and</div>
                              <Field name="responseTimeHigh">
                                {({ input }) => (
                                  <input
                                    {...input}
                                    type="text"
                                    placeholder={cm.responsePhHigh}
                                    className={filterInputClasses}
                                    style={filterInputStyle}
                                    id="responseTimeHigh"
                                  />
                                )}
                              </Field>
                            </div>
                          </div>
                        </TCell>
                      </TRow>
                      {/* Timed out */}
                      <TRow>
                        <TCell colspan="3">
                          <div>
                            <div className="pt-1">{cm.timedout.title}</div>
                            <div className="d-flex justify-content-start m-1">
                              <Field name="timedout" component="select" className="form-select" aria-label="code match">
                                <option value="nothing">{cm.timedout.options.notImportant}</option>
                                <option value="true">{cm.timedout.options.timedout}</option>
                                <option value="false">{cm.timedout.options.notTimedout}</option>
                              </Field>
                            </div>
                          </div>
                        </TCell>
                      </TRow>
                      {/* Cache status picker */}
                      {/* { parent === 'crawlerActivity' &&
                      <TRow onclick={() => this.switchViewMode('cacheStatusPicker', values)}>
                        <TCell>{cm.cacheStatus}</TCell>
                        <TCell addClass="pr-3">
                          {params.cacheStatus !== null
                            ? cm.cacheStates.options.find(o => o.param === params.cacheStatus).name
                            : cm.cacheStates.options[0].name }
                        </TCell>
                        <TCell><IconMore/></TCell>
                      </TRow>
                      } */}
                      {/* <TRow onclick={() => this.switchViewMode('timePeriodPicker', values)}>
                        <TCell>{cm.period}</TCell>
                        <TCell addClass="pr-3">
                          {params.timePeriod !== null
                            ? cm.timePeriods.options.find(o => o.param === params.timePeriod).name
                            : cm.timePeriods.options[0].name }
                        </TCell>
                        <TCell><IconMore/></TCell>
                      </TRow> */}
                      <TRowSpacer height={parent === 'crawlerActivity' ? '20px' : '150px'} />
                    </TWrapper>
                  </ModalBodyScroller>
                </form>
              )}
            />
          </ModalWrapper>
        );
      // case 'timePeriodPicker':
      //   return (
      //     <ListPickerModal
      //       title={cm.timePeriods.title}
      //       sectionTitles={[cm.timePeriods.section]}
      //       options={cm.timePeriods.options}
      //       selectedOptions={[params.timePeriod || cm.timePeriods.options[0].param]}
      //       limit={1}
      //       onEvent={({ action, payload }) => this.didReceiveAction({ action: action === 'listPickerUpdated'
      //                                                                         ? 'changeTimePeriod'
      //                                                                         :  action, payload })}
      //     />
      //   )
      // case 'cacheStatusPicker':
      //   return (
      //     <ListPickerModal
      //       title={cm.cacheStates.title}
      //       sectionTitles={[cm.cacheStates.section]}
      //       options={cm.cacheStates.options}
      //       selectedOptions={[params.cacheStatus || cm.cacheStates.options[0].param]}
      //       limit={1}
      //       onEvent={({ action, payload }) => this.didReceiveAction({ action: action === 'listPickerUpdated'
      //                                                                         ? 'changeCacheStatus'
      //                                                                         :  action, payload })}
      //     />
      case 'userAgentPicker':
        return (
          <ListPickerModal
            title={cm.userAgents.title}
            sectionTitles={[cm.userAgents.section]}
            options={cm.userAgents.options}
            selectedOptions={[params.userAgent || cm.userAgents.options[0].param]}
            limit={1}
            onEvent={({ action, payload }) =>
              this.didReceiveAction({ action: action === 'listPickerUpdated' ? 'changeUserAgent' : action, payload })
            }
          />
        );
      default:
        console.warn(`Unknown viewMode ${viewMode}`);
        return null;
    }
  }
}

function mapStateToProps(state) {
  return {
    language: state.page.language,
    cm: state.page.contentData[cid] || null,
    apiErrTxt: state.page.contentData.apiError,
    lastErrorCode: state.page.lastErrorCode,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getContent, setLastErrorCode }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivitySearchEditor);
