import React from 'react';
import { Card } from '../../../components/CssFrameworkComponents';
import { Input, Select, Switch } from 'antd';

const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

const postfix = ' Prerender (+https://github.com/prerender/prerender)';
const postfixPattern = `${escapeRegExp(postfix)}$`;
const postfixRegexp = new RegExp(postfixPattern);

const RenderingDeviceCard = ({ title, icon, resolution, userAgent, checkbox, onChangedAction }) => {
  if (!title || !icon || !resolution || !userAgent || !checkbox || !onChangedAction) return null;

  const currentResolutionIndex = resolution.options.findIndex((r) => r.selected) || 0;

  const getViewport = () => {
    const currentViewPortOptions = resolution.options[currentResolutionIndex];
    return { width: currentViewPortOptions.width, height: currentViewPortOptions.height };
  };

  const onResolutionChanged = (value) => {
    const current = resolution.options[value];
    onChangedAction({
      userAgent: userAgent.value,
      viewPort: { width: current.width, height: current.height },
      enabled: checkbox.state,
    });
  };

  const onUserAgentChanged = (event) => {
    onChangedAction({
      userAgent: event.target.value,
      viewPort: getViewport(),
      enabled: checkbox.state,
    });
  };

  const onUserAgentLostFocus = () => {
    let current = userAgent.default;
    if (userAgent.value !== '' && userAgent.value !== userAgent.default) {
      current = userAgent.value.replace(postfixRegexp, '') + postfix;
    }

    onChangedAction({
      userAgent: current,
      viewPort: getViewport(),
      enabled: checkbox.state,
    });
  };

  const onEnabledChanged = () => {
    const current = !checkbox.state;
    onChangedAction({
      userAgent: userAgent.value,
      viewPort: getViewport(),
      enabled: current,
    });
  };

  return (
    <Card title={title}>
      <div className="row">
        <div className="col-2 align-self-center">
          <div className="row">
            <i className={`fa-3x fe ${icon}`}></i>
          </div>
        </div>
        <div className="col">
          <div className="row align-items-center pb-3">
            <div className="col-3">
              <label htmlFor="resolution">{resolution.title}</label>
            </div>
            <div className="col">
              <Select
                name="resolution"
                id="resolution"
                onChange={onResolutionChanged}
                defaultValue={currentResolutionIndex}
                disabled={resolution.disabled}
                popupMatchSelectWidth={false}
                style={{ width: 220 }}
              >
                {resolution.options.map((option, i) => (
                  <Select.Option key={i} value={i}>
                    {option.text}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="row align-items-center pb-3">
            <div className="col-3">
              <label htmlFor="userAgent">{userAgent.title}</label>
            </div>
            <div className="col">
              <Input
                name="userAgent"
                id="userAgent"
                type="text"
                onChange={onUserAgentChanged}
                onBlur={onUserAgentLostFocus}
                value={userAgent.value}
                disabled={userAgent.disabled}
              />
            </div>
          </div>
          <div className="row align-items-center pb-3">
            <div className="col-3">
              <label htmlFor="desktopCheckbox" className="form-check-label">
                {checkbox.label}
              </label>
            </div>
            <div className="col form-check form-switch">
              <Switch disabled={checkbox.disabled} checked={checkbox.state} onChange={onEnabledChanged} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RenderingDeviceCard;
