import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalWrapper, ModalHeader, ModalBodyScroller } from '../../components/ModalLib';
import { ControlButton, Checkbox } from '../../components/PageElementsLib';
import { Container } from '../../components/CssFrameworkComponents';
import { setShowSEOScoreURLsOnly } from './redux/urlFilterModalActions';
import { TWrapper, TSectionHeader, TRow, TCell } from '../../components/TableLib';
import { closeModal } from '../../actions/pageActions';

const cid = 'UrlFilterModal';
const titleStyle = { width: '8em' };

const URLFilterModal = ({ cm, urlFilterModal, setShowSEOScoreURLsOnly: doSetShowSEOScoreURLsOnly, closeModal: doCloseModal, onEvent }) => {
  const { showSEOScoreURLsOnly } = urlFilterModal;

  const onApply = () => {
    doCloseModal();
    onEvent({ action: 'applyFilter', payload: { showSEOScoreURLsOnly } });
  };

  return (<ModalWrapper maxHeight='500px'>
      <ModalHeader
        modalHeaderLeft = {
          <ControlButton text={cm.header.btnLeft} onclick={() => onEvent({ action: 'modalCancelled' })}/>
        }
        modalHeaderRight = {
          <ControlButton text={cm.header.btnRight} onclick={onApply}/>
        }
        title={ cm.header.title }
      />
      <ModalBodyScroller>
      <Container fluid>
        <TWrapper
          tableId="urlFilter"
          parentType="modal"
          showTHead={false}
          cols={[
            { size: 'min', valign: 'middle' },
            { size: 'flex', float: 'right', valign: 'middle', addClass: 'text-secondary pr-0' },
            { size: 'min', float: 'right', valign: 'middle' }, { size: 'min', float: 'right', valign: 'middle' }]}
        >
          <TSectionHeader text={cm.body.title}/>
          <TRow>
            <TCell>
              <div>
                <div className="pt-1">{cm.status}</div>
                <div className="d-flex justify-content-start">
                  <div style={titleStyle} className="m-1">
                    <Checkbox
                      checked={showSEOScoreURLsOnly}
                      onchange={ () => doSetShowSEOScoreURLsOnly(!showSEOScoreURLsOnly) }
                      labelText={cm.body.seoScoreFilterLabel}
                    />
                  </div>
                </div>
              </div>
            </TCell>
          </TRow>

        </TWrapper>
      </Container>
      </ModalBodyScroller>
    </ModalWrapper>);
};

function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    urlFilterModal: state.urlFilterModal,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    setShowSEOScoreURLsOnly,
    closeModal,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(URLFilterModal);
