// imported by /reducers/index.js
// holds road search parameters and result
import {
  PRERENDER_USER_CLEAR,
  PRERENDER_USER_IN_PROGRESS,
  PRERENDER_USER_SET,
  PRERENDER_USER_UNAUTHORIZED,
  SET_SHOW_ONBOARDING,
  UPDATE_PRERENDER_USER_TRACKING_CODE,
  UPDATE_PRERENDER_TOKEN,
  DECREASE_PROGRESS_COUNT,
  DECREASE_NOTIFICATIONS_UNREAD_COUNT,
  PRERENDER_USER_PATCH,
} from '../actions/types';

import { PrerenderUser, defaultUser } from '../features/PrerenderUser';

import { stringToDate } from '../assets/dateFormatter';

type UserReducerAction = {
  type: string;
  payload: any;
};

export default function prerenderUserReducer(
  state: PrerenderUser = defaultUser,
  action: UserReducerAction
): PrerenderUser {
  const { payload } = action;
  switch (action.type) {
    case PRERENDER_USER_SET: {
      const {
        id,
        email,
        numPagesCached,
        token,
        signupDate,
        trackingCodeInstalled,
        cacheFreshness,
        hasBillingInfo,
        recachingDisabled,
        planName,
        costPerMonth,
        emailVerified,
        customPrice,
        customCachedPagesLimit,
        recacheDelay,
        userPausedRecaching,
        queryParamsWhitelist,
        ignoreAllQueryParams,
        hasBillingAddress,
        firstName,
        lastName,
        isMeteredBilling,
        plan,
        renderCounter,
        cancelledAt,
        hasCustomContractPricing,
        mobileAdaptive,
        settings,
        features,
        allURLCount,
        companyId,
        companyName,
        adminEmail,
        isTrial,
        isCacheFreshnessDisabled,
        nextBillingAt,
        lastCharged,
        customRecacheDelay,
        chargeBeePlanId,
        showRegistrationSteps,
        trackingCodeInstalledDate,
        isAdmin,
        billingPeriod,
        hadPaidPlan,
        csmId,
        role,
        gettingStartedSteps,
        numberOfNewNotifications,
        hasBillingManager,
      } = payload;

      return {
        ...state,
        id,
        email,
        numPagesCached,
        token,
        signupDate: stringToDate(signupDate),
        trackingCodeInstalled,
        cacheFreshness,
        hasBillingInfo,
        recachingDisabled,
        planName,
        costPerMonth,
        emailVerified,
        customPrice,
        hasCustomContractPricing,
        customCachedPagesLimit,
        recacheDelay,
        userPausedRecaching,
        queryParamsWhitelist,
        ignoreAllQueryParams,
        hasBillingAddress,
        firstName,
        lastName,
        isMeteredBilling,
        plan,
        renderCounter,
        cancelledAt,
        mobileAdaptive,
        settings,
        features,
        allURLCount,
        companyId,
        companyName,
        adminEmail,
        isTrial,
        isCacheFreshnessDisabled,
        customRecacheDelay,
        chargebeePlanId: chargeBeePlanId,
        inProgress: false,
        hasSession: true,
        isCancelled: cancelledAt && recachingDisabled,
        nextBillingAt: stringToDate(nextBillingAt),
        lastCharged: stringToDate(lastCharged),
        upgradedFromChargebeePlanId: payload?.upgradedFromChargebeePlanId,
        upgradedAt: stringToDate(payload?.upgradedAt),
        showRegistrationSteps,
        trackingCodeInstalledDate: stringToDate(trackingCodeInstalledDate),
        isAdmin,
        billingPeriod,
        hadPaidPlan,
        csmId,
        role,
        gettingStartedSteps,
        hasBillingManager,
        unreadNotifications:
          typeof numberOfNewNotifications === 'number' ? numberOfNewNotifications : state.unreadNotifications,
      };
    }

    case UPDATE_PRERENDER_TOKEN:
      return { ...state, token: payload };

    case PRERENDER_USER_IN_PROGRESS:
      return { ...state, inProgress: payload.value };

    case PRERENDER_USER_UNAUTHORIZED:
      return { ...state, hasSession: false };
    case UPDATE_PRERENDER_USER_TRACKING_CODE:
      return { ...state, trackingCodeInstalled: payload };
    case SET_SHOW_ONBOARDING:
      return {
        ...state,
        showOnboarding: payload,
      };
    case DECREASE_PROGRESS_COUNT:
      return {
        ...state,
        gettingStartedSteps: {
          ...state.gettingStartedSteps,
          ...payload,
        },
      };
    case PRERENDER_USER_CLEAR:
      return defaultUser;

    case DECREASE_NOTIFICATIONS_UNREAD_COUNT:
      if (state.unreadNotifications - payload > -1) {
        return { ...state, unreadNotifications: state.unreadNotifications - payload };
      }
      return { ...state, unreadNotifications: 0 };

    case PRERENDER_USER_PATCH:
      return {
        ...state,
        ...payload,
      };
    default:
  }
  return state;
}
