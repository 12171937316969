import { restApi } from '../api/restApiSlice';

const upvotyApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getAuthToken: builder.query({
      query: () => ({ url: `/upvoty/auth-token`, method: 'GET' }),
    }),
  }),
});

export const { useGetAuthTokenQuery } = upvotyApi;
