import { useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import { ExclamationCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { isFree } from '@prerender/billing-shared';
import { formatDays } from '../../assets/dateFormatter';
import RecacheIntervalSlider from './recacheIntervalSlider';
import { useGetPlansQuery } from '../billing/api/billingApiSlice';
import { getSuggestedPlan } from '../../chargebee/cost';

import './recacheIntervalSetting.css';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import RecacheIntervalCaption from './recacheIntervalCaption';
import getPlanAlert from './planAlert';
import { useEvent } from '../events/hooks/useEvent';
import ButtonWithRole from '../../components/ButtonWithRole';
import USER_ROLES from '../auth/userRoles';

const isInInterval = (allowedIntervalRange, value) => {
  if (!allowedIntervalRange) {
    return true; // No interval -> no restrictions -> always in the interval
  } else {
    return allowedIntervalRange[0] <= value && value <= allowedIntervalRange[1];
  }
};

const getMaxFreshness = (currentFreshness, max) => {
  if ((max === 60 && currentFreshness <= 60) || (max < 60 && currentFreshness === 60)) {
    return 60;
  }
  if (max !== 90 && currentFreshness === 90) {
    return 90;
  }
  if (max === 90 || (max === 60 && currentFreshness === 90) || (max === 90 && currentFreshness === 60)) {
    return 90;
  }
  return 30;
};

export default function RecacheIntervalSetting({ onSubmit, onCancel, isPopup = true }) {
  const { track } = useEvent();
  const navigate = useNavigate();
  const { data: plans, isLoading: getPlanInProgress } = useGetPlansQuery();
  const prerenderUser = usePrerenderUser();

  const [suggestedPlan, setSuggestedPlan] = useState(null);
  const [savedCost, setSavedCost] = useState(0);
  const [days, setDays] = useState(prerenderUser.cacheFreshness);

  const isPlanInInterval = prerenderUser.isTrial || isInInterval(prerenderUser.plan?.cacheRefreshInterval, days);

  const isFreeUser = useMemo(() => isFree(prerenderUser.plan.current.id), [prerenderUser]);
  const { cacheFreshness } = prerenderUser;
  const currentPlan = useMemo(() => plans?.data?.find((plansEntry) => plansEntry.current), [plans]);

  useEffect(() => {
    if (getPlanInProgress || !plans?.data || !currentPlan || !days) {
      return;
    }

    const suggestedPlanAndCost = getSuggestedPlan(days, prerenderUser, currentPlan, plans.data);
    setSuggestedPlan(suggestedPlanAndCost?.suggestedPlan);
    setSavedCost(suggestedPlanAndCost?.savedCost);
  }, [days, prerenderUser, getPlanInProgress, plans, currentPlan]);

  const maxCacheFreshness =
    prerenderUser.plan.cacheRefreshInterval &&
    Array.isArray(prerenderUser.plan.cacheRefreshInterval) &&
    prerenderUser.plan.cacheRefreshInterval.length == 2
      ? prerenderUser.plan.cacheRefreshInterval[1]
      : 30;

  const onSliderChange = (value) => {
    setDays(value);
  };

  const {
    item: planAlert,
    showAlert,
    type: alertType,
  } = getPlanAlert({
    prerenderUser,
    suggestedPlan,
    isInInterval: isPlanInInterval,
    cacheFreshness: days,
    savedCost,
  });

  return (
    <>
      <div className="row">
        <div className="col-auto">
          <div className="recache-interval-setting-header" style={{ width: '14em', display: 'inline-block' }}>
            Cache Expiration: {formatDays(days)}
          </div>
          {showAlert && alertType === 'warning' && <ExclamationCircleFilled style={{ color: '#FAAD14' }} />}
          {showAlert && alertType === 'error' && <CloseCircleFilled style={{ color: '#FF4D4F' }} />}
        </div>
        <div className="col"></div>
      </div>
      {isPopup && (
        <div className="row pt-3">
          <RecacheIntervalCaption prerenderUser={prerenderUser} />
        </div>
      )}
      <div className="row pt-3">
        <div className="col">
          <RecacheIntervalSlider
            value={days}
            onChange={onSliderChange}
            currentFreshness={cacheFreshness}
            max={maxCacheFreshness}
          />
        </div>
      </div>
      <div className="row">
        <div className="col small">6 hours</div>
        <div className="col text-right small">{formatDays(getMaxFreshness(cacheFreshness, maxCacheFreshness))}</div>
      </div>
      <div className="row mt-3">{planAlert}</div>
      <div className="row mt-3">
        <div className="col">
          {isFreeUser && (
            <Button
              size="small"
              onClick={() => {
                track('Recache settings upgrade button clicked', {
                  subscription_plan: prerenderUser.chargebeePlanId,
                });
                navigate('/billing/plans');
              }}
            >
              Upgrade
            </Button>
          )}
        </div>
        <div className="col">
          <div className="float-right">
            {onCancel && (
              <Button size="small" className="mr-2" onClick={() => onCancel()}>
                Cancel
              </Button>
            )}
            <ButtonWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
              type="primary"
              size={isPopup ? 'small' : 'middle'}
              onClick={() => onSubmit({ days })}
              disabled={days === prerenderUser.cacheFreshness || !isPlanInInterval}
            >
              Apply
            </ButtonWithRole>
          </div>
        </div>
      </div>
    </>
  );
}
