import { Button, Form, Input, Radio, Space } from 'antd';
import '../RegistrationPage.css';
import { useEffect } from 'react';
import { useEvent } from '../../events/hooks/useEvent';

const industryOptions = [
  {
    value: 'E-commerce',
    label: 'E-commerce',
  },
  {
    value: 'SEO or Marketing Agency',
    label: 'SEO or Marketing Agency',
  },
  {
    value: 'Online Marketplace',
    label: 'Online Marketplace',
  },
  {
    value: 'Technology',
    label: 'Technology',
  },
  {
    value: 'Food & Beverage',
    label: 'Food & Beverage',
  },
  {
    value: 'Telecom',
    label: 'Telecom',
  },
  {
    value: 'Finance',
    label: 'Finance',
  },
  {
    value: 'Government',
    label: 'Government',
  },
  {
    value: 'Job Boards & Careers',
    label: 'Job Boards & Careers',
  },
  {
    value: 'Real Estate',
    label: 'Real Estate',
  },
  {
    value: 'Travel & Hospitality',
    label: 'Travel & Hospitality',
  },
  {
    value: 'Other',
    label: 'Other (please specify)',
  },
];

const roleOptions = [
  {
    value: 'SEO Expert',
    label: 'SEO Expert',
  },
  {
    value: 'Product Manager',
    label: 'Product Manager',
  },
  {
    value: 'Project Manager',
    label: 'Project Manager',
  },
  {
    value: 'Sales',
    label: 'Sales',
  },
  {
    value: 'Marketing',
    label: 'Marketing',
  },
  {
    value: 'Billing & Procurement',
    label: 'Billing & Procurement',
  },
  {
    value: 'Software Engineer',
    label: 'Software Engineer',
  },
  {
    value: 'Other',
    label: 'Other (please specify)',
  },
];

const mapFieldsData = (data) => {
  return {
    industry: data?.otherIndustry?.length ? data.otherIndustry : data.industry,
    role: data?.otherRole?.length ? data.otherRole : data.role,
  };
};

const mapFromOptions = (value, options) => {
  if (value !== null) {
    if (options.find((option) => option.value === value)) {
      return [value, null];
    } else {
      return ['Other', value];
    }
  }

  return [null, null];
};

const mapValuesToFields = (values, roleOptions, industryOptions) => {
  const [role, otherRole] = mapFromOptions(values.role, roleOptions);
  const [industry, otherIndustry] = mapFromOptions(values.industry, industryOptions);

  return { role, industry, otherRole, otherIndustry };
};

export const RoleAndIndustryForm = ({ onFinish, values }) => {
  const [form] = Form.useForm();
  const industryWatch = Form.useWatch('industry', form);
  const roleWatch = Form.useWatch('role', form);
  const { track, updateUserProfile } = useEvent();

  useEffect(() => {
    if (industryWatch && industryWatch !== 'Other') {
      form.setFieldValue('otherIndustry', undefined);
    }
  }, [industryWatch]);

  useEffect(() => {
    if (roleWatch && roleWatch !== 'Other') {
      form.setFieldValue('otherRole', undefined);
    }
  }, [roleWatch]);

  const onFinishForm = (data) => {
    const mappedData = mapFieldsData(data);
    onFinish(mappedData);
    track('Signup Form: Role & Industry Entered', { industry: mappedData.industry, role: mappedData.role });
    updateUserProfile({ industry: mappedData.industry, role: mappedData.role });
  };

  return (
    <>
      <Form
        onFinish={(data) => {
          onFinishForm(data);
        }}
        layout="vertical"
        form={form}
        initialValues={mapValuesToFields(values, roleOptions, industryOptions)}
      >
        <div className="my-5">
          <h1 className="text-center">Select your Role</h1>
          <h3 className="text-center">We want to get to know you better. Which role describes you best?</h3>
        </div>
        <Form.Item
          label="Select Industry"
          name={'industry'}
          rules={[{ required: true, message: 'Please select the industry' }]}
        >
          <Radio.Group buttonStyle="solid">
            {industryOptions.map((item, index) => (
              <Radio.Button value={item.value} key={index}>
                {item.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item name={'otherIndustry'} noStyle={industryWatch !== 'Other'}>
          <Input type={industryWatch === 'Other' ? 'text' : 'hidden'} />
        </Form.Item>
        <Form.Item label="Your Role" name={'role'} rules={[{ required: true, message: 'Please select the role' }]}>
          <Radio.Group>
            <Space direction="vertical">
              {roleOptions.map((item, index) => (
                <Radio value={item.value} key={index}>
                  {item.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name={'otherRole'} noStyle={roleWatch !== 'Other'}>
          <Input type={roleWatch === 'Other' ? 'text' : 'hidden'} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Continue
        </Button>
      </Form>
    </>
  );
};
