import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Dropdown, Badge } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const MENU_ITEMS = [
  {
    key: 'my-account',
    label: <Link to="/account">My Account</Link>,
  },
  {
    key: 'billing',
    label: <Link to="/billing">Billing</Link>,
  },
  {
    type: 'divider',
  },
  {
    key: 'sign-out',
    label: <Link to="/signout">Sign Out</Link>,
  },
];

function NotificationsMenuItem({ count }) {
  return (
    <Link to="/notifications">
      Notifications <Badge count={count} />
    </Link>
  );
}

function AccountDropdown({ unreadNotificationsCount = 0 }) {
  const menuItems = React.useMemo(() => {
    return [
      {
        key: 'notifications',
        label: <NotificationsMenuItem count={unreadNotificationsCount} />,
      },
      ...MENU_ITEMS,
    ];
  }, [unreadNotificationsCount]);

  return (
    <Dropdown
      menu={{
        items: menuItems,
      }}
    >
      <Badge count={unreadNotificationsCount}>
        <Avatar icon={<UserOutlined />} style={{ color: '#1A5F11', backgroundColor: '#CCF5C7' }} />
      </Badge>
    </Dropdown>
  );
}

export default AccountDropdown;
