import { useMemo } from 'react';
import { getAccessToken } from '../features/auth';
import { jwtDecode } from 'jwt-decode';

const useKeycloakRealm = () => {
  const token = getAccessToken();

  const realm = useMemo(() => {
    if (!token) return null;
    const { iss } = jwtDecode(token) || {};
    if (typeof iss !== 'string') return null;

    return iss.substring(iss.lastIndexOf('/') + 1);
  }, [token]);
  return realm;
};

export default useKeycloakRealm;
