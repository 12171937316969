import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getHashParam, setHashParam } from '../assets/hashParams';

const formatPageNumbers = (stats) => {
  const from = stats.page * stats.amtpp + 1;
  const to = stats.page * stats.amtpp + stats.amt;

  return from <= to ? `Results ${from} - ${to}` : ''; // This can happen when stats.amt === 0, so the last page is empty
};

class PaginationComp extends Component {
  static defaultProps = {
    stats: {
      total: 1,
      amt: 1,
      pages: 0,
      page: 0,
      amtpp: 1,
    },
    onPageChange: () => {},
  };

  handleEvent(event) {
    if (event.type === 'hashchange') this.onHashChanged();
  }

  componentDidMount() {
    //this.startScrollEventCronJob();
    // Q: Why not this.onHashChanged ? A: 'this' needs to be bound correctly
    // Q: Why not a this.onHashChanged.bind(this)? A: We need to be able to remove it
    // Q: Why not a lambda? A: We need to be able to remove it
    window.addEventListener('hashchange', this);
    PaginationComp.writePageToHash(this.props.stats.page);
  }

  static writePageToHash(pageIndex) {
    const page = pageIndex + 1;
    setHashParam('page', page);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this);
    setHashParam('page');
  }

  paginatorClicked(dir) {
    const { stats, onPageChange } = this.props;
    if (dir === 'down' && stats.page > 0) {
      onPageChange({ action: 'changePage', payload: stats.page - 1 });
      PaginationComp.writePageToHash(stats.page - 1);
    } else if (dir === 'up' && stats.page < stats.pages) {
      onPageChange({ action: 'changePage', payload: stats.page + 1 });
      PaginationComp.writePageToHash(stats.page + 1);
    }
  }

  onHashChanged() {
    const { onPageChange } = this.props;
    const pageStr = getHashParam('page', null);
    const pageInt = parseInt(pageStr, 10);
    const page = Math.max(1, pageInt) || 1; // ||1  - in case of pageInt is NaN Math.max returns with NaN

    const pageIndex = page - 1;
    PaginationComp.writePageToHash(pageIndex);
    onPageChange({ action: 'changePage', payload: pageIndex });
  }

  render() {
    const {
      tableId,
      stats,
      onPageChange,
      isLoading,
    } = this.props;
    return (
      <div id={`div${tableId}`}>
        {onPageChange && stats.pages > 0 && !isLoading && (
          <div className="card-footer d-flex justify-content-between">
            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
              <li className="page-item">
                {stats.page > 0 && (
                  <button
                    className="page-link pl-0 pr-4 border-right text-secondary"
                    onClick={() => this.paginatorClicked('down')}
                  >
                    <i className="fe fe-arrow-left mr-1"></i> Prev
                  </button>
                )}
                {stats.page <= 0 && <div className="mx-5" />}
              </li>
            </ul>
            <div className="pagination card-pagination">
              <div className="text-secondary mt-1">{formatPageNumbers(stats)}</div>
            </div>
            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
              <li className="page-item">
                {stats.page < stats.pages && (
                  <button
                    className="page-link pl-4 pr-0 border-left text-secondary"
                    onClick={() => this.paginatorClicked('up')}
                  >
                    Next <i className="fe fe-arrow-right ml-1"></i>
                  </button>
                )}
                {stats.page >= stats.pages && <div className="mx-5" />}
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const Pagination = connect(mapStateToProps, mapDispatchToProps)(PaginationComp);

export { Pagination };
