import { Modal, Select, Alert } from 'antd';
import { ModalHeader } from '../../../components/ModalLib';
import { ControlButton } from '../../../components/PageElementsLib';
import { TWrapper, TRow, TCell, TSectionHeader } from '../../../components/TableLib';
import { useState, useEffect } from 'react';
import * as ms from 'ms';

const defaultInterval = '24';

const ONE_HOUR_IN_MS = 1000 * 60 * 60;

const DomainModal = ({ open, onSubmit, onClose, errorMsg, confirmLoading, existing }) => {
  const [domain, setDomain] = useState('');
  const [interval, setInterval] = useState(defaultInterval);

  useEffect(() => {
    if (existing) {
      setDomain(existing.domain);
      const intervalInHours =
        existing.revisitInterval !== '-' ? ms(existing.revisitInterval) / ONE_HOUR_IN_MS : defaultInterval;
      setInterval(intervalInHours.toString());
    }
  }, [open]);

  const _onSubmit = () => {
    onSubmit({
      url: domain,
      revisitInterval: Number(interval),
      id: existing ? existing.id : null,
    });
  };

  return (
    <Modal
      styles={{
        content: { top: 28, borderRadius: 5, width: 500, padding: '0 0 4px 0', minHeight: 415 },
        header: { padding: 0 },
        body: { height: '100%' },
        mask: { backgroundColor: '#12263f', opacity: 0.5 },
      }}
      maskClosable={true}
      closable={false}
      title={null}
      footer={null}
      open={open}
      onOk={() => null}
      onCancel={() => null}
      transitionName=""
      maskTransitionName=""
      confirmLoading={confirmLoading}
      afterClose={() => {
        setDomain('');
        setInterval(defaultInterval);
      }}
    >
      <form>
        <ModalHeader
          modalHeaderLeft={<ControlButton text="Cancel" onclick={() => onClose()} />}
          modalHeaderRight={
            <ControlButton
              loading={confirmLoading === true}
              disabled={confirmLoading === true}
              text={existing ? 'Update' : 'Add'}
              onclick={() => _onSubmit()}
            />
          }
          title={existing ? 'Edit domain check' : 'Add domain'}
        />
        {errorMsg && <Alert style={{ margin: 8 }} showIcon type="error" message={errorMsg} />}
        <TWrapper tableId="addSpaChecker" parentType="modal" showTHead={false} cols={[{ size: 'flex' }]}>
          <TSectionHeader text="Domain name" />
          <TRow>
            <TCell addClass="py-2">
              <input
                // {...input}
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                type="url"
                placeholder="e.g. domain.com"
                className="form-control w-100 no-border px-0"
                id="domain"
              />
            </TCell>
          </TRow>
          <TSectionHeader text="Select check interval" />
          <TRow>
            <TCell addClass="py-2">
              <Select
                placeholder="Every x hour"
                className="form-control w-100 no-border px-0"
                style={{ width: '100vh', border: 0, padding: 0 }}
                onSelect={(i) => setInterval(i)}
                value={interval}
                options={[
                  {
                    value: '1',
                    label: 'Every 1 hour',
                  },
                  {
                    value: '4',
                    label: 'Every 4 hour',
                  },
                  {
                    value: '8',
                    label: 'Every 8 hour',
                  },
                  {
                    value: '24',
                    label: 'Every 24 hour',
                  },
                  {
                    value: '168',
                    label: 'Every week',
                  },
                ]}
              />
            </TCell>
          </TRow>
        </TWrapper>
        <div className="alert alert-info ml-3 mr-3 p-3 mb-3" role="alert">
          <div className="row">
            <div className="col-auto pr-0">
              <i className="fa fa-info-circle pr-2" style={{ fontSize: '1.5em' }}></i>
            </div>
            <div className="col">
              Please note that enabling these checks might result in additional rendering cost, depending on the check
              interval you choose.
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default DomainModal;
