import { Button, Modal, Space, Typography, Flex, Avatar, Divider, Skeleton } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { useEvent } from '../events/hooks/useEvent';
import { useMemo } from 'react';
import { SALES_TEAM } from './contacts';

function BookMeetingModal({ contactType = '', onClose, eventsTrackingData = {}, csmData, isLoading }) {
  const { track } = useEvent();

  const contactDetails = useMemo(() => {
    if (contactType === 'csm' && csmData) {
      return {
        email: csmData.email,
        bookMeetingURL: csmData.meeting_url,
        trackingEventName: 'Book meeting clicked (CSM)',
        picture: csmData.picture,
        name: csmData.name,
        title: csmData.title,
        description: `You can find here all the contact information needed to reach out to your ${csmData.title}.`,
      };
    } else if (contactType === 'sales') {
      return SALES_TEAM;
    }
    return {
      title: '',
      description: '',
      name: '',
      picture: '',
      email: '',
    };
  }, [contactType, csmData]);

  return (
    <Modal
      title={contactDetails.title}
      open={!!contactType}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
    >
      <Skeleton loading={isLoading} active>
        <Space direction="vertical" size="large">
          <Typography.Paragraph>
            <p>{contactDetails.description}</p>
          </Typography.Paragraph>

          <Flex gap="large" align="center">
            <Flex flex={1} vertical align="center" gap="small">
              <Typography.Text strong>{contactDetails.name}</Typography.Text>
              <Avatar size={100} src={contactDetails.picture} gap={12} />
            </Flex>
            <Flex flex={3} vertical>
              <Typography.Text style={{ textAlign: 'center' }}>
                You can reach out via email at <a href={`mailto:${contactDetails.email}`}>{contactDetails.email}</a>
              </Typography.Text>
              <Divider plain>Or</Divider>
              <Button
                type="primary"
                href={contactDetails.bookMeetingURL}
                target="_blank"
                icon={<LinkOutlined />}
                onClick={() => track(contactDetails.trackingEventName, eventsTrackingData)}
              >
                Book a meeting
              </Button>
            </Flex>
          </Flex>
        </Space>
      </Skeleton>
    </Modal>
  );
}

export default BookMeetingModal;
