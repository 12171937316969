import { Table, Alert } from 'antd';

import { useGetGscSitemapsQuery, useImportGscSitemapMutation } from '../api/gscApiSlice';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';

const SitemapControl = () => {
  const { data: gscSitemaps } = useGetGscSitemapsQuery();
  const sitemaps = gscSitemaps?.data?.items;
  const userErrors = gscSitemaps?.data?.userErrors;

  const [importGscSitemap] = useImportGscSitemapMutation();

  return (
    <div className="w-full mt-10">
      <h2>Sitemaps</h2>

      {userErrors &&
        userErrors.length > 0 &&
        userErrors.map((u) => (
          <Alert
            showIcon
            type="error"
            className="mt-2 mx-4"
            bordered
            description={u.message}
            closable
            key={'user-error-' + +new Date()}
          />
        ))}

      <div className="flex flex-col mt-5 w-full">
        <Table bordered dataSource={sitemaps} rowKey="url">
          <Table.Column title="Site" dataIndex="siteUrl" key="siteUrl" />
          <Table.Column
            title="URL"
            dataIndex="url"
            key="url"
            render={(value, record) => {
              if (record.importedSitemapId) {
                return <a href={`/sitemaps/map/${record.importedSitemapId}`}>{value}</a>;
              }
              return value;
            }}
          />
          <Table.Column title="Last Submitted" dataIndex="lastSubmitted" key="lastSubmitted" />
          <Table.Column
            title="Actions"
            dataIndex="importedSitemapId"
            key="importedSitemapId"
            render={(value, record) => {
              if (!record.importedSitemapId) {
                return (
                  <ButtonWithRole
                    disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                    key={record.url}
                    onClick={() => importGscSitemap({ url: record.url })}
                  >
                    Import
                  </ButtonWithRole>
                );
              }
              return <a href={`/sitemaps/map/${record.importedSitemapId}`}>View</a>;
            }}
          />
        </Table>
      </div>
    </div>
  );
};

export default SitemapControl;
