import React, { useMemo, useState } from 'react';
import { Button, Flex, Select } from 'antd';
import { ModalWrapper, ModalHeader } from '../../components/ModalLib';
import { TWrapper, TRow, TCell } from '../../components/TableLib';
import { DatePicker } from 'antd';
import * as dayjs from 'dayjs';

const TABLE_OPTION = 'table';
const REQ_PER_DOMAIN_OPTION = 'req-per-domain';

const exportTypeOptions = [
  { value: TABLE_OPTION, label: 'Analytics Data' },
  { value: REQ_PER_DOMAIN_OPTION, label: 'Number of crawler requests per domain' },
];

const selectIntervalOptions = [
  { value: '1day', label: 'Past 24 hours' },
  { value: '7days', label: 'Past 7 days' },
  { value: '30days', label: 'Past 30 days' },
];

const selectFileTypeOptions = [
  { value: 'csv', label: 'CSV file' },
  /* { value: 'excel', label: 'Spreadsheet (XLSX - Max 65536 rows of data allowed)' }, */
];

export default function ExportAnalyticsModal({ onEvent }) {
  const [userInput, setUserInput] = useState({
    exportType: exportTypeOptions[0].value,

    // TABLE_OPTION
    interval: selectIntervalOptions[0].value,
    fileType: selectFileTypeOptions[0].value,

    // REQ_PER_DOMAIN_OPTION
    dateRange: null,
  });

  const appendUserInput = (newInput) => {
    setUserInput((prevInput) => ({ ...prevInput, ...newInput }));
  };

  const renderContent = () => {
    if (userInput.exportType === TABLE_OPTION) {
      return [
        <TRow>
          <TCell addStyle={{ border: 'none' }}>
            <p>Select Report Interval</p>
            <Select
              options={selectIntervalOptions}
              value={userInput.interval}
              className="w-100 no-border"
              onChange={(value) => appendUserInput({ interval: value })}
            ></Select>
          </TCell>
        </TRow>,
        <TRow>
          <TCell addStyle={{ border: 'none' }}>
            <p>Select Report Type</p>
            <Select
              options={selectFileTypeOptions}
              value={userInput.fileType}
              className="w-100 no-border"
              onChange={(value) => appendUserInput({ fileType: value })}
            ></Select>
          </TCell>
        </TRow>,
      ];
    }

    if (userInput.exportType === REQ_PER_DOMAIN_OPTION) {
      return [
        <TRow>
          <TCell addStyle={{ border: 'none' }}>
            <p>Select Report Interval</p>
            <DatePicker.RangePicker
              value={userInput.dateRange}
              width="100%"
              onChange={(value) => appendUserInput({ dateRange: value })}
              disabledDate={(current, { from }) => {
                const now = dayjs();
                if (now.isBefore(current)) {
                  return true;
                }
                if (from) {
                  return Math.abs(current.diff(from, 'days')) >= 365;
                }
                return false;
              }}
            />
          </TCell>
        </TRow>,
      ];
    }

    return null;
  };
  return (
    <ModalWrapper>
      <ModalHeader title="Export CDN Analytics" />
      <TWrapper
        tableId="analyticsExportModalWrapper"
        parentType="modal"
        showTHead={false}
        cols={[{ size: 'flex', valign: 'middle' }]}
      >
        <TRow>
          <TCell addStyle={{ border: 'none' }}>
            <p>Select Export type</p>
            <Select
              options={exportTypeOptions}
              value={userInput.exportType}
              className="w-100 no-border"
              onChange={(value) => appendUserInput({ exportType: value })}
            ></Select>
          </TCell>
        </TRow>
        {renderContent()}
        <TRow>
          <TCell colspan="3">
            <Flex justify="space-between">
              <Button onClick={() => onEvent({ action: 'modalCancelled' })}>Cancel</Button>
              <Button
                type="primary"
                onClick={() => {
                  switch (userInput.exportType) {
                    case TABLE_OPTION: {
                      return onEvent({ action: 'exportAnalytics', payload: userInput });
                    }
                    case REQ_PER_DOMAIN_OPTION: {
                      return onEvent({ action: 'exportRequestPerDomain', payload: userInput });
                    }
                    default:
                      return null;
                  }
                }}
                disabled={useMemo(() => {
                  if (userInput.exportType === REQ_PER_DOMAIN_OPTION) {
                    return !userInput.dateRange;
                  }

                  return false;
                })}
              >
                {'Export'}
              </Button>
            </Flex>
          </TCell>
        </TRow>
      </TWrapper>
    </ModalWrapper>
  );
}
