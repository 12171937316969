import { Flex, Typography, Card, Button, Skeleton, Tooltip as AntdTooltip } from 'antd';
// import { SettingOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';

const { Paragraph } = Typography;

import { Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ComposedChart, Legend, Tooltip } from 'recharts';

import { DashboardPieChart } from './util/DashboardPieChart';
import { BlurringOverlay } from '../../../components/BlurringOverlay/BlurringOverlay';
import { useIsMobileView } from '../../../hooks/useIsMobileView';

import {
  useGetPagesDeliveredByStatusCodeQuery,
  useGetPagesDeliveredByStatusCodeDailyQuery,
} from '../api/dashboardApiSlice';

const colorsPie = ['#21AB7B', '#FF6176', '#57BFD4'];

const colors = {
  '2xx': '#21AB7B',
  301: `#57BFD4`,
  302: `#FFB354`,
  307: `#FFB354`,
  308: `#FFB354`,
  '3xx': `#FFB354`,
  401: `#FF6176`,
  403: `#FF6176`,
  404: `#FF6176`,
  410: `#FF6176`,
  429: `#FF6176`,
  '4xx': `#FF6176`,
  503: `#FF6176`,
  504: `#FF6176`,
  '5xx': `#FF6176`,
};

export function DeliveredByStatusCharts({ days, trackingCodeInstalled, domain }) {
  const {
    data: dataPieRaw,
    isFetching: isLoadingPie,
    error: errorPie,
  } = useGetPagesDeliveredByStatusCodeQuery({ days, domain }, { refetchOnMountOrArgChange: true });
  const {
    data: dataChartRaw,
    isFetching: isLoadingChart,
    error: errorChart,
  } = useGetPagesDeliveredByStatusCodeDailyQuery({ days, domain }, { refetchOnMountOrArgChange: true });

  const isMobile = useIsMobileView();

  if (errorChart || errorPie) {
    return (
      <Card bordered={false} style={{ width: '100%' }}>
        <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px' }}>
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <p style={{ color: 'red' }}>An unexpected error occured, please try again by reloading the page.</p>
          </div>
        </Flex>
      </Card>
    );
  }

  if (isLoadingChart || isLoadingPie) {
    return (
      <Card bordered={false} style={{ width: '100%' }}>
        <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px' }}>
          <Skeleton />
        </Flex>
      </Card>
    );
  }

  const dataChart =
    dataChartRaw?.data?.map(({ day, ...fields }) => {
      const name = dayjs(day).format('MMM DD');

      const filteredFields = {};

      for (const [key, value] of Object.entries(fields)) {
        if (value > 0) {
          const status = `Status code ${key.replace('statusCode', '')}`;
          filteredFields[status] = value;
        }
      }

      return {
        name,
        ...filteredFields,
      };
    }) || [];

  const barNames = dataChart.reduce((acc, curr) => {
    const keys = Object.keys(curr).filter((d) => d !== 'name' && !acc.includes(d));
    return [...acc, ...keys];
  }, []);

  const dataPie = (dataPieRaw.data ? Object.keys(dataPieRaw.data) : [])
    .map((statusStr) => {
      const status = `Status code ${statusStr.replace('statusCode', '')}`;
      return {
        name: status,
        value: Number(dataPieRaw.data[statusStr]),
      };
    })
    .filter(({ value }) => value > 0);

  // if (dataPie.length === 0) {
  //   dataPie.push({ name: 'No data', value: 1 });
  // }

  return (
    <Flex style={{ width: '100%', position: 'relative' }}>
      <BlurringOverlay
        enabled={!trackingCodeInstalled}
        location="Dashboard Pages delivered by Status codes"
        content="Complete your Prerender integration to unlock its full potential."
      />
      <Card bordered={false} style={{ width: '100%', filter: !trackingCodeInstalled ? 'blur(3px)' : null }}>
        <Flex style={{ height: '100%', width: '100%' }} vertical={isMobile} gap={isMobile ? 10 : 0}>
          <Flex
            vertical={true}
            style={{
              width: !isMobile ? '30%' : '100%',
              borderRight: !isMobile ? '1px solid rgba(0,0,0,.1)' : null,
              borderBottom: isMobile ? '1px solid rgba(0,0,0,.1)' : null,
              marginRight: '15px',
            }}
          >
            <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px' }}>
              <Paragraph strong style={{ marginBottom: '0' }}>
                Pages delivered by Status codes
              </Paragraph>
              <Paragraph style={{ marginBottom: '0', marginRight: '15px' }}>
                <AntdTooltip arrow={false} title="Status codes for pages delivered by our CDN">
                  <InfoCircleOutlined />
                </AntdTooltip>
              </Paragraph>
            </Flex>
            <Flex justify={'space-between'} align={'center'} style={{ height: isMobile ? '300px' : '100%' }}>
              <DashboardPieChart
                data={dataPie}
                colors={(data) => {
                  const color = colors[data.name.replace('Status code', '').trim()];

                  return color;
                }}
              ></DashboardPieChart>
            </Flex>
          </Flex>
          <Flex vertical={true} style={{ width: !isMobile ? '70%' : '100%' }}>
            <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px' }}>
              <Paragraph strong style={{ marginBottom: '0' }}>
                Status codes by date
              </Paragraph>
              <Flex gap="small" wrap="wrap" align={'center'}>
                <Link to="/crawl-stats">
                  <Button size={'small'}>Show more</Button>
                </Link>
                {/* <SettingOutlined /> */}
              </Flex>
            </Flex>
            <ResponsiveContainer width="95%" height={400}>
              <ComposedChart
                data={dataChart}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
                barSize={80}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}

                {barNames.map((name, index) => {
                  const color = colors[name.replace('Status code', '').trim()];
                  return <Bar key={name} dataKey={name} stackId="a" fill={color} />;
                })}

                {/* <Line dot={false} type="monotone" dataKey="pv" id="trendline0" stroke="#FFB353" strokeWidth={2} /> */}
              </ComposedChart>
            </ResponsiveContainer>
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
}
