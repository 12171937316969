export const SET_ORIGINAL_STATE = 'settings/setOriginalState';

export const SET_EMAIL_PREFERENCES = 'settings/setEmailPreferences';

export const SET_PAUSE_RECACHE = 'settings/setPauseRecache';

export const SET_MOBILE_RENDERING = 'settings/setMobileRendering';
export const SET_MOBILE_RENDERING_SETTING = 'settings/setMobileRenderingSetting';
export const SET_DESKTOP_RENDERING_SETTING = 'settings/setDesktopRenderingSetting';

export const SET_PAGE_LOAD_TIMEOUT = 'settings/setPageLoadTimeout';

export const SET_LOAD_IMAGES = 'settings/setLoadImages';

export const SET_RENDER_REGION = 'settings/setRenderRegion';

export const SET_FORCE_HTTPS = 'settings/setForceHTTPS';

export const SET_IN_PROGRESS = 'settings/saveSettingsInProgress';
export const SAVE_SETTINGS = 'settings/saveSettings';

export const SET_TEST_SETTINGS_IN_PROGRESS = 'settings/testSettingsInProgress';
export const CLEAR_TEST_RESULT = 'settings/clearTestResult';
export const SET_TEST_RESULT = 'settings/setTestResult';
export const SET_TESTED = 'settings/setTested';
