import React from 'react';
import { Button, Modal, Typography } from 'antd';

import { useExportDataMutation } from '../AnalyticsApiSlice';

const MESSAGE_CODES = {
  initial: 'initial',
  loading: 'loading',
  alreadyExists: 'alreadyExists',
  success: 'success',
  error: 'error',
};

const MESSAGES = {
  [MESSAGE_CODES.initial]: (totalCount) =>
    `Are you sure you want to export ${totalCount} records?
  Please note that exporting URLs happens in the background and you'll be notified by email once it's done.`,
  [MESSAGE_CODES.loading]: 'Preparing to export data...',
  [MESSAGE_CODES.alreadyExists]:
    'You already have an export job in progress. We will inform you via email when that job finishes.',
  [MESSAGE_CODES.success]:
    'We have scheduled your export. You will get a download link for the .csv file in your email once the export is ready',
  [MESSAGE_CODES.error]: 'An error occurred while exporting data. Please try again later.',
};

function ModalMessage({ code, totalCount }) {
  if (code === MESSAGE_CODES.initial) {
    return <Typography.Text>{MESSAGES.initial(totalCount)}</Typography.Text>;
  }

  return <Typography.Text>{MESSAGES[code]}</Typography.Text>;
}

function AnalyticsExportButton({ totalCount = 0, query = {} }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [exportAnalytics, exportAnalyticsResult] = useExportDataMutation();
  const [message, setMessage] = React.useState(MESSAGE_CODES.initial);

  const hasEexportTriggered = !exportAnalyticsResult.isUninitialized;

  const onModalClose = () => {
    setMessage(MESSAGE_CODES.initial);
    exportAnalyticsResult.reset();
  };

  const onConfirmExport = () => {
    if (hasEexportTriggered) {
      setIsModalOpen(false);
    } else if (!exportAnalyticsResult.isLoading) {
      setMessage(MESSAGE_CODES.loading);
      exportAnalytics(query)
        .unwrap()
        .then(({ status }) => {
          if (status === 'queued') {
            setMessage(MESSAGE_CODES.success);
          } else if (status === 'already exists') {
            setMessage(MESSAGE_CODES.alreadyExists);
          }
        })
        .catch(() => setMessage(MESSAGE_CODES.error));
    }
  };

  const onCancel = () => {
    if (!exportAnalyticsResult.isLoading) setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        title="Export analytics data"
        open={isModalOpen}
        onOk={onConfirmExport}
        onCancel={onCancel}
        okText={hasEexportTriggered ? 'Done' : 'Export .csv file'}
        confirmLoading={exportAnalyticsResult.isLoading}
        afterClose={onModalClose}
      >
        <ModalMessage code={message} totalCount={totalCount} />
      </Modal>
      <Button onClick={() => setIsModalOpen(true)} size="small" disabled={!totalCount}>
        Export
      </Button>
    </div>
  );
}

export default AnalyticsExportButton;
