import { Select, Typography } from 'antd';
import USER_ROLES from '../features/auth/userRoles';

const { Text } = Typography;

const roleOptions = [
  {
    key: USER_ROLES.SUPER_ADMIN,
    label: 'Account Owner',
    description: 'The creator of the account. Full access to the dashboard and can invite, edit, or remove users.',
  },
  {
    key: USER_ROLES.ADMIN,
    label: 'Admin',
    description: 'Full access to the dashboard and can invite, edit, or remove users, except for the Account Owner.',
  },
  {
    key: USER_ROLES.BILLING_MANAGER,
    label: 'Billing Manager',
    description:
      "Full access to the billing menu, but 'View Only' access to the rest of the dashboard. Can invite other Billing Managers and Guests.",
  },
  {
    key: USER_ROLES.TEAM_MEMBER,
    label: 'Team Member',
    description:
      "Full access to the dashboard, but 'View Only' access to billing and security. Can invite other Team Members and Guests.",
  },
  {
    key: USER_ROLES.GUEST,
    label: 'Guest',
    description: "'View Only' access to the entire dashboard.",
  },
];

const Description = ({ children }) => (
  <Text type="secondary" style={{ fontSize: 12, whiteSpace: 'normal', fontWeight: 'normal', lineHeight: '14px' }}>
    {children}
  </Text>
);

export const RoleSelect = ({ visibleRoles, value, onChange, style }) => {
  return (
    <Select
      placeholder="Role"
      options={visibleRoles}
      value={value}
      onChange={onChange}
      style={{ width: '100%', ...style }}
      popupMatchSelectWidth={false}
      dropdownStyle={{ maxWidth: 300 }}
      optionRender={(option) => {
        const roleOption = roleOptions.find((role) => role.key === option.value);
        if (!roleOption) return null;
        return (
          <div>
            <div>
              <Text>{option.label}</Text>
            </div>
            <Description>{roleOption.description}</Description>
          </div>
        );
      }}
    />
  );
};
