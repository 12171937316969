import {
  SET_ORIGINAL_STATE,
  SET_EMAIL_PREFERENCES,
  SET_PAUSE_RECACHE,
  SET_MOBILE_RENDERING,
  SET_MOBILE_RENDERING_SETTING,
  SET_DESKTOP_RENDERING_SETTING,
  SET_PAGE_LOAD_TIMEOUT,
  SET_LOAD_IMAGES,
  SET_RENDER_REGION,
  SET_FORCE_HTTPS,
  SET_IN_PROGRESS,
  SET_TEST_SETTINGS_IN_PROGRESS,
  CLEAR_TEST_RESULT,
  SET_TEST_RESULT,
  SET_TESTED,
} from './actionTypes';

import { environmentApi } from '../../api/environmentApiSlice';
import config from '../../../assets/config';
import internalApi, { THROW_ON_ERROR_V2 } from '../../../assets/internalApi';
import { setLastErrorCode, clearLastErrorCode } from '../../../actions/pageActions';
import { getLocalErrorCode } from '../../../assets/lib';

export const setEmailPreferences = (value) => ({ type: SET_EMAIL_PREFERENCES, payload: value });

export const setOriginalState = (value) => ({ type: SET_ORIGINAL_STATE, payload: value });

export const setPauseRecache = (value) => ({ type: SET_PAUSE_RECACHE, payload: value });

export const setMobileRendering = (value) => ({ type: SET_MOBILE_RENDERING, payload: value });
export const setMobileRenderingSetting = (value) => ({ type: SET_MOBILE_RENDERING_SETTING, payload: value });
export const setDesktopRenderingSetting = (value) => ({ type: SET_DESKTOP_RENDERING_SETTING, payload: value });

export const setPageLoadTimeoutSetting = (value) => ({ type: SET_PAGE_LOAD_TIMEOUT, payload: value });

export const setLoadImages = (value) => ({ type: SET_LOAD_IMAGES, payload: value });

export const setRenderRegion = (value) => ({ type: SET_RENDER_REGION, payload: value });

export const setForceHTTPS = (value) => ({ type: SET_FORCE_HTTPS, payload: value });

export const saveSettings = (value) => async (dispatch) => {
  try {
    dispatch({ type: SET_IN_PROGRESS, payload: true });

    const { settings, userId } = value;

    const requestUri = `${config.apiUrl.dataServer}/api/v1/users/${userId}/settings`;
    await internalApi(
      'put',
      requestUri,
      {
        recachePaused: settings.recachePaused,
        emailPreferences: settings.emailPreferences,
        customViewport: settings.renderingSettings.desktop.viewPort,
        customDesktopUserAgent: settings.renderingSettings.desktop.userAgent,
        customMobileViewport: settings.renderingSettings.mobile.viewPort,
        customMobileUserAgent: settings.renderingSettings.mobile.userAgent,
        mobileAdaptive: settings.renderingSettings.mobile.enabled,
        pageLoadTimeout: settings.pageLoadTimeout * 1000,
        loadImages: settings.loadImages,
        renderRegion: settings.renderLocation.renderRegion,
        forceHTTPS: settings.forceHTTPS,
      },
      THROW_ON_ERROR_V2
    );
    dispatch(environmentApi.util.invalidateTags(['EnvironmentHistory']));

    dispatch(clearLastErrorCode());
    return {};
  } catch (error) {
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
    return { error };
  } finally {
    dispatch({ type: SET_IN_PROGRESS, payload: false });
  }
};

export const setTestResult = (value) => ({ type: SET_TEST_RESULT, payload: value });
export const clearTestResult = () => ({ type: CLEAR_TEST_RESULT });

export const testSettings = (value) => async (dispatch) => {
  try {
    dispatch({ type: SET_IN_PROGRESS, payload: true });
    dispatch({ type: SET_TEST_SETTINGS_IN_PROGRESS, payload: true });

    const { settings, url } = value;

    const requestUri = `${config.apiUrl.dataServer}/api/v2/users/settings/test`;

    const payload = await internalApi(
      'post',
      requestUri,
      {
        url,
        mobileAdaptive: settings.renderingSettings.mobile.enabled,
        customViewport: settings.renderingSettings.desktop.viewPort,
        customDesktopUserAgent: settings.renderingSettings.desktop.userAgent,
        customMobileViewport: settings.renderingSettings.mobile.viewPort,
        customMobileUserAgent: settings.renderingSettings.mobile.userAgent,
        pageLoadTimeout: settings.pageLoadTimeout * 1000,
        loadImages: settings.loadImages,
        renderRegion: settings.renderLocation.renderRegion,
        forceHTTPS: settings.forceHTTPS,
      },
      THROW_ON_ERROR_V2
    );

    dispatch(setTestResult(payload));

    dispatch(clearLastErrorCode());
  } catch (error) {
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  } finally {
    dispatch({ type: SET_IN_PROGRESS, payload: false });
    dispatch({ type: SET_TESTED, payload: true });
  }
};
