const diffInSecs = (dt2, dt1) => Math.round((dt2.getTime() - dt1.getTime()) / 1000);

const epochStart = new Date(null).getTime();

const getDateTimeTooltip = (d) => {
  const time = new Date(d).toLocaleString();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  return timeZone ? `${time}<br>${timeZone}` : `${time}`;
};

const pluralS = (quantity, size) => {
  if (size === 'short') return '';
  else if (size !== 'short' && quantity === 1) return '';
  else if (size !== 'short' && quantity !== 1) return 's';
  else return ''; // Not possible branch
};

const getAgeString = (dateObj, suffix = 'ago', size = 'short', showNever = false) => {
  if (showNever && dateObj.getTime() === epochStart) {
    return '---';
  }

  const paddedSuffix = suffix ? ` ${suffix}` : '';

  // '2 hours ago' - quantity: 2, label: hour, pluralS: s, suffix: ago
  const format = (quantity, label) => `${quantity}${label}${pluralS(quantity, size)}${paddedSuffix}`;

  const interval = size === 'short' ? { min: 'm', hour: 'h', day: 'd' } : { min: ' min', hour: ' hour', day: ' day' };
  const nowDate = new Date();
  const mins = Math.round((nowDate.getTime() - dateObj.getTime()) / 60000);
  if (mins === 0) return 'now';
  else if (mins < 60) return format(mins, interval.min); // Less than an hour
  else if (mins >= 24 * 60) { // more than a day
    const days = parseInt(mins / (24 * 60), 10);
    return format(days, interval.day);
  } else if (mins % 60 > 0) { // more than an hour, less than a day, not integral hour
    const h = parseInt(mins / 60, 10);
    return format(h, interval.hour);
  } else { // more than an hour, less than a day, integral hour
    return format(mins / 60, interval.hour);
  }
};

export {
  getAgeString,
  getDateTimeTooltip,
  diffInSecs,
};
