import React from 'react';
import { Divider, Flex, Form, Input, Alert, Button, Spin } from 'antd';
import { useDispatch } from 'react-redux';
import requireAuth from '../../components/hocs/requireAuth';
import { configForOrigin } from '../auth/keycloakAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import ButtonWithRole from '../../components/ButtonWithRole';
import USER_ROLES from '../auth/userRoles';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import { Link } from 'react-router-dom';
import { useGetPhoneNumberQuery, useSetAdminEmailMutation, useSetUserDetailsMutation } from './api/myAccountApiSlice';
import { getPrerenderUserThrows } from '../../actions/prerenderUserActions';
import { useEvent } from '../../features/events/hooks/useEvent';

const realmConfig = configForOrigin();

const META_TAGS = {
  title: 'My Account - Prerender.io',
};

function MyAccountPage() {
  const dispatch = useDispatch();
  const user = usePrerenderUser();

  const { track } = useEvent();

  const [adminEmailForm] = Form.useForm();
  const [userDetailsForm] = Form.useForm();

  const { data: userPhone, isFetching: isFetchingPhone } = useGetPhoneNumberQuery();

  const [setAdminEmail, { isLoading: isLoadingAdminEmail, isError: isSetAdminError }] = useSetAdminEmailMutation();

  const [setUserDetails, { isLoading: isLoadingUserDetails, isError: isSetUserDetailsError }] =
    useSetUserDetailsMutation();

  const isFormLoading = isLoadingAdminEmail || isLoadingUserDetails;

  const saveAdminEmail = async ({ adminEmail, companyName }) => {
    try {
      track('Company details changed', { adminEmail, companyName });
      await setAdminEmail({ adminEmail, companyName }).unwrap();
      dispatch(getPrerenderUserThrows());
    } catch {}
  };

  const saveUserDetails = async ({ email, firstName, lastName, phoneNumber }) => {
    try {
      track('User details changed');
      await setUserDetails({ email, firstName, lastName, phoneNumber }).unwrap();
      dispatch(getPrerenderUserThrows());
    } catch {}
  };

  return (
    <AdminTemplate metaTags={META_TAGS}>
      {isSetAdminError && <Alert message="Could not save company details." type="error" />}
      <Flex vertical gap={10}>
        <Form
          form={adminEmailForm}
          name="myAccount"
          layout="vertical"
          style={{
            maxWidth: '100%',
          }}
          onFinish={saveAdminEmail}
          autoComplete="off"
        >
          <Title level={3}>Company Settings</Title>
          <Form.Item
            label="Admin email"
            name="adminEmail"
            className="col-12 col-md-6 col-lg-5 col-xl-4"
            extra="This is the primary contact email for us to reach you. Many of the automated notifications will be sent to this address."
            rules={[{ required: true }, { type: 'email' }]}
            initialValue={user.adminEmail}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Company name"
            name="companyName"
            className="col-12 col-md-6 col-lg-5 col-xl-4"
            extra="Name of your company."
            rules={[{ required: true }]}
            initialValue={user.companyName}
          >
            <Input />
          </Form.Item>
          {user.role !== USER_ROLES.SUPER_ADMIN && (
            <>
              <Alert
                style={{ marginTop: '2rem', marginBottom: '2rem' }}
                message="You must be an account owner to change these settings."
                type="warning"
              />
            </>
          )}
          <ButtonWithRole
            disabledFor={[USER_ROLES.GUEST, USER_ROLES.TEAM_MEMBER, USER_ROLES.BILLING_MANAGER, USER_ROLES.ADMIN]}
            type="primary"
            htmlType="submit"
            loading={isFormLoading}
          >
            Change Company Details
          </ButtonWithRole>
          <Paragraph type="secondary">You must be an account owner to change your information.</Paragraph>
        </Form>

        <Form
          form={userDetailsForm}
          name="myUser"
          layout="vertical"
          style={{
            maxWidth: '100%',
            marginTop: '2rem',
          }}
          onFinish={saveUserDetails}
          autoComplete="off"
        >
          <Title level={3}>User Details</Title>
          {isSetUserDetailsError && <Alert message="Could not save user details." type="error" />}
          <Flex gap="middle" align="start" vertical={false}>
            <Form.Item
              label="First name"
              name="firstName"
              className="col-12 col-md-6 col-lg-5 col-xl-4"
              rules={[{ required: true }]}
              initialValue={user.firstName}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last name"
              name="lastName"
              className="col-12 col-md-6 col-lg-5 col-xl-4"
              rules={[{ required: true }]}
              initialValue={user.lastName}
            >
              <Input />
            </Form.Item>
          </Flex>
          <Flex gap="middle" align="start" vertical={false}>
            <Form.Item
              label="Your email"
              name="email"
              className="col-12 col-md-6 col-lg-5 col-xl-4"
              extra="This is what you use to log in to your account."
              rules={[{ required: true }, { type: 'email' }]}
              initialValue={user.email}
            >
              <Input />
            </Form.Item>
            {!isFetchingPhone && (
              <Form.Item
                label="Phone number"
                name="phoneNumber"
                className="col-12 col-md-6 col-lg-5 col-xl-4"
                extra="Phone number in international format. "
                rules={[{ required: false }]}
                initialValue={userPhone?.phoneNumber}
              >
                <Input />
              </Form.Item>
            )}
            {isFetchingPhone && (
              <>
                <Paragraph>Phone number</Paragraph>
                <Spin />
              </>
            )}
          </Flex>
          <Button type="primary" htmlType="submit" loading={isFormLoading}>
            Save User Details
          </Button>
        </Form>

        <Divider />

        <Title level={3}>Update Your Password</Title>
        <Flex gap="middle" align="start" vertical>
          <Paragraph>
            We will redirect you to our authentication provider where you can change your password and/or social logins.
          </Paragraph>
          <Link
            to={`${realmConfig.url}realms/${realmConfig.realm}/account/#/security/signingin`}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Button>Change Password</Button>
          </Link>
        </Flex>
      </Flex>
    </AdminTemplate>
  );
}

export default requireAuth(MyAccountPage);
