import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type UpdateActionOptions = {
  filterNullish?: boolean;
  resetPagination?: boolean;
};

function useQueryStringParameters<T extends Record<string, any>>(parser: (searchParams: Record<string, string>) => T) {
  const { search } = useLocation();
  const navigate = useNavigate();

  const queryStrings: T = React.useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const searchParamsObject = Object.fromEntries(searchParams.entries());
    return parser(searchParamsObject);
  }, [search]);

  const updateParameter = React.useCallback(
    (
      paramName: string,
      paramValue: any,
      options: UpdateActionOptions = { filterNullish: true, resetPagination: true }
    ) => {
      const searchParams = new URLSearchParams(search);

      if (options.filterNullish && !paramValue && typeof paramValue !== 'number' && typeof paramValue !== 'boolean')
        searchParams.delete(paramName);
      else searchParams.set(paramName, paramValue.toString());

      if (options.resetPagination) {
        searchParams.delete('page');
        searchParams.delete('pageSize');
      }

      navigate(`?${searchParams.toString()}`);
    },
    [search]
  );

  const updateMultipleParameters = React.useCallback(
    (newParameters: T, options: UpdateActionOptions = { filterNullish: true, resetPagination: true }) => {
      const searchParams = new URLSearchParams(search);

      Object.entries(newParameters).forEach(([paramName, paramValue]) => {
        if (options.filterNullish && !paramValue && typeof paramValue !== 'number' && typeof paramValue !== 'boolean')
          searchParams.delete(paramName);
        else searchParams.set(paramName, paramValue.toString());
      });

      if (options.resetPagination) {
        searchParams.delete('page');
        searchParams.delete('pageSize');
      }

      navigate(`?${searchParams.toString()}`);
    },
    [search]
  );

  function clearAllParameters() {
    navigate('');
  }

  return { queryStrings, updateParameter, updateMultipleParameters, clearAllParameters };
}

export default useQueryStringParameters;
