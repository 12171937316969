import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { setHashParam } from '../assets/hashParams';

const Header = ({ children, title = '', actionBar = null }) => (
  <div className="header">
    <div className="header-body" style={{ paddingTop: 0 }}>
      <div className="row align-items-end">
        <div className="col">
          <h1 className="header-title" style={{ fontSize: 30, fontWeight: 600, color: 'rgba(38, 38, 38, 0.88)' }}>
            {title}
          </h1>
        </div>
        <div className="col-auto">{actionBar}</div>
      </div>
      <div className="row align-items-center" style={{ marginTop: 8 }}>
        <div className="col">{children}</div>
      </div>
    </div>
  </div>
);

const Card = ({
  width = 'col-12',
  title = '',
  badge = '',
  badgeTheme = 'success',
  children = null,
  btn,
  btnTheme = 'outline-primary',
  btnTarget = '/',
  headerOptionLeft,
  headerOptionRight,
  headerOptionCenter,
  body = false,
  additionalCardClasses = '',
}) => (
  <div className={width}>
    <div className={`card ${additionalCardClasses}`}>
      {(title || badge || btn || headerOptionLeft || headerOptionRight || headerOptionCenter) && (
        <>
          <div className="card-header">
            {(headerOptionLeft || headerOptionRight || headerOptionCenter) && (
              <div
                className="row align-items-center"
                style={{ marginLeft: '-19px', marginRight: '-19px', overflow: 'hidden' }}
              >
                {headerOptionLeft && (
                  <div className="col" style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    {headerOptionLeft}
                  </div>
                )}
                {headerOptionCenter && <div className="col text-center">{headerOptionCenter || <div />}</div>}
                {headerOptionRight && <div className="col-auto">{headerOptionRight}</div>}
              </div>
            )}
            {title && <h4 className="card-header-title">{title}</h4>}
            {badge && <span className={`badge bg-${badgeTheme}`}>{badge}</span>}
            {btn && (
              <Link className={`ml-2 btn btn-sm btn-${btnTheme}`} to={btnTarget}>
                {btn}
              </Link>
            )}
          </div>
          <div className="card-body">{children}</div>
        </>
      )}
      {!title && !badge && !btn && !headerOptionLeft && !headerOptionRight && !headerOptionCenter && (
        <>
          {body && <div className="card-body">{children}</div>}
          {!body && children}
        </>
      )}
    </div>
  </div>
);

const Container = ({ children, addClass, fluid = false }) => {
  const containerClass = fluid ? 'container-fluid' : 'container';
  return <div className={`${containerClass}${addClass ? ` ${addClass}` : ''}`}>{children}</div>;
};

const ListGroup = ({ children = null }) => <div className="list-group list-group-flush my-n3">{children}</div>;

const ListGroupItem = ({ children = null, addClass = '', addStyle = null }) => (
  <div className={`list-group-item ${addClass}`} style={addStyle}>
    <div className="row align-items-center">{children}</div>
  </div>
);

const Alert = ({ className = '', content = {}, onAction = {}, modal = false }) => {
  // content = { text, linkText, linkTarget, linkAbsolutTarget, onLinkClick }
  if (!content) return null;
  const { text, color = 'danger', linkText, linkTarget, linkAbsoluteTarget, onLinkClick } = content;

  if (!text) return null;

  const alertClass = modal
    ? `alert alert-${color} border-radius-0 m-0`
    : `alert alert-${color} alert-dismissible fade show`;
  return (
    <div className={className}>
      <div className={alertClass} role="alert">
        {text}
        {linkTarget && (
          <Link className="alert-link ml-2" to={linkTarget}>
            <strong>{linkText}</strong>
          </Link>
        )}
        {linkAbsoluteTarget && (
          <a className="alert-link ml-2" href={linkAbsoluteTarget}>
            <strong>{linkText}</strong>
          </a>
        )}
        {onLinkClick && (
          <button className="btn btn-link alert-link p-0 ml-1" onClick={() => onAction(onLinkClick)}>
            <strong>{linkText}</strong>
          </button>
        )}
        {linkText && '.'}
        {!modal && (
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
    </div>
  );
};

const NavTabsComponent = ({ tabs = [], onAction = {}, activeTab }) => {
  return (
    <ul className="nav nav-tabs nav-overflow header-tabs">
      {tabs.map((tab) => {
        if (tab.hidden) {
          return null;
        }

        return (
          <li key={`tab${tab.id}`} className="nav-item">
            <div
              className={`nav-link text-nowrap cursor-pointer ${tab.id === activeTab ? 'active' : ''}`}
              style={
                tab.id === activeTab
                  ? {
                      color: '#2DA01D',
                      fontWeight: 500,
                      borderBottom: '2px solid #2DA01D',
                      paddingBottom: 12,
                    }
                  : { color: '#262626', paddingBottom: 12 }
              }
              onClick={() => onAction({ action: 'tabSelected', payload: tab.id })}
            >
              {tab.text}
              {tab.badge && <span className="badge rounded-pill bg-soft-secondary mx-1">{tab.badge}</span>}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

const NavTabs = ({ tabs = [], onAction = {}, activeTab }) => {
  useEffect(() => {
    setHashParam('tab', activeTab);
    return () => {
      setHashParam('tab');
    };
  }, [activeTab]);

  return <NavTabsComponent tabs={tabs} onAction={onAction} activeTab={activeTab} />;
};

export { Header, Container, Card, ListGroup, ListGroupItem, Alert, NavTabs, NavTabsComponent };
