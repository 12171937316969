import React from 'react';
import { Route, Routes } from 'react-router-dom';
import VerificationError from '../../VerificationError';
import VerifyIntegrationStep from './VerifyIntegrationStep';

function VerifyDomainRouter(props) {
  return (
    <Routes>
      <Route path="failed" element={<VerificationError {...props} />} />
      <Route path="" element={<VerifyIntegrationStep {...props} />} />
    </Routes>
  );
}

export default VerifyDomainRouter;
