import { restApi } from '../api/restApiSlice';

export const seoReportApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    tagTypes: ['seoReport', 'seoReportUrls'],
    getSeoReport: builder.query({
      query: (params) => ({ url: `/seo/score/summary`, method: 'GET', params }),
      providesTags: ['seoReport'],
    }),
    getSeoReportUrls: builder.query({
      query: (params) => ({ url: 'seo/urls', method: 'GET', params }),
      providesTags: ['seoReportUrls'],
    }),
    exportSeoReportCSV: builder.mutation({
      query: (params) => ({ url: 'seo-report-export', method: 'POST', params }),
    }),
  }),
});

export const { useGetSeoReportQuery, useGetSeoReportUrlsQuery, useExportSeoReportCSVMutation } = seoReportApiSlice;
