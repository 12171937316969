import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Pagination = {
  page: number;
  pageSize: number;
};

function useQueryStringPagination(defaultPageSize = 30) {
  const { search } = useLocation();
  const navigate = useNavigate();

  const pagination: Pagination = React.useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return {
      page: parseInt(searchParams.get('page')) || 1,
      pageSize: parseInt(searchParams.get('pageSize')) || defaultPageSize,
    };
  }, [search]);

  const updatePagination = (page = 1, pageSize = defaultPageSize) => {
    const searchParams = new URLSearchParams(search);
    searchParams.set('page', page.toString());
    searchParams.set('pageSize', pageSize.toString());
    navigate(`?${searchParams.toString()}`);
  };

  return {
    pagination,
    updatePagination,
  };
}

export default useQueryStringPagination;
