import { Badge, Button, Popover } from 'antd';
import { useEffect, useState } from 'react';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useSetGetStartedProgressMutation } from './GetStartedCheckerApiSlice';
import CheckerSteps from './CheckerSteps';
import { useDispatch } from 'react-redux';
import { decreaseProgressCount } from '../../actions/prerenderUserActions';
import { useEvent } from '../events/hooks/useEvent';

const GetStartedChecker = () => {
  const user = usePrerenderUser();
  const [stepStatuses, setStepStatuses] = useState(null);
  const [uncompletedSteps, setUncompletedSteps] = useState(0);
  const [setGetStartedProgress] = useSetGetStartedProgressMutation();
  const dispatch = useDispatch();
  const { track } = useEvent();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (user) {
      setStepStatuses({
        registrationCompleted: true,
        integrationWizardCompleted: user.gettingStartedSteps.integrationWizardCompleted,
        queryParameters: user.gettingStartedSteps.queryParameters,
        reviewSitemaps: user.gettingStartedSteps.reviewSitemaps,
        teamInvited: user.gettingStartedSteps.teamInvited,
      });
    }
  }, [user]);

  const onStepCompleted = (step) => {
    setStepStatuses((prevStatuses) => {
      const newStatuses = { ...prevStatuses, [step]: true };
      return newStatuses;
    });
    setGetStartedProgress({ [step]: true });
    dispatch(decreaseProgressCount({ [step]: true }));
  };

  useEffect(() => {
    if (stepStatuses) {
      const completedSteps = Object.values(stepStatuses).filter(Boolean).length;
      const totalSteps = Object.keys(stepStatuses).length;
      setUncompletedSteps(totalSteps - completedSteps);
    }
  }, [stepStatuses]);

  return (
    <Popover
      content={
        <CheckerSteps stepStatuses={stepStatuses} onStepCompleted={onStepCompleted} closePopup={() => setOpen(false)} />
      }
      open={open}
      onOpenChange={(visible) => setOpen(visible)}
      trigger="click"
    >
      {uncompletedSteps > 0 ? (
        <Badge count={uncompletedSteps}>
          <Button type="primary" size="large" onClick={() => track('Finish setup button clicked')}>
            Finish Setup
          </Button>
        </Badge>
      ) : (
        <Button type="primary" size="large">
          Finish Setup
        </Button>
      )}
    </Popover>
  );
};

export default GetStartedChecker;
