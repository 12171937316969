import { Typography, theme } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph, Text } = Typography;

const listData = [
  {
    key: 'send-invite',
    title: 'Send an invite',
    content: 'Know someone who might benefit from Prerender? Send them your unique referral link.',
  },
  {
    key: 'successful-referral',
    title: 'Successful Referral',
    content:
      'For your referral to be successful, the new user must sign up to a paid Prerender plan using your email link.',
  },
  {
    key: 'earn-credits',
    title: 'Save',
    content: 'Earn $45 off your next invoice for each successful referral. Refer as many users as you like!',
  },
];

const InformationBlock = () => {
  const { token } = theme.useToken();

  return (
    <>
      <Title level={5} style={{ marginBottom: token.marginXL }}>
        How Does It Work?
      </Title>
      <ol>
        {listData.map((item) => (
          <li key={item.key} style={{ marginBottom: token.marginMD }}>
            <div>
              <Text strong>{item.title}</Text>
              <br />
              <Paragraph>{item.content}</Paragraph>
            </div>
          </li>
        ))}
      </ol>

      <Title level={5} style={{ marginTop: token.marginXL }}>
        Not An Invite To Your Workspace
      </Title>
      <Paragraph style={{ margin: 0 }}>
        <Text strong>Note:</Text> referring someone doesn’t give them access to your account. If you'd like to
        collaborate with someone, <Link to="/security/team-members">add them to your team</Link>.
      </Paragraph>
    </>
  );
};

export default InformationBlock;
