import { Link } from 'react-router-dom';
import { Empty, Space, Typography } from 'antd';

function EmptyComponent({
  hasQuery = false,
  isPriorityTab = false,
  labels = { table: {}, options: { view: {}, add: {} } },
}) {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <Space direction="vertical" size="small">
          <p>{hasQuery ? labels.table.noMatch : labels.table.noQueue}</p>
          <Typography.Paragraph>
            <p>
              <Typography.Text>{labels.options.view.text}</Typography.Text>
              <Link className="text-info mx-2" to="/recache-stats">
                {labels.options.view.link}
              </Link>
              {labels.options.view.or && <Typography.Text>{labels.options.view.or}</Typography.Text>}
              {isPriorityTab && (
                <>
                  <Typography.Text>
                    {` `}
                    {labels.options.add.text}
                  </Typography.Text>
                  <Link className="text-info mx-2" to="/cache">
                    {labels.options.add.link1}
                  </Link>
                  <Typography.Text>{labels.options.add.or}</Typography.Text>
                  <a
                    className="text-info ml-2"
                    target="_blank"
                    href="https://docs.prerender.io/docs/en/6-api"
                    rel="noreferrer"
                  >
                    {labels.options.add.link2}
                  </a>
                  .
                </>
              )}
            </p>
          </Typography.Paragraph>
        </Space>
      }
    />
  );
}

export default EmptyComponent;
