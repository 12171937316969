import React from 'react';
import { Flex, Typography, Card, Divider, Skeleton, Tooltip, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import humanFormat from 'human-format';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

import { useGetUsageOverviewQuery } from '../api/dashboardApiSlice';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import useFeatureFlag, { FeatureFlag } from '../../../hooks/useFeatureFlag';
import { Link } from 'react-router-dom';

const { Paragraph, Text } = Typography;

function toFixedIfNecessary(value) {
  if (!value) {
    return undefined;
  }

  return +parseFloat(value).toFixed(2);
}

function toFixedDuration(value) {
  if (!value) {
    return undefined;
  }

  const duration = dayjs.duration(Number(value)).asSeconds();

  return toFixedIfNecessary(duration);
}

export function DashboardUsageOverview({ days, domain }) {
  const { data: response, isFetching } = useGetUsageOverviewQuery(
    { days, domain },
    { refetchOnMountOrArgChange: true }
  );

  const numPagesCached = response?.data?.maxPagesCached ? humanFormat(response?.data?.maxPagesCached) : '-';
  const renderCounter = response?.data?.pagesRendered ? humanFormat(response?.data?.pagesRendered) : '-';

  const isMobile = useIsMobileView();
  const isDetailedViewAvailable = useFeatureFlag([FeatureFlag.AccountDetails]);

  return (
    <Card bordered={false} style={{ width: !isMobile ? '28%' : '100%' }} loading={isFetching}>
      <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px' }}>
        <Paragraph strong style={{ marginBottom: '0' }}>
          Usage overview
        </Paragraph>
        {isDetailedViewAvailable && (
          <Link to={{ pathname: '/detailed-view' }}>
            <Button size="small">Detailed view</Button>
          </Link>
        )}
      </Flex>

      <Flex justify={'space-between'}>
        <div>
          {!isFetching && (
            <>
              <Text strong style={{ fontSize: '30px' }}>
                {toFixedIfNecessary(response?.data?.crawlBudgetIncrease) || '-'}
              </Text>
              <Text style={{ fontSize: '25px' }}>x</Text>
            </>
          )}
          <Paragraph style={{ marginBottom: '2px' }}>Crawl Budget Increase</Paragraph>
        </div>
        <div>
          <Tooltip
            arrow={false}
            title="We analyze this by comparing our Average Delivery time to your Original Delivery time"
          >
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      </Flex>
      <Divider style={{ margin: '12px 0' }} />

      <Flex justify={'space-between'}>
        <div>
          {!isFetching && (
            <Text strong style={{ fontSize: '30px' }}>
              {numPagesCached}
            </Text>
          )}
          <Paragraph style={{ marginBottom: '2px' }}>Pages Cached</Paragraph>
        </div>
        <div>
          {!isFetching && (
            <Text strong style={{ fontSize: '30px' }}>
              {renderCounter}
            </Text>
          )}
          <Paragraph style={{ marginBottom: '2px' }}>Pages Rendered</Paragraph>
        </div>
      </Flex>
      <Divider style={{ margin: '12px 0' }} />

      <Flex justify={'space-between'}>
        <div>
          {!isFetching && (
            <>
              <Text strong style={{ fontSize: '30px' }}>
                {toFixedDuration(response?.data?.averageDeliveryTime) || '-'}
              </Text>
              <Text style={{ fontSize: '25px' }}> sec</Text>
            </>
          )}
          <Paragraph style={{ marginBottom: '2px' }}>
            Average Delivery time
            <br />
            (with Prerender)
          </Paragraph>
        </div>
        <div>
          <Tooltip arrow={false} title="Average response time provided by our CDN">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      </Flex>
      <Divider style={{ margin: '12px 0' }} />

      <Flex justify={'space-between'}>
        <div>
          {!isFetching && (
            <>
              <Text strong style={{ fontSize: '30px' }}>
                {toFixedDuration(response?.data?.originalDeliveryTime) || '-'}
              </Text>
              <Text style={{ fontSize: '25px' }}> sec</Text>
            </>
          )}
          <Paragraph style={{ marginBottom: '2px' }}>
            Original Delivery time
            <br />
            (without Prerender)
          </Paragraph>
        </div>
        <div>
          <Tooltip arrow={false} title="Average response time of your pages">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      </Flex>
    </Card>
  );
}
