import React from 'react';
import { Form, Input, Typography } from 'antd';

function KeywordsCounter({ keywordsCount, maxKeywords }) {
  const isMaxKeywordsReached = keywordsCount === maxKeywords;
  return (
    <Typography.Text strong={isMaxKeywordsReached} type={isMaxKeywordsReached ? 'danger' : ''}>
      {keywordsCount}/{maxKeywords} remaining
    </Typography.Text>
  );
}

function DomainKeywordsEdit({ initialKeywords = [], usedKeywords = 0, maxKeywords = 15 }) {
  const [keywordsCount, setKeywordsCount] = React.useState(usedKeywords);

  const onKeywordsChange = (e) => {
    const keywords = e.target.value.split('\n').filter((keyword) => keyword.trim() !== '');
    if (!!initialKeywords.length) {
      setKeywordsCount(usedKeywords - initialKeywords?.length + keywords.length);
    } else {
      setKeywordsCount(usedKeywords + keywords.length);
    }
  };

  const onPressEnter = (e) => {
    if (keywordsCount >= maxKeywords) e.preventDefault();
  };

  const minRows = initialKeywords.length ? initialKeywords.length + 1 : 5;

  return (
    <Form.Item
      label={
        <Typography.Text>
          Keywords to track, <KeywordsCounter keywordsCount={keywordsCount} maxKeywords={maxKeywords} />
        </Typography.Text>
      }
    >
      <Form.Item
        name="keywords"
        noStyle
        rules={[
          {
            required: true,
            message: 'Please enter at least one keyword',
          },
        ]}
      >
        <Input.TextArea
          name="keywords"
          autoSize={{ minRows }}
          onChange={onKeywordsChange}
          onPressEnter={onPressEnter}
        />
      </Form.Item>
      <Typography.Text type="secondary">
        Enter the keywords you want to track for this domain. One keyword per line.
      </Typography.Text>
    </Form.Item>
  );
}

export default DomainKeywordsEdit;
