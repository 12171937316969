import React from 'react';
import { Table, ConfigProvider, Empty, Space, Button, Typography } from 'antd';
import { FilePdfOutlined, CalendarOutlined, CloudDownloadOutlined } from '@ant-design/icons';

import InvoiceStatus from './InvoiceStatus';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { formatCentsToDollar } from '../../../assets/currencies';
import { createUsNumberFormat } from '../../../utils/createUsNumberFormat';

function BillingHistoryTable({ isLoading = false, items = [], handleOpenInvoicePdf, hideRendersColumns = false }) {
  const isMobile = useIsMobileView();

  const visibleColumns = React.useMemo(() => {
    const columns = [
      {
        title: 'Invoice',
        width: '99%',
        render: (_text, item) => (
          <Space>
            {!isMobile && (item.status === 'payment_due' ? <CalendarOutlined /> : <FilePdfOutlined />)}
            <Typography.Text>#{item.id}</Typography.Text>
          </Space>
        ),
      },
      {
        title: 'Issue date',
        className: 'col-min',
        render: (_text, item) =>
          new Date(item.date).toLocaleString('en-US', {
            year: 'numeric',
            month: isMobile ? 'numeric' : 'short',
            day: 'numeric',
          }),
      },
      {
        title: 'Due date',
        className: 'col-min',
        render: (_text, item) =>
          new Date(item.dueDate).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          }),
        isHidden: isMobile,
      },
      {
        title: 'Amount',
        dataIndex: 'totalInCent',
        render: (text) => formatCentsToDollar(text),
      },
      {
        title: 'Included renders',
        className: 'col-min',
        dataIndex: 'includedRenders',
        render: (text) => createUsNumberFormat(text || 0),
        isHidden: hideRendersColumns,
      },
      {
        title: 'Extra renders',
        className: 'col-min',
        dataIndex: 'extraRenders',
        render: (text) => createUsNumberFormat(text || 0),
        isHidden: hideRendersColumns,
      },
      {
        title: 'Total renders',
        className: 'col-min',
        dataIndex: 'renders',
        render: (text) => createUsNumberFormat(text || 0),
        isHidden: hideRendersColumns,
      },
      {
        title: 'Payment date',
        className: 'col-min',
        render: (_text, item) =>
          item.paidAt
            ? new Date(item.paidAt).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
            : '-',
        isHidden: isMobile,
      },
      {
        title: 'Status',
        render: (_text, item) => (
          <Space>
            <InvoiceStatus invoice={item} />
            <Button icon={<CloudDownloadOutlined />} onClick={() => handleOpenInvoicePdf(item.id)}></Button>
          </Space>
        ),
      },
    ];

    return columns.filter((column) => !column.isHidden);
  }, [isMobile, handleOpenInvoicePdf, hideRendersColumns]);

  return (
    <ConfigProvider
      renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You don't have any invoices yet." />}
    >
      <Table
        bordered={!isLoading}
        rowKey="id"
        loading={isLoading}
        columns={visibleColumns}
        dataSource={items}
        pagination={false}
        showHeader={!!items.length}
        scroll={{ x: true }}
      />
    </ConfigProvider>
  );
}

export default BillingHistoryTable;
