// Define action creators that dispatch actions:
import config from '../assets/config';
import internalApi from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';
import { setLastErrorCode, clearLastErrorCode } from './pageActions';

import {
  DASHBOARDSTATS_SET,
  DASHBOARDSTATS_IN_PROGRESS,
} from './types';

const dashboardStatsSearch = () => async (dispatch) => {
  try {
    dispatch({ type: DASHBOARDSTATS_IN_PROGRESS, payload: true });
    const requestUri = `${config.apiUrl.dataServer}/api/v2/dashboard-stats`;
    const data = await internalApi('get', requestUri, null);
    dispatch({ type: DASHBOARDSTATS_SET, payload: data });
    dispatch(clearLastErrorCode());
  } catch (error) {
    console.log(error);
    dispatch({ type: DASHBOARDSTATS_IN_PROGRESS, payload: false });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

export default dashboardStatsSearch;
