import _ from 'lodash';
import { formatCentsToDollar } from '../../../assets/currencies';
import CalendarIcon from '../../../assets/icons/calendar-for-cost-estimate-icon.svg';
import PuzzleIcon from '../../../assets/icons/puzzle-for-cost-estimate.svg';
import CreditCardIcon from '../../../assets/icons/credit-card-icon.svg';
import DollarIcon from '../../../assets/icons/dollar-icon.svg';
import { useGetCostEstimateQuery } from '../api/billingApiSlice';
import { Spin } from 'antd';

export const BillingOverviewCostEstimate = () => {
  const {
    data: costEstimate,
    isError: isErrorLoadingCostEstimate,
    isLoading: isLoadingCostEstimate,
  } = useGetCostEstimateQuery();

  return (
    <div>
      <h3 className="font-weight-bold">Cost Estimate</h3>
      <p>This is your cost estimate based on usage over the last 30 days.</p>
      {isErrorLoadingCostEstimate ? (
        <p style={{ color: 'red' }}>
          An unexpected error has occured while retrieving your cost estimate. Please try again by reloading the page.
        </p>
      ) : isLoadingCostEstimate ? null : (
        <ul className="mt-4 list-unstyled">
          <li className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img src={CalendarIcon} />
              <span className="ml-3">Subscription Cost</span>
            </div>
            <span>
              {!_.isNumber(costEstimate.data?.reservedCost)
                ? 'n/a'
                : formatCentsToDollar(costEstimate.data.reservedCost || 0)}
            </span>
          </li>
          <hr />
          <li className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img src={PuzzleIcon} />
              <span className="ml-3">Packages Cost</span>
            </div>
            <span>
              {!_.isNumber(costEstimate.data?.packagesCost)
                ? 'n/a'
                : formatCentsToDollar(costEstimate.data.packagesCost || 0)}
            </span>
          </li>
          <hr />
          <li className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img src={CreditCardIcon} />
              <span className="ml-3">Extra Renders Cost</span>
            </div>
            <span>
              {!_.isNumber(costEstimate.data?.onDemandCost)
                ? 'n/a'
                : formatCentsToDollar(costEstimate.data.onDemandCost || 0)}
            </span>
          </li>
          <hr />
          <li className="d-flex align-items-center justify-content-between font-weight-bold">
            <div className="d-flex align-items-center">
              <img src={DollarIcon} />
              <span className="ml-3">Total Estimated Cost</span>
            </div>
            <span>
              {_.isNumber(costEstimate.data?.reservedCost) ||
              _.isNumber(costEstimate.data?.packagesCost) ||
              _.isNumber(costEstimate.data?.onDemandCost)
                ? formatCentsToDollar(
                    (costEstimate?.data?.reservedCost || 0) +
                      (costEstimate?.data?.packagesCost || 0) +
                      (costEstimate?.data?.onDemandCost || 0)
                  )
                : 'n/a'}
            </span>
          </li>
        </ul>
      )}
    </div>
  );
};
