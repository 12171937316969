import { useState, useEffect } from 'react';

import { usePrerenderUser } from '../../hooks/usePrerenderUser';

export function useHotJar() {
  const user = usePrerenderUser();

  const [userIdentified, setUserIdentified] = useState(false);

  useEffect(() => {
    if (user && user.id && window.hj && userIdentified === false) {
      window.hj('identify', user.id, {
        user_id: user.id,
        created: user.signupDate,
        company: user.companyId,
      });

      setUserIdentified(true);
    }
  }, [user, userIdentified]);
}
