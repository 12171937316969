import { Tag, Tooltip } from 'antd';
import React from 'react';

import { TOOLTIP_ROOT_ID } from '../Tooltip';

export default function SeoScoreStatusComponent({ status }) {
  const tooltipConfig = React.useMemo(() => {
    if (status === 'passed') {
      return {
        title: 'Healthy',
        color: 'success',
        label: 'Passed',
      };
    }

    if (status === 'improvement') {
      return {
        title: 'Require improvements',
        color: 'orange',
        label: 'Improvement',
      };
    }

    return {
      title: 'Will be checked on the next rendering',
      color: 'default',
      label: 'Not Checked',
    };
  }, [status]);

  return (
    <div data-tooltip-id={TOOLTIP_ROOT_ID} data-tooltip-content={tooltipConfig.title} data-tooltip-place="top">
      <Tag color={tooltipConfig.color}>{status}</Tag>
    </div>
  );
}
