import { restApi } from './restApiSlice';

export const marketingApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getMarketingData: builder.query({
      query: () => ({ url: '/users/marketing', method: 'GET' }),
    }),
    setMarketingData: builder.mutation({
      query: (body) => ({
        url: `/users/marketing`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const { useGetMarketingDataQuery, useSetMarketingDataMutation } = marketingApi;
