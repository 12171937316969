import { restApi } from '../../api/restApiSlice';

export const urlsApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getURLs: builder.query({
      query: ({ limit, offset, wildcard }) => {
        return {
          url: 'urls',
          method: 'GET',
          params: {
            limit,
            offset,
            wildcard,
          },
        };
      },
      transformResponse: (response) => response.data,
      providesTags: (result, error, arg) => {
        const data = result?.data?.urls;
        return ['URL', ...(data ? data.map(({ id }) => ({ type: 'URL', id })) : [])];
      },
    }),
    getCacheClearStatus: builder.query({
      query: () => ({
        url: '/urls/cache-clear-status',
      }),
      providesTags: ['CacheClearStatus'],
    }),
    getURLsCount: builder.query({
      providesTags: ['URL'],
      query: (params) => ({
        url: '/urls/count',
        method: 'GET',
        params,
      }),
    }),
    clearUrlCache: builder.mutation({
      query: (wildcard) => ({
        url: '/urls/cache',
        method: 'DELETE',
        body: { wildcard },
      }),
      invalidatesTags: ['CacheClearStatus', 'URL'],
    }),
    addUrls: builder.mutation({
      query: (urls) => ({
        url: '/urls',
        method: 'POST',
        body: { urls },
        invalidatesTags: ['URL'],
      }),
    }),
  }),
});

export const {
  useLazyGetURLsCountQuery,
  useGetURLsCountQuery,
  useLazyGetURLsQuery,
  useLazyGetCacheClearStatusQuery,
  useClearUrlCacheMutation,
  useAddUrlsMutation,
} = urlsApi;
