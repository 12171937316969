import React, { useEffect, useState } from 'react';

import { Alert, Modal, Form, Input } from 'antd';
import DeviceTypeSelector from '../../../components/DeviceTypeSelector';
import { isValidUrl } from '../../validators/url';
import { useAddURLsMutation } from '../CacheManagerApi';

const GENERIC_ERROR = 'An error occurred while adding URLs. Please try again later.';

export default function CachedPagesAdd({ onSuccess, mobileAdaptive, showModal, onModalClose }) {
  const defaultAdaptiveTypes = mobileAdaptive ? ['desktop', 'mobile'] : ['desktop'];
  const [adaptiveTypes, setAdaptiveTypes] = useState(defaultAdaptiveTypes);
  const [customMessage, setCustomMessage] = useState('');
  const [urlsAlreadyExists, setUrlsAlreadyExists] = useState([]);
  const [addUrls, addUrlsResult] = useAddURLsMutation();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ urls: '' });
    setUrlsAlreadyExists([]);
  }, [showModal]);

  const handleModalClose = () => {
    setUrlsAlreadyExists([]);
    setCustomMessage('');
    addUrlsResult.reset();
    onModalClose();
  };

  const saveUrlList = async (urlList, adaptiveTypes) => {
    const urls = urlList.flatMap((url) =>
      adaptiveTypes.map((adaptiveType) => ({
        url,
        adaptiveType,
      }))
    );
    try {
      const addUrlsResult = (await addUrls(urls).unwrap()).data.result;
      const urlsAlreadyExistsInAddedResult = addUrlsResult.filter((r) => r.error?.urlAlreadyExists).map((e) => e.item);
      setUrlsAlreadyExists(urlsAlreadyExistsInAddedResult);
      if (urlsAlreadyExistsInAddedResult.length === 0) {
        const addedElements = addUrlsResult.filter((r) => !r.error?.message);
        onSuccess(addedElements.length);
        handleModalClose();
      }
    } catch {}
  };

  const saveForm = ({ urls }) => {
    if (!urls) return;

    const urlList = urls.split('\n').filter((u) => !!u);
    const invalidLine = urlList.findIndex((u) => !isValidUrl(u));

    // Set the error message
    setCustomMessage(
      urlList.length > 100
        ? `You list of ${urlList.length} URLs exceeds 100 URLs.`
        : invalidLine !== -1
        ? `Your list contains an invalid URL in line ${invalidLine + 1}.`
        : ''
    );

    if (urlList.length > 0 && invalidLine === -1) {
      saveUrlList(urlList, adaptiveTypes);
    }
  };

  const renderUrlsAlreadyExistsBox = () => {
    const MAX_URLS_TO_SHOW = 10;
    const urlsAlreadyExistsHead = urlsAlreadyExists.slice(0, MAX_URLS_TO_SHOW);
    if (urlsAlreadyExists.length > MAX_URLS_TO_SHOW) {
      urlsAlreadyExistsHead.push('...');
    }
    return (
      <Alert
        type="error"
        message="URLs already exist!"
        description={
          <>
            {urlsAlreadyExistsHead.map((u, i) => (
              <div key={`url-already-exists-${i.url}`} className="text-truncate">
                {u.url} - {u.adaptiveType}
              </div>
            ))}
          </>
        }
      />
    );
  };

  return (
    <>
      <Modal
        closable
        open={showModal}
        title="Add URLs"
        okText="Add"
        cancelText="Cancel"
        onCancel={handleModalClose}
        okButtonProps={{ htmlType: 'submit', form: 'addCachedPage', loading: addUrlsResult.isLoading }}
        styles={{ footer: { display: 'flex', justifyContent: 'space-between' } }}
      >
        {addUrlsResult.isError && <Alert showIcon type="error" message={GENERIC_ERROR} />}

        {customMessage && <Alert showIcon type="warning" message={customMessage} />}

        {urlsAlreadyExists.length ? <div style={{ marginTop: 8 }}>{renderUrlsAlreadyExistsBox()}</div> : null}

        <Form id="addCachedPage" form={form} layout="vertical" onFinish={saveForm}>
          <p style={{ marginTop: 16, marginBottom: 16 }}>Enter a list of up to 100 URLs</p>
          <p style={{ marginBottom: 4 }}>Device type</p>
          <DeviceTypeSelector mobileAdaptive={mobileAdaptive} setAdaptiveTypes={setAdaptiveTypes} />
          <Form.Item name="urls">
            <Input.TextArea
              rows={10}
              placeholder="https://example.com/index.html&#10;https://example.com/about&#10;..."
              style={{ marginTop: 16 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
