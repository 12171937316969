import Keycloak from 'keycloak-js';
import { KEYCLOAK_AUTH_ERROR, KEYCLOAK_AUTH_SUCCESS, KEYCLOAK_IN_PROGRESS } from '../../actions/types';

const keycloakOrigin = () => window.location.origin.toString();

const localEnvConfig = {
  realm: 'prerender',
  url: 'http://localhost:8088/auth/',
  clientId: 'prerender-frontend',
  prerenderOrigin: 'http://localhost:8443',
};

const stagingEnvConfig = {
  realm: 'prerender',
  url: 'https://auth.prerender-staging.dev/auth/',
  clientId: 'prerender-frontend',
  prerenderOrigin: 'https://dashboard.prerender-staging.dev',
};

const stagingSamlEnvConfig = {
  realm: 'prerender-staging-saml',
  url: 'https://auth-staging-saml-vhf.prerender.io/auth/',
  clientId: 'prerender-frontend',
  prerenderOrigin: 'https://staging-saml-dashboard.prerender.io',
};

const prodEnvConfig = {
  realm: 'prerender',
  url: 'https://auth.prerender.io/auth/',
  clientId: 'prerender-frontend',
  prerenderOrigin: 'https://dashboard.prerender.io',
};

const prodNewEnvConfig = {
  realm: 'prerender',
  url: 'https://auth.prerender.io/auth/',
  clientId: 'prerender-frontend',
  prerenderOrigin: 'https://app.prerender.com',
};

const generateKeycloakConfigFromSSOEnvName = (environmentName) => ({
  realm: `prerender-${environmentName}`,
  url: `https://${environmentName}.auth.prerender.io/auth/`,
  clientId: 'prerender-frontend',
});

const predefinedCOnfigs = [prodNewEnvConfig, prodEnvConfig, localEnvConfig, stagingEnvConfig, stagingSamlEnvConfig];

const originRegex = /^https:\/\/([a-z0-9-_]+)\.app\.prerender\.io$/;

export const getSSOSiteName = () => {
  const origin = keycloakOrigin();
  const match = originRegex.exec(origin);
  return (match && match[1]) || false;
};

export const isSSOSite = () => !!getSSOSiteName();

export const configForOrigin = () => {
  const ssoSiteName = getSSOSiteName();
  let config;
  if (ssoSiteName) {
    config = generateKeycloakConfigFromSSOEnvName(ssoSiteName);
  } else {
    const origin = keycloakOrigin();
    config = predefinedCOnfigs.find((e) => e.prerenderOrigin === origin);
  }

  if (!config) {
    console.error(`Coudn't find config for ${origin} in using first prod env config`);
    return prodEnvConfig;
  } else {
    return config;
  }
};

let keycloak = null;

export const getAccessToken = () => keycloak && keycloak.token;

export const keycloakLogout = () => {
  if (keycloak) keycloak.logout({ redirectUri: `${window.location.origin.toString()}/` });
};

export const initKeycloak = (dispatch) => {
  dispatch({ type: KEYCLOAK_IN_PROGRESS });
  const keycloakInitOptions = {
    onLoad: 'login-required',
    checkLoginIframe: false,
  };

  const keycloakOptions = configForOrigin();

  keycloak = new Keycloak(keycloakOptions);

  // We're extending the createLoginUrl logic, So keeping the orignaizal implementation referenced at _createLoginUrl
  keycloak._createLoginUrl = keycloak.createLoginUrl;
  keycloak.createLoginUrl = (opts) => {
    const url = keycloak._createLoginUrl(opts);
    const username = sessionStorage.getItem('username');

    if (username) {
      return url + `&username=${username}`;
    }

    return url;
  };

  keycloak.onAuthSuccess = () => {
    dispatch({ type: KEYCLOAK_AUTH_SUCCESS });
  };

  keycloak.onAuthError = (authErr) => {
    dispatch({ type: KEYCLOAK_AUTH_ERROR, payload: authErr || 'unknown auth error' });
  };

  keycloak.onTokenExpired = () => {
    dispatch({ type: KEYCLOAK_IN_PROGRESS });

    keycloak
      .updateToken(180)
      .success(() => {
        // TODO: do something more clever there
        dispatch({ type: KEYCLOAK_AUTH_SUCCESS });
      })
      .error((e) => {
        dispatch({ type: KEYCLOAK_AUTH_ERROR, payload: e || 'unknown update token auth error' });
      });
  };

  keycloak.init(keycloakInitOptions).catch((e) => {
    console.log('Failed to initialize keycloak', e);
  });
};

/* eslint-disable prefer-template */
// https://auth.prerender.io/auth/realms/prerender/login-actions/reset-credentials?client_id=prerender-frontend
export const keycloakResetPasswordLink = (email) => {
  const cfg = configForOrigin();
  return (
    `${cfg.url}realms/${cfg.realm}` +
    `/login-actions/reset-credentials?client_id=${cfg.clientId}` +
    (email ? `&username=${encodeURIComponent(email)}` : '')
  );
};

// eslint-disable-next-line
// localhost:8088/auth/realms/prerender/protocol/openid-connect/auth?client_id=prerender-frontend&redirect_uri=http%3A%2F%2Flocalhost%3A8443%2F&response_mode=fragment&response_type=code&scope=openid
export const keycloakLoginLink = (redirectURL, email) => {
  const cfg = configForOrigin();
  const url =
    `${cfg.url}realms/${cfg.realm}` +
    `/protocol/openid-connect/auth?client_id=${cfg.clientId}` +
    (redirectURL ? `&redirect_uri=${encodeURIComponent(redirectURL)}` : '') +
    '&response_mode=fragment&response_type=code&scope=openid' +
    (email ? `&username=${encodeURIComponent(email)}` : '');
  return url;
};

// eslint-disable-next-line
// // https://auth.prerender.io/auth/realms/prerender/protocol/openid-connect/registrations?client_id=prerender-frontend&response_type=code&scope=openid%20email&redirect_uri=https://dashboard.prerender.io
// export const keycloakRegisterLink = () => {
//   const cfg = configForEnv(keycloakEnvironment());
//   return `${cfg.url}realms/${cfg.realm}`
//     + `/protocol/openid-connect/registrations?client_id=${cfg.clientId}`
//     + `&response_type=code&scope=openid%20email&redirect_uri=${window.location.origin}`;
// };
