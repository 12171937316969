import { useEffect, useState } from 'react';
import { Card, Flex, Typography, Button, Space, theme } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import NavigationButtons from '../../NavigationButtons';
import Integrations from './Integrations';
import SpinningWheel from '../../../../../components/SpinningWheel';
import { useGetDocumentationByIntegrationMethodQuery, useGetDomainQuery } from '../../../api/integrationWizardSlice';
import './documentation.css';
import { usePrerenderUser } from '../../../../../hooks/usePrerenderUser';
import { useEvent } from '../../../../events/hooks/useEvent';
import CurrentToken from '../../../../SecurityAndAccess/PrerenderToken/CurrentToken';

const { Paragraph, Title } = Typography;

const PRERENDER_DOC = 'prerender';

const IntegrationsTitle = ({ selectedOption }) => {
  if (selectedOption === PRERENDER_DOC) {
    return (
      <div>
        <Title level={4}>How does Prerender work?</Title>
      </div>
    );
  }
  return (
    <div>
      <Title level={4}>Follow this guide to integrate your site</Title>
      <Paragraph>
        Integration is quick, but it can be confusing if you’re not a developer. Marketers, bring on your dev team here.
      </Paragraph>
      <Paragraph>First, start by choosing your preferred integration on the left-hand side.</Paragraph>
    </div>
  );
};

const IntegrationStep = ({ onGoBack, onGoNext, goToStart }) => {
  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();
  const [selectedOption, setSelectedOption] = useState(PRERENDER_DOC);
  const [docContent, setDocContent] = useState(null);
  const { token } = theme.useToken();

  const {
    currentData: data,
    isFetching,
    isError,
  } = useGetDomainQuery('integration-step', { refetchOnMountOrArgChange: true });
  const {
    data: docData,
    isFetching: docLoading,
    error,
  } = useGetDocumentationByIntegrationMethodQuery(selectedOption, { skip: !selectedOption });

  useEffect(() => {
    if (docData?.html) {
      setDocContent(docData?.html);
    }
  }, [docData]);

  useEffect(() => {
    if (isError || (!data?.domain && !isFetching)) {
      goToStart();
    }
  }, [isError, goToStart, data?.domain, isFetching]);

  const onDocButtonClick = () => {
    setSelectedOption(PRERENDER_DOC);
  };

  if (isFetching) {
    return (
      <Flex vertical align="center" justify="center" style={{ flex: 1 }}>
        <SpinningWheel matchParent />
      </Flex>
    );
  }

  return (
    <Flex justify="center" vertical gap={16} style={{ minHeight: '75vh', paddingTop: 32 }}>
      <Flex style={{ minHeight: '460px', height: '100%', position: 'relative' }}>
        <div
          style={{
            width: 300,
            transition: 'width 0.3s ease',
            padding: '12px 24px',
            gap: 8,
            position: 'relative',
          }}
        >
          <div style={{ position: 'absolute', inset: 0, overflow: 'auto' }}>
            <Space direction="vertical" size="small" style={{ width: '100%' }}>
              <Flex justify="center">
                <Button
                  type="default"
                  style={
                    selectedOption === PRERENDER_DOC
                      ? {
                          backgroundColor: token.colorPrimaryBg,
                          borderColor: token.colorPrimary,
                          width: '100%',
                        }
                      : { width: '100%' }
                  }
                  onClick={onDocButtonClick}
                >
                  {selectedOption === PRERENDER_DOC && (
                    <div style={{ position: 'absolute', color: token.colorPrimary, right: 28 }}>
                      <CheckCircleFilled />
                    </div>
                  )}
                  How does Prerender work
                </Button>
              </Flex>
              <Card title="Prerender token (API Token)" size="small" bordered>
                <CurrentToken showBanner={false} tokenStyle={{ fontSize: 16 }} />
              </Card>
              <Card title="Integration methods" size="small" bordered>
                <Integrations selectedMethod={selectedOption} setSelectedMethod={setSelectedOption} />
              </Card>
            </Space>
          </div>
        </div>
        <div style={{ width: 2, background: 'grey', margin: '0 16px', borderRadius: '4px' }}></div>
        <Flex vertical gap={8} style={{ flex: 1 }}>
          <IntegrationsTitle selectedOption={selectedOption} />
          <Card
            style={{ flex: 1, display: 'flex' }}
            styles={{ body: { flex: 1, display: 'flex', position: 'relative' } }}
          >
            <div style={{ position: 'absolute', inset: 0, display: 'flex' }}>
              {docLoading && (
                <Flex style={{ flex: 1 }} justify="center" align="center">
                  <SpinningWheel matchParent />
                </Flex>
              )}
              {!docLoading && !error && docData?.html && (
                <div className="documentation" dangerouslySetInnerHTML={{ __html: docContent }}></div>
              )}
              {!docLoading && error && (
                <div
                  style={{
                    padding: '12px 24px',
                    color: 'red',
                  }}
                >
                  An unexpected error has occured while retrieving documentation for {selectedOption}. Please try again
                  by reloading the page.
                </div>
              )}
            </div>
          </Card>
        </Flex>
      </Flex>
      <div style={{ background: '#F5F5F5', paddingTop: 16 }}>
        <NavigationButtons
          onGoBack={onGoBack}
          onGoNext={() => {
            track('Integration Wizard: Integration method selected', {
              integration_method: selectedOption !== PRERENDER_DOC ? selectedOption : null,
              subscription_plan: prerenderUser.chargebeePlanId,
            });
            onGoNext();
          }}
        />
      </div>
    </Flex>
  );
};

export default IntegrationStep;
