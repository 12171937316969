import React from 'react';
import { Link } from 'react-router-dom';

import BillingUpcomingTagIcon from '../../../assets/icons/billing-upcoming-tag-icon.svg';
import BillingUpcomingBadgeIcon from '../../../assets/icons/billing-upcoming-badge-icon.svg';
import BillingDoneTagIcon from '../../../assets/icons/billing-done-tag-icon.svg';

import { useGetInvoicesQuery } from '../api/billingApiSlice';
import { formatCentsToDollar } from '../../../assets/currencies';
import { useEvent } from '../../events/hooks/useEvent';

import InvoiceStatus from '../history/InvoiceStatus';

export const BillingOverviewBillingActivity = ({ user }) => {
  const { data: invoicesList, isError: isErrorLoadingInvoices } = useGetInvoicesQuery();

  const { track } = useEvent();

  const onSeeAllLinkClick = React.useCallback(() => {
    track('Billing History Viewed ', { subscription_plan: user.planName, location: 'See All Button' });
  }, [user]);

  const mapInvoice = (invoice) => {
    let isPlan = invoice.isPackageOnly === false;

    const name = isPlan ? invoice.planName : invoice.packageName;

    return (
      <li key={invoice.id} className="mb-2 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img src={invoice.status === 'paid' ? BillingDoneTagIcon : BillingUpcomingTagIcon} />
          <div className="ml-3">
            <p className="mb-0">{`${name} ${isPlan ? 'Plan' : 'Package'} - $${(invoice.totalInCent / 100).toFixed(
              2
            )}`}</p>
            <p className="mb-0 text-sm text-grey">
              {new Date(invoice.date).toLocaleString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </p>
          </div>
        </div>
        <InvoiceStatus invoice={invoice} />
      </li>
    );
  };

  return (
    <div>
      <h3 className="font-weight-bold">Billing Activity</h3>
      {isErrorLoadingInvoices ? (
        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
          <p style={{ color: 'red' }}>An unexpected error occured, please try again by reloading the page.</p>
        </div>
      ) : user.nextBillingAt || user.lastCharged ? (
        <ul className="mt-4 list-unstyled">
          {user.plan.estimate.planPriceInCents === 0 ? <p>No upcoming bill with a free plan.</p> : null}

          {user.nextBillingAt && user.plan.estimate.planPriceInCents > 0 && (
            <li className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <img src={BillingUpcomingTagIcon} />
                <div className="ml-3">
                  <p className="mb-0">{`${user.planName} Plan - ${formatCentsToDollar(
                    user.plan.estimate.planPriceInCents
                  )}`}</p>
                  <p className="mb-0">{`Packages - ${formatCentsToDollar(user.plan.estimate.packagesPriceInCents)}`}</p>
                  <p className="mb-0 text-sm text-grey">
                    {user.nextBillingAt.toLocaleString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </p>
                </div>
              </div>
              <img src={BillingUpcomingBadgeIcon}></img>
            </li>
          )}
          <hr className="my-2" />
          {invoicesList?.data.length > 0 ? (
            invoicesList?.data.slice(0, 3).map(mapInvoice)
          ) : (
            <div className="mt-4 d-flex justify-content-center">
              <p className="text-grey">No Invoices yet.</p>
            </div>
          )}
        </ul>
      ) : (
        <p className="text-grey">No charges lately.</p>
      )}
      {invoicesList?.data.length > 0 && (
        <div onClick={onSeeAllLinkClick}>
          <Link
            to="/billing/history"
            className="p-2 container-fluid d-flex align-items-center justify-content-center"
            style={{
              backgroundColor: 'white',
              color: '#4e6775',
              border: '1px solid #4e6775',
              justifyContent: 'center',
              borderRadius: '6px',
            }}
          >
            See all
          </Link>
        </div>
      )}
    </div>
  );
};
