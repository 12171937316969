import { Badge } from 'antd';

function HighlightedCard({ children, exclusiveForYou = false, betterPlan = false, recommendedPlan = false }) {
  if (exclusiveForYou)
    return (
      <Badge.Ribbon text="Exclusive Offer for You!" color="purple">
        {children}
      </Badge.Ribbon>
    );

  if (betterPlan)
    return (
      <Badge.Ribbon text="Better Plan for You!" color="green">
        {children}
      </Badge.Ribbon>
    );

  if (recommendedPlan)
    return (
      <Badge.Ribbon text="Recommended" color="#2da01d">
        {children}
      </Badge.Ribbon>
    );

  return children;
}

export default HighlightedCard;
