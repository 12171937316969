import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalWrapper } from './ModalLib';
import { openModal, closeModal } from '../actions/pageActions';

class ConfirmationModal extends Component {
  didReceiveAction({ action }) {
    const { closeModal: doCloseModal, onAction } = this.props;
    doCloseModal();
    if (onAction) onAction({ action });
  }

  render() {
    const { title, text, options = [{ action: 'okay', text: 'Okay', color: 'primary' }], closeModal: doCloseModal } = this.props;
    return (
      <ModalWrapper>
        <div className="modal-header">
          <h3 className="modal-title">{title}</h3>
          <button type="button" className="close" style={{ fontSize: '2.4rem' }} onClick={() => doCloseModal()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>{text}</p>
        </div>
        <div className="modal-footer">
          { options.map((option) => <button key={option.action} type="button"
                                            className={`btn btn-${option.color || 'primary'}`}
                                            onClick={() => this.didReceiveAction({ action: option.action })}>
              {option.text}
            </button>)}
        </div>
      </ModalWrapper>
    );
  }
}

function mapStateToProps() { return {}; }

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openModal, closeModal }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
