import React from 'react';
import { List, Avatar, theme } from 'antd';
import { SettingFilled, CheckSquareFilled, CloseSquareOutlined } from '@ant-design/icons';

import { getAgeString } from '../../../assets/time';

const renderUnit = (value, unit, units) => {
  if (typeof value !== 'number') return '';

  if (unit && value <= 1) return ` ${unit}`;
  if (units && value > 1) return ` ${units}`;
};

function Icon({ CustomIconComponent, value }) {
  const { token } = theme.useToken();

  if (CustomIconComponent) return <CustomIconComponent style={{ color: token.colorPrimary }} />;

  if (value === true) return <CheckSquareFilled style={{ color: token.colorPrimary }} />;
  if (value === false) return <CloseSquareOutlined style={{ color: token.colorPrimary }} />;

  return <SettingFilled style={{ color: token.colorPrimary }} />;
}

function AuditLogListItem({ item }) {
  const { title, description } = React.useMemo(() => {
    const { modifiedBy, name, value, unit, units, type } = item;
    const description = getAgeString(new Date(item.modifiedAt), 'ago', 'full');

    let title = '';
    if (value === true) title = `${modifiedBy} enabled ${name}.`;
    else if (value === false) title = `${modifiedBy} disabled ${name}.`;
    else if (type === 'coupon') title = `${modifiedBy} ${value}.`;
    else if (type === 'referral') title = `${modifiedBy} ${value}.`;
    else title = `${modifiedBy} set the ${name} to ${value}${renderUnit(value, unit, units)}.`;
    return {
      title,
      description,
    };
  }, [item]);

  return (
    <List.Item>
      <List.Item.Meta
        avatar={
          <Avatar
            icon={<Icon CustomIconComponent={item.CustomIconComponent} value={item.value} />}
            style={{ background: 'transparent' }}
          />
        }
        title={title}
        description={description}
      />
    </List.Item>
  );
}

export default AuditLogListItem;
