import React, { useState } from 'react';
import { Button, InputNumber, Select, Typography, Flex } from 'antd';
import './dropdownFilters.css';

enum SelectOptions {
  Equals = 'equals',
  Between = 'between',
}

type StatusCodeFilter = {
  statusCodeLow: number;
  statusCodeHigh: number;
  statusCodeEq: number;
};

type StatusDropdownFilterProps = {
  confirm: () => void;
  selectedKeys: Partial<StatusCodeFilter>[];
  setSelectedKeys: (keys: Partial<StatusCodeFilter>[]) => void;
  clearFilters: () => void;
};

const QUERY_OPTIONS = [
  { value: SelectOptions.Equals, label: 'Equals' },
  { value: SelectOptions.Between, label: 'Between' },
];

export function StatusDropdownFilter({
  confirm,
  selectedKeys,
  setSelectedKeys,
  clearFilters,
}: StatusDropdownFilterProps) {
  const [selectedValue, setSelectedValue] = useState<SelectOptions>(SelectOptions.Equals);
  const [statusCode, setStatusCode] = useState(selectedKeys.length ? selectedKeys[0] : null);
  const [disabled, setDisabled] = useState(true);

  const onSubmit = () => {
    setSelectedKeys(statusCode == null ? [] : [statusCode]);
    confirm();
  };

  const onReset = () => {
    setStatusCode(null);
    clearFilters();
    setDisabled(true);
  };

  return (
    <Flex vertical gap="middle" style={{ padding: 12 }}>
      Status Code
      <Flex gap="small" align="center">
        <Select
          onChange={(value) => setSelectedValue(value)}
          options={QUERY_OPTIONS}
          defaultValue={QUERY_OPTIONS[0].value}
          style={{ width: 120 }}
        />
        {selectedValue === 'between' && (
          <>
            <InputNumber
              min={100}
              max={599}
              placeholder="200"
              value={statusCode?.statusCodeLow}
              onChange={(value) => {
                setStatusCode({ ...statusCode, statusCodeLow: value, statusCodeEq: null });
                setDisabled(false);
              }}
            />
            <Typography.Text>and</Typography.Text>
            <InputNumber
              min={100}
              max={599}
              placeholder="499"
              value={statusCode?.statusCodeHigh}
              onChange={(value) => {
                setStatusCode({ ...statusCode, statusCodeHigh: value, statusCodeEq: null });
                setDisabled(false);
              }}
            />
          </>
        )}
        {selectedValue === 'equals' && (
          <InputNumber
            min={100}
            max={599}
            placeholder="200"
            value={statusCode?.statusCodeEq}
            onChange={(value) => {
              setStatusCode({ ...statusCode, statusCodeEq: value, statusCodeLow: null, statusCodeHigh: null });
              setDisabled(false);
            }}
          />
        )}
      </Flex>
      <div className="dropdownFilters-buttons">
        <Button size="small" type="link" onClick={onReset} disabled={disabled}>
          Reset
        </Button>
        <Button type="primary" size="small" onClick={onSubmit}>
          OK
        </Button>
      </div>
    </Flex>
  );
}
