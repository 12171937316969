import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Tag, Tooltip } from 'antd';
import React from 'react';

export default function SeoScoreTag({ score }) {
  if (score === null) {
    return (
      <Tooltip title={'Will be scored on the next rendering'} placement="top">
        <Tag icon={<ClockCircleOutlined style={{ verticalAlign: 'inherit' }} />} color="default">
          Not Scored
        </Tag>
      </Tooltip>
    );
  } else if (score >= 20) {
    return (
      <Tooltip title={'Good'} placement="top">
        <Tag icon={<CheckCircleOutlined style={{ verticalAlign: 'inherit' }} />} color="green">
          {score}
        </Tag>
      </Tooltip>
    );
  } else if (score >= -30 && score < 20) {
    return (
      <Tooltip title={'Needs Improvement'} placement="top">
        <Tag icon={<ExclamationCircleOutlined style={{ verticalAlign: 'inherit' }} />} color="orange">
          {score}
        </Tag>
      </Tooltip>
    );
  } else if (score < -30) {
    return (
      <Tooltip title={'Poor'} placement="top">
        <Tag icon={<CloseCircleOutlined style={{ verticalAlign: 'inherit' }} />} color="red">
          {score}
        </Tag>
      </Tooltip>
    );
  }
}
