import { Content } from 'antd/lib/layout/layout';
import { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';

const META_TAGS = {
  title: 'Deep Insight - Prerender.io',
};
const InsightRouter = lazy(() => import('./insight/InsightRouter'));

const InsightPage = ({ user }) => {
  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Content>
        <Suspense fallback={<h1>Loading...</h1>}>
          <InsightRouter user={user} />
        </Suspense>
      </Content>
    </AdminTemplate>
  );
};

const mapStateToProps = (state) => ({
  user: state.prerenderUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(InsightPage));
