import React from 'react';
import { Typography, Flex, Space, Button, Pagination, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useLocation, Link } from 'react-router-dom';

import usePagination from '../../utils/useQueryStringPagination';
import AdminTemplate from '../../layout/AdminTemplate';
import NotificationCategoryFilter from './NotificationCategoryFilter';
import NotificationsList from './NotificationsList';
import { useGetNotificationsQuery } from './NotificationsApiSlice';
import './notifications.css';

const META_TAGS = {
  title: 'Notifications - Prerender.io',
};

function NotificationsListPage() {
  const location = useLocation();
  const { pagination, updatePagination } = usePagination(50);
  const selectedCategory = React.useMemo(
    () => new URLSearchParams(location.search).get('category') || undefined,
    [location.search]
  );
  const notificationsResult = useGetNotificationsQuery(
    { category: selectedCategory, ...pagination },
    { refetchOnMountOrArgChange: true }
  );

  const paginationVisible = notificationsResult.data?.totalCount > pagination.pageSize;

  React.useEffect(() => {
    if (notificationsResult.isError) {
      message.error('Failed to fetch notifications');
    }
  }, [notificationsResult.isError]);

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Space direction="vertical" size="large">
        <Link to="/">
          <Button icon={<CloseOutlined />}>Close</Button>
        </Link>
        <div>
          <Typography.Title level={2}>Notifications Center</Typography.Title>
          <Typography.Paragraph type="secondary">
            Here you can see all the most relevant events that happened in your account.
          </Typography.Paragraph>
        </div>
      </Space>

      <Flex vertical gap="large">
        <Flex justify="end">
          <NotificationCategoryFilter selectedCategory={selectedCategory} />
        </Flex>

        <NotificationsList
          isLoading={notificationsResult.isFetching}
          notifications={notificationsResult.data?.data}
          selectedCategory={selectedCategory}
        />

        {paginationVisible && (
          <Pagination
            current={pagination.page}
            pageSize={pagination.pageSize}
            total={notificationsResult.data?.totalCount}
            onChange={updatePagination}
          />
        )}
      </Flex>
    </AdminTemplate>
  );
}

export default NotificationsListPage;
