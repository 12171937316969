import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card } from '../../../components/CssFrameworkComponents';
import { createHtmlContentFromCmText } from '../../../assets/contentFormatter';
import { setPageLoadTimeoutSetting } from '../redux/settingsActions';
import { Select } from 'antd';

const RenderTimeoutCard = ({ cm, settings, setPageLoadTimeoutSetting: doSetPageLoadTimeoutSetting }) => {
  const options = [
    { text: `5 ${cm.seconds}`, id: 5 },
    { text: `10 ${cm.seconds}`, id: 10 },
    { text: `20 ${cm.seconds}`, id: 20 },
    { text: `25 ${cm.seconds}`, id: 25 },
    { text: `30 ${cm.seconds}`, id: 30 },
    { text: `60 ${cm.seconds}`, id: 60 },
  ];

  const getTimeoutOptions = (currentTimeout, customText) => {
    const data = [...options];
    const current = data.find((option) => option.id === currentTimeout);

    if (!current) {
      data.push({
        text: `${customText} - (${currentTimeout} ${cm.seconds})`,
        selected: true,
        id: 'custom',
      });
    } else {
      current.selected = true;
    }

    return data;
  };

  const currentTimeout = settings.pageLoadTimeout;
  const allOptions = getTimeoutOptions(currentTimeout, cm.customText);
  const currentTimeoutId = allOptions.find((r) => r.selected).id || allOptions[0].id;

  const onTimeoutChanged = (value) => {
    const current = parseInt(value, 10);
    doSetPageLoadTimeoutSetting(current);
  };

  return (
    <Card title={cm.title}>
      <div className="col">
        {cm.descriptions.map((description, i) => (
          <div className="row" key={i}>
            <p>{description.map(createHtmlContentFromCmText)}</p>
          </div>
        ))}
        <div className="row">
          <div className="col">
            <Select
              name="timeout"
              id="timeout"
              onChange={onTimeoutChanged}
              defaultValue={currentTimeoutId}
              disabled={settings.inProgress}
              style={{ width: '100%' }}
            >
              {allOptions.map((option, i) => (
                <Select.Option value={option.id} key={i}>
                  {option.text}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    cm: {
      title: 'Rendering Timeout',
      seconds: 'seconds',
      descriptions: [
        [
          {
            type: 'text',
            text: 'With this option, you can configure how long the rendering process should wait before raising a timeout error.',
          },
        ],
        [
          {
            type: 'text',
            // eslint-disable-next-line max-len
            text: "Setting this to a long interval could result in connection timeouts or trigger the crawler's timeout. Setting this option to a short interval could result in a premature cut-off of the rendering, as the rendering process will quit when it's finished. You can reduce the maximum timeout if your website does not have a long loading time and receives a notable amount of invalid requests.",
          },
        ],
        [
          {
            type: 'text',
            // eslint-disable-next-line max-len
            text: "After changing this option from the initial most stable setting, please monitor your rendering errors for timeouts as this can heavily influence the product's quality.",
          },
        ],
      ],
      customText: 'Custom',
    },
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setPageLoadTimeoutSetting }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderTimeoutCard);
