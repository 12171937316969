import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken } from '../auth';
import config from '../../assets/config';

export const restApiLegacy = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${config.apiUrl.dataServer}/api`,
    prepareHeaders: (headers) => {
      const accessToken = getAccessToken();
      if (accessToken) {
        headers.set('x-prerender-authorization', accessToken);
      }
      return headers;
    },
  }),
  tagTypes: ['CachedPages'],
  reducerPath: 'legacyApi',
  endpoints: () => ({}),
});

export function legacyAPIRequest({ url, method, body = null }) {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('x-prerender-authorization', getAccessToken());

  const options = {
    headers,
    method,
  };

  if (body) options.body = JSON.stringify(body);

  const request = new Request(`${config.apiUrl.dataServer}/api/${url}`, options);

  return fetch(request);
}
