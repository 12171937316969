import { message } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DisableRecacheCard from '../disableRecacheCard/disableRecacheCard';
import ForceHTTPSCard from '../forceHTTPSCard/ForceHTTPSCard';
import ImageLoadingCard from '../imageLoadingCard/imageLoadingCard';
import { saveSettings } from '../redux/settingsActions';
import RenderingDevicesCard from '../renderingDevicesCard/renderingDevicesCard';
import RenderingLocationsCard from '../renderingLocationsCard/renderingLocationsCard';
import RenderTimeoutCard from '../renderTimeoutCard/renderTimeoutCard';
import { useEvent } from '../../events/hooks/useEvent';
import { getStateFromPrerenderUser } from '../redux/settingsReducer';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import { getPrerenderUser } from '../../../actions/prerenderUserActions';

const deviceStatusChanged = (originalState, settings) => {
  return (
    originalState.renderingSettings.mobile.enabled !== settings.renderingSettings.mobile.enabled ||
    originalState.renderingSettings.desktop.enabled !== settings.renderingSettings.desktop.enabled
  );
};

const GeneralSettings = () => {
  const prerenderUser = usePrerenderUser();
  const settings = useSelector((state) => state.settings);
  const dispatch = useDispatch();
  const { track } = useEvent();

  const onSaveClick = () => {
    const originalState = getStateFromPrerenderUser(prerenderUser);
    if (deviceStatusChanged(originalState, settings)) {
      track('Rendering Device Status Updated', {
        mobile_is_activated: settings.renderingSettings.mobile.enabled,
        desktop_is_activated: settings.renderingSettings.desktop.enabled,
      });
    }

    dispatch(
      saveSettings({
        settings,
        userId: prerenderUser.id,
      })
    )
      .then(({ error }) => {
        if (error) {
          throw error;
        }
        dispatch(getPrerenderUser());
        message.success('Settings saved successfully');
        track('Settings Updated', {
          token_is_updated: false,
          recaching_status_is_updated: deviceStatusChanged(originalState, settings),
          rendering_timeout_is_updated: originalState.pageLoadTimeout !== settings.pageLoadTimeout,
          rendering_location_is_updated:
            originalState.renderLocation.renderRegion !== settings.renderLocation.renderRegion,
          https_status_is_updated: originalState.forceHTTPS !== settings.forceHTTPS,
        });
      })
      .catch(() => {
        message.error('An error occurred while saving settings');
      });
  };

  return (
    <div className="row">
      <DisableRecacheCard />
      <RenderingDevicesCard />
      <RenderTimeoutCard />
      <ImageLoadingCard />
      <RenderingLocationsCard />
      <ForceHTTPSCard />

      <div className="col-12 mb-7">
        <ButtonWithRole
          type="primary"
          style={{ width: '100%' }}
          disabled={!settings.diff || settings.inProgress}
          onClick={onSaveClick}
          disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
        >
          Apply Changes
        </ButtonWithRole>
      </div>
    </div>
  );
};

export default GeneralSettings;
