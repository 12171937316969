import React from 'react';
import { Dropdown, Tooltip, Switch, Flex, Space, Typography, Modal, theme } from 'antd';

import ButtonWithRole from '../../../components/ButtonWithRole';
import RecacheIntervalCard from '../../recache-interval-settings/recacheIntervalCard';
import ClearCacheComponent from '../../clearCache/clearCacheCard';
import USER_ROLES from '../../auth/userRoles';

type HeadingProps = {
  cacheSettingsToolTip: string;
  domainsAmount: number;
  cachedPagesAmount: number;
  query?: string;
  lastCrawledFilterActive: boolean;
  setLastCrawledFilterActive: (value: boolean) => void;
  onAddUrlClick: () => void;
  onUpdateCacheFreshness: (days: number) => void;
};

function Heading({
  cacheSettingsToolTip,
  domainsAmount,
  cachedPagesAmount,
  query,
  lastCrawledFilterActive,
  setLastCrawledFilterActive,
  onAddUrlClick,
  onUpdateCacheFreshness,
}: HeadingProps) {
  const [cacheFreshnessModalVisible, setCacheFreshnessModalVisible] = React.useState(false);
  const [confirmCacheFreshnessModal, setConfirmCacheFreshnessModal] = React.useState({
    visible: false,
    value: 0,
  });
  const { token } = theme.useToken();

  const onRecacheIntervalEvent = ({
    action,
    payload,
  }: {
    action: 'updateCacheFreshnessSlider' | 'closeCacheFreshnessCard';
    payload: { interval: number };
  }) => {
    if (action === 'closeCacheFreshnessCard') setCacheFreshnessModalVisible(false);
    if (action === 'updateCacheFreshnessSlider') {
      if (payload.interval < 1) {
        setConfirmCacheFreshnessModal({ visible: true, value: payload.interval });
      } else {
        onUpdateCacheFreshness(payload.interval);
      }
      setCacheFreshnessModalVisible(false);
    }
  };

  const onConfirmCacheFreshness = () => {
    onUpdateCacheFreshness(confirmCacheFreshnessModal.value);
    setConfirmCacheFreshnessModal({ visible: false, value: 0 });
    setCacheFreshnessModalVisible(false);
  };

  return (
    <>
      <Modal
        title="Cache Settings"
        open={confirmCacheFreshnessModal.visible}
        okText="Confirm"
        onOk={onConfirmCacheFreshness}
        onCancel={() => setConfirmCacheFreshnessModal({ visible: false, value: 0 })}
      >
        <div className="text-danger">
          Decreasing the cache freshness interval will lead to an increased number of renders. Would you like to proceed
          with the change? You can read more details regarding our rendering process in{' '}
          <a href="https://docs.prerender.io/docs/what-you-pay-for" target="_blank" rel="noreferrer">
            this
          </a>{' '}
          article.
        </div>
      </Modal>

      <Flex align="flex-start" justify="space-between">
        <Space direction="vertical" style={{ marginBottom: '16px' }}>
          <div>
            {domainsAmount > 1
              ? `You have ${cachedPagesAmount.toLocaleString('en-US')} URLs from ${domainsAmount.toLocaleString(
                  'en-US'
                )} domains cached in total`
              : `You have ${cachedPagesAmount.toLocaleString('en-US')} URLs cached in total`}

            <p>{query && <b className="ml-2">{query}</b>}</p>
          </div>

          <Flex gap="small">
            <Switch title="Not crawled pages" value={lastCrawledFilterActive} onChange={setLastCrawledFilterActive} />
            <Typography.Text style={{ fontSize: token.fontSizeSM }} strong>
              Pages that have not been requested in the past 30 days
            </Typography.Text>
          </Flex>
        </Space>
        <div className="col">
          <div className="float-right">
            <ButtonWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
              type="primary"
              className="ml-2"
              onClick={onAddUrlClick}
            >
              Add URLs
            </ButtonWithRole>
            <Dropdown
              dropdownRender={() => (
                <div style={{ maxWidth: 500, background: '#ffffff' }}>
                  <RecacheIntervalCard onEvent={onRecacheIntervalEvent} />
                </div>
              )}
              trigger={['click']}
              placement="bottomRight"
              onOpenChange={setCacheFreshnessModalVisible}
              open={cacheFreshnessModalVisible}
              destroyPopupOnHide
            >
              <Tooltip title={cacheSettingsToolTip}>
                <ButtonWithRole
                  disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                  className="ml-2"
                  type="primary"
                >
                  Cache Settings
                </ButtonWithRole>
              </Tooltip>
            </Dropdown>
            <ClearCacheComponent />
          </div>
        </div>
      </Flex>
    </>
  );
}

export default Heading;
