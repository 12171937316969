export const isMobileCrawlingEnabled = (sitemapAdaptiveType) =>
  sitemapAdaptiveType === 'all' || sitemapAdaptiveType === 'mobile';

export const isDesktopCrawlingEnabled = (sitemapAdaptiveType) =>
  sitemapAdaptiveType === 'all' || sitemapAdaptiveType === 'desktop';

export const sitemapAdaptiveTypeToArray = (sitemapAdaptiveType, mobileAdaptive) =>
  !mobileAdaptive
    ? ['desktop']
    : [
        ...(isMobileCrawlingEnabled(sitemapAdaptiveType) ? ['mobile'] : []),
        ...(isDesktopCrawlingEnabled(sitemapAdaptiveType) ? ['desktop'] : []),
      ];

export const arrayToAdaptiveType = (adaptiveTypes, mobileAdaptive) =>
  !mobileAdaptive
    ? 'desktop'
    : adaptiveTypes.includes('desktop') && adaptiveTypes.includes('mobile')
    ? 'all'
    : adaptiveTypes.includes('desktop')
    ? 'desktop'
    : 'mobile';

export const HOURS_IN_SECONDS = 60 * 60;

export const hoursToString = (hours) => (hours === 1 ? '1 hour' : `${hours} hours`);
