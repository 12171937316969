import { restApiLegacy } from '../../api/restApiSliceLegacy';

export const dashboardLegacySlice = restApiLegacy.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardStats: builder.query({
      query: () => `/v2/dashboard-stats`,
    }),
    getCrawlStats: builder.query({
      query: () => `/crawl-stats`,
      params: {
        pageSize: 6,
      },
    }),
    getCachedPages: builder.query({
      query: () => `/cached-pages`,
      params: {
        pageSize: 6,
        sort: 'last_refresh',
        sortDirection: 'DESC',
        notnull: 'last_refresh',
        showSEOScoreURLsOnly: false,
      },
      providesTags: ['CachedPages'],
    }),
  }),
});

export const { useGetDashboardStatsQuery, useGetCrawlStatsQuery, useGetCachedPagesQuery } = dashboardLegacySlice;
