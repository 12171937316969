import { restApi } from '../../api/restApiSlice';

export const referAndEarnApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    tagTypes: ['referrals', 'referralStats'],
    getReferrals: builder.query({
      query: (params) => ({ url: '/referrals', method: 'GET', params }),
      providesTags: ['referrals'],
    }),
    getReferralStats: builder.query({
      query: () => ({ url: '/referrals/stats', method: 'GET' }),
      providesTags: ['referralStats'],
    }),
    sendReferral: builder.mutation({
      query: (body) => ({ url: '/referrals', method: 'POST', body }),
      invalidatesTags: ['referrals'],
    }),
  }),
});

export const { useGetReferralsQuery, useGetReferralStatsQuery, useSendReferralMutation } = referAndEarnApiSlice;
