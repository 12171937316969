// this is an import import module
// If sessionId, token and anonymousId are available in cookie then they are part of the request anyways.
// If sessionId, token and anonymousId are not stored in a cookie they must be obtained from redux store by
// the calling instance and passed on as parameters to this function.

import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getAccessToken } from '../features/auth';

const ERR_OBJ = { status: 'error', error: { code: 'E0001', message: 'Api call failed' } };

const extractErrorFromErrorResponseV1 = (error) =>
  error.response && error.response.data ? error.response.data : error;

const extractErrorFromErrorResponseV2 = (error) =>
  error.response && error.response.data ? error.response.data.error : error;

const isErrorStatusCode = (code) => code > 400;

export const DONT_THROW_ON_ERROR = 0;
export const THROW_ON_ERROR_V1 = 1;
export const THROW_ON_ERROR_V2 = 2;

const client = axios.create();
axiosRetry(client, { retries: 3 });

// Add auth token to the requests
client.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  const { headers } = config;
  if (accessToken) {
    headers['x-prerender-authorization'] = accessToken;
    headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

export default async function internalApi(method, url, postData, throwOnError = DONT_THROW_ON_ERROR) {
  try {
    const options = {
      method,
    };
    if (method === 'post' || method === 'update' || method === 'put' || method === 'delete' || method === 'patch')
      options.data = postData;
    if (postData && typeof FormData !== 'undefined' && postData instanceof FormData) {
      const imageUploadCall = axios.CancelToken.source();
      options.cancelToken = imageUploadCall.token;
      options.headers['Content-Type'] = 'multipart/form-data';
    }

    const response = await client(url, options); // non 200 responses end up in catch() branch immediately!

    if (throwOnError && isErrorStatusCode(response.code)) {
      throw response;
    }

    if (!response.data) {
      return null;
    }

    if (
      response.headers['content-type'].indexOf('text/csv') > -1 ||
      response.headers['content-type'].indexOf('application/xml') > -1
    ) {
      return response.data; // return pure CSV data if available.
    }
    return response && response.data && response.data.result
      ? response.data.result
      : response.data // Prerender API responses don't have a result object
      ? response.data
      : ERR_OBJ;
  } catch (error) {
    switch (throwOnError) {
      case DONT_THROW_ON_ERROR:
        throw error;
      case THROW_ON_ERROR_V1:
        throw extractErrorFromErrorResponseV1(error);
      case THROW_ON_ERROR_V2:
        throw extractErrorFromErrorResponseV2(error);
      default:
        throw error;
    }
  }
}
