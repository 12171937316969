import { Content } from 'antd/lib/layout/layout';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../atoms';
import requireAuth from '../components/hocs/requireAuth';

export const DevTestPage = requireAuth(() => {
  const user = useRecoilValue(userAtom);

  return (
    <>
      <Content>
        <h1>User</h1>
        <pre>{JSON.stringify(user, null, 2)}</pre>
      </Content>
    </>
  );
});
