import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card } from '../../../components/CssFrameworkComponents';
import { createHtmlContentFromCmText } from '../../../assets/contentFormatter';
import { setLoadImages } from '../redux/settingsActions';
import { Switch } from 'antd';

const ImageLoadingCard = ({ cm, settings, setLoadImages: doSetLoadImages }) => {
  const toggleLoadImages = () => {
    const current = !settings.loadImages;
    doSetLoadImages(current);
  };

  return (
    <Card title="Image Loading">
      <div className="col">
        {cm.descriptions.map((description, i) => (
          <div className="row" key={i}>
            <p>{description.map(createHtmlContentFromCmText)}</p>
          </div>
        ))}
        <div className="row">
          <span>
            <Switch
              checked={settings.loadImages}
              onClick={toggleLoadImages}
              disabled={settings.inProgress}
              style={{ marginRight: 12 }}
            />
            {settings.loadImages ? cm.switch.activeText : cm.switch.enableText}
          </span>
        </div>
      </div>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    cm: {
      switch: {
        enableText: 'Enable',
        activeText: 'Active',
      },
      descriptions: [
        [
          {
            type: 'text',
            // eslint-disable-next-line max-len
            text: "This option allows the renderer to wait for the images to be loaded, which slows down the rendering and is only recommended when your website's logic depends on image loading events.",
          },
        ],
      ],
      customText: 'Custom',
    },
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setLoadImages }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageLoadingCard);
