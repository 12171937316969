import React from 'react';
import { Alert, Row, Col, Space, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import QueryParamsSettings from './QueryParamsSettings';
import URLParameters from './URLParameters';
import KnownTrackerList from './KnownTrackerList';

function URLParametersTab() {
  return (
    <Space size="large" direction="vertical">
      <Alert
        showIcon
        icon={<InfoCircleOutlined />}
        type="info"
        message="This feature allows you to choose which URLs containing query parameters, should be cached under the corresponding canonical URL in order to reduce the number of cached pages."
      />

      <Typography.Text type="secondary">
        Pages will be cached and served without using ignored query parameters.
      </Typography.Text>

      <QueryParamsSettings />

      <Row gutter={[16, 16]}>
        <Col xs={24} xl={16}>
          <URLParameters />
        </Col>
        <Col xs={24} xl={8}>
          <KnownTrackerList />
        </Col>
      </Row>
    </Space>
  );
}

export default URLParametersTab;
