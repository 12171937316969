import { ConfigProvider, Table, Empty } from 'antd';

const tableColumns = [
  {
    title: 'Code',
    key: 'code',
    dataIndex: 'couponId',
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'displayName',
  },
  {
    title: 'Description',
    key: 'description',
    dataIndex: 'description',
  },
  {
    title: 'Applied at',
    key: 'appliedAt',
    dataIndex: 'createdAt',
    render: (value) => new Date(value).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }),
  },
];

function AppliedCouponsTable({ isLoading, rows = [] }) {
  const hasData = rows.length > 0 && !isLoading;
  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You don't have any discounts applied yet." />
      )}
    >
      <Table
        bordered={hasData}
        rowKey="id"
        loading={isLoading}
        columns={tableColumns}
        dataSource={rows}
        pagination={false}
        showHeader={hasData}
        scroll={{ x: true }}
      />
    </ConfigProvider>
  );
}

export default AppliedCouponsTable;
