import React from 'react';
import { Container } from '../../../components/CssFrameworkComponents';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import {
  initChargebee,
  openChargebeePortalBillingAddress,
  openChargebeePortalPaymentSources,
} from '../../../chargebee/chargebee';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { setShowHasBillingAddressBadge, setShowHasBillingInfoBadge } from '../../../actions/billingInfoActions';
import { useLazyIsPaymentInfoValidQuery } from '../api/billingApiSlice';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';

const BillingPayment = () => {
  const billingInfoContentData = useSelector((state) => state.page.contentData.BillingInfo);
  const hasNoAddressSetInChargebee = useSelector((state) => state.billingInfo.showHasBillingAddressBadge);
  const hasNoPaymentMethodSetInChargebee = useSelector((state) => state.billingInfo.showHasBillingInfoBadge);

  const [
    triggerIsPaymentInfoValid,
    {
      data: isPaymentInfoValid,
      isFetching: isLoadingIsPaymentInfoValid,
      isError: isErrorLoadingIsPaymentInfoValid,
      refetch: reFetchIsPaymentInfoValid,
    },
  ] = useLazyIsPaymentInfoValidQuery();

  React.useEffect(() => {
    triggerIsPaymentInfoValid(undefined, false);
  }, []);

  const dispatch = useDispatch();

  const handleChangeBillingAddress = React.useCallback(() => {
    openChargebeePortalBillingAddress({
      onClosed: () => {
        // Unfortunately, there is no specific event which is fired on billing address added.
        // dispatch(setShowHasBillingAddressBadge(false));
        triggerIsPaymentInfoValid(undefined, false);
      },
    });
  }, []);

  const handleChangePaymentMethod = React.useCallback(() => {
    openChargebeePortalPaymentSources({
      onPaymentSourceAdded: () => {
        // dispatch(setShowHasBillingInfoBadge(false));
        triggerIsPaymentInfoValid(undefined, false);
      },
      paymentSourceUpdate: () => {
        // dispatch(setShowHasBillingInfoBadge(false));
        triggerIsPaymentInfoValid(undefined, false);
      },
    });
  }, []);

  React.useEffect(() => {
    const url = window.location.href;
    initChargebee(billingInfoContentData.chargeBee.site, billingInfoContentData.chargeBee.domain, url);
  }, [billingInfoContentData.chargeBee]);

  return (
    <Container fluid addClass={'p-0'}>
      <h3 className="">Payment Settings</h3>
      <p className="text-base text-grey">Update your billing details below.</p>
      <Row gutter={12}>
        <Col xs={24} lg={12}>
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title text-base fw-bold">Billing Address</h5>
                {!isLoadingIsPaymentInfoValid && (
                  <>
                    {!isPaymentInfoValid?.data?.isAddressValid ? (
                      <ExclamationCircleOutlined style={{ color: '#d16759' }} />
                    ) : (
                      <CheckCircleOutlined style={{ color: '#6FC49D' }} />
                    )}
                  </>
                )}
                {isLoadingIsPaymentInfoValid ? <Spin /> : null}
              </div>
              {!isLoadingIsPaymentInfoValid && (
                <p className="card-text fw-light">
                  {!isPaymentInfoValid?.data?.isAddressValid
                    ? 'Please add your billing address.'
                    : 'You previously set your billing address.'}
                </p>
              )}

              {!isLoadingIsPaymentInfoValid && (
                <>
                  <hr />
                  <ButtonWithRole
                    disabledFor={(USER_ROLES.GUEST, USER_ROLES.TEAM_MEMBER)}
                    onClick={handleChangeBillingAddress}
                    className="container-fluid"
                  >
                    {!isPaymentInfoValid?.data?.isAddressValid ? 'Add' : 'Change'}
                  </ButtonWithRole>
                </>
              )}
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="card-title text-base fw-bold">Payment Method</h5>
                {!isLoadingIsPaymentInfoValid && (
                  <>
                    {!isPaymentInfoValid?.data?.hasPaymentMethod ? (
                      <ExclamationCircleOutlined style={{ color: '#d16759' }} />
                    ) : (
                      <CheckCircleOutlined style={{ color: '#6FC49D' }} />
                    )}
                  </>
                )}
                {isLoadingIsPaymentInfoValid ? <Spin /> : null}
              </div>
              {!isLoadingIsPaymentInfoValid && (
                <p className="card-text fw-light">
                  {!isPaymentInfoValid?.data?.hasPaymentMethod
                    ? 'Please add your preferred payment method.'
                    : 'You previously set a payment method.'}
                </p>
              )}
              {!isLoadingIsPaymentInfoValid && (
                <>
                  <hr />
                  <ButtonWithRole
                    disabledFor={[USER_ROLES.GUEST, USER_ROLES.TEAM_MEMBER]}
                    onClick={handleChangePaymentMethod}
                    className="container-fluid"
                  >
                    {!isPaymentInfoValid?.data?.hasPaymentMethod ? 'Add' : 'Change'}
                  </ButtonWithRole>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default BillingPayment;
