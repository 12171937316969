import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card } from '../../../components/CssFrameworkComponents';
import { createHtmlContentFromCmText } from '../../../assets/contentFormatter';
import { setForceHTTPS } from '../redux/settingsActions';
import { Modal, Switch, theme } from 'antd';

const ForceHTTPSCard = ({ cm, settings, setForceHTTPS: doSetForceHTTPS }) => {
  const { token } = theme.useToken();
  const warning = () => {
    Modal.warning({
      content: (
        <p>
          All URLs using the <b>http</b> protocol will be <b>removed</b> and replaced with the same URLs using the{' '}
          <b>https</b> protocol
        </p>
      ),
      okButtonProps: {
        style: { backgroundColor: token.colorPrimary, borderColor: token.colorPrimary, color: 'white' },
      },
    });
  };

  const toggleForceHTTPS = () => {
    if (!settings.forceHTTPS) {
      warning();
    }
    const current = !settings.forceHTTPS;
    doSetForceHTTPS(current);
  };

  return (
    <Card title={cm.title}>
      <div className="col">
        {cm.descriptions.map((description, i) => (
          <div className="row" key={i}>
            <p>{description.map(createHtmlContentFromCmText)}</p>
          </div>
        ))}
        <div className="row">
          <span>
            <Switch
              checked={settings.forceHTTPS}
              onClick={toggleForceHTTPS}
              disabled={settings.inProgress}
              style={{ marginRight: 12 }}
            />
            {settings.forceHTTPS ? cm.switch.activeText : cm.switch.enableText}
          </span>
        </div>
      </div>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    cm: {
      title: 'Enforce HTTPS Protocol',
      switch: {
        enableText: 'Not Enforced',
        activeText: 'Enforced',
      },
      descriptions: [
        [
          {
            type: 'text',
            // eslint-disable-next-line max-len
            text: 'When this configuration is applied, the rendering servers will only call your site through HTTPS (secure connection), even if the original request was made for an HTTP (not encrypted version).',
          },
        ],
        [
          {
            type: 'text',
            // eslint-disable-next-line max-len
            text: "Usually, this doesn't change the site's content. The only exception is for unique circumstances when you can configure the origin server to serve different content. In that case, it may cause an issue.",
          },
        ],
      ],
    },
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setForceHTTPS }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ForceHTTPSCard);
