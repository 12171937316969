import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Flex, Typography, Row, Col, Card, Skeleton, message, theme } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import AdminTemplate from '../../layout/AdminTemplate';
import {
  useGetDomainDetailsQuery,
  useGetExtraStatsQuery,
  useUpdateDomainDetailsMutation,
} from './api/domainManagerApiSlice';
import CacheAgeChart from './CacheAgeChart';
import SchedulerSettings from './SchedulerSettings';
import DomainStats from './DomainStats';
import CacheAgeTable from './CacheAgeTable';
import { SchedulerSettingsTable } from './components/SchedulerSettingsTable';
import {
  useGetSchedulerSettingsQuery,
  useAddSchedulerSettingsMutation,
  useDeleteSchedulerSettingsMutation,
} from './api/schedulerSettingsApiSlice';
import useFeatureFlag, { FeatureFlag } from '../../hooks/useFeatureFlag';

const META_TAGS = {
  title: 'Domain Manager Details - Prerender.io',
};

const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
const today = new Date();

function DomainDetailsPage() {
  const { domain } = useParams();
  const { token } = theme.useToken();

  const isDomainSchedulerSettingsEnabled = useFeatureFlag([FeatureFlag.DomainSchedulerSettings]);
  const isDomainDetailsEnabled = useFeatureFlag([FeatureFlag.DomainsDetails]);

  const { data: domainDetails, isLoading: isLoadingDetails, isError: fetchingError } = useGetDomainDetailsQuery(domain);
  const [updateDomainDetails, { isLoading: isUpdating, isError: updatingError, isSuccess: updatingSuccess }] =
    useUpdateDomainDetailsMutation();
  const extraStatsResult = useGetExtraStatsQuery({ domain, from: oneMonthAgo.toISOString(), to: today.toISOString() });

  const [messageApi, contextHolder] = message.useMessage();
  const {
    data: schedulerSettings,
    isLoading: isLoadingSettings,
    isError: fetchingSettingsError,
  } = useGetSchedulerSettingsQuery(domain);
  const [
    addSchedulerSettings,
    { isLoading: isAddSettingLoading, isSuccess: addingSettingSuccess, error: addSettingError },
  ] = useAddSchedulerSettingsMutation();
  const [
    deleteSchedulerSettings,
    { isLoading: isDeleteSettingLoading, isError: deletingSettingError, isSucess: deletingSettingSuccess },
  ] = useDeleteSchedulerSettingsMutation();

  const updateSettings = (settings) => {
    updateDomainDetails({
      domain,
      slowRenderTriggerSeconds: settings.slowRenderTrigger,
      cacheLifetimeHours: settings.cacheExpiration,
    });
  };

  const onAddSetting = (settings) => {
    const cacheExpirationValue =
      settings.cacheExpiration > 24 ? (settings.cacheExpiration - 23) * 24 : settings.cacheExpiration;
    addSchedulerSettings({
      domain,
      rule: settings.rule,
      cache_freshness_hours: cacheExpirationValue,
    });
  };

  useEffect(() => {
    if (fetchingError)
      messageApi.error({
        content: 'Failed to fetch domain details',
      });

    if (updatingError)
      messageApi.error({
        content: 'Failed to update scheduler settings',
      });

    if (updatingSuccess)
      messageApi.success({
        content: 'Scheduler settings updated successfully',
      });
  }, [fetchingError, updatingError, updatingSuccess]);

  useEffect(() => {
    if (fetchingSettingsError)
      messageApi.error({
        content: 'Failed to fetch scheduler settings',
      });
    if (addSettingError) {
      messageApi.error({
        content: addSettingError?.data?.message,
      });
    }
    if (deletingSettingError)
      messageApi.error({
        content: 'Failed to delete rule',
      });
    if (addingSettingSuccess)
      messageApi.success({
        content: 'Scheduler settings rule added successfully',
      });
    if (deletingSettingSuccess)
      messageApi.success({
        content: 'Scheduler settings rule delete successfully',
      });
  }, [fetchingSettingsError, addSettingError, deletingSettingError, addingSettingSuccess, deletingSettingSuccess]);

  return (
    <AdminTemplate metaTags={META_TAGS}>
      {contextHolder}
      <Flex vertical gap="large">
        <Flex gap="large">
          <Link to="/domain-manager">
            <Button icon={<LeftOutlined />} type="link">
              Back
            </Button>
          </Link>
          <Typography.Title level={3}>Domain Details - {domain}</Typography.Title>
        </Flex>

        <DomainStats
          isLoading={isLoadingDetails}
          uniquePages={domainDetails?.pagesCount}
          firstSeenAt={domainDetails?.createdAt}
          averageCacheMinutes={domainDetails?.avgCacheMinutes}
          pagesNotCrawled={extraStatsResult.data?.notCrawledPages}
        />

        <Row gutter={[token.marginMD, token.marginMD]}>
          <Col xs={24} xl={12}>
            <Card title="Cache Age" bordered>
              <Skeleton loading={isLoadingDetails} active>
                <CacheAgeChart
                  lessOneDay={domainDetails?.numCacheToday}
                  gOneLSevenDays={domainDetails?.numCache_g1l7}
                  gSevenLThirtyDays={domainDetails?.numCache_g7l30}
                  gThirtyDays={domainDetails?.numCache_g30}
                />
                <CacheAgeTable
                  lessOneDay={domainDetails?.numCacheToday}
                  gOneLSevenDays={domainDetails?.numCache_g1l7}
                  gSevenLThirtyDays={domainDetails?.numCache_g7l30}
                  gThirtyDays={domainDetails?.numCache_g30}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col xs={24} xl={12}>
            <Card title="Scheduler Settings" bordered>
              <Skeleton loading={isLoadingDetails || isLoadingSettings} active>
                {isDomainSchedulerSettingsEnabled && (
                  <SchedulerSettingsTable
                    schedulerSettings={schedulerSettings?.data}
                    isLoading={isDeleteSettingLoading}
                    onDeleteSetting={deleteSchedulerSettings}
                    onAddSetting={onAddSetting}
                    isAddSettingLoading={isAddSettingLoading}
                  />
                )}
                {isDomainDetailsEnabled && (
                  <SchedulerSettings
                    isLoading={isUpdating}
                    disabled={fetchingError}
                    onSave={updateSettings}
                    slowRenderTrigger={domainDetails?.slowRenderTriggerSeconds}
                    cacheExpiration={domainDetails?.cacheLifetimeHours}
                  />
                )}
              </Skeleton>
            </Card>
          </Col>
        </Row>
      </Flex>
    </AdminTemplate>
  );
}

export default DomainDetailsPage;
