import { getAccessToken } from '../../auth';
import config from '../../../assets/config';

export const useEvent = () => {
  const baseUrl = `${config.apiUrl.restServer}/v3`;

  return {
    track: (event, properties) => {
      const accessToken = getAccessToken();

      fetch(`${baseUrl}/mt/${event}`, {
        method: 'POST',
        body: JSON.stringify({ properties }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
        },
      })
        .then(() => {
          if (process.env.NODE_ENV === 'development') {
            console.log(`Fired Tracking of "${event}" with`, properties);
          }
        })
        .catch(() => {
          console.log(`Failed to track ${event}`);
          console.error({ event, properties });
        });

      // Just for PoC
      if (window.ctrack) window.ctrack(event, properties);
    },
    updateUserProfile: (properties) => {
      const accessToken = getAccessToken();

      fetch(`${baseUrl}/mt/user`, {
        method: 'POST',
        body: JSON.stringify(properties),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
        },
      })
        .then(() => {
          if (process.env.NODE_ENV === 'development') {
            console.log('Fired user profile updating with properties: ', properties);
          }
        })
        .catch(() => {
          console.log(`Failed to update user profile with properties: ${properties}`);
          console.error({ properties });
        });
    },
    updateUserProfileWithEmail: (properties) => {
      const accessToken = getAccessToken();

      fetch(`${baseUrl}/mt/user`, {
        method: 'PUT',
        body: JSON.stringify(properties),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
        },
      })
        .then(() => {
          if (process.env.NODE_ENV === 'development') {
            console.log('Fired user profile updating with properties: ', properties);
          }
        })
        .catch(() => {
          console.log(`Failed to update user profile with properties: ${properties}`);
          console.error({ properties });
        });
    },
  };
};
