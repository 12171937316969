import { Fragment } from 'react';
import { ModalHeader, ModalBodyScroller, ModalFooter } from '../../components/ModalLib';
import { ControlButton } from '../../components/PageElementsLib';
import { Container } from '../../components/CssFrameworkComponents';
import { MODAL_CANCALLED_ACTION, INTEGRATION_ACKNOWLEDGED, PREVIOUS_STEP } from './onboardingActions';
import Markdown from '../../components/Markdown';

const IntegrationModalContent = ({ prerenderUser, cm, onEvent, content }) => {
  if (!content) return null;
  const mdContent = content.replace('{{TOKEN}}', prerenderUser.token);

  return (<Fragment>
  <ModalHeader
    modalHeaderLeft={<ControlButton text={cm.header.btnLeft} onclick={() => onEvent({ action: MODAL_CANCALLED_ACTION })}/>}
    modalHeaderRight={<div className="mx-4"/>}
    title={ cm.header.title }
  />
  <ModalBodyScroller>
    <Container fluid>
      <div className="row align-items-center m-4">
        <div className="col-12">
          <Markdown
            children={mdContent}
            classOverride={{ img: 'w-100', a: 'link-success' }}
            cm={cm.markdown}
            />
        </div>
      </div>
    </Container>
  </ModalBodyScroller>
  <ModalFooter
    modalFooterLeft={<ControlButton text={cm.footer.btnLeft} onclick={() => onEvent({ action: PREVIOUS_STEP })}/>}
    modalFooterRight={<ControlButton text={cm.footer.btnRight} onclick={() => onEvent({ action: INTEGRATION_ACKNOWLEDGED })}/>}/>
</Fragment>);
};
export default IntegrationModalContent;
