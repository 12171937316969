// Define action creators that dispatch actions:
import config from '../assets/config';
import internalApi from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';
import { setLastErrorCode, clearLastErrorCode } from './pageActions';

import { CRAWLERACTIVITY_SET, CRAWLERACTIVITY_IN_PROGRESS, CRAWLERACTIVITY_SET_PARAMS } from './types';

const crawlerActivitySearch =
  (stateBucket = 'all', params) =>
  async (dispatch) => {
    try {
      dispatch({ type: CRAWLERACTIVITY_IN_PROGRESS, payload: { stateBucket, value: true } });
      dispatch({ type: CRAWLERACTIVITY_SET_PARAMS, payload: { stateBucket, params } });
      let queryParams = '';
      queryParams += params.page ? `&page=${params.page}` : '';
      queryParams += params.pageSize ? `&pageSize=${params.pageSize}` : '';
      // queryParams+= params.sort ? `&sort=${params.sort}` : '';
      // queryParams+= params.query ? `&query=${params.query}` : '';
      queryParams += params.statusCodeEq ? `&statusCode=${params.statusCodeEq}` : '';
      queryParams += params.statusCodeLow ? `&statusCodeLow=${params.statusCodeLow}` : '';
      queryParams += params.statusCodeHigh ? `&statusCodeHigh=${params.statusCodeHigh}` : '';
      queryParams += params.responseTimeLow ? `&responseTimeLow=${params.responseTimeLow}` : '';
      queryParams += params.responseTimeHigh ? `&responseTimeHigh=${params.responseTimeHigh}` : '';
      queryParams += params.timedout ? `&timedout=${params.timedout}` : '';
      queryParams += params.domain ? `&domain=${params.domain}` : '';
      queryParams += params.adaptive_type ? `&adaptive_type=${params.adaptive_type}` : '';
      queryParams += params.renderedTimeHigh ? `&renderedTimeHigh=${params.renderedTimeHigh}` : '';
      queryParams += params.renderedTimeLow ? `&renderedTimeLow=${params.renderedTimeLow}` : '';
      queryParams += params.userAgentCaseSensitiveSearch
        ? `&uaCaseSensitive=${params.userAgentCaseSensitiveSearch}`
        : '';

      queryParams += params.cacheHit ? `&cacheHit=${params.cacheHit}` : '';

      // queryParams+= params.timePeriod ? `&timePeriod=${params.timePeriod}` : '';
      // queryParams+= params.cacheStatus ? `&cacheStatus=${params.cacheStatus}` : '';
      queryParams += params.userAgent ? `&userAgent=${encodeURIComponent(params.userAgent)}` : '';
      if (params.query) {
        queryParams += `&q=${encodeURIComponent(params.query)}`;
        queryParams += `&qCondition=${encodeURIComponent(params.queryCondition)}`;
      }
      if (params.sort && params.sortDirection) {
        queryParams += `&sort=${params.sort}`;
        queryParams += `&sortDirection=${params.sortDirection}`;
      }
      const requestUri = `${config.apiUrl.dataServer}/api/crawl-stats?${queryParams}`;
      const data = await internalApi('get', requestUri, null);
      dispatch({ type: CRAWLERACTIVITY_SET, payload: { stateBucket, data, params } });
      dispatch(clearLastErrorCode());
    } catch (error) {
      console.log(error);
      dispatch({ type: CRAWLERACTIVITY_IN_PROGRESS, payload: { stateBucket, value: false } });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };

export default crawlerActivitySearch;
