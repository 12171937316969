import React from 'react';
import { Flex, Modal, Typography, theme } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { ChargeBeePlanId } from '@prerender/billing-shared';

export function shouldDisplayDowngradePlanDisclaimer(currentRenders, newPlan) {
  return newPlan.id !== ChargeBeePlanId.Basic && currentRenders >= newPlan.includedCaches;
}

function AlertTitle() {
  const { token } = theme.useToken();
  return (
    <Flex gap="middle" align="center">
      <WarningFilled style={{ color: token.colorWarning }} />
      <Typography.Title level={4} style={{ margin: 0 }}>
        Downgrading plan
      </Typography.Title>
    </Flex>
  );
}

function DowngradePlanDisclaimer({ downgradedPlan, onConfirm, onCancel }) {
  return (
    <Modal
      title={<AlertTitle />}
      open={!!downgradedPlan}
      onOk={onConfirm}
      okText="Downgrade"
      okButtonProps={{
        danger: true,
        type: 'primary',
      }}
      onCancel={onCancel}
    >
      {downgradedPlan && (
        <Typography.Paragraph>
          <p>
            The "{downgradedPlan.name}" plan <Typography.Text strong>contains fewer renders</Typography.Text> than your
            estimated usage.
          </p>
          <p>
            Switching to this plan might lead to exceeding the render limit with a cost of{' '}
            {downgradedPlan.addonCostInCents / 100} per 1000 additional renders.
          </p>
        </Typography.Paragraph>
      )}
    </Modal>
  );
}

export default DowngradePlanDisclaimer;
