import { useEffect } from 'react';
import requireAuth from '../../components/hocs/requireAuth';
import CacheAgeChart from './CacheAgeChart';
import CacheAgeTable from './CacheAgeTable';
import { FeatureFlag } from '../../hooks/useFeatureFlag';
import AdminTemplate from '../../layout/AdminTemplate';
import { Card, Col, Row, Skeleton, Space, Typography, message, theme } from 'antd';

import { useGetAccountDetailsQuery } from './api/domainManagerApiSlice';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { setCacheFreshness } from '../../actions/prerenderUserActions';
import { useEvent } from '../events/hooks/useEvent';
import RecacheIntervalSetting from '../recache-interval-settings/recacheIntervalSetting';
import { useDispatch } from 'react-redux';
import { AverageCacheAgeCard, NotCrawledPagesCard, UniquePagesCard } from './components/StatsCards';

const { Title } = Typography;

const META_TAGS = {
  title: 'Detailed View - Prerender.io',
};

const DetailedView = () => {
  const { token } = theme.useToken();
  const [messageApi, contextHolder] = message.useMessage();
  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();
  const dispatch = useDispatch();

  const { data, isLoading: isLoadingDetails, isError: fetchingError } = useGetAccountDetailsQuery();

  const updateCacheFreshness = (value) => {
    dispatch(setCacheFreshness(prerenderUser, value.days));
    track('Cache Expiration Set', {
      subscription_plan: prerenderUser.chargebeePlanId,
      cache_expiration_time: value.days,
    });
  };

  useEffect(() => {
    if (fetchingError) {
      messageApi.error({ content: 'Failed to fetch account details' });
    }
  }, [fetchingError]);

  return (
    <AdminTemplate metaTags={META_TAGS}>
      {contextHolder}
      <Space direction="vertical" size="large">
        <Title level={2}>Detailed View</Title>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={8}>
            <UniquePagesCard isLoading={isLoadingDetails} uniquePages={data?.pagesCount} />
          </Col>
          <Col xs={24} sm={12} md={8}>
            <AverageCacheAgeCard isLoading={isLoadingDetails} averageCacheMinutes={data?.avgCacheMinutes} />
          </Col>
          <Col xs={24} sm={12} md={8}>
            <NotCrawledPagesCard isLoading={isLoadingDetails} pagesNotCrawled={data?.numberOfNotCrawledURLs} />
          </Col>
        </Row>
        <Row gutter={[token.marginMD, token.marginMD]}>
          <Col xs={24} xl={14}>
            <Card title="Cache Age" bordered>
              <Skeleton loading={isLoadingDetails} active>
                <CacheAgeChart
                  lessOneDay={data?.numCacheToday}
                  gOneLSevenDays={data?.numCache_g1l7}
                  gSevenLThirtyDays={data?.numCache_g7l30}
                  gThirtyDays={data?.numCache_g30}
                />
                <CacheAgeTable
                  lessOneDay={data?.numCacheToday}
                  gOneLSevenDays={data?.numCache_g1l7}
                  gSevenLThirtyDays={data?.numCache_g7l30}
                  gThirtyDays={data?.numCache_g30}
                />
              </Skeleton>
            </Card>
          </Col>
          <Col xs={24} xl={10}>
            <Card title="Scheduler Settings" bordered>
              <Space direction="vertical" style={{ width: '100%' }}>
                <RecacheIntervalSetting isPopup={false} onSubmit={updateCacheFreshness} />
              </Space>
            </Card>
          </Col>
        </Row>
      </Space>
    </AdminTemplate>
  );
};

function isAuthorized({ featureFlags }) {
  return featureFlags.includes(FeatureFlag.AccountDetails);
}

export default requireAuth(DetailedView, isAuthorized);
