import { Content } from 'antd/lib/layout/layout';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContent } from '../../actions/pageActions';
import { fetchContent } from '../onboarding/redux/onboardingActions';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { Container } from '../../components/CssFrameworkComponents';
import Markdown from '../../components/Markdown';
import { useParams, useNavigate } from 'react-router-dom';
import { IntegrationSteps } from './components/IntegrationSteps';
import { Navigate } from 'react-router-dom';

const META_TAGS = {
  title: 'Onboarding - Prerender.io',
};
const contentId = 'Onboarding';

const IntegrationDetailsPage = ({
  cm,
  onboarding,
  fetchContent: doFetchContent,
  getContent: doGetContent,
  prerenderUser,
}) => {
  const { integraionContent, selectedIntegration } = onboarding;
  const navigate = useNavigate();
  const params = useParams();
  const integration = params.name;
  const content = selectedIntegration === integration ? integraionContent : null;

  useEffect(() => {
    if (!cm) {
      doGetContent(contentId);
    }
  }, [doGetContent, cm]);

  useEffect(() => {
    if (!content) {
      doFetchContent(integration);
    }
  }, [doFetchContent, content]);

  if (!content || !cm) {
    return <h1>Loading...</h1>;
  }

  const mdCm = cm.modal.integration;
  const mdContent = content.replace('{{TOKEN}}', prerenderUser.token);
  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Content>
        <Container fluid>
          <div className="row align-items-center m-4">
            <div className="row mt-2">
              <IntegrationSteps current={1} />
            </div>
            <div className="col-12  mt-5">
              <Markdown children={mdContent} classOverride={{ img: 'w-100', a: 'link-success' }} cm={mdCm.markdown} />
            </div>
          </div>
          <div className="card-footer" id="modal-footer">
            <div className="row align-items-center">
              <div className="col-auto pl-0">
                <button
                  onClick={() => {
                    navigate('/integration');
                  }}
                  className="btn btn-outline-primary btn-sm btn-rounded "
                >
                  <span>Go back</span>
                </button>
              </div>
              <div className="col text-center">
                <h3 className="mb-0"></h3>
              </div>
              <div className="col-auto pr-0">
                <button
                  onClick={() => {
                    navigate(`/integration/${integration}/verification`);
                  }}
                  className="btn btn-success btn-sm btn-rounded "
                >
                  <span>Proceed to verification</span>
                </button>
              </div>
            </div>
          </div>
        </Container>
      </Content>
      <Navigate to="/integration-wizard" />
    </AdminTemplate>
  );
};

const mapStateToProps = (state) => ({
  onboarding: state.onboarding,
  prerenderUser: state.prerenderUser,
  cm: state.page.contentData[contentId],
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchContent,
      getContent,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(IntegrationDetailsPage));
