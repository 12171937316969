import { Content } from 'antd/lib/layout/layout';
import { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';
import { isCustom } from '@prerender/billing-shared';
import { FeatureFlag } from '../hooks/useFeatureFlag';

const META_TAGS = {
  title: 'Custom Deal Tracker - Prerender.io',
};

const CustomDealTracker = lazy(() => import('./insight/CustomDealTracker'));

const CustomDealTrackerPage = ({ user }) => {
  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Content>
        <Suspense fallback={<h1>Loading...</h1>}>
          <CustomDealTracker user={user} />
        </Suspense>
      </Content>
    </AdminTemplate>
  );
};

const mapStateToProps = (state) => ({
  user: state.prerenderUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

function isAuthorized({ chargebeePlanId, featureFlags }) {
  return isCustom(chargebeePlanId) || featureFlags.includes(FeatureFlag.CustomDeal);
}
export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(CustomDealTrackerPage, isAuthorized));
