import { Typography, Space, Button } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import RenderedHtml from './RenderedHtml';

/**
 * @param {Object} props
 * @param {Object} props.notification
 * @param {String} props.notification.description
 * @param {String} props.notification.createdAt - ISO date string
 * @param {Object} props.notification.metadata
 * @param {String=} props.notification.metadata.htmlContent - HTML content
 */
function RenderLimit75Notification({ notification }) {
  const { metadata = {} } = notification;

  return (
    <Space direction="vertical" size="large">
      <Typography.Text strong>{new Date(notification.createdAt).toLocaleDateString()}</Typography.Text>
      <Typography.Title type="warning" level={4}>
        <WarningFilled /> You reached 75% of your renders limit
      </Typography.Title>
      <Typography.Title level={3}>{notification.description}</Typography.Title>

      <RenderedHtml html={metadata.htmlContent} />

      <Link to="/billing/overview#render-usage">
        <Button type="primary">Check Usage</Button>
      </Link>
    </Space>
  );
}

export default RenderLimit75Notification;
