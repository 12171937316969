import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

const mapDataToChart = (data) => {
  return [
    { name: 'Good (the score >= 20)', value: data.good, color: '#80D1A4' },
    { name: 'Needs Improvement (between -30 and 20)', value: data.needsImprovement, color: '#FFBD80' },
    { name: 'Poor (the score <= -30)', value: data.poor, color: '#F57876' },
  ];
};

export const SeoReportChart = ({ data }) => {
  const chartData = data ? mapDataToChart(data) : [];

  return (
    <ResponsiveContainer minWidth={300} minHeight={300}>
      <PieChart>
        <Legend align="right" verticalAlign="middle" layout="vertical" />
        <Pie
          data={chartData.length === 0 ? [{ name: 'No data', value: 1 }] : chartData}
          cx="50%"
          cy="50%"
          outerRadius={110}
          fill="#8884d8"
          dataKey="value"
          nameKey="name"
          label
        >
          {chartData.length ? (
            chartData.map((item, index) => <Cell key={`cell-${item.name}`} fill={item.color} />)
          ) : (
            <Cell key={`cell-0`} fill={`#E3EBF6`} />
          )}
        </Pie>
        {chartData.length > 0 && <Tooltip />}
      </PieChart>
    </ResponsiveContainer>
  );
};
