import React from 'react';
import { Badge } from 'antd';
import DownloadIcon from '../../../assets/icons/download-icon.svg';

const InvoiceStatus = ({ invoice }) => {
  let status = 'Unknown';
  let backgroundColor = '#E7E7E7';
  let color = `#6B6B6B`;

  if (invoice.status === 'paid') {
    status = 'Paid';
    backgroundColor = '#ECFDF3';
    color = `#5EB08A`;
  } else if (invoice.status === 'posted') {
    status = 'Posted';
  } else if (invoice.status === 'payment_due') {
    status = 'Due';
  } else if (invoice.status === 'not_paid') {
    status = 'Not Paid';
  } else if (invoice.status === 'voided') {
    status = 'Voided';
  } else if (invoice.status === 'pending') {
    status = 'Pending';
  }

  return (
      <Badge count={status} style={{ backgroundColor, color }} />
  );
};

export default InvoiceStatus;
