import React from 'react';
import { Skeleton, Row, Col, Typography, theme, Tag, Empty } from 'antd';
import { Link } from 'react-router-dom';

import { NOTIFICATION_CATEGORIES } from './Notification';

const SKELETONS = Array.from({ length: 10 }, (_, i) => i);

const DATE_COL_SPAN = 3;
const CATEGORY_COL_SPAN = 3;
const DESCRIPTION_COL_SPAN = 18;

const CATEGORY_LABELS = {
  [NOTIFICATION_CATEGORIES.product]: 'Product',
  [NOTIFICATION_CATEGORIES.billing]: 'Billing',
  [NOTIFICATION_CATEGORIES.reports]: 'Reports',
};

const CATEGORY_COLORS = {
  [NOTIFICATION_CATEGORIES.product]: 'blue',
  [NOTIFICATION_CATEGORIES.billing]: 'red',
  [NOTIFICATION_CATEGORIES.reports]: 'green',
};

function cutString(str, length = 320) {
  return str.length > length ? `${str.substring(0, length)}...` : str;
}

function NotificationPreview({ notification }) {
  const strong = !notification.seenAt;

  const displayDate = React.useMemo(
    () => new Date(notification.createdAt).toLocaleDateString(),
    [notification.createdAt]
  );

  return (
    <Link to={`/notifications/${notification.id}`}>
      <div className="notification" style={{ backgroundColor: notification.seenAt ? 'transparent' : 'white' }}>
        <Row style={{ height: '100%' }} align="middle">
          <Col span={DATE_COL_SPAN}>
            <Typography.Text strong={strong}>{displayDate}</Typography.Text>
          </Col>
          <Col span={CATEGORY_COL_SPAN}>
            <Tag color={CATEGORY_COLORS[notification.category]}>{CATEGORY_LABELS[notification.category]}</Tag>
          </Col>

          <Col span={DESCRIPTION_COL_SPAN}>
            <Typography.Text strong={strong}>
              <p className="notification__preview">{cutString(notification.description)}</p>
            </Typography.Text>
          </Col>
        </Row>
      </div>
    </Link>
  );
}

function Loader() {
  const { token } = theme.useToken();
  return (
    <>
      {SKELETONS.map((i) => (
        <Row gutter={[token.marginMD, token.marginMD]} key={i}>
          <Col span={DATE_COL_SPAN}>
            <Skeleton active paragraph={false} />
          </Col>
          <Col span={DESCRIPTION_COL_SPAN}>
            <Skeleton active paragraph={{ rows: 1 }} />
          </Col>
        </Row>
      ))}
    </>
  );
}

function NotificationsList({ isLoading, notifications = [], selectedCategory }) {
  return (
    <div>
      {isLoading && <Loader />}

      {!isLoading && (
        <>
          {!notifications.length && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                selectedCategory
                  ? `You don't have any notifications for category: "${CATEGORY_LABELS[selectedCategory]}"`
                  : "You don't have any notifications yet"
              }
            />
          )}
          {notifications.map((notification, index) => (
            <NotificationPreview key={index} notification={notification} />
          ))}
        </>
      )}
    </div>
  );
}
export default NotificationsList;
