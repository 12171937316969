import { Card, Typography } from 'antd';
import { ListGroup, ListGroupItem } from '../../../components/CssFrameworkComponents';
import { SwitchWithRole } from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { saveSettings, setEmailPreferences } from '../redux/settingsActions';

const EmailPreferencesTab = () => {
  const dispatch = useDispatch();
  const prerenderUser = usePrerenderUser();
  const settings = useSelector((state) => state.settings);

  const updateEmailPreferences = useCallback(
    (update) => {
      const newEmailPreferences = {
        ...settings.emailPreferences,
        ...update,
      };
      dispatch(setEmailPreferences(newEmailPreferences));
      dispatch(
        saveSettings({
          settings: {
            ...settings,
            emailPreferences: newEmailPreferences,
          },
          userId: prerenderUser.id,
        })
      );
    },
    [settings, prerenderUser.id, setEmailPreferences, saveSettings]
  );

  return (
    <Card body>
      <ListGroup>
        <ListGroupItem>
          <div className="col">
            <Typography.Title level={5}>75% Reached</Typography.Title>
            <Typography.Text type="secondary">
              Do you want an email update when you reach 75% of your plan?
            </Typography.Text>
          </div>
          <div className="col-auto">
            <SwitchWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
              checked={settings.emailPreferences.send75Percent}
              onClick={() => updateEmailPreferences({ send75Percent: !settings.emailPreferences.send75Percent })}
              disabled={prerenderUser.inProgress}
            />
          </div>
        </ListGroupItem>
        <ListGroupItem>
          <div className="col">
            <Typography.Title level={5}>100% Reached</Typography.Title>
            <Typography.Text type="secondary">
              Do you want an email update when you reach 100% of your plan?
            </Typography.Text>
          </div>
          <div className="col-auto">
            <SwitchWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
              checked={settings.emailPreferences.send100Percent}
              onClick={() => updateEmailPreferences({ send100Percent: !settings.emailPreferences.send100Percent })}
              disabled={prerenderUser.inProgress}
            />
          </div>
        </ListGroupItem>
        {prerenderUser.isMeteredBilling && (
          <ListGroupItem>
            <div className="col">
              <Typography.Title level={5}>Better Plan Update</Typography.Title>
              <Typography.Text type="secondary">
                Do you want an email update if we find a better plan according to your usage?
              </Typography.Text>
            </div>
            <div className="col-auto">
              <SwitchWithRole
                disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
                checked={settings.emailPreferences.sendSuggested}
                onClick={() => updateEmailPreferences({ sendSuggested: !settings.emailPreferences.sendSuggested })}
                disabled={prerenderUser.inProgress}
              />
            </div>
          </ListGroupItem>
        )}
        <ListGroupItem>
          <div className="col">
            <Typography.Title level={5}>Page Report</Typography.Title>
            <Typography.Text type="secondary">
              Do you want an email update about your problematic pages?
            </Typography.Text>
          </div>
          <div className="col-auto">
            <SwitchWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
              checked={!!settings.emailPreferences.emailReportPeriod}
              onClick={() =>
                updateEmailPreferences({
                  emailReportPeriod: settings.emailPreferences.emailReportPeriod ? null : 'WEEKLY',
                })
              }
              disabled={
                prerenderUser.inProgress ||
                // Non weekly report is set by us, so it can't be managed
                (settings.emailPreferences.emailReportPeriod !== 'WEEKLY' &&
                  settings.emailPreferences.emailReportPeriod !== undefined &&
                  settings.emailPreferences.emailReportPeriod !== null)
              }
            />
          </div>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default EmailPreferencesTab;
