import React from 'react';
import { Row, Col, Card, Typography, Space, Button, Alert } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import ApplyCouponForm from './ApplyCouponForm';
import AppliedCouponsTable from './AppliedCouponTable';
import { useAppliedCouponsQuery, useApplyCouponMutation } from './CouponsApi';
import { useEvent } from '../events/hooks/useEvent';

const MESSAGES = {
  fetchError:
    'There was an error while trying to load the list of your applied discounts. Please try again in a few minutes.',
  applyError: 'There was an error while trying to apply the discount.',
  applySuccess: 'The discount was successfully applied.',
};

function Overview({ userEmail }) {
  const { data, isFetching, isError: hasFetchError, refetch } = useAppliedCouponsQuery();
  const [
    submitCode,
    { isLoading: isApplying, isError: hasApplyError, isSuccess: hasAppliedSuccessfully, error: applyError },
  ] = useApplyCouponMutation();
  const { track } = useEvent();

  const coupons = data?.data || [];

  const applyCoupon = (couponCode) => {
    submitCode(couponCode)
      .unwrap()
      .then(() => {
        track('Coupon apply success', { couponCode, userEmail });
      })
      .catch(() => {
        track('Coupon apply fail', { couponCode, userEmail });
      });
  };

  return (
    <div>
      <h3>Offers and Discounts</h3>
      <p className="text-grey">Apply your coupons to access offers and discounts.</p>

      {hasAppliedSuccessfully && (
        <Alert banner closable message={MESSAGES.applySuccess} type="success" style={{ marginBottom: 20 }} />
      )}
      {(hasFetchError || hasApplyError) && (
        <Alert
          banner
          closable
          message={hasFetchError ? MESSAGES.fetchError : applyError.data?.error || MESSAGES.applyError}
          type="error"
          style={{ marginBottom: 20 }}
        />
      )}
      <Row gutter={[24, 24]}>
        <Col xs={24} xl={17}>
          <Card
            title="Discounts Already Applied"
            extra={[<Button key="refresh" onClick={refetch} disabled={isFetching} icon={<ReloadOutlined />} />]}
          >
            <AppliedCouponsTable rows={coupons} isLoading={isFetching} />
          </Card>
        </Col>
        <Col xs={8} xl={7}>
          <Card title="New Discount">
            <Space size="middle" direction="vertical">
              <Typography.Text>You can apply a new discount by inserting a coupon's code below.</Typography.Text>
              <ApplyCouponForm applyCoupon={applyCoupon} isLoading={isApplying} />
            </Space>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Overview;
