import { Tooltip, Modal, Checkbox } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { sitemapAdaptiveTypeToArray, arrayToAdaptiveType } from '../sitemapUtil';
import DeviceTypeSelector from '../../../components/DeviceTypeSelector';

export function RecacheSitemapModal({
  open,
  onOk,
  confirmLoading,
  onCancel,
  sitemapSettings,
  prerenderUser,
  isReCacheSitemapError,
}) {
  const [adaptiveTypes, setAdaptiveTypes] = useState([]);
  const defaultAdaptiveTypes = useMemo(
    () => sitemapAdaptiveTypeToArray(sitemapSettings.adaptiveType, prerenderUser.mobileAdaptive),
    [sitemapSettings.adaptiveType]
  );

  const ok = () => {
    const adaptiveType = arrayToAdaptiveType(adaptiveTypes, prerenderUser.mobileAdaptive);
    onOk({ adaptiveType });
  };

  return (
    <Modal
      title="Recache sitemap"
      open={open}
      onOk={ok}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      transitionName=""
      maskTransitionName=""
      okButtonProps={{ disabled: adaptiveTypes.length === 0 }}
    >
      {isReCacheSitemapError && (
        <ErrorAlert onAction={resetRecacheError} modal={true} content={{ text: 'Failed to recache sitemap' }} />
      )}
      <div>Do you want to recache this sitemap?</div>
      <DeviceTypeSelector
        mobileAdaptive={prerenderUser.mobileAdaptive}
        setAdaptiveTypes={setAdaptiveTypes}
      ></DeviceTypeSelector>
    </Modal>
  );
}
