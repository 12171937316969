// imported by /reducers/index.js
// holds road search parameters and result

import { matchWith } from '../assets/lib';

import {
  CRAWLERSTATS_OVERVIEW_SET,
  CRAWLERSTATS_OVERVIEW_IN_PROGRESS,
  CRAWLERSTATS_OVERVIEW_RESET,
  CRAWLERSTATS_OVERVIEW_SET_PARAMS,

  CRAWLERSTATS_RESPONSE_TIMES_SET,
  CRAWLERSTATS_RESPONSE_TIMES_IN_PROGRESS,
  CRAWLERSTATS_RESPONSE_TIMES_RESET,
  CRAWLERSTATS_RESPONSE_TIMES_SET_PARAMS,
} from '../actions/types';
import { calculateStats, EMPTY_STATS } from '../assets/pagingFunctions';

const DEFAULT_PAGE_SIZE = 200;

const emptyState = {
  inProgress: false,
  params: {
    query: '',
    statusCode: null,
    responseTime: null,
    timePeriod: null,
    userAgent: null,
    cacheStatus: null,
  },
  requests: {
    cacheHits: [],
    cacheMisses: [],
  },
  stats: EMPTY_STATS,
};

const initialState = {
  overview: emptyState,
  responseTime: emptyState,
};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case CRAWLERSTATS_OVERVIEW_SET:
    {
      const newState = {
        inProgress: false,
        params: matchWith(emptyState.params, payload.params),
        requests: payload.data,
        stats: calculateStats(payload, DEFAULT_PAGE_SIZE),
      };
      return {
        ...state,
        overview: newState,
      };
    }

    case CRAWLERSTATS_RESPONSE_TIMES_SET:
    {
      const newState = {
        inProgress: false,
        params: matchWith(emptyState.params, payload.params),
        requests: {
          cacheHits: payload.data.cacheHits.map((item) => ({ ...item, number: (item.number / 1000) })),
          cacheMisses: payload.data.cacheMisses.map((item) => ({ ...item, number: (item.number / 1000) })),
        },
        stats: calculateStats(payload, DEFAULT_PAGE_SIZE),
      };
      return {
        ...state,
        responseTime: newState,
      };
    }

    case CRAWLERSTATS_OVERVIEW_IN_PROGRESS:
      return { ...state,
        overview: { ...state.overview, inProgress: payload.value },
      };

    case CRAWLERSTATS_RESPONSE_TIMES_IN_PROGRESS:
      return { ...state,
        responseTime: { ...state.responseTime, inProgress: payload.value },
      };

    case CRAWLERSTATS_OVERVIEW_SET_PARAMS:
      return { ...state,
        overview: { ...state.overview, params: payload.params },
      };
    case CRAWLERSTATS_RESPONSE_TIMES_SET_PARAMS:
      return { ...state,
        responseTime: { ...state.responseTime, params: payload.params },
      };
    case CRAWLERSTATS_OVERVIEW_RESET:
      return {
        ...state,
        overview: { ...emptyState },
      };
    case CRAWLERSTATS_RESPONSE_TIMES_RESET:
      return {
        ...state,
        responseTime: { ...emptyState },
      };
    default:
      break;
  }
  return state;
};
