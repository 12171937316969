// imported by /reducers/index.js
import { matchWith } from '../assets/lib';

import {
  CACHEDPAGES_SET,
  CACHEDPAGES_IN_PROGRESS,
  CACHEDPAGES_RESET,
  CACHEDPAGES_SET_STATUS,
} from '../actions/types';
import { calculateStats, EMPTY_STATS } from '../assets/pagingFunctions';

const DEFAULT_PAGE_SIZE = 200;

const emptyState = {
  inProgress: false,
  params: {
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    query: '',
  },
  stats: EMPTY_STATS,
  pages: [],
  isOutOfInterval: false,
  suggestedEstimatedPlan: null,
  savedCost: 0,
};

const initialState = {
  dashboard: emptyState,
  all: emptyState,
};

const cachedPagesReducer = (state = initialState, action) => {
  const { payload } = action;
  const bucket = payload && payload.stateBucket ? payload.stateBucket : 'dashboard';
  switch (action.type) {
    case CACHEDPAGES_SET:
    {
      const newState = {
        ...state[bucket],
        inProgress: false,
        params: matchWith(state[bucket].params, payload.params),
        stats: calculateStats(payload, DEFAULT_PAGE_SIZE),
        pages: payload.data,
      };
      return { ...state,
        dashboard: bucket === 'dashboard' ? newState : state.dashboard,
        all: bucket === 'all' ? newState : state.all,
      };
    }
    case CACHEDPAGES_SET_STATUS: {
      const newState = { ...state[bucket],
        pages: state[bucket].pages.map((page) => (payload.urls.indexOf(page.url) === -1 ? page : { ...page, cache_state: payload.cache_state })),
        inProgress: false,
      };
      return { ...state,
        dashboard: bucket === 'dashboard' ? newState : state.dashboard,
        all: bucket === 'all' ? newState : state.all,
      };
    }

    case CACHEDPAGES_IN_PROGRESS:
      return { ...state,
        dashboard: bucket === 'dashboard' ? { ...state.dashboard, inProgress: payload.value } : state.dashboard,
        all: bucket === 'all' ? { ...state.all, inProgress: payload.value } : state.all,
      };

    case CACHEDPAGES_RESET:
      return initialState;

    default:
      break;
  }
  return state;
};

export default cachedPagesReducer;
