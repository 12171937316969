import { Tooltip, Modal, Checkbox, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { sitemapAdaptiveTypeToArray, arrayToAdaptiveType, hoursToString, HOURS_IN_SECONDS } from '../sitemapUtil';

export function SitemapSettingsModal({ open, onCancel, onOk, sitemapSettings, prerenderUser, intervalHours }) {
  const [revisitInterval, setRevisitInterval] = useState(0);
  const [adaptiveTypes, setAdaptiveTypes] = useState([]);

  const defaultAdaptiveTypes = useMemo(
    () => sitemapAdaptiveTypeToArray(sitemapSettings.adaptiveType, prerenderUser.mobileAdaptive),
    [sitemapSettings.adaptiveType]
  );
  useEffect(() => {
    setAdaptiveTypes(defaultAdaptiveTypes);
  }, [open]);

  useEffect(() => {
    setRevisitInterval(intervalHours * HOURS_IN_SECONDS);
  }, [open]);

  const adaptiveType = useMemo(
    () => arrayToAdaptiveType(adaptiveTypes, prerenderUser.mobileAdaptive),
    [adaptiveTypes, prerenderUser]
  );

  const ok = () => {
    onOk({ adaptiveType, revisitInterval });
  };

  return (
    <Modal
      open={open}
      title="Sitemap Settings"
      onCancel={onCancel}
      onOk={ok}
      okButtonProps={{
        disabled:
          adaptiveTypes.length === 0 ||
          (sitemapSettings.revisitInterval === revisitInterval && sitemapSettings.adaptiveType === adaptiveType),
      }}
      transitionName=""
      maskTransitionName=""
    >
      <div>Sitemap auto crawl device settings</div>
      <Tooltip
        title={!prerenderUser.mobileAdaptive ? 'Please enable Mobile Optimized Rendering in Advanced Settings' : null}
      >
        <Checkbox.Group
          options={[
            { label: 'Desktop', value: 'desktop' },
            { label: 'Mobile', value: 'mobile' },
          ]}
          defaultValue={defaultAdaptiveTypes}
          value={adaptiveTypes}
          disabled={!prerenderUser.mobileAdaptive}
          onChange={(checkedValues) => {
            setAdaptiveTypes(checkedValues);
          }}
        />
      </Tooltip>
      <div style={{ paddingTop: '1em' }}>Revisit interval</div>

      <Select
        defaultValue={hoursToString(intervalHours)}
        value={hoursToString(revisitInterval / HOURS_IN_SECONDS)}
        style={{ width: 150 }}
        options={[
          { value: 168 * HOURS_IN_SECONDS, label: '168 hours' },
          { value: 24 * HOURS_IN_SECONDS, label: '24 hours' },
          { value: 8 * HOURS_IN_SECONDS, label: '8 hours' },
          { value: 1 * HOURS_IN_SECONDS, label: '1 hour' },
        ]}
        onChange={(v) => setRevisitInterval(v)}
      ></Select>
    </Modal>
  );
}
