import React from 'react';
import { Form, Modal } from 'antd';

import DomainKeywordsEdit from './DomainKeywordsEdit';
import { useEvent } from '../events/hooks/useEvent';

function DomainKeywordsModal({ open, onClose, loading, keywords, usedKeywords, maxKeywords, onUpdate, domain }) {
  const [form] = Form.useForm();
  const { track } = useEvent();

  const countUsedKeywords = (oldKeywords, newKeywords) => {
    return usedKeywords - oldKeywords.length + newKeywords.length;
  };

  const onFinish = (values) => {
    const newKeywords = values.keywords.split('\n').filter(Boolean);
    onUpdate(newKeywords);
    const usedKeywords = countUsedKeywords(keywords, newKeywords);

    track('Rank Tracker: Keywords updated', {
      domain: domain,
      keywords_total_count: maxKeywords,
      keywords_used_count: usedKeywords,
    });
  };

  React.useEffect(() => {
    if (keywords?.length && open) {
      form.setFieldsValue({ keywords: keywords.join('\n') });
    }
  }, [keywords, open]);

  return (
    <Modal
      title="Edit keywords"
      open={open}
      onCancel={onClose}
      okButtonProps={{ htmlType: 'submit', form: 'editKeywords', loading }}
      okText="Update keywords"
      destroyOnClose
    >
      <Form name="editKeywords" layout="vertical" disabled={loading} form={form} onFinish={onFinish}>
        <DomainKeywordsEdit initialKeywords={keywords} maxKeywords={maxKeywords} usedKeywords={usedKeywords} />
      </Form>
    </Modal>
  );
}

export default DomainKeywordsModal;
