import { USAGE_STATISTICS_IN_PROGRESS, USAGE_STATISTICS_UPDATED } from './actionTypes';

const initialState = {
  crawlerVisits: [],
  renderingUsage: [],
  inProgress: false,
};

const usageStatisticsReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case USAGE_STATISTICS_UPDATED:
      return { ...state,
        inProgress: false,
        crawlerVisits: payload.crawlerVisits,
        renderingUsage: payload.renderingUsage,
      };
    case USAGE_STATISTICS_IN_PROGRESS:
      return { ...state, inProgress: payload.inProgress };
    default:
      return state;
  }
};

export default usageStatisticsReducer;
