// From https://github.com/hejmsdz/use-hash-param/blob/master/src/index.js

export const getHashSearchParams = (location) => {
  const hash = location.hash.slice(1);
  const [prefix, query] = hash.split('?');

  return [prefix, new URLSearchParams(query)];
};

export const getHashParam = (key, defaultValue) => {
  if (typeof window === 'undefined') {
    return defaultValue;
  }
  const [, searchParams] = getHashSearchParams(window.location);
  return searchParams.get(key);
};

export const setHashParam = (key, value) => {
  if (typeof window !== 'undefined') {
    const [prefix, searchParams] = getHashSearchParams(window.location);

    if (typeof value === 'undefined' || value === '') {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }

    const search = searchParams.toString();
    window.location.hash = search ? `${prefix}?${search}` : prefix;
  }
};
