import { Image, Card, Space, Flex, Typography, theme } from 'antd';

function HighlightedFeaturesBanners({ children }) {
  const { token } = theme.useToken();

  // If there are no children or all children are null, return nothing
  if (!children) return null;
  if (!children.some((child) => child)) return null;

  return (
    <Card>
      <Flex gap={token.marginXL} vertical>
        {children}
      </Flex>
    </Card>
  );
}

export function HighlightedFeatureBanner({ imageUrl, title, description, CTA }) {
  return (
    <Flex justify="space-between" align="center">
      <Space size="large">
        <Image preview={false} width={112} src={imageUrl} />
        <div>
          <Typography.Title level={5}>{title}</Typography.Title>
          <Typography.Paragraph>{description}</Typography.Paragraph>
        </div>
      </Space>

      {CTA}
    </Flex>
  );
}

export default HighlightedFeaturesBanners;
