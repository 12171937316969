import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import { Alert, Card, Flex, Input, Layout, Table, Typography, Button, message, Tag, Tooltip } from 'antd';
import { FileSearchOutlined, EyeOutlined, SearchOutlined, ScheduleOutlined, DeleteOutlined } from '@ant-design/icons';
import AdminTemplate from '../../layout/AdminTemplate';
import { getDisplayRange } from '../../utils/getDisplayRange';
import useFeatureFlag, { FeatureFlag } from '../../hooks/useFeatureFlag';
import { useEvent } from '../events/hooks/useEvent';
import { PRERENDER_EMAIL, usePrerenderUser } from '../../hooks/usePrerenderUser';
import {
  useGetDomainStatisticsQuery,
  useExportDomainsCsvMutation,
  useDeleteDomainMutation,
} from './api/domainManagerApiSlice';
import { isFreePlan } from '../../chargebee/chargebee';
import ExportButton from '../../components/ExportButton';
import ExportCSVModal from '../SeoReport/components/ExportCSVModal';
import ButtonWithRole from '../../components/ButtonWithRole';
import USER_ROLES from '../auth/userRoles';

const { Content } = Layout;
const { Title, Text } = Typography;

const META_TAGS = {
  title: 'Domain Manager List - Prerender.io',
};

const PAGE_SIZE = 100;

const mapToApiPayload = (params) => {
  return {
    page: params.pagination.current,
    pageSize: params.pagination.pageSize,
    sort: params.sorter?.field,
    sortDirection: params.sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    domain: params.filters?.domain ? params.filters?.domain[0] : undefined,
  };
};

const getResponseMessage = (response, email) => {
  switch (response?.status) {
    case 'queued':
      return {
        content: email
          ? `Your export is processing and will take a couple of minutes. We\'ll email it to you at "${email}" when it\'s ready.`
          : 'We have scheduled your export. You will get a download link in your email once the export is ready.',
        type: 'success',
      };
    case 'not queued':
      return {
        content:
          // eslint-disable-next-line max-len
          "Click start to export all your cached URLs into a CSV. Please note that exporting URLs happens in the background and you'll be notified by email once it's done.",
        type: 'info',
      };
    case 'processing':
      return {
        content: "You already have an export job in progress. We'll inform you via email if that job has finished.",
        type: 'warning',
      };
    case 'already exists':
      return {
        content: 'CSV export has been already requested. Please try again later.',
        type: 'warning',
      };
    case 'limited':
      return {
        content: 'Please upgrade your plan to use this feature.',
        type: 'warning',
      };
    default:
      return {
        content: "Something bad has happened. We're looking into it...",
        type: 'error',
      };
  }
};

const DomainsListPage = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const initialParams = {
    pagination: {
      current: 1,
      pageSize: PAGE_SIZE,
    },
    sorter: {
      field: 'created_at',
      order: 'descend',
    },
  };
  const [domains, setDomains] = useState([]);
  const [totalDomains, setTotalDomains] = useState(0);
  const [tableParams, setTableParams] = useState(initialParams);
  const [searchInput, setSearchInput] = useState('');
  const [showExportModal, setShowExportModal] = useState(false);
  const [exportDomainManagerCsv] = useExportDomainsCsvMutation();
  const [deleteDomain] = useDeleteDomainMutation();

  const { data, error, isFetching } = useGetDomainStatisticsQuery(mapToApiPayload(tableParams), {
    refetchOnMountOrArgChange: true,
  });

  const canUseDomainDetails = useFeatureFlag([FeatureFlag.DomainsDetails]);
  const canUseSchedulerSettings = useFeatureFlag([FeatureFlag.DomainSchedulerSettings]);
  const isDetailsPageAvailable = canUseDomainDetails || canUseSchedulerSettings;

  const isSeoReportPageAvailable = useFeatureFlag([FeatureFlag.SeoReport]);

  const { track } = useEvent();
  const user = usePrerenderUser();
  const isPrerenderUser = user.email.endsWith(PRERENDER_EMAIL);

  useEffect(() => {
    if (data) {
      setDomains(
        data.data.map((item) => {
          const formattedNumberOfUrls = new Intl.NumberFormat().format(item.number_of_urls);
          return {
            key: item.id,
            domain: item.domain,
            created_at: item.created_at ? dayjs(item.created_at).format('YYYY-MM-DD') : '-',
            number_of_urls: formattedNumberOfUrls,
            deletable: item.deletable,
          };
        })
      );
      setTotalDomains(data.totalCount);
    } else if (error) {
      message.error('Failed to fetch domain statistics');
    }
  }, [data, error]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      sorter,
    });
  };

  const debouncedSetTableParams = useCallback(
    debounce((newInput) => {
      setTableParams((oldParams) => ({
        ...oldParams,
        pagination: { ...oldParams.pagination, current: 1 },
        filters: { domain: [newInput] },
      }));
    }, 1500),
    []
  );

  const handleDeleteDomain = async (domainId, domain) => {
    try {
      await deleteDomain(domainId).unwrap();
      messageApi.success('Domain deleted successfully');
      track('Domain Deleted', { domain, subscription_plan: user.chargebeePlanId });
    } catch {
      messageApi.error('Failed to delete domain');
    }
  };

  const onInputChange = (e) => {
    const newInput = e.target.value;
    setSearchInput(newInput);
    debouncedSetTableParams(newInput);
  };

  const displayRange = getDisplayRange(tableParams.pagination, totalDomains);
  const paidPlan = !isFreePlan(user.plan);

  const handleExportCsv = async () => {
    const exportParams = {
      sort: tableParams.sorter?.field,
      sortDirection: tableParams.sorter?.order === 'descend' ? 'DESC' : 'ASC',
      q: tableParams.filters?.domain ? tableParams.filters?.domain[0] : undefined,
    };

    try {
      const { data: response } = await exportDomainManagerCsv(exportParams);
      const message = getResponseMessage(response, user.email);
      messageApi.open(message);
    } catch {
      messageApi.error({ content: 'Failed to export CSV' });
    }
  };

  return (
    <AdminTemplate metaTags={META_TAGS}>
      {contextHolder}
      {canUseDomainDetails && (
        <ExportCSVModal
          onClose={() => setShowExportModal(false)}
          onExport={async () => {
            await handleExportCsv();
            setShowExportModal(false);
          }}
          showModal={showExportModal}
          text="Export Domains list as CSV?"
        />
      )}

      <Content>
        <Flex gap={16} vertical={true} style={{ marginBottom: '16px' }}>
          <div>
            <Title level={2}>Domain Manager</Title>
            <Typography.Paragraph type="secondary">
              Monitor your activity and adjust settings for each cached domain.
            </Typography.Paragraph>
          </div>

          <Alert message="Please note the list refreshes every 24 hours" type="info" showIcon />
          <Card title="My Domains">
            <Flex justify="space-between">
              <Input
                placeholder="search domain"
                allowClear
                value={searchInput}
                onChange={onInputChange}
                prefix={<SearchOutlined style={{ color: '#d9d9d9' }} />}
                style={{ marginBottom: 16, width: '30%' }}
              />
              <div>
                <Text>
                  Displaying {displayRange} domains from {Intl.NumberFormat().format(totalDomains)}
                </Text>
                {canUseDomainDetails && (
                  <ExportButton
                    isFreePlan={!paidPlan}
                    hasSelectedUrls={false}
                    onClick={() => setShowExportModal(true)}
                  />
                )}
              </div>
            </Flex>
            <Table
              loading={isFetching}
              pagination={{
                current: tableParams.pagination.current,
                pageSize: tableParams.pagination.pageSize,
                total: totalDomains,
              }}
              dataSource={domains}
              onChange={handleTableChange}
              bordered
              rowKey="domain"
            >
              <Table.Column title="Domain" dataIndex="domain" key="domain" width="90%" />
              <Table.Column title="Created On" dataIndex="created_at" key="created_at" sorter className="col-min" />
              <Table.Column title="URLs" dataIndex="number_of_urls" key="number_of_urls" sorter />
              {isDetailsPageAvailable && (
                <Table.Column
                  title="Actions"
                  render={(_text, data) => (
                    <Link to={data.domain}>
                      <Button icon={<EyeOutlined />}>details</Button>
                    </Link>
                  )}
                />
              )}
              <Table.Column
                title=""
                width="10%"
                render={(text, record) => (
                  <Flex gap={8}>
                    {isSeoReportPageAvailable && (
                      <Tooltip title="SEO Report">
                        <Link to={{ pathname: `/seo-report/${record.key}`, state: { domain: record.domain } }}>
                          <Button
                            icon={<FileSearchOutlined />}
                            onClick={() => {
                              track('Seo Report Button Clicked', {
                                domain: record.domain,
                                subscription_plan: user.chargebeePlanId,
                              });
                            }}
                          />
                        </Link>
                      </Tooltip>
                    )}
                    {isPrerenderUser && (
                      <Tooltip title="Analytics">
                        <Link to={{ pathname: `/analytics/${record.domain}` }}>
                          <Button
                            icon={<ScheduleOutlined />}
                            onClick={() => {
                              track('Analytics Button Clicked', {
                                domain: record.domain,
                                subscription_plan: user.chargebeePlanId,
                              });
                            }}
                          />
                        </Link>
                      </Tooltip>
                    )}
                    {record.deletable && (
                      <Tooltip
                        title={
                          [USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST].includes(user.role)
                            ? 'Action only allowed for Account Owner and admin'
                            : 'Delete domain'
                        }
                      >
                        <ButtonWithRole
                          danger
                          disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.TEAM_MEMBER, USER_ROLES.GUEST]}
                          icon={<DeleteOutlined />}
                          onClick={() => handleDeleteDomain(record.key, record.domain)}
                        />
                      </Tooltip>
                    )}
                  </Flex>
                )}
              />
            </Table>
          </Card>
        </Flex>
      </Content>
    </AdminTemplate>
  );
};

export default DomainsListPage;
