// Define action creators that dispatch actions:
import config from '../assets/config';
import internalApi from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';
import { setLastErrorCode, clearLastErrorCode } from './pageActions';

import { CACHINGSTATS_SET, CACHINGSTATS_IN_PROGRESS, CACHINGSTATS_SET_PARAMS } from './types';

const cachingStatsSearch =
  (stateBucket = 'overview', params) =>
  async (dispatch) => {
    try {
      dispatch({ type: CACHINGSTATS_IN_PROGRESS, payload: { stateBucket, value: true } });
      dispatch({ type: CACHINGSTATS_SET_PARAMS, payload: { stateBucket, params } });
      let filter = '';
      // filter+= params.query ? `&query=${params.query}` : '';
      filter += params.statusCodeEq ? `&statusCode=${params.statusCodeEq}` : '';
      filter += params.statusCodeLow ? `&statusCodeLow=${params.statusCodeLow}` : '';
      filter += params.statusCodeHigh ? `&statusCodeHigh=${params.statusCodeHigh}` : '';
      filter += params.responseTimeLow ? `&responseTimeLow=${params.responseTimeLow}` : '';
      filter += params.responseTimeHigh ? `&responseTimeHigh=${params.responseTimeHigh}` : '';
      filter += params.timedout ? `&timedout=${params.timedout}` : '';
      filter += params.domain ? `&domain=${params.domain}` : '';
      filter += params.adaptive_type ? `&adaptive_type=${params.adaptive_type}` : '';
      // filter+= params.timePeriod ? `&timePeriod=${params.timePeriod}` : '';
      const endpoint = stateBucket === 'overview' ? 'recache-overview-stats' : 'recache-500-stats';
      const requestUri = `${config.apiUrl.dataServer}/api/${endpoint}?${filter}`;
      const data = await internalApi('get', requestUri, null);
      dispatch({ type: CACHINGSTATS_SET, payload: { stateBucket, data, params } });
      dispatch(clearLastErrorCode());
    } catch (error) {
      console.log(error);
      dispatch({ type: CACHINGSTATS_IN_PROGRESS, payload: { stateBucket, value: false } });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };

export default cachingStatsSearch;
