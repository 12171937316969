import { restApi } from '../../api/restApiSlice';

export const domain404CheckerApi = restApi.injectEndpoints({
  tagTypes: ['Domain404Check'],
  endpoints: (builder) => ({
    getDomain404Checks: builder.query({
      query: (domain) => {
        return {
          url: '/domain-404-check',
          method: 'GET',
          params: { domain },
        };
      },
      providesTags: (result, error, arg) => [
        'Domain404Check',
        ...(result?.data ? result.data.map(({ id }) => ({ type: 'Domain404Check', id })) : []),
      ],
    }),
    addDomain404Check: builder.mutation({
      query: (domain404Check) => ({
        url: '/domain-404-check',
        method: 'POST',
        body: domain404Check,
      }),
      invalidatesTags: ['Domain404Check'],
    }),
    editDomain404Check: builder.mutation({
      query: (updateDomainCheck) => {
        const { id, ...update } = updateDomainCheck;
        return {
          url: `/domain-404-check/${id}`,
          method: 'PUT',
          body: update,
        };
      },
      invalidatesTags: (result, error, updateDomainCheck) => [{ type: 'Domain404Check', id: updateDomainCheck.id }],
    }),
    toggleAllDomain404Checks: builder.mutation({
      query: (enabled) => {
        return {
          url: `/domain-404-check/toggle-all/${enabled}`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Domain404Check'],
    }),
    deleteDomain404Check: builder.mutation({
      query: (domainCheckId) => {
        return {
          url: `/domain-404-check/${domainCheckId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, domainCheckId) => [{ type: 'Domain404Check', id: domainCheckId }],
    }),
  }),
});

export const {
  useGetDomain404ChecksQuery,
  useAddDomain404CheckMutation,
  useEditDomain404CheckMutation,
  useToggleAllDomain404ChecksMutation,
  useDeleteDomain404CheckMutation,
} = domain404CheckerApi;
