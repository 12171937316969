import React from 'react';
import { Table, ConfigProvider, Empty } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';
import { useUserRole } from '../../../hooks/usePrerenderUser';

const getColumns = (paramColName, actionColName, onEditClicked) => [
  {
    title: paramColName,
    key: 'param',
    dataIndex: 'param',
    width: '99%',
  },
  {
    title: actionColName,
    key: 'action',
    dataIndex: 'action',
    className: 'col-min',
  },
  {
    title: 'Edit',
    key: 'edit',
    width: '1%',
    render: (_text, _record, index) => (
      <ButtonWithRole
        disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
        type="text"
        onClick={() => onEditClicked(index)}
        icon={<EditOutlined />}
      ></ButtonWithRole>
    ),
  },
];

function UrlParametersSettingsTable({
  isLoading = false,
  tableLabels = {},
  items = [],
  hideIgnoreWhenCachingParams,
  hideUseForCachingParams,
  onRowEditClick,
  onSelectedItemsChange,
}) {
  const paramColName = tableLabels.header.param;
  const actionColName = tableLabels.header.action;
  const userRole = useUserRole();

  const rows = React.useMemo(
    () =>
      items.map((item) => ({
        id: item.id,
        param: item.value,
        ignore: item.ignore,
        action: item.ignore ? tableLabels.ignore : tableLabels.use,
      })),
    [items]
  );

  const getRowClassName = (row) => {
    if (row.ignore && hideIgnoreWhenCachingParams) return 'text-muted';

    if (!row.ignore && hideUseForCachingParams) return 'text-muted';

    return '';
  };

  const onEditClick = (index) => onRowEditClick(items[index]);

  return (
    <ConfigProvider
      renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={tableLabels.noItems} />}
    >
      <Table
        bordered={!!rows.length}
        rowKey="id"
        loading={isLoading}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowIds) => onSelectedItemsChange(selectedRowIds),
          getCheckboxProps: (_record) => ({
            disabled: userRole === USER_ROLES.BILLING_MANAGER || userRole === USER_ROLES.GUEST,
          }),
        }}
        columns={getColumns(paramColName, actionColName, onEditClick)}
        dataSource={rows}
        pagination={false}
        rowClassName={getRowClassName}
        showHeader={!!rows.length}
        scroll={{ x: true }}
      />
    </ConfigProvider>
  );
}

export default UrlParametersSettingsTable;
