import { Button, Form, Input } from 'antd';
import '../RegistrationPage.css';
import { useEvent } from '../../events/hooks/useEvent';

export const DomainForm = ({ onFinish, onGoBack, values }) => {
  const [form] = Form.useForm();
  const { track, updateUserProfile } = useEvent();

  return (
    <>
      <Form
        onFinish={(data) => {
          onFinish(data);
          track('Signup Form: Domain Entered', { signup_form_domain: data.domain });
          updateUserProfile({ signup_form_domain: data.domain });
        }}
        layout="vertical"
        form={form}
      >
        <div className="my-5">
          <h1 className="text-center">Enter your domain</h1>
          <h3 className="text-center">We'll verify your domain to ensure seamless integration with Prerender.</h3>
        </div>
        <Form.Item
          name={'domain'}
          initialValue={values.domain}
          rules={[{ required: true, message: 'Please enter your domain' }]}
          label="Domain"
        >
          <Input placeholder="example.com" />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Continue
        </Button>
        <Button type="link" onClick={onGoBack}>
          Go back
        </Button>
      </Form>
    </>
  );
};
