import { message } from 'antd';
import axios from 'axios';
import config from '../assets/config';

export const experimentalClient = async (token, url) => {
  try {
    return await axios.get(url, {
      baseURL: `${config.apiUrl.insightServer}/experimental/${token}`,
    });
  } catch (error) {
    message.error('Sorry, something went wrong. Please try again later.', 10);
    console.error(error);

    throw error;
  }
};
