import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getContent } from '../actions/pageActions';

export function usePageContent(cid, options = {}) {
  const dispatch = useDispatch();

  const cm = useSelector((state) => state.page.contentData[cid]);
  const language = useSelector((state) => state.page.language);

  // Load page config
  useEffect(() => {
    if (!cm) {
      dispatch(getContent({ component: cid, lang: language, ...options }));
    }
  }, [language, cm]);

  return { cm, language };
}
