import { GET_SEO_SCORE_IN_PROGRESS, SET_SEO_SCORE } from './actionTypes';
import config from '../../../assets/config';
import internalApi, { THROW_ON_ERROR_V2 } from '../../../assets/internalApi';
import { clearLastErrorCode, setLastErrorCode } from '../../../actions/pageActions';
import { getLocalErrorCode } from '../../../assets/lib';

export const getSeoScore = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_SEO_SCORE_IN_PROGRESS, payload: true });
    const requestUri = `${config.apiUrl.dataServer}/api/v2/score?id=${id}`;
    const result = await internalApi('get', requestUri, undefined, THROW_ON_ERROR_V2);
    dispatch(clearLastErrorCode());
    dispatch({ type: SET_SEO_SCORE, payload: { result, id } });
  } catch (error) {
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  } finally {
    dispatch({ type: GET_SEO_SCORE_IN_PROGRESS, payload: false });
  }
};

export const clearSeoScore = () => ({ type: SET_SEO_SCORE, payload: null });
