import { Flex } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useGetDomainQuery, useSetDomainMutation } from '../../../api/integrationWizardSlice';
import SpinningWheel from '../../../../../components/SpinningWheel';
import EnterDomainForm from './domainSubsteps/EnterDomainForm';
import { clearIntegrationResult, validateIntegration } from '../../../../integration/redux/IntegrationActions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { usePrerenderUser } from '../../../../../hooks/usePrerenderUser';
import { useEvent } from '../../../../events/hooks/useEvent';

const VerifyIntegrationStep = ({ onGoNext, onGoBack, currentStep }) => {
  const { data, isFetching } = useGetDomainQuery(null, { refetchOnMountOrArgChange: true });
  const [setDomain, setDomainResult] = useSetDomainMutation();
  const [domainInput, setDomainInput] = useState('');
  const onboardingData = useSelector((state) => state.onboarding);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();

  useEffect(() => {
    if (data) {
      setDomainInput(data.domain || '');
    }
  }, [data]);

  const onDomainEntered = (values) => {
    const newDomain = values.domain;
    setDomain(newDomain);
    setDomainInput('');
    handleVerification(newDomain);
  };

  const handleVerification = (newDomain) => {
    dispatch(validateIntegration(newDomain.startsWith('http') ? newDomain : `https://${newDomain}`));
  };

  useEffect(() => {
    if (onboardingData.validationResult?.working) {
      dispatch(clearIntegrationResult());
      track('Verification Completed', {
        subscription_plan: prerenderUser.chargebeePlanId,
      });
      onGoNext();
    } else if (onboardingData.validationResult?.working === false) {
      track('Verification Failed', {
        subscription_plan: prerenderUser.chargebeePlanId,
        error_code: onboardingData.validationResult?.errors.length ? onboardingData.validationResult?.errors[0] : null,
      });
      navigate('/integration-wizard/verify-integration/failed');
    }
  }, [onboardingData.validationResult?.working]);

  return (
    <Flex vertical align="center" justify="center" gap="middle" style={{ minHeight: 450 }}>
      {isFetching || onboardingData.inProgress ? (
        <SpinningWheel matchParent />
      ) : (
        <EnterDomainForm
          onFinish={onDomainEntered}
          domain={domainInput}
          loading={isFetching || setDomainResult.isLoading}
          error={setDomainResult.isError}
          onGoBack={onGoBack}
          currentStep={currentStep}
        />
      )}
    </Flex>
  );
};

export default VerifyIntegrationStep;
