import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Tag, Flex } from 'antd';
import React from 'react';

import { TOOLTIP_ROOT_ID } from '../Tooltip';

function SeoScoreComponent({ score }) {
  const tooltipConfig = React.useMemo(() => {
    if (score === null) {
      return {
        title: 'Will be scored on the next rendering',
        icon: <ClockCircleOutlined />,
        color: 'default',
        score: 'Not Scored',
      };
    }
    if (score < 0) {
      return {
        title: 'Very Low SEO',
        icon: <CloseCircleOutlined />,
        color: 'error',
        score,
      };
    }

    if (score < 30) {
      return {
        title: 'Not Optimized',
        icon: <ExclamationCircleOutlined />,
        color: 'processing',
        score,
      };
    }

    return {
      title: 'Healthy',
      icon: <CheckCircleOutlined />,
      color: 'success',
      score,
    };
  }, [score]);

  return (
    <Flex
      data-tooltip-id={TOOLTIP_ROOT_ID}
      data-tooltip-content={tooltipConfig.title}
      data-tooltip-place="top"
      align="center"
    >
      <Tag icon={tooltipConfig.icon} color={tooltipConfig.color}>
        <span>{tooltipConfig.score}</span>
      </Tag>
    </Flex>
  );
}

export default React.memo(SeoScoreComponent);
