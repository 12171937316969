import React from 'react';
import { Flex, Button, Typography, theme } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';

type PrevNextPaginationProps = {
  onPrevClick: () => void;
  onNextClick: () => void;
  page: number;
  pageSize: number;
};

function PrevNextPagination({ onNextClick, onPrevClick, page, pageSize }: PrevNextPaginationProps) {
  const { token } = theme.useToken();

  return (
    <Flex justify="space-between" align="center" style={{ margin: token.marginSM }}>
      <Button icon={<ArrowLeftOutlined />} onClick={onPrevClick} disabled={!onPrevClick}>
        Previous Page
      </Button>
      <div>
        <Typography.Text type="secondary">
          Page {page}: {page > 1 ? pageSize * (page - 1) + 1 : 1} - {pageSize * page}
        </Typography.Text>
      </div>
      <Button icon={<ArrowRightOutlined />} iconPosition="end" onClick={onNextClick} disabled={!onNextClick}>
        Next Page
      </Button>
    </Flex>
  );
}

export default PrevNextPagination;
