import { restApi } from '../../api/restApiSlice';

const schedulerSettingsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getSchedulerSettings: builder.query({
      query: (domain) => ({ url: `/domains/scheduler-settings`, method: 'GET', params: { domain } }),
      providesTags: ['schedulerSettings'],
    }),
    addSchedulerSettings: builder.mutation({
      query: (body) => ({ url: '/domains/scheduler-settings', method: 'POST', body }),
      invalidatesTags: ['schedulerSettings'],
    }),
    deleteSchedulerSettings: builder.mutation({
      query: (id) => {
        return { url: `/domains/scheduler-settings`, method: 'DELETE', params: { id } };
      },
      invalidatesTags: ['schedulerSettings'],
    }),
  }),
});

export const { useGetSchedulerSettingsQuery, useAddSchedulerSettingsMutation, useDeleteSchedulerSettingsMutation } =
  schedulerSettingsApiSlice;
