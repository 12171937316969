import { Button, Divider, Drawer, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../atoms';

export default function UpgradeDetails({ open, onClose }) {
  const user = useRecoilValue(userAtom);

  // Days since plan was retired
  const daysSinceRetired = Math.floor((new Date() - new Date('2021-10-01')) / (1000 * 60 * 60 * 24));

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title="Plan Upgrade - Details"
      width={600}
      footer={
        <Link to="/billing/plans">
          <Button type="primary" block>
            Upgrade Now
          </Button>
        </Link>
      }
    >
      <Typography.Title level={4}>When was my plan retired?</Typography.Title>
      <Typography.Paragraph>
        We have retired your plan on <b>1st of October, 2021</b>. Which was <b>{daysSinceRetired}</b> days ago.
      </Typography.Paragraph>

      <Divider />

      <Typography.Title level={4}>What will happen to my plan?</Typography.Title>
      <Typography.Paragraph>
        Your old plan will be upgraded to cheapest plan available for your current usage.
      </Typography.Paragraph>

      <Divider />

      <Typography.Title level={4}>What will change in billing?</Typography.Title>
      <Typography.Paragraph>
        Your old plan was a <b>post-paid</b> plan. But we are not offering post-paid plans anymore. So your plan will be
        upgraded to a <b>pre-paid</b> plan.
      </Typography.Paragraph>

      <Divider />

      <Typography.Title level={4}>When will this happen?</Typography.Title>
      <Typography.Paragraph>
        We are upgrading our customers in small batches. So you will be upgraded soon, but we can't give you an exact
        date.
      </Typography.Paragraph>

      <Divider />

      <Typography.Title level={4}>Do I have to do anything?</Typography.Title>
      <Typography.Paragraph>
        No, we are handling everything for you, and after the upgrade your plan will unlock every feature which was not
        available for the old plans.
      </Typography.Paragraph>

      <Divider />

      <Typography.Title level={4}>Can I upgrade myself?</Typography.Title>
      <Typography.Paragraph>Yes, in case you pick a new plan before we upgrade your account.</Typography.Paragraph>
    </Drawer>
  );
}
