import { restApi } from '../../api/restApiSlice';

export const domainsCountApiSlice = restApi.injectEndpoints({
  tagTypes: ['domainsCount'],
  endpoints: (builder) => ({
    getDomainsCount: builder.query({
      query: (params) => ({ url: `/domain-statistics/domains-count`, method: 'GET', params }),
      providesTags: ['domainsCount'],
    }),
  }),
});

export const { useGetDomainsCountQuery } = domainsCountApiSlice;
