import { Button, Flex, Form, Input } from 'antd';
import { RoleSelect } from '../../../../../components/RoleSelect';
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useUserRole } from '../../../../../hooks/usePrerenderUser';
import { getLowerTierRoles } from '../../../../teams';
import { useMemo } from 'react';

const StatusIcon = ({ status }) => {
  if (status === 'success') {
    return <CheckCircleOutlined style={{ color: 'green', alignSelf: 'center' }} />;
  }
  if (status === 'error') {
    return <CloseCircleOutlined style={{ color: 'red', alignSelf: 'center' }} />;
  }
  return null;
};

const InviteTeamMembersForm = ({ form, onFinish, onInputChange, addedUsers, setAddedUsers, userStatuses }) => {
  const userRole = useUserRole();

  const visibleRoles = useMemo(() => {
    return getLowerTierRoles(userRole);
  }, [userRole]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={(values) => onFinish(values)}
      style={{ maxWidth: 560, marginTop: 32, width: '100%' }}
      initialValues={{
        users: [{ email: '', role: visibleRoles[0].value }],
      }}
    >
      <Form.List name="users">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Flex gap="small" style={{ marginBottom: 4, width: '100%' }} align="baseline" key={key}>
                <Form.Item
                  {...restField}
                  label="Email address"
                  name={[name, 'email']}
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: 'Please input a valid email address',
                    },
                  ]}
                >
                  <Input
                    name="email"
                    placeholder="Email of team member"
                    style={{ width: 300 }}
                    onChange={onInputChange}
                  />
                </Form.Item>
                <Form.Item
                  label="User role"
                  name={[name, 'role']}
                  rules={[{ required: true, message: 'Please select a user role' }]}
                >
                  <RoleSelect
                    visibleRoles={visibleRoles}
                    value={form.getFieldValue(['users', name, 'role'])}
                    onChange={(value) => form.setFieldValue(['users', name, 'role'], value)}
                    style={{ width: 200 }}
                  />
                </Form.Item>
                <StatusIcon status={userStatuses[form.getFieldValue(['users', name, 'email'])]} />
                <CloseOutlined onClick={() => remove(name)} style={{ alignSelf: 'center' }} />
              </Flex>
            ))}
            <Form.Item>
              <Button
                type="text"
                onClick={() => {
                  add();
                  setAddedUsers([...addedUsers, { email: '', role: '' }]);
                }}
                block
                icon={<PlusOutlined />}
              >
                Add another
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default InviteTeamMembersForm;
