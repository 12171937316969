import { Flex, theme, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function HiddenContent({ title, children, showLoading, loadingText }) {
  const { token } = theme.useToken();

  return (
    <div style={{ position: 'relative' }}>
      <Flex
        justify="center"
        align="center"
        style={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          height: '100%',
          backdropFilter: 'blur(5px)',
          background: 'rgba(255, 255, 255, 0.75)',
        }}
      >
        {title && <Typography.Title level={3}>{title}</Typography.Title>}
        {showLoading && (
          <Flex justify="center" align="center" vertical gap={'large'}>
            <LoadingOutlined style={{ fontSize: 40, color: token.colorPrimary }} />
            <Typography.Paragraph>{loadingText}</Typography.Paragraph>
          </Flex>
        )}
      </Flex>
      {children}
    </div>
  );
}

export default HiddenContent;
