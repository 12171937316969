import React from 'react';
import { Select } from 'antd';

export const DomainSearchDropdown = React.forwardRef(
  ({ selectedDomain, domains, performDomainSearch, ...selectProps }, ref) => {
    return (
      <Select
        ref={ref}
        showSearch
        allowClear={true}
        style={{ width: 200 }}
        popupMatchSelectWidth={false}
        filterOption={(input, option) => (option?.domain ?? '').includes(input)}
        filterSort={(optionA, optionB) => (optionA?.domain ?? '').localeCompare(optionB?.domain ?? '')}
        placeholder="Domain"
        options={domains}
        fieldNames={{ label: 'domain', value: 'domain' }}
        onChange={performDomainSearch}
        value={selectedDomain}
        {...selectProps}
      />
    );
  }
);
