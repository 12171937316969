import { restApiLegacy } from '../api/restApiSliceLegacy';

export const teamApiSliceLegacy = restApiLegacy.injectEndpoints({
  endpoints: (builder) => ({
    deleteUser: builder.mutation({
      query: (email) => ({
        url: '/v2/user',
        method: 'DELETE',
        body: {
          email,
        },
      }),
    }),
  }),
});
export const { useDeleteUserMutation } = teamApiSliceLegacy;
