import { restApi } from '../api/restApiSlice';

export const couponsApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    appliedCoupons: builder.query({
      query: () => '/coupons',
      providesTags: ['Coupon'],
    }),
    applyCoupon: builder.mutation({
      query: (code) => ({
        url: `/coupons/apply/${code}`,
        method: 'POST',
      }),
      invalidatesTags: ['Coupon'],
    }),
  }),
});

export const { useAppliedCouponsQuery, useApplyCouponMutation } = couponsApi;
