import { ConfigProvider, Empty, Table, Tag } from 'antd';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => mapStatusToTag(status),
  },
  {
    title: 'Invited On',
    dataIndex: 'referredOn',
    key: 'referredOn',
    sorter: true,
  },
];

const ReferralStatus = {
  INVITE_CREATED: 'invite_created',
  EMAIL_SENT: 'email_sent',
  SIGNED_UP: 'signed_up',
  CREDIT_EARNED: 'credit_earned',
  CANNOT_BE_REFERRED: 'cannot_be_referred',
};

const mapStatusToTag = (status) => {
  switch (status) {
    case ReferralStatus.INVITE_CREATED:
      return <Tag color="magenta">Invite created</Tag>;
    case ReferralStatus.EMAIL_SENT:
      return <Tag color="blue">Email sent</Tag>;
    case ReferralStatus.SIGNED_UP:
      return <Tag color="gold">Signed up</Tag>;
    case ReferralStatus.CREDIT_EARNED:
      return <Tag color="green">Credit earned</Tag>;
    case ReferralStatus.CANNOT_BE_REFERRED:
      return <Tag color="red">Cannot be referred</Tag>;
    default:
      return <Tag color="default">Processing</Tag>;
  }
};

const ReferralStatusTable = ({ tableData, isFetchingReferrals, handleTableChange, tableParams, totalReferrals }) => {
  return (
    <ConfigProvider
      renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="You don't have any referrals yet." />}
    >
      <Table
        bordered
        columns={columns}
        dataSource={tableData}
        loading={isFetchingReferrals}
        onChange={handleTableChange}
        pagination={{
          current: tableParams.pagination.current,
          pageSize: tableParams.pagination.pageSize,
          total: totalReferrals,
        }}
      />
    </ConfigProvider>
  );
};

export default ReferralStatusTable;
