import { useCallback, useEffect } from 'react';
import { useLazyIsPaymentInfoValidQuery } from '../api/billingApiSlice';
import { openChargebeePortalBillingAddress, openChargebeePortalPaymentSources } from '../../../chargebee/chargebee';

export const usePaymentChargebeeInfo = () => {
  const [
    triggerIsPaymentInfoValid,
    { data: isPaymentInfoValid, isFetching: isLoadingIsPaymentInfoValid, isError: isErrorLoadingIsPaymentInfoValid },
  ] = useLazyIsPaymentInfoValidQuery();

  useEffect(() => {
    triggerIsPaymentInfoValid(undefined, false);
  }, []);

  const onChangeBillingAddress = useCallback(() => {
    openChargebeePortalBillingAddress({
      onClosed: () => {
        triggerIsPaymentInfoValid(undefined, false);
      },
    });
  }, [triggerIsPaymentInfoValid]);

  const onChangePaymentMethod = useCallback(() => {
    openChargebeePortalPaymentSources({
      onPaymentSourceAdded: () => {
        triggerIsPaymentInfoValid(undefined, false);
      },
      paymentSourceUpdate: () => {
        triggerIsPaymentInfoValid(undefined, false);
      },
    });
  }, [triggerIsPaymentInfoValid]);

  return {
    onChangeBillingAddress,
    onChangePaymentMethod,
    isPaymentInfoValid,
    isLoadingIsPaymentInfoValid,
    isErrorLoadingIsPaymentInfoValid,
  };
};
