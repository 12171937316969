import React from 'react';
import { Alert, Typography, Button, theme } from 'antd';
import { Link } from 'react-router-dom';

const URLS_CACHED_MESSAGES = {
  singular: '1 URL was added to the Priority Recache Queue.',
  plural: 'URLs were added to the Priority Recache Queue.',
};

function Message({ text }: { text: string }) {
  return (
    <Typography.Text>
      {text}
      <Link to="/recache-queue">
        <Button type="link">View Queue</Button>
      </Link>
    </Typography.Text>
  );
}

type URLsCachedAlertProps = {
  amount: number;
  afterClose: () => void;
};

function URLsCachedAlert({ amount, afterClose }: URLsCachedAlertProps) {
  const { token } = theme.useToken();

  return (
    <Alert
      style={{ marginBottom: token.marginMD }}
      type="success"
      message={
        <Message text={amount === 1 ? URLS_CACHED_MESSAGES.singular : `${amount} ${URLS_CACHED_MESSAGES.plural}`} />
      }
      closable
      afterClose={afterClose}
    />
  );
}

export default URLsCachedAlert;
