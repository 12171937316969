import { Card, Statistic } from 'antd';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getChartData } from '../../assets/charts';
import { getUsageStatisticsData } from './redux/usageStatisticsActions';

const getChartMinimumValue = (data) =>
  Math.floor(data.length === 0 ? 0 : data.reduce((value, d) => Math.min(value, d.number * 0.9), Number.MAX_VALUE));

const UsageStatisticsCard = ({ getUsageStatisticsData: doGetUsageStatisticsData, usageStatistics, prerenderUser }) => {
  const { crawlerVisits, renderingUsage } = usageStatistics;
  const { numPagesCached, renderCounter } = prerenderUser;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    doGetUsageStatisticsData().finally(() => setIsLoading(false));
  }, [doGetUsageStatisticsData]);

  const crawlerVisitsMinimum = getChartMinimumValue(crawlerVisits);
  const renderingUsageMinimum = getChartMinimumValue(renderingUsage);

  const options = {
    animation: true,
    plugins: {
      legend: { display: true, position: 'bottom', labels: { boxWidth: 10 } },
    },
    maintainAspectRatio: false,
    scales: {
      y0: {
        grid: { color: '#f0f0f0', drawOnChartArea: false },
        min: crawlerVisitsMinimum,
      },
      y1: {
        grid: { color: '#eeeeee', drawOnChartArea: false },
        min: renderingUsageMinimum,
        position: 'right',
      },
      x: { grid: { color: '#f0f0f0' } },
    },
  };

  const data = getChartData('line-double-axis', 'last7d', [
    { source: crawlerVisits, label: 'Crawler Visits', color: 'success' },
    { source: renderingUsage, label: 'Renders', color: 'warning' },
  ]);

  return (
    <>
      <div className="col-lg-8">
        <Card title="Usage Overtime - Last 7 Days" loading={isLoading} bodyStyle={{ minHeight: 328 }}>
          <Line style={{ minHeight: '20em' }} data={data} options={options} />
        </Card>
      </div>
      <div className="col-lg-4">
        <Card loading={isLoading} title="Usage Highlights" hoverable>
          <Card.Grid className="p-2 text-center">
            <Statistic title="Pages Cached" value={numPagesCached} valueStyle={{ fontSize: '18px' }} />
          </Card.Grid>
          <Card.Grid className="p-2 text-center">
            <Statistic title="Pages Rendered" value={renderCounter} valueStyle={{ fontSize: '18px' }} />
          </Card.Grid>
          <Card.Grid className="p-2 text-center">
            <Statistic
              title="Crawlers Served (7 day)"
              value={crawlerVisits.reduce((a, b) => a + b.number, 0)}
              valueStyle={{ fontSize: '18px' }}
            />
          </Card.Grid>
        </Card>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  usageStatistics: state.usageStatistics,
  prerenderUser: state.prerenderUser,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUsageStatisticsData,
    },
    dispatch
  );

const UsageStatisticsComponent = connect(mapStateToProps, mapDispatchToProps)(UsageStatisticsCard);

export default UsageStatisticsComponent;
