import React from 'react';
import { Card, Space, Table, Alert } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { KNOWN_TRACKER_QUERY_PARAMS } from '@prerender/uri-lib';

function KnownTrackerList() {
  return (
    <Card title="Known Trackers">
      <Space size="large" direction="vertical">
        <Alert
          showIcon
          icon={<InfoCircleOutlined />}
          type="info"
          message="Known tracking parameters are managed by Prerender, and those are always ignored, to reduce the chance of URL duplications."
        />
        <Table
          bordered
          rowKey="name"
          columns={[
            {
              key: 'name',
              dataIndex: 'name',
            },
          ]}
          dataSource={KNOWN_TRACKER_QUERY_PARAMS.map((it) => ({ name: it }))}
          showHeader={false}
        />
      </Space>
    </Card>
  );
}

export default KnownTrackerList;
