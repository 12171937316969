import React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { Input, Form, Modal, Radio, Space, Button, Flex, Typography, Tag, Divider, message } from 'antd';

import { useLazyGetURLsCountQuery } from '../../urls/api/urlsApiSlice';
import {
  useCreateURLParameterMutation,
  useUpdateURLParameterMutation,
  useDeleteURLParameterMutation,
} from '../CacheManagerApi';
import URLParameter from './URLParameter';

const wildcardToRegex = (rule: string) => {
  const escapeRegex = (u: string) => u.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'); // eslint-disable-line
  return new RegExp(`^${rule.split('*').map(escapeRegex).join('.*')}$`);
};

function exactWildcardMatch(text = '', pattern = '') {
  const regExp = wildcardToRegex(pattern);
  return regExp.test(text);
}

function MatchTag({ text, pattern }) {
  const isEmpty = !text || !pattern;
  if (isEmpty) return null;

  const match = exactWildcardMatch(text, pattern);

  if (match) return <Tag color="success">Match</Tag>;
  return <Tag color="error">No match</Tag>;
}

function UrlAffected({ count, isLoading, isEmpty }) {
  if (isEmpty) return <Typography.Paragraph>Number of URLs affected: start typing to find out</Typography.Paragraph>;
  if (isLoading) return <Typography.Paragraph>Number of URLs affected: calculating...</Typography.Paragraph>;
  return <Typography.Paragraph>Number of URLs affected: {count}</Typography.Paragraph>;
}

const ALLOWED_PARAM_NAME_PATTERN = /^[a-zA-Z0-9-_*]*$/;
const SPECIAL_CHARS_ERROR = 'Only letters, numbers, hyphens, and asterisks are allowed';
const DEFAULT_ERROR = "Please only enter the name of the query parameter that needs to be ignored e.g. 'page'";

type UrlParamEditorProps = {
  urlParam?: URLParameter;
  open: boolean;
  onClose: () => void;
};

const UrlParamEditor = ({ urlParam, open, onClose }: UrlParamEditorProps) => {
  const [testParamInput, setTestParamInput] = useState('');

  const [getURLsCount, urlsCountResult] = useLazyGetURLsCountQuery();
  const [createURLParameter, createURLParameterResult] = useCreateURLParameterMutation();
  const [updateURLParameter, updateURLParameterResult] = useUpdateURLParameterMutation();
  const [deleteURLParameter, deleteURLParameterResult] = useDeleteURLParameterMutation();

  const [form] = Form.useForm();
  const latestRequestRef = useRef(null);
  const [messageApi, messageContext] = message.useMessage();

  const urlParamName = Form.useWatch('param', { form, preserve: true });
  const viewMode = urlParam && urlParam.id ? 'edit' : 'add';

  React.useEffect(() => {
    if (createURLParameterResult.isError) messageApi.error('Failed to create URL parameter. Please try again later.');
    if (updateURLParameterResult.isError) messageApi.error('Failed to update URL parameter. Please try again later.');
    if (deleteURLParameterResult.isError) messageApi.error('Failed to delete URL parameter. Please try again later.');
  }, [createURLParameterResult.isError, updateURLParameterResult.isError, deleteURLParameterResult.isError]);

  const onModalCancel = () => {
    setTestParamInput('');
    form.resetFields();
    onClose();
  };

  const onModalSubmit = async ({ param = '', rule }) => {
    try {
      if (viewMode === 'edit') {
        const { id } = urlParam;
        await updateURLParameter([{ id, value: param, ignore: rule === 'ignore' }]).unwrap();
      } else {
        await createURLParameter({ value: param, ignore: rule === 'ignore' }).unwrap();
      }
      onClose();
    } catch {}
  };

  const onDeleteURL = async (id: URLParameter['id']) => {
    try {
      await deleteURLParameter([id]).unwrap();
      onClose();
    } catch {}
  };

  const debouncedUrlCountQuery = useCallback(
    debounce((paramName: string) => {
      if (latestRequestRef.current) {
        latestRequestRef.current.abort();
        latestRequestRef.current = null;
      }
      if (paramName) latestRequestRef.current = getURLsCount({ wildcard: paramName, wildcard_type: 'query' });
    }, 500),
    []
  );

  async function triggerUrlCountQuery(urlParamName) {
    try {
      await form.validateFields();
      debouncedUrlCountQuery(urlParamName);
    } catch (error) {
      debouncedUrlCountQuery(null);
    }
  }

  const onTestParamInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => setTestParamInput(e.target.value);

  useEffect(() => {
    if (urlParam) {
      const activeRule = urlParam.id ? (urlParam.ignore ? 'ignore' : 'allow') : 'ignore';

      form.setFieldsValue({ rule: activeRule, param: urlParam.value });
    }
  }, [urlParam, form]);

  useEffect(() => {
    if (open) triggerUrlCountQuery(urlParamName);
  }, [urlParamName, form, open]);

  return (
    <>
      {messageContext}
      <Modal
        destroyOnClose
        closable
        open={open}
        title="Add URL Parameter"
        okText="Save"
        cancelText="Cancel"
        onCancel={onModalCancel}
        okButtonProps={{
          htmlType: 'submit',
          form: 'addUrlParam',
          loading: createURLParameterResult.isLoading || updateURLParameterResult.isLoading,
        }}
        styles={{ footer: { display: 'flex', justifyContent: 'space-between' } }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <Flex gap="small">
              {viewMode === 'edit' && (
                <Button onClick={() => onDeleteURL(urlParam.id)} loading={deleteURLParameterResult.isLoading} danger>
                  Delete from list
                </Button>
              )}
              <OkBtn />
            </Flex>
          </>
        )}
      >
        {open && (
          <Form name="addUrlParam" form={form} style={{ marginTop: 12 }} layout="vertical" onFinish={onModalSubmit}>
            <Form.Item
              style={{ marginTop: 4, marginBottom: 6 }}
              name="param"
              label="Parameter string"
              rules={[
                {
                  required: true,
                  message: DEFAULT_ERROR,
                },
                {
                  max: 50,
                  message: DEFAULT_ERROR,
                },
                {
                  pattern: ALLOWED_PARAM_NAME_PATTERN,
                  message: SPECIAL_CHARS_ERROR,
                },
              ]}
            >
              <Input id="param" placeholder="Enter a URL query parameter" autoFocus value={urlParamName} showCount />
            </Form.Item>
            <div>
              <UrlAffected
                count={urlsCountResult.data?.count}
                isLoading={urlsCountResult.isFetching}
                isEmpty={!urlParamName}
              />
            </div>
            <div style={{ marginTop: 4, marginBottom: 24 }}>
              <Typography.Paragraph type="secondary" style={{ marginBottom: '4px' }}>
                If you are using a wildcard, you can try it here
              </Typography.Paragraph>
              <Input
                addonBefore={
                  <div style={{ width: '70px' }}>
                    <MatchTag pattern={urlParamName} text={testParamInput} />
                  </div>
                }
                placeholder="test-query-param"
                value={testParamInput}
                onChange={onTestParamInputChange}
              />
            </div>

            <Divider />

            <Form.Item name="rule" label="Caching rule" initialValue="ignore">
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="ignore">Ignore when Caching</Radio>
                  <Radio value="allow">Use when Caching</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default UrlParamEditor;
