import { Flex, Form, Input } from 'antd';
import ButtonWithRole from '../../../../components/ButtonWithRole';
import USER_ROLES from '../../../auth/userRoles';

const ReferralForm = ({ isLoading, onFinish }) => {
  const [form] = Form.useForm();

  const sanitizeInput = (value) => {
    value = value.replace(/[^a-zA-Z\s']/g, '');
    value = value.replace(/\s+/g, ' ');
    return value;
  };

  const handleInputChange = (e) => {
    const sanitizedValue = sanitizeInput(e.target.value);
    form.setFieldsValue({ name: sanitizedValue });
  };

  const onFinishForm = (values) => {
    const trimmedValues = Object.keys(values).reduce((acc, key) => {
      acc[key] = values[key].trim();
      if (key === 'email') {
        acc[key] = acc[key].toLowerCase();
      }
      return acc;
    }, {});
    onFinish(trimmedValues);
  };

  return (
    <Form
      form={form}
      name="sendReferral"
      layout="vertical"
      style={{
        maxWidth: '100%',
      }}
      onFinish={onFinishForm}
      autoComplete="off"
    >
      <Form.Item label="Name" name="name" rules={[{ min: 2 }, { required: true }]}>
        <Input onChange={handleInputChange} />
      </Form.Item>
      <Form.Item
        label="Email Address"
        name="email"
        rules={[{ type: 'email', message: 'Please enter a valid email address' }, { required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <ButtonWithRole
          disabledFor={[USER_ROLES.GUEST, USER_ROLES.TEAM_MEMBER]}
          type="primary"
          htmlType="submit"
          loading={isLoading}
        >
          Send an Invite
        </ButtonWithRole>
      </Form.Item>
    </Form>
  );
};

export default ReferralForm;
