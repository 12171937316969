import { restApi } from './restApiSlice';

const queryParamsToConvertToNumber = [
  'responseTimeLow',
  'responseTimeHigh',
  'renderedTimeLow',
  'renderedTimeHigh',
  'statusCodeEq',
  'statusCodeLow',
  'statusCodeHigh',
];

export const cdnAnalyticsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    exportAnalytics: builder.mutation({
      query: (inputParams) => {
        const requestPayload = {
          url: 'cdn-analytics-export',
          method: 'POST',
          params: Object.fromEntries(
            Object.entries(inputParams)
              .filter(([_paramName, param]) => param != null && param !== '')
              .map(([paramName, param]) => [
                paramName,
                queryParamsToConvertToNumber.includes(paramName) ? Number(param) : param,
              ])
          ),
        };
        if (inputParams.query) {
          requestPayload.params.q = inputParams.query;
        }
        if (inputParams.queryCondition) {
          requestPayload.params.qCondition = inputParams.queryCondition;
        }
        return requestPayload;
      },
    }),
    exportRequestPerDomain: builder.mutation({
      query: (inputParams) => ({
        url: 'cdn-requests-per-domain-export',
        method: 'POST',
        params: Object.fromEntries(
          Object.entries(inputParams)
            .filter(([_paramName, param]) => param != null && param !== '')
            .map(([paramName, param]) => [
              paramName,
              queryParamsToConvertToNumber.includes(paramName) ? Number(param) : param,
            ])
        ),
      }),
    }),
  }),
});

export const { useExportAnalyticsMutation } = cdnAnalyticsApiSlice;
