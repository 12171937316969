import { useEffect, useState } from 'react';
import { isFreePlan } from '../../chargebee/chargebee';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';

export default function CustifyJsSnipet() {
  const prerenderUser = usePrerenderUser();
  window.ctrack = window.ctrack || (() => {});

  const identifyUser = () => {
    if (!prerenderUser?.id) {
      return;
    }
    // sending only paid users data. Data from not existing users is ignored by Custify
    if (!prerenderUser.plan || isFreePlan(prerenderUser.plan)) return;

    window.ctrack = (...args) => {
      return window._ctrack.track(...args);
    };

    window._ctrack.identify({
      userId: prerenderUser?.id, // Your Id of the user
      email: prerenderUser?.email, // Email of the user
      company_id: prerenderUser?.companyId, // Your Id of the company
    });
  };

  useEffect(() => {
    identifyUser();
  }, [prerenderUser]);

  return null;
}
