import { useSelector as _useSelector, TypedUseSelectorHook } from 'react-redux';
import { PrerenderUser } from '../../features/PrerenderUser';

export const useSelector: TypedUseSelectorHook<{
  prerenderUser: PrerenderUser;
}> = _useSelector;

export const PRERENDER_EMAIL = '@prerender.io';

export const usePrerenderUser = () => useSelector((state) => state.prerenderUser);

export const usePrerenderUserId = () => useSelector((state) => state.prerenderUser.id);
export const usePrerenderEmail = () => useSelector((state) => state.prerenderUser.email);
export const usePrerenderUserFeatures = () => useSelector((state) => state.prerenderUser.features);
export const useCharbeePlanId = () => useSelector((state) => state.prerenderUser.chargebeePlanId);
export const useHasTrackingCode = () => useSelector((state) => state.prerenderUser.trackingCodeInstalled);

export const useIsPrerenderUser = () => {
  const email = usePrerenderEmail();
  return email.endsWith(PRERENDER_EMAIL);
};

export const useUserRole = () => useSelector((state) => state.prerenderUser.role);
