// imported by /reducers/index.js
// holds road search parameters and result

import {
  DASHBOARDSTATS_SET,
  DASHBOARDSTATS_IN_PROGRESS,
} from '../actions/types';

const emptyState = {
  inProgress: false,
  avgRenderingTime: 0,
  avgPrerenderedResponseTime: 0,
  priorityQueueSize: 0,
  recacheQueueSize: 0,
  timeoutStats: {},
};

const initialState = emptyState;

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case DASHBOARDSTATS_SET:
    {
      const { avgRenderingTime, avgPrerenderedResponseTime, priorityQueueSize, recacheQueueSize, timeoutStats } = payload;
      return { ...state, avgRenderingTime, avgPrerenderedResponseTime, priorityQueueSize, recacheQueueSize, timeoutStats, inProgress: false };
    }
    case DASHBOARDSTATS_IN_PROGRESS:
      return { ...state, inProgress: payload };
    default:
      break;
  }
  return state;
};
