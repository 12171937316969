import { restApiLegacy } from '../../api/restApiSliceLegacy';

export const billingApiLegacySlice = restApiLegacy.injectEndpoints({
  tagTypes: ['Subcription', 'Plan'],
  endpoints: (builder) => ({
    getSubscription: builder.query({
      query: () => `/v2/subscription`,
      providesTags: ['Subcription'],
    }),
    getCollectNowLink: builder.query({
      query: () => `/v2/collect-now-link`,
    }),
    reactivateSubscription: builder.mutation({
      query: () => {
        return {
          url: `/v2/reactivate-subscription`,
          method: 'PATCH',
        };
      },
      invalidatesTags: ['Subcription'],
    }),
  }),
});

export const { useGetSubscriptionQuery, useLazyGetCollectNowLinkQuery, useReactivateSubscriptionMutation } =
  billingApiLegacySlice;
