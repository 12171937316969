import { isFreePlan, isEnterprisePlan, FREE_PLAN_LIMIT, RENDERS_PER_UNIT } from './chargebee';

const percents = [
  0, // cant do 0 days
  0.91,
  0.44,
  0.28,
  0.20,
  0.16,
  0.13,
  0.11,
  0.09,
  0.08,
  0.07,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.02,
  0.01,
  0.01,
  0.01,
  0.01,
  0.01,
];

const getLegacyCostsByDay = (days) => {
  if (days === 60) {
    return {
      free: 0,
      startup: 5,
      business: 11,
      growth: 20,
      premium: 35,
      enterprise: 60,
      upToOneMillion: 90,
      upToTwoMillion: 160,
      upToThreeMillion: 250,
      upToFourMillion: 350,
      upToFiveMillion: 400,
    };
  } else if (days === 90) {
    return {
      free: 0,
      startup: 4,
      business: 8,
      growth: 16,
      premium: 28,
      enterprise: 40,
      upToOneMillion: 70,
      upToTwoMillion: 110,
      upToThreeMillion: 180,
      upToFourMillion: 280,
      upToFiveMillion: 340,
    };
  } else {
    let startup = 15;
    let business = 35;
    let growth = 65;
    let premium = 120;
    let enterprise = 275;

    const pricePerRecache = 0.00015386666;

    if (days < 7) {
      for (let i = 6; i >= days; i -= 1) {
        startup += (startup * percents[i]);
        business += (business * percents[i]);
        growth += (growth * percents[i]);
        premium += (premium * percents[i]);
        enterprise += (enterprise * percents[i]);
      }
    } else if (days > 7) {
      for (let i = 8; i <= days; i += 1) {
        startup -= (startup * percents[i]);
        business -= (business * percents[i]);
        growth -= (growth * percents[i]);
        premium -= (premium * percents[i]);
        enterprise -= (enterprise * percents[i]);
      }
    }

    return {
      free: 0,
      startup: Math.ceil(startup),
      business: Math.ceil(business),
      growth: Math.ceil(growth),
      premium: Math.ceil(premium),
      enterprise: Math.ceil(enterprise),
      upToOneMillion: Math.ceil((30 / days) * 1000000 * pricePerRecache),
      upToTwoMillion: Math.ceil((30 / days) * 2000000 * pricePerRecache),
      upToThreeMillion: Math.ceil((30 / days) * 3000000 * pricePerRecache),
      upToFourMillion: Math.ceil((30 / days) * 4000000 * pricePerRecache),
      upToFiveMillion: Math.ceil((30 / days) * 5000000 * pricePerRecache),
    };
  }
};

const getLegacyCost = (cacheFreshness, numCached) => {
  const costs = getLegacyCostsByDay(cacheFreshness);
  return ((numCached > 5000000) ? 0
    : (numCached > 4000000) ? costs.upToFiveMillion
      : (numCached > 3000000) ? costs.upToFourMillion
        : (numCached > 2000000) ? costs.upToThreeMillion
          : (numCached > 1000000) ? costs.upToTwoMillion
            : (numCached > 500000) ? costs.upToOneMillion
              : (numCached > 200000) ? costs.enterprise
                : (numCached > 100000) ? costs.premium
                  : (numCached > 50000) ? costs.growth
                    : (numCached > 20000) ? costs.business
                      : (numCached > 250) ? costs.startup
                        : 0);
};

const MONTH = 30;

export const getEstimatedRendersAndCost = (prerenderUser, plan, interval) => {
  const eRenders = Math.floor(prerenderUser.numPagesCached * (MONTH / interval));
  if (prerenderUser.isMeteredBilling) {
    const extraCaches = Math.max(eRenders - plan.includedCaches, 0);
    const extraCost = ((extraCaches / RENDERS_PER_UNIT) * plan.addonCostInCents) / 100;
    return {
      renders: eRenders,
      cost: Math.floor(plan.costInCents / 100 + extraCost),
    };
  } else if (prerenderUser.customPrice || prerenderUser.customPrice === 0) {
    return {
      renders: eRenders,
      cost: prerenderUser.customPrice,
    };
  } else {
    return {
      renders: eRenders,
      cost: getLegacyCost(interval, prerenderUser.numPagesCached),
    };
  }
};

export const getSuggestedPlan = (interval, prerenderUser, currentPlan, plans) => {
  if (!prerenderUser.isMeteredBilling || isEnterprisePlan(prerenderUser.plan)
    || !currentPlan || !plans || plans.length === 0) {
    return null;
  }
  const { cost: currentCost, renders } = getEstimatedRendersAndCost(prerenderUser, currentPlan, interval);
  // do not suggest lower plan
  const higherPlans = plans
    .filter((p) => p.available || p.id === currentPlan.id)
    .filter((p) => p.index >= currentPlan.index);
  // we need to find the first plan which has at least that much amount of includedRenderedPages as the renderCounter
  const plansWithInRange = higherPlans.filter(
    (p) => p.cacheRefreshInterval[0] <= interval && interval <= p.cacheRefreshInterval[1]
  );

  // do not suggest free plan if renders greater than free limit
  const activePlans = renders >= FREE_PLAN_LIMIT ? plansWithInRange.filter((p) => !isFreePlan(p)) : plansWithInRange;

  // plan interval no longer available
  if (activePlans.length === 0) {
    return null;
  }

  const { cost: cheapestPlanCost } = getEstimatedRendersAndCost(prerenderUser, activePlans[0], interval);

  const minimumPlanCost = Math.max(cheapestPlanCost, currentCost);

  const suggestedPlanAndCost = activePlans.reduce((c, p) => {
    const { cost: planCost } = getEstimatedRendersAndCost(prerenderUser, p, interval);
    if (planCost <= c.cost) {
      return {
        cost: planCost,
        plan: p,
      };
    }
    return c;
  }, { cost: minimumPlanCost, plan: currentPlan });

  if (suggestedPlanAndCost && suggestedPlanAndCost.plan.index > currentPlan.index) {
    const { plan: suggestedPlan, cost } = suggestedPlanAndCost;
    return { suggestedPlan, savedCost: Math.max(0, currentCost - cost), cost };
  } else {
    return null;
  }
};
