const { atom } = require('recoil');
const { persistAtom } = recoilPersist();
import { recoilPersist } from 'recoil-persist';
import config from './assets/config';
import internalApi from './assets/internalApi';

export const onboardingAtom = atom({
  key: 'onboardingAtom',
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

export const userAtom = atom({
  key: 'userAtom',
  default: {},
  effects: [
    ({ setSelf }) => {
      internalApi('get', `${config.apiUrl.dataServer}/api/v2/user`, null).then(setSelf);
    },
  ],
});
