import { usePrerenderUserFeatures } from './usePrerenderUser';

export enum FeatureFlag {
  DomainsDetails = 'domains-details',
  SeoReport = 'seo-report',
  DomainSchedulerSettings = 'domain-scheduler-settings',
  DisableClearCache = 'disable-clear-cache',
  Oversight = 'oversight',
  AccountDetails = 'account-details',
  CustomDeal = 'custom-deal',
}

function useFeatureFlag(requiredFeatureFlags: FeatureFlag[]): boolean {
  const features = usePrerenderUserFeatures();

  for (const requiredFeatureFlag of requiredFeatureFlags) {
    if (!features.includes(requiredFeatureFlag)) {
      return false;
    }
  }

  return true;
}

export default useFeatureFlag;
