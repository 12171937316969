// imported by /reducers/index.js

import { matchWith } from '../assets/lib';

import {
  CACHINGACTIVITY_SET,
  CACHINGACTIVITY_IN_PROGRESS,
  CACHINGACTIVITY_RESET,
  CACHINGACTIVITY_SET_PARAMS,
} from '../actions/types';

const emptyState = {
  inProgress: false,
  params: {
    page: 0,
    pageSize: 200,
    q: '',
    qCondition: 'like',
    statusCodeEq: null,
    statusCodeLow: null,
    statusCodeHigh: null,
    responseTimeLow: null,
    responseTimeHigh: null,
    timedout: null,
    renderedTimeLow: null,
    renderedTimeHigh: null,
    // timePeriod: null
  },
  stats: {
    total: 0,
    pages: 0,
    amt: 0,
  },
  requests: [],
};

const initialState = {
  dashboard: emptyState,
  all: emptyState,
};

export default function cachingActivityReducer(state = initialState, action) {
  const { payload } = action;
  const bucket = payload && payload.stateBucket ? payload.stateBucket : 'dashboard';
  switch (action.type) {
    case CACHINGACTIVITY_SET: {
      const dl = payload.data.length;
      const amtpp = payload.params.pageSize || state[bucket].params.pageSize;
      const total = dl === amtpp ? dl + 1 : dl; // assume we have more than one page
      const newState = {
        inProgress: false,
        params: matchWith(state[bucket].params, payload.params),
        stats: { total, amt: dl, pages: dl > 0 ? parseInt(total / amtpp, 10) + payload.params.page : 0 },
        requests: payload.data,
      };
      return {
        ...state,
        dashboard: bucket === 'dashboard' ? newState : state.dasbboard,
        all: bucket === 'all' ? newState : state.all,
      };
    }
    case CACHINGACTIVITY_SET_PARAMS:
      return {
        ...state,
        dashboard: bucket === 'dashboard' ? { ...state.dashboard, params: payload.params } : state.dashboard,
        all: bucket === 'all' ? { ...state.all, params: payload.params } : state.all,
      };

    case CACHINGACTIVITY_IN_PROGRESS:
      return {
        ...state,
        dashboard: bucket === 'dashboard' ? { ...state.dashboard, inProgress: payload.value } : state.dashboard,
        all: bucket === 'all' ? { ...state.all, inProgress: payload.value } : state.all,
      };

    case CACHINGACTIVITY_RESET:
      return initialState;
    default:
  }
  return state;
}
