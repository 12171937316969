const KNOWN_TRACKER_QUERY_PARAMS = [
  'fbclid',
  'gclid',
  'gclsrc',
  'utm_*',
  '_ga',
  'mc_cid',
  'mc_eid',
  '_bta_tid',
  '_bta_c',
  'trk_*',
  'gdfms',
  'gdftrk',
  'gdffi',
  '_ke',
  'sb_referer_host',
  'mkwid',
  'pcrid',
  'ef_id',
  's_kwcid',
  'msclkid',
  'dm_i',
  'epik',
  'pk_*',
  'piwik_*',
  'mtm_*',
  'matomo_*',
  'hsa_*',
  '_branch_match_id',
  'mkevt',
  'mkcid',
  'mkrid',
  'campid',
  'toolid',
  'customid',
  'igshid',
  'si',
  'sms_*',
];

module.exports = {
  KNOWN_TRACKER_QUERY_PARAMS,
  EXACT_TRACKER_QUERY_PARAMS: new Set(KNOWN_TRACKER_QUERY_PARAMS.filter((param) => !param.endsWith('*'))),
  PREFIX_TRACKER_QUERY_PARAMS: KNOWN_TRACKER_QUERY_PARAMS.filter((param) => param.endsWith('*')).map((param) =>
    param.slice(0, -1)
  ),
};
