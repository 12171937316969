import React from 'react';
import { Card, Badge, Button, Typography, Flex } from 'antd';

const CardWithBadge = ({ children, badgeText }) =>
  badgeText ? (
    <Badge.Ribbon text={badgeText}>
      <Card style={{ border: '2px solid #2da01d', backgroundColor: '#FAFFFC' }} styles={{ body: { padding: 16 } }}>
        {children}
      </Card>
    </Badge.Ribbon>
  ) : (
    <Card styles={{ body: { padding: 16 } }}>{children}</Card>
  );

const CardContent = ({ icon, title, current, buttonText, disabled, onClick }) => (
  <Flex vertical align="center" gap={8}>
    <i className={`fa-3x fe ${icon}`}></i>
    <Typography.Text strong>{title}</Typography.Text>
    <Button
      type={current ? 'text' : 'primary'}
      disabled={disabled || current}
      onClick={onClick}
      style={{ width: '100%' }}
    >
      {buttonText}
    </Button>
  </Flex>
);

const RenderingLocationCard = ({ current, disabled, icon, title, buttonText, onClick }) => (
  <CardWithBadge badgeText={current ? 'Current' : null}>
    <CardContent
      icon={icon}
      title={title}
      current={current}
      buttonText={buttonText}
      disabled={disabled}
      onClick={onClick}
    />
  </CardWithBadge>
);

export default RenderingLocationCard;
