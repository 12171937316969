import { legacyAPIRequest } from '../api/restApiSliceLegacy';
import { restApi } from '../api/restApiSlice';

import CachedPage, { AdaptiveType } from './CacheManagerTab/CachedPage';
import { CachedPagesQueryParameters } from './CacheManagerTab/QueryParameters';
import URLParameter from './URLParametersTab/URLParameter';

export type CachedPagesPagination = {
  page: number;
  pageSize: number;
};

type CachedPageQuery = CachedPagesQueryParameters & CachedPagesPagination;

type AddURLsBody = {
  url: string;
  adaptiveType: AdaptiveType;
}[];

type AddURLsResponse = {
  data: {
    result: {
      item: {
        url: string;
        adaptiveType: AdaptiveType;
      };

      error?: {
        message: string;
      };
    }[];
  };
};

export enum CachedCsvExportStatus {
  QUEUED = 'queued',
  NOT_QUEUED = 'not queued',
  ALREADY_EXISTS = 'already exists',
  LIMITED = 'limited',
}

type ExportCsvResponse = {
  status: CachedCsvExportStatus;
};

const CacheManagerApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    // Cache Manager TAB

    getCachedPagesList: builder.query<CachedPage[], CachedPageQuery>({
      queryFn: async (params) => {
        try {
          const parsedParams = Object.entries(params).reduce((acc, [key, value]) => {
            if (Array.isArray(value)) {
              acc[key] = value.join(',');
            } else {
              acc[key] = value;
            }
            return acc;
          }, {});

          const searchParams = new URLSearchParams({
            ...parsedParams,
            page: params.page.toString(),
            pageSize: params.pageSize.toString(),
          });
          const rawResult = await legacyAPIRequest({ url: `cached-pages?${searchParams}`, method: 'GET' });
          if (!rawResult.ok) throw new Error('Failed to fetch cached pages');
          const result = await rawResult.json();
          return { data: result };
        } catch (error) {
          return { error: error.message };
        }
      },
      providesTags: ['CachedPages'],
    }),

    addURLs: builder.mutation<AddURLsResponse, AddURLsBody>({
      query: (urls) => {
        return { url: '/urls', method: 'POST', body: { urls } };
      },
      invalidatesTags: ['CachedPages', 'URL'],
    }),

    updateCacheFreshness: builder.mutation<void, { value: number }>({
      queryFn: async (body) => {
        try {
          const result = await legacyAPIRequest({ url: `set-cache-freshness`, method: 'POST', body });
          if (result.ok) return { data: null };
          throw new Error('Failed to update cache freshness');
        } catch (error) {
          return { error: error.message };
        }
      },
    }),

    exportURLsAsCSV: builder.mutation<ExportCsvResponse, CachedPagesQueryParameters>({
      query: (params) => ({
        url: 'cached-pages-csv-export',
        method: 'POST',
        params,
      }),
    }),

    exportURLsAsSitemap: builder.mutation<string, void>({
      queryFn: async () => {
        try {
          const rawResult = await legacyAPIRequest({ url: 'v2/generate-sitemap', method: 'GET' });
          if (!rawResult.ok) throw new Error('Failed to generate sitemap');

          const result = await rawResult.text();

          return { data: result };
        } catch (error) {
          return { error: error.message };
        }
      },
    }),

    recacheURLs: builder.mutation<void, { ids: string[] }>({
      queryFn: async (body) => {
        try {
          const result = await legacyAPIRequest({ url: 'v2/cache-page', method: 'POST', body });
          if (result.ok) return { data: null };
          throw new Error('Failed to recache URLs');
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['CachedPages'],
    }),

    deleteURLs: builder.mutation<void, { ids: string[] }>({
      queryFn: async (body) => {
        try {
          const result = await legacyAPIRequest({ url: 'remove-cached-urls', method: 'DELETE', body });
          if (result.ok) return { data: null };
          throw new Error('Failed to delete URLs');
        } catch (error) {
          return { error: error.message };
        }
      },
      invalidatesTags: ['CachedPages'],
    }),

    // URL Parameters TAB

    getURLParameters: builder.query<URLParameter[], void>({
      queryFn: async () => {
        const result = await legacyAPIRequest({ url: 'url-parameters', method: 'GET' });
        const data = await result.json();
        if (result.ok) return { data };
        throw new Error('Failed to fetch URL parameters');
      },
      providesTags: ['URLParameters'],
    }),

    createURLParameter: builder.mutation<void, Pick<URLParameter, 'value' | 'ignore'>>({
      queryFn: async (body) => {
        const result = await legacyAPIRequest({ url: 'url-parameters', method: 'PUT', body });
        if (result.ok) return { data: null };
        throw new Error('Failed to create URL parameter');
      },
      invalidatesTags: ['URLParameters'],
    }),

    updateURLParameter: builder.mutation<void, Pick<URLParameter, 'id' | 'value' | 'ignore'>[]>({
      queryFn: async (urlParams) => {
        const result = await legacyAPIRequest({ url: 'v2/url-parameters', method: 'PUT', body: { urlParams } });
        if (result.ok) return { data: null };
        throw new Error('Failed to update URL parameter');
      },
      invalidatesTags: ['URLParameters'],
    }),

    deleteURLParameter: builder.mutation<void, URLParameter['id'][]>({
      queryFn: async (ids) => {
        const result = await legacyAPIRequest({ url: 'v2/url-parameters', method: 'DELETE', body: { ids } });
        if (result.ok) return { data: null };
        throw new Error('Failed to delete URL parameter');
      },
      invalidatesTags: ['URLParameters'],
    }),
  }),
});

export const {
  // Cache Manager TAB
  useGetCachedPagesListQuery,
  useAddURLsMutation,
  useUpdateCacheFreshnessMutation,
  useExportURLsAsCSVMutation,
  useExportURLsAsSitemapMutation,
  useRecacheURLsMutation,
  useDeleteURLsMutation,

  // URL Parameters TAB
  useGetURLParametersQuery,
  useCreateURLParameterMutation,
  useUpdateURLParameterMutation,
  useDeleteURLParameterMutation,
} = CacheManagerApi;
