import { useState } from 'react';
import { Button, Checkbox, Input } from 'antd';
import './dropdownFilters.css';

export const ResponseDropdownFilter = ({ close, confirm, selectedKeys, setSelectedKeys, clearFilters }) => {
  const [responseTime, setResponseTime] = useState(selectedKeys.length ? selectedKeys[0] : null);
  const [disabled, setDisabled] = useState(true);

  const onSubmit = () => {
    setSelectedKeys(responseTime == null ? [] : [responseTime]);
    confirm();
  };

  const onReset = () => {
    setResponseTime(null);
    clearFilters();
    setDisabled(true);
  };

  return (
    <div className="dropdownFilters-layout">
      Response Time in Sec.
      <div className="dropdownFilters-container">
        Between
        <Input
          className="dropdownFilters-input"
          placeholder="e.g.1"
          value={responseTime?.responseTimeLow}
          onChange={(e) => {
            setResponseTime({ ...responseTime, responseTimeLow: e.target.value });
            setDisabled(false);
          }}
        />
        and
        <Input
          className="dropdownFilters-input"
          placeholder="e.g.120"
          value={responseTime?.responseTimeHigh}
          onChange={(e) => {
            setResponseTime({ ...responseTime, responseTimeHigh: e.target.value });
            setDisabled(false);
          }}
        />
      </div>
      <Checkbox
        className="dropdownFilters-checkbox"
        checked={responseTime?.timedout}
        onChange={(e) => {
          setResponseTime({ ...responseTime, timedout: e.target.checked });
          setDisabled(false);
        }}
      >
        Timed out
      </Checkbox>
      <div className="dropdownFilters-buttons ">
        <Button size="small" type="link" onClick={onReset} disabled={disabled}>
          Reset
        </Button>
        <Button type="primary" size="small" onClick={onSubmit}>
          OK
        </Button>
      </div>
    </div>
  );
};
