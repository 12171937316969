import { Button, Checkbox, Form, Input } from 'antd';
import '../RegistrationPage.css';
import { useEffect } from 'react';
import { useEvent } from '../../events/hooks/useEvent';

const referralSourcesOptions = [
  { value: 'Web Search', label: 'Web Search (e.g., Google)' },
  { value: 'Social Media', label: 'Social Media (e.g., Linkedin, Twitter)' },
  { value: 'Event or Conference', label: 'Event or Conference (e.g., BrightonSEO)' },
  {
    value: 'News or Industry Blogs',
    label: 'News or Industry Blogs',
  },
  {
    value: 'Email Newsletter',
    label: 'Email Newsletter',
  },
  { value: 'Recommendation from a Friend/Colleague', label: 'Recommendation from a Friend/Colleague' },
  {
    value: 'Other',
    label: 'Other (please specify)',
  },
];

const mapFieldsData = (data) => {
  return {
    referralSources: [...data.referralSources, data.otherSource].filter((el) => el !== 'Other' && el !== undefined),
  };
};

const mapValuesToFields = (values, options) => {
  const result = { referralSources: [], otherSource: '' };
  values?.referralSources?.forEach((source) => {
    if (options.find((option) => option.value === source)) {
      result.referralSources.push(source);
    } else {
      result.otherSource = source;
      result.referralSources.push('Other');
    }
  });
  return result;
};

export const ReferralSourceForm = ({ onFinish, onGoBack, values }) => {
  const [form] = Form.useForm();
  const referralSourceWatch = Form.useWatch('referralSources', form);
  const { track, updateUserProfile } = useEvent();

  useEffect(() => {
    if (referralSourceWatch && !referralSourceWatch.includes('Other')) {
      form.setFieldValue('otherSource', undefined);
    }
  }, [referralSourceWatch]);

  const onFinishForm = (data) => {
    const mappedData = mapFieldsData(data);
    onFinish(mappedData);
    track('Signup Form: Marketing Source Entered', { signup_form_utmsource: mappedData.referralSources });
    updateUserProfile({ signup_form_utmsource: mappedData.referralSources });
  };

  return (
    <>
      <Form
        onFinish={(data) => {
          onFinishForm(data);
        }}
        layout="vertical"
        form={form}
        initialValues={mapValuesToFields(values, referralSourcesOptions)}
      >
        <h1 className="text-center my-5">Where did you hear about us?</h1>
        <Form.Item name={'referralSources'} rules={[{ required: true, message: 'Please select at least one' }]}>
          <Checkbox.Group options={referralSourcesOptions} />
        </Form.Item>
        <Form.Item name={'otherSource'} noStyle={referralSourceWatch && !referralSourceWatch.includes('Other')}>
          <Input type={referralSourceWatch && referralSourceWatch.includes('Other') ? 'text' : 'hidden'} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Continue
        </Button>
        <Button type="link" onClick={onGoBack}>
          Go back
        </Button>
      </Form>
    </>
  );
};
