import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Tag, Flex, Typography, Button } from 'antd';
import { DollarOutlined } from '@ant-design/icons';
import { isEnterprise } from '@prerender/billing-shared';
import dayjs from 'dayjs';

import { HelpSection } from '../../features/CustomerSupport';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useEvent } from '../../features/events/hooks/useEvent';
import { AppUserRoles } from '../../features/teams';
import AccountDropdown from './AccountDropdown';
import { GetStartedChecker } from '../../features/GetStartedChecker';

function UserInfos({ user }) {
  const hasName = user.firstName || user.lastName;
  const hasCompanyName = !!user.companyName && user.companyName !== user.email;

  if (hasName && hasCompanyName) {
    return (
      <Flex gap="small">
        <Typography.Text>
          {user.firstName} {user.lastName} ({user.companyName})
        </Typography.Text>{' '}
        <Tag color="success">{AppUserRoles[user.role]}</Tag>
      </Flex>
    );
  } else if (hasName && !hasCompanyName) {
    return (
      <Flex gap="small">
        <Typography.Text>
          {user.firstName} {user.lastName}
        </Typography.Text>{' '}
        <Tag color="success">{AppUserRoles[user.role]}</Tag>
      </Flex>
    );
  } else if (!hasName && hasCompanyName) {
    return (
      <Flex gap="small">
        <Typography.Text>({user.companyName})</Typography.Text> <Tag color="success">{AppUserRoles[user.role]}</Tag>
      </Flex>
    );
  }

  return undefined;
}

const isSignupLessThanTwoWeeks = (signupDate) => {
  const twoWeeksAgo = dayjs().subtract(14, 'days');
  return dayjs(signupDate).isAfter(twoWeeksAgo);
};

const NavigationTopbar = () => {
  const user = usePrerenderUser();
  const eventsTrackingData = {
    userId: user.id,
    userEmail: user.email,
    companyId: user.companyId,
    companyName: user.companyName,
  };
  const navigate = useNavigate();
  const { track } = useEvent();
  const isEnterpriseUser = isEnterprise(user?.chargebeePlanId);

  const onReferralButtonClick = () => {
    track('Refer and Earn button clicked', { subscription_plan: user.chargebeePlanId });
    navigate('/billing/refer-and-earn');
  };

  const showGetStartedChecker =
    Object.values(user?.gettingStartedSteps).some((value) => value === false) &&
    isSignupLessThanTwoWeeks(user.signupDate);

  const hasLeftElement = showGetStartedChecker || !isEnterpriseUser;

  return (
    <Flex align="center" justify={hasLeftElement ? 'space-between' : 'flex-end'} flex={1}>
      <Flex>
        {showGetStartedChecker ? (
          <GetStartedChecker />
        ) : (
          !isEnterpriseUser && (
            <Button
              onClick={onReferralButtonClick}
              type="primary"
              icon={<DollarOutlined style={{ fontSize: 18 }} />}
              size="large"
            >
              Refer & Earn
            </Button>
          )
        )}
      </Flex>
      <Flex align="center" gap="middle">
        <HelpSection contactCSMVisible={isEnterprise(user.chargebeePlanId)} eventsTrackingData={eventsTrackingData} />
        <Flex vertical justify="center">
          <Typography.Text>{user.email}</Typography.Text>
          <UserInfos user={user} />
        </Flex>
        <AccountDropdown unreadNotificationsCount={user.unreadNotifications} />
      </Flex>
    </Flex>
  );
};

export default NavigationTopbar;
