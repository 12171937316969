import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isFreePlan } from '../../chargebee/chargebee';
import config from '../../assets/config';
import { getAccessToken } from '../auth';

const DEFAULT_FORM_ID = 5311267927697;
const FREE_USERS_ZENDESK_FORM_ID = 6468710722449;
const EMAIL_FIELD_ID = 5311779281937;
const PLAN_FIELD_ID = 5311954585105;
const NAME_FIELD_ID = 5311710421649;

const planMapping = {
  Basic: 'basic_',
  Professional: 'professional_',
  Startup: 'startup_',
  Business: 'business_',
  Enterprise: 'enterprise_',
  'Prerender Base': 'other_',
};

export const openWebWidget = () => {
  window.zE && window.zE('webWidget', 'show');
  window.zE && window.zE('webWidget', 'open');
};

export const closeWebWidget = () => {
  window.zE && window.zE('webWidget', 'close');
};

export const hideWebWidget = () => {
  window.zE && window.zE('webWidget', 'hide');
};

export const clearWebWidget = () => {
  window.zE && window.zE('webWidget', 'clear');
};

export const resetWebWidget = () => {
  window.zE && window.zE('webWidget', 'reset');
};

const fetchZendeskToken = async (accessToken, callback) => {
  try {
    const response = await fetch(`${config.apiUrl.restServer}/v3/zendesk/token`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await response.json();
    callback(json.access_token);
  } catch (err) {
    console.error('Failed fetching Zendesk Auth token', err);
  }
};

export const useZendesk = (shouldLoadSDK = true) => {
  const [zendeskScriptLoaded, setZendeskScriptLoaded] = useState(false);
  const prerenderUser = useSelector((state) => state.prerenderUser);
  const isLoggedIn = useSelector((state) => state.keycloak?.loggedIn);

  useEffect(() => {
    if (isLoggedIn && shouldLoadSDK) {
      const zendeskScript = document.createElement('script');

      zendeskScript.src = 'https://static.zdassets.com/ekr/snippet.js?key=191a5952-f3cc-4d91-a942-150c09c561f2';
      zendeskScript.id = 'ze-snippet';
      zendeskScript.async = true;

      window.zESettings = {
        webWidget: {
          authenticate: {
            chat: {
              jwtFn(callback) {
                fetchZendeskToken(getAccessToken(), callback);
              },
            },
          },
        },
      };

      const onZendeskScriptLoaded = () => {
        if (window.zE && !zendeskScriptLoaded) {
          hideWebWidget();
        }
        setZendeskScriptLoaded(true);
      };

      zendeskScript.addEventListener('load', onZendeskScriptLoaded);
      document.body.appendChild(zendeskScript);

      return () => {
        if (zendeskScriptLoaded) {
          closeWebWidget();
          resetWebWidget();
          setZendeskScriptLoaded(false);
        }

        zendeskScript.removeEventListener('load', onZendeskScriptLoaded);
        document.body.removeChild(zendeskScript);
      };
    }
  }, [isLoggedIn, shouldLoadSDK]);

  useEffect(() => {
    if (zendeskScriptLoaded) {
      clearWebWidget();

      const fields = [];
      let zendeskFormId = DEFAULT_FORM_ID;
      if (prerenderUser.hasSession) {
        if (prerenderUser?.email) {
          fields.push(
            { id: 'email', prefill: { '*': prerenderUser.email } },
            { id: EMAIL_FIELD_ID, prefill: { '*': prerenderUser.email } }
          );
        }

        if (prerenderUser?.plan) {
          fields.push({ id: PLAN_FIELD_ID, prefill: { '*': planMapping[prerenderUser.plan.name] || 'other_' } });
          if (prerenderUser.isMeteredBilling && isFreePlan(prerenderUser.plan)) {
            zendeskFormId = FREE_USERS_ZENDESK_FORM_ID;
          }
        }

        if (prerenderUser?.firstName || prerenderUser?.lastName) {
          const firstName = prerenderUser.firstName ? `${prerenderUser.firstName} ` : '';
          const lastName = prerenderUser.lastName ? `${prerenderUser.lastName}` : '';
          const name = firstName + lastName;
          fields.push({ id: 'name', prefill: { '*': name } }, { id: NAME_FIELD_ID, prefill: { '*': name } });
        }
      }

      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            ticketForms: [{ id: zendeskFormId }],
            fields,
          },
          zIndex: 9999999999,
          position: { horizontal: 'right', vertical: 'bottom' },
        },
      });

      window.zE('webWidget:on', 'close', hideWebWidget);
    }
  }, [
    prerenderUser.hasSession,
    prerenderUser.email,
    prerenderUser.firstName,
    prerenderUser.lastName,
    prerenderUser.plan,
    prerenderUser.plan.name,
    prerenderUser.isMeteredBilling,
    zendeskScriptLoaded,
  ]);

  return null;
};
