import {
  KeyOutlined,
  HourglassOutlined,
  DesktopOutlined,
  MobileOutlined,
  FieldTimeOutlined,
  DollarCircleOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';

import { formatDays } from '../../../assets/dateFormatter';

const logReadableConfig = {
  environmentName: { name: 'Environment Name' },
  apiToken: { name: 'Api Token', CustomIconComponent: KeyOutlined },
  cacheFreshness: {
    name: 'Cache Freshness',
    CustomIconComponent: HourglassOutlined,
    formatter: formatDays,
  },
  automaticRecachingPaused: { name: 'Automatic Recaching Paused' },
  ignoreAllQueryParams: { name: 'Ignore All Query Params' },
  mobileOptimizedRendering: { name: 'Mobile Optimized Rendering' },
  onlyServerCacheHits: { name: 'Only Server Cache Hits' },
  queryParamsWhitelist: { name: 'Query Parameters Whitelist' },
  customRecacheDelay: { name: 'Custom Recache Delay', unit: 'ms', units: 'ms' },
  extractHiddenCss: { name: 'Extract Hidden Css' },
  enforceHttpsProtocol: { name: 'Enforce Https Protocol' },
  desktopViewport: {
    name: 'Desktop Viewport',
    unit: 'px',
    units: 'px',
    CustomIconComponent: DesktopOutlined,
  },
  mobileViewport: {
    name: 'Mobile Viewport',
    unit: 'px',
    units: 'px',
    CustomIconComponent: MobileOutlined,
  },
  imageLoading: { name: 'Image Loading' },
  renderLocation: { name: 'Render Location' },
  skipCustomElementsForcePolyfill: { name: 'Skip Custom Elements Force Polyfill' },
  removeStyleTags: { name: 'Remove Style Tags' },
  removeUrlHash: { name: 'Remove Hash From URL' },
  enableServiceWorker: { name: 'Service Worker' },
  renderingTimeout: {
    name: 'Rendering Timeout',
    unit: 'second',
    units: 'seconds',
    CustomIconComponent: FieldTimeOutlined,
  },
  parseShadowDom: { name: 'Parse Shadow DOM' },
  retryTimedOutRenders: { name: 'Retry Timed Out Renders' },
  retryDirtyRenders: { name: 'Retry Dirty Renders' },
  desktopUserAgent: { name: 'Desktop User-Agent', CustomIconComponent: DesktopOutlined },
  mobileUserAgent: { name: 'Mobile User-Agent', CustomIconComponent: MobileOutlined },
  prerenderCssLocation: { name: 'Prerender CSS Location' },
  preCheckStatus: { name: 'Check HTTP status before render' },
  scrollEnabled: { name: 'Scroll during render' },
  onlyServeCacheHits: { name: 'Only serve cache hits' },
  chargebeeCouponId: {
    name: 'Coupon Event',
    type: 'coupon',
    CustomIconComponent: DollarCircleOutlined,
    formatter: (value, log) => {
      if (log.values.message === 'Coupon Applied') return `applied a coupon, code=${value}`;
      if (log.values.message === 'Coupon Removed') return `removed a coupon, code=${value}`;
      return value;
    },
  },
  referralDetails: {
    name: 'Referral Event',
    type: 'referral',
    CustomIconComponent: UsergroupAddOutlined,
    formatter: (value, log) => {
      if (log.values.message === 'Email referred') return `referred ${value.inviteeName}<${value.inviteeEmail}>`;
      if (log.values.message === 'Credit earned')
        return `earned credits by referencing ${value.inviteeName}<${value.inviteeEmail}>`;
      return value;
    },
  },
  minimumBodyLength: {
    name: 'Minimum <body> length',
    unit: 'character',
    units: 'characters',
  },
};

export default logReadableConfig;
