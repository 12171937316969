import { useState } from 'react';
import { Button, Input } from 'antd';
import './dropdownFilters.css';

export const RenderedTimeDropdown = ({ close, confirm, selectedKeys, setSelectedKeys, clearFilters, label }) => {
  const [renderedTime, setRenderedTime] = useState(selectedKeys.length ? selectedKeys[0] : null);
  const [disabled, setDisabled] = useState(true);

  const onSubmit = () => {
    setSelectedKeys(renderedTime == null ? [] : [renderedTime]);
    confirm();
  };

  const onReset = () => {
    setRenderedTime(null);
    clearFilters();
    setDisabled(true);
  };

  return (
    <div className="dropdownFilters-layout">
      {label}
      <div className="dropdownFilters-container">
        Between
        <Input
          className="dropdownFilters-input"
          placeholder="e.g.0"
          value={renderedTime?.renderedTimeLow}
          onChange={(e) => {
            setRenderedTime({ ...renderedTime, renderedTimeLow: e.target.value });
            setDisabled(false);
          }}
        />
        and
        <Input
          className="dropdownFilters-input"
          placeholder="e.g.24"
          value={renderedTime?.renderedTimeHigh}
          onChange={(e) => {
            setRenderedTime({ ...renderedTime, renderedTimeHigh: e.target.value });
            setDisabled(false);
          }}
        />
        ago
      </div>
      <div className="dropdownFilters-buttons ">
        <Button size="small" type="link" onClick={onReset} disabled={disabled}>
          Reset
        </Button>
        <Button type="primary" size="small" onClick={onSubmit}>
          OK
        </Button>
      </div>
    </div>
  );
};
