import { restApi } from './restApiSlice';

export const cachedPagesApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    exportCsv: builder.mutation({
      query: ({ sort, query, queryCondition, filter }) => {
        const params = {
          sortDirection: sort?.asc ? 'ASC' : 'DESC',
        };

        if (sort.name) params.sort = sort.name;
        if (query) params.q = query;
        if (queryCondition) params.qCondition = queryCondition;

        if (filter) {
          if (filter.seo_score) params.seo_score = filter.seo_score;
          if (filter.cache_state) params.cache_state = filter.cache_state;
          if (filter.source) params.source = filter.source;
          if (filter.adaptive_type) params.adaptive_type = filter.adaptive_type;
        }

        return {
          url: 'cached-pages-csv-export',
          method: 'POST',
          params,
        };
      },
    }),
  }),
});

export const { useExportCsvMutation } = cachedPagesApi;
