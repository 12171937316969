import { AdaptiveType, CacheState, Source, SeoScoreStatus } from './CachedPage';

export enum QueryParamNames {
  Sort = 'sort',
  SortDirection = 'sortDirection',
  Q = 'q',
  QCondition = 'qCondition',
  Domain = 'domain',
  Notnull = 'notnull',
  Timedout = 'timedout',
  LastDeliveryAt = 'last_delivery_at',
  CacheState = 'cache_state',
  Source = 'source',
  AdaptiveType = 'adaptive_type',
  SeoScoreStatus = 'seo_score_status',
}

type Sort =
  | 'last_refresh'
  | 'seo_score'
  | 'seo_score_status'
  | 'cache_state'
  | 'created_at'
  | 'source'
  | 'last_delivery_at';

export type CachedPagesQueryParameters = Partial<{
  [QueryParamNames.Sort]: Sort;
  [QueryParamNames.SortDirection]: 'DESC' | 'ASC';
  [QueryParamNames.Q]: string;
  [QueryParamNames.QCondition]: string;
  [QueryParamNames.Domain]: string;
  [QueryParamNames.Notnull]: string;
  [QueryParamNames.Timedout]: 'true' | 'false';
  [QueryParamNames.LastDeliveryAt]: string;
  [QueryParamNames.CacheState]: CacheState[];
  [QueryParamNames.Source]: Source[];
  [QueryParamNames.AdaptiveType]: AdaptiveType[];
  [QueryParamNames.SeoScoreStatus]: SeoScoreStatus[];
}>;

export function isCachedPagesParam(name: string): name is QueryParamNames {
  return Object.values(QueryParamNames).includes(name as QueryParamNames);
}

export function buildCachedPagesQueryParameters(data: Record<string, string>): CachedPagesQueryParameters {
  return Object.entries(data).reduce((acc, [name, value]) => {
    if (!isCachedPagesParam(name)) return acc;

    const paramName = name as QueryParamNames;

    if (paramName === QueryParamNames.AdaptiveType) {
      acc[paramName] = value.split(',') as AdaptiveType[];
      return acc;
    }

    if (paramName === QueryParamNames.CacheState) {
      acc[paramName] = value.split(',') as CacheState[];
      return acc;
    }

    if (paramName === QueryParamNames.Source) {
      acc[paramName] = value.split(',') as Source[];
      return acc;
    }

    if (paramName === QueryParamNames.SortDirection) {
      acc[paramName] = value as 'DESC' | 'ASC';
      return acc;
    }

    if (paramName === QueryParamNames.Timedout) {
      acc[paramName] = value as 'true' | 'false';
      return acc;
    }

    if (paramName === QueryParamNames.Sort) {
      acc[paramName] = value as Sort;
      return acc;
    }

    if (paramName === QueryParamNames.SeoScoreStatus) {
      acc[paramName] = value.split(',') as SeoScoreStatus[];
      return acc;
    }

    acc[paramName] = value;

    return acc;
  }, {} as CachedPagesQueryParameters);
}
