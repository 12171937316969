import { restApi } from '../../api/restApiSlice';

const domainStatisticsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomainStatistics: builder.query({
      query: (params) => ({ url: `/domain-statistics`, method: 'GET', params }),
      providesTags: ['domainStatistics'],
    }),
    exportDomainsCsv: builder.mutation({
      query: (params) => ({ url: `/domain-statistics/export`, method: 'POST', params }),
    }),
    deleteDomain: builder.mutation({
      query: (id) => ({ url: `/domain-statistics/${id}`, method: 'DELETE' }),
      invalidatesTags: ['domainStatistics'],
    }),
    getDomains: builder.query({
      query: (query) => ({ url: `/domains`, method: 'GET', params: { query } }),
      providesTags: ['domains'],
    }),
  }),
});

export const {
  useGetDomainStatisticsQuery,
  useLazyGetDomainStatisticsQuery,
  useExportDomainsCsvMutation,
  useDeleteDomainMutation,
  useGetDomainsQuery,
} = domainStatisticsApiSlice;

const domainDetailsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getDomainDetails: builder.query({
      query: (domain) => ({ url: `/domains/${domain}/config`, method: 'GET' }),
      providesTags: ['domainDetails'],
    }),
    getExtraStats: builder.query({
      query: (params) => ({ url: '/analytics/stats', method: 'GET', params }),
      providesTags: ['domainDetails'],
    }),
    updateDomainDetails: builder.mutation({
      query: ({ domain, ...body }) => ({ url: `/domains/${domain}/config`, method: 'PATCH', body }),
      invalidatesTags: ['domainDetails'],
    }),
  }),
});

export const { useGetDomainDetailsQuery, useUpdateDomainDetailsMutation, useGetExtraStatsQuery } =
  domainDetailsApiSlice;

const accountDetailsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccountDetails: builder.query({
      query: () => ({ url: `/domains/company/config`, method: 'GET' }),
      providesTags: ['accountDetails'],
    }),
  }),
});

export const { useGetAccountDetailsQuery } = accountDetailsApiSlice;
