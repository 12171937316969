// Define action creators that dispatch actions:
import config from '../assets/config';
import internalApi from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';
import { rendersApiSlice } from '../features/api/rendersApiSlice';
import { setLastErrorCode, clearLastErrorCode } from './pageActions';

import { CACHINGACTIVITY_IN_PROGRESS, CACHINGACTIVITY_SET, CACHINGACTIVITY_SET_PARAMS } from './types';

const cachingActivitySearch =
  (stateBucket = 'all', params) =>
  async (dispatch) => {
    try {
      dispatch({ type: CACHINGACTIVITY_IN_PROGRESS, payload: { stateBucket, value: true } });
      dispatch({ type: CACHINGACTIVITY_SET_PARAMS, payload: { stateBucket, params } });
      const { data } = await dispatch(rendersApiSlice.endpoints.recacheStats.initiate(params));
      dispatch({ type: CACHINGACTIVITY_SET, payload: { stateBucket, data, params } });
      dispatch(clearLastErrorCode());
    } catch (error) {
      console.log(error);
      dispatch({ type: CACHINGACTIVITY_IN_PROGRESS, payload: { stateBucket, value: false } });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };

export default cachingActivitySearch;
