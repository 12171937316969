import { GET_PLANS_IN_PROGRESS, GET_PLANS_SUCCESS, CURRENT_PLAN_CHANGED } from '../actions/types';

const initialCheckBillingResult = { error: null, plan: {} };

const initialState = {
  inProgress: false,
  plans: [],
  currentPlan: {},
  checkBillingResult: initialCheckBillingResult,
};

export default function pricingPageReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case GET_PLANS_IN_PROGRESS:
      return { ...state, inProgress: payload };
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        plans: payload,
      };
    case CURRENT_PLAN_CHANGED:
      return {
        ...state,
        currentPlan: payload,
      };
    default:
      return state;
  }
}
