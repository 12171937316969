import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { restApi } from './features/api/restApiSlice';
import { restApiLegacy } from './features/api/restApiSliceLegacy';

import reducers from './reducers';

const store = configureStore({
  reducer: {
    ...reducers,
    [restApi.reducerPath]: restApi.reducer,
    [restApiLegacy.reducerPath]: restApiLegacy.reducer,
  },
  preloadedState: window.INITIAL_STATE,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(restApi.middleware)
      .concat(restApiLegacy.middleware),
});

setupListeners(store.dispatch);

export default store;
