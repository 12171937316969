import { restApi } from '../api/restApiSlice';

const notificationsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: (params) => ({ url: '/notifications', method: 'GET', params }),
      providesTags: ['Notifications'],
    }),
    getNotification: builder.query({
      query: (notificationId) => ({ url: `/notifications/${notificationId}`, method: 'GET' }),
      transformResponse: (response) => response.data,
      providesTags: ['Notifications'],
    }),
    markAsRead: builder.mutation({
      query: (loginUserNotificationIds = []) => ({
        url: `/notifications/mark-as-seen`,
        method: 'PATCH',
        body: { loginUserNotificationIds },
      }),
      invalidatesTags: ['Notifications'],
    }),
  }),
});

export const { useGetNotificationsQuery, useGetNotificationQuery, useMarkAsReadMutation } = notificationsApiSlice;
