/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reportModalHeight } from '../actions/pageActions';

/************************
 ModalContainer component
************************/

const initialState = {};

class ModalContainerComp extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  render() {
    const { modalVisible, modalContent } = this.props;
    if (modalVisible) {
      return (
        <>
          <div>{modalContent}</div>
          <div className="modal-backdrop fade show" id="backdrop" style={{ display: 'none' }} />
        </>
      );
    } else {
      return <div />;
    }
  }
}

function ModalContainerMapStateToProps(state) {
  return {
    apiErrTxt: state.page.contentData.apiError,
    modalVisible: state.page.modalVisible,
    modalContent: state.page.modalContent,
  };
}

const ModalContainer = connect(ModalContainerMapStateToProps)(ModalContainerComp);

/**********************
 ModalWrapper component
**********************/

const openModalDom = () => {
  const modal = document.getElementById('modal');
  modal.style.display = 'block';
  modal.className += 'show';
  document.getElementById('backdrop').style.display = 'block';
  document.getElementById('page-top').className += 'modal-open';
};

const closeModalDom = () => {
  const bodyClass = document.getElementById('page-top').className;
  document.getElementById('page-top').className = bodyClass.replace('modal-open', '');
};

class ModalWrapperComp extends Component {
  constructor(props) {
    super(props);
    this.modalWrapperRef = React.createRef();
  }

  componentDidMount() {
    // perfomr DOM manipulations for showing the Modal:
    openModalDom();
    // report current modal height to page reducer so that e.g. ModalBodyScroller can access it:
    window.addEventListener('resize', () => this.reportModalHeight());
    setTimeout(() => this.reportModalHeight(), 220);
  }

  reportModalHeight() {
    if (this.modalWrapperRef && this.modalWrapperRef.current) {
      this.props.reportModalHeight(parseInt(this.modalWrapperRef.current.getBoundingClientRect().height, 10));
    }
  }

  componentWillUnmount() {
    closeModalDom();
    window.removeEventListener('resize', () => this.reportModalHeight());
  }

  render() {
    const { maxHeight, maxWidth, isMobile, minHeight = '250px', minWidth, height, width } = this.props;
    const maxHeightValue = isMobile ? '99%' : '85%';
    const divStyle = { maxHeight: maxHeight || maxHeightValue, overflow: 'hidden', minHeight };
    const modalDialogStyle = {};

    if (maxWidth) {
      divStyle.maxWidth = maxWidth;
      modalDialogStyle.maxWidth = maxWidth;
    }

    if (minWidth) {
      divStyle.minWidth = minWidth;
      modalDialogStyle.minWidth = minWidth;
    }

    if (height) {
      divStyle.height = height;
      modalDialogStyle.height = height;
    }

    if (width) {
      divStyle.width = width;
      modalDialogStyle.width = width;
    }

    return (
      <div className="modal show" id="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog no-xs-margin" role="document" style={modalDialogStyle}>
          <div className="modal-content" id="modal-content-wrapper" ref={this.modalWrapperRef} style={divStyle}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

function ModalWrapperMapStateToProps(state) {
  return {
    isMobile: state.page.mobileDevice,
    modalVisible: state.page.modalVisible,
  };
}

function ModalWrapperMapDispatchToProps(dispatch) {
  return bindActionCreators({ reportModalHeight }, dispatch);
}

const ModalWrapper = connect(ModalWrapperMapStateToProps, ModalWrapperMapDispatchToProps)(ModalWrapperComp);

/**********************
 ModalHeader component
**********************/

const ModalHeader = ({ modalHeaderLeft, title, modalHeaderRight }) => (
  <div className="card-header" style={{ minHeight: '3.75rem', height: '3.75rem' }} id="modal-header">
    <div className="row align-items-center">
      <div className="col-auto pl-0">{modalHeaderLeft || <div />}</div>
      <div className="col text-center">
        <h3 className="mb-0">{title || ''}</h3>
      </div>
      <div className="col-auto pr-0">{modalHeaderRight || <div className="ml-5 mr-3">&nbsp;</div>}</div>
    </div>
  </div>
);

/**********************
 ModalFooter component
**********************/
const ModalFooter = ({ modalFooterLeft, title, modalFooterRight }) => (
  <div className="card-footer" id="modal-footer">
    <div className="row align-items-center">
      <div className="col-auto pl-0">{modalFooterLeft || <div />}</div>
      <div className="col text-center">
        <h3 className="mb-0">{title || ''}</h3>
      </div>
      <div className="col-auto pr-0">{modalFooterRight || <div className="ml-5 mr-3">&nbsp;</div>}</div>
    </div>
  </div>
);

/***************************
 ModalBodyScroller component
***************************/

class ModalBodyScrollerComp extends Component {
  constructor(props) {
    super(props);
    this.modalBodyScrollerRef = React.createRef();
    this.initialHeight = null;
  }

  componentDidMount() {
    setTimeout(() => {
      this.initialHeight = this.modalBodyScrollerRef.current.getBoundingClientRect().height;
    }, 200);
  }

  render() {
    const { modalHeight } = this.props;
    const style =
      this.initialHeight !== null && modalHeight - 30 < this.initialHeight ? { height: modalHeight - 40 } : {};
    return (
      <div
        className="modal-body-scroller flex-grow-1"
        id="modal-body-scroller"
        ref={this.modalBodyScrollerRef}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }
}

function ModalBodyScrollerMapStateToProps(state) {
  return {
    modalHeight: state.page.modalHeight,
  };
}

const ModalBodyScroller = connect(ModalBodyScrollerMapStateToProps)(ModalBodyScrollerComp);

export { ModalContainer, ModalWrapper, ModalHeader, ModalFooter, ModalBodyScroller };
