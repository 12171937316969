import { restApi } from '../../api/restApiSlice';

export const auditLogApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getAuditLog: builder.query({
      query: () => ({ url: '/user-event-log', method: 'GET' }),
      providesTags: ['AuditLog'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (response) => response.data,
    }),
  }),
});

export const { useGetAuditLogQuery } = auditLogApi;
