import { isFree } from '@prerender/billing-shared';
import { Typography } from 'antd';

import FeatureRequests from '../features/FeatureRequests';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';

const META_TAGS = {
  title: 'Feature Requests - Prerender.io',
};

const FeatureRequestsPage = () => {
  return (
    <AdminTemplate metaTags={META_TAGS}>
      <div>
        <Typography.Title level={2}>Request a feature</Typography.Title>
        <Typography.Paragraph type="secondary">
          Share ideas, improvements, and feedback to help optimize the platform to meet your needs.
        </Typography.Paragraph>
      </div>
      <FeatureRequests />
    </AdminTemplate>
  );
};

function isAuthorized({ chargebeePlanId }) {
  return !isFree(chargebeePlanId);
}

export default requireAuth(FeatureRequestsPage, isAuthorized);
