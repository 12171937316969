import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ModalWrapper, ModalHeader, ModalBodyScroller } from './ModalLib';
import { TWrapper, TRow, TCell, TSectionHeader, TRowSpacer } from './TableLib';
import { CheckMark, ControlButton } from './PageElementsLib';

class ListPickerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: props.selectedOptions || [],
    };
    this.callParentEventHandler = props.onEvent;
  }

  toggleCheckMark(param) {
    const { selectedOptions } = this.state;
    if (this.props.limit === 1) {
      this.setState({ selectedOptions: [param] });
      return;
    }
    this.setState({
      selectedOptions: selectedOptions.find((i) => i === param)
        ? selectedOptions.filter((o) => o !== param)
        : selectedOptions.length === this.props.limit
          ? selectedOptions
          : [...selectedOptions, param],
    });
  }

  render() {
    const { options = [], title = 'Options', sectionTitles = [] } = this.props;
    const { selectedOptions } = this.state;
    return (
      <ModalWrapper>
        <ModalHeader
          modalHeaderLeft={<ControlButton text="Cancel" onclick={() => this.callParentEventHandler({ action: 'showOverview' })}/>}
          modalHeaderRight={<ControlButton text="Apply"
                                           onclick={() => this.callParentEventHandler({ action: 'listPickerUpdated', payload: selectedOptions })}/>}
          title={title}
        />
        <ModalBodyScroller>
          <TWrapper
            tableId="listpicker"
            parentType="modal"
            showTHead={false}
            cols={[{ size: 'min', valign: 'middle' }, { size: 'flex', valign: 'middle' }]}
          >
            { sectionTitles.length > 0 && <TSectionHeader text={sectionTitles[0]} />}
            { options.map(({ param, name }, rowId) => (
              <TRow key={`lpo${rowId}`} onclick={() => this.toggleCheckMark(param)}>
                <TCell><div className="px-2"><CheckMark checked={selectedOptions.find((o) => o === param)} /></div></TCell>
                <TCell>{name}</TCell>
              </TRow>
            ))}
            <TRowSpacer height={options.length < 4 ? '280px' : '20px'}/>
            </TWrapper>
          </ModalBodyScroller>
      </ModalWrapper>
    );
  }
}

function mapStateToProps() {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPickerModal);
