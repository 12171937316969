import React from 'react';
import { List, Typography, ConfigProvider, Empty, message } from 'antd';

import logReadableConfig from './logReadableConfig';
import AuditLogListItem from './AuditLogListItem';
import { useGetAuditLogQuery } from './auditLogApi';

function AuditLogPage() {
  const { data = [], isFetching, error: fetchingError } = useGetAuditLogQuery('', { refetchOnMountOrArgChange: true });
  const [messageApi, contextHolder] = message.useMessage();

  const logs = React.useMemo(
    () =>
      data
        .flatMap((log) =>
          Object.entries(log.values).map(([fieldName, fieldValue]) => {
            const readableKey = logReadableConfig[fieldName];
            if (!readableKey) return null;

            const value = readableKey.formatter ? readableKey.formatter(fieldValue, log) : fieldValue;
            return {
              ...readableKey,
              modifiedBy: log.modifiedBy,
              modifiedAt: log.modifiedAt,
              value,
            };
          })
        )
        .filter((parsedLog) => parsedLog),
    [data]
  );

  React.useEffect(() => {
    if (fetchingError?.error) {
      messageApi.error('An error occurred while fetching audit logs. Please try again later.');
    }
  }, [fetchingError?.error]);

  return (
    <>
      {contextHolder}
      <Typography.Title level={5}>Audit Log list</Typography.Title>
      <Typography.Paragraph type="secondary">History of every change made by your team members.</Typography.Paragraph>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="There are no meaningful logs recorded at the moment."
          />
        )}
      >
        <List
          loading={isFetching}
          itemLayout="horizontal"
          dataSource={logs}
          renderItem={(item) => <AuditLogListItem item={item} />}
        />
      </ConfigProvider>
    </>
  );
}

export default AuditLogPage;
