import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const getStepName = (stepNumber) => {
  switch (stepNumber) {
    case 0:
      return 'integrationSelector';

    case 1:
      return 'integrationSelected';

    case 2:
      return 'integrationVerification';

    case 3:
      return 'integrationVerificationResult';

    default:
      return undefined;
  }
};

const Tracker = ({ children, prerenderUser, page, onboarding }) => {
  const [trackData, setTrackData] = useState(null);

  useEffect(() => {
    window.dataLayer.push({
      'prerender.dashboard.userId': prerenderUser.id,
      'prerender.dashboard.companyId': prerenderUser.companyId,
    });
  }, [prerenderUser.id, prerenderUser.companyId]);

  useEffect(() => {
    window.dataLayer.push({
      event: 'prerender.dashboard.event.cookieConsentChanged',
      'prerender.dashboard.cookieConsent': page.cookieConsent,
    });
  }, [page.cookieConsent]);

  useEffect(() => {
    // clear the event data
    window.dataLayer.push(function clear() {
      this.set('prerender.dashboard.eventData', null);
    });

    window.dataLayer.push(trackData);
  }, [trackData]);

  useEffect(() => {
    if (page.modalVisible !== '') {
      const data = {
        event: 'prerender.dashboard.event.modal.opened',
        'prerender.dashboard.eventData.modal.modalId': page.modalVisible,
      };

      setTrackData(data);
    }
  }, [page.modalVisible]);

  useEffect(() => {
    if (onboarding.step) {
      const stepName = getStepName(onboarding.step);
      const data = {
        event: `prerender.dashboard.event.onboarding.${stepName}`,
      };

      if (onboarding.selectedIntegration && onboarding.step === 1) {
        data['prerender.dashboard.eventData.onboarding.integration'] = onboarding.selectedIntegration;
      }

      if (onboarding.validationResult !== null && onboarding.step === 3) {
        data['prerender.dashboard.eventData.onboarding.validation.success'] = onboarding.validationResult.working;
        data['prerender.dashboard.eventData.onboarding.validation.url'] = onboarding.validationResult.url;
      }

      setTrackData(data);
    }
  }, [onboarding.selectedIntegration, onboarding.step, onboarding.validationResult]);

  return children;
};

function mapStateToProps(state) {
  return {
    page: state.page,
    prerenderUser: state.prerenderUser,
    onboarding: state.onboarding,
  };
}

const TrackerComponent = connect(mapStateToProps)(Tracker);

export default TrackerComponent;
