import { restApi } from './restApiSlice';

const queryParamsToConvertToNumber = [
  'responseTimeLow',
  'responseTimeHigh',
  'renderedTimeLow',
  'renderedTimeHigh',
  'statusCodeEq',
  'statusCodeLow',
  'statusCodeHigh',
];

export const rendersApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    exportHistory: builder.mutation({
      query: (inputParams) => ({
        url: '/renders/history/export',
        method: 'POST',
        params: Object.fromEntries(
          Object.entries(inputParams)
            .filter(([_paramName, param]) => param != null && param !== '')
            .map(([paramName, param]) => [
              paramName,
              queryParamsToConvertToNumber.includes(paramName) ? Number(param) : param,
            ])
        ),
      }),
    }),
    recacheStats: builder.mutation({
      query: (inputParams) => ({
        url: '/renders/recache-stats',
        method: 'GET',
        params: Object.fromEntries(
          Object.entries(inputParams)
            .filter(([_paramName, param]) => param != null && param !== '')
            .map(([paramName, param]) => [
              paramName,
              queryParamsToConvertToNumber.includes(paramName) ? Number(param) : param,
            ])
        ),
      }),
    }),
  }),
});

export const { useExportHistoryMutation } = rendersApiSlice;
