import {
  IGNORED_URLS_SET,
  IGNORED_URLS_IN_PROGRESS,
  IGNORED_URLS_RESET,
} from '../actions/types';

const emptyState = {
  inProgress: false,
  items: [],
};

const initialState = emptyState;

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case IGNORED_URLS_SET:
    {
      return { ...state,
        inProgress: false,
        items: payload.data,
      };
    }
    case IGNORED_URLS_IN_PROGRESS:
      return { ...state, inProgress: payload };
    case IGNORED_URLS_RESET:
      return initialState;
    default:
      break;
  }
  return state;
};
