// Define action creators that dispatch actions:
import config from '../assets/config';
import internalApi, { THROW_ON_ERROR_V2, THROW_ON_ERROR_V1 } from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';
import { setLastErrorCode, clearLastErrorCode } from './pageActions';

import { CACHEDPAGES_SET, CACHEDPAGES_IN_PROGRESS, CACHEDPAGES_SET_STATUS } from './types';

const valueOrEmptyString = (value) => value || '';

function getQueryFromFilterObject(params) {
  if (!params || typeof params !== 'object') return;

  const paramsObj = {
    page: valueOrEmptyString(params.page),
    pageSize: valueOrEmptyString(params.pageSize),
    sort: valueOrEmptyString(params.sort?.name),
    sortDirection: params.sort?.asc ? 'ASC' : 'DESC',
    q: valueOrEmptyString(params.query),
    qCondition: params.queryCondition,
    domain: valueOrEmptyString(params.domain),
    notnull: valueOrEmptyString(params.notnull),
    timedout: params.timedout ? (params.timedout ? 'true' : 'false') : '',
    last_delivery_at: params.lastDeliveryAt || '',
  };

  ['seo_score', 'seo_score_status', 'cache_state', 'source', 'adaptive_type'].forEach((p) => {
    if (params.filter?.[p]) paramsObj[p] = params.filter[p];
  });

  return new URLSearchParams(paramsObj).toString();
}

export const cachedPagesSearch =
  (stateBucket = 'all', params) =>
  async (dispatch) => {
    try {
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: true } });
      const filterQuery = getQueryFromFilterObject(params);
      const requestUri = `${config.apiUrl.dataServer}/api/cached-pages?${filterQuery}`;
      const data = await internalApi('get', requestUri, null);
      dispatch({ type: CACHEDPAGES_SET, payload: { stateBucket, data, params } });
      dispatch(clearLastErrorCode());
    } catch (error) {
      console.log(error);
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: false } });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };

export const requestCaching =
  (stateBucket = 'all', urls = [], onSuccess) =>
  async (dispatch) => {
    try {
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: true } });
      const requestUri = `${config.apiUrl.dataServer}/api/v2/cache-page`;
      const payload = { ids: urls.map((u) => u.id) };
      await internalApi('post', requestUri, payload);
      dispatch({ type: CACHEDPAGES_SET_STATUS, payload: { stateBucket, urls, cache_state: 'Recaching...' } });
      dispatch(clearLastErrorCode());
      if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error);
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: false } });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };

export const exportSitemap =
  (stateBucket = 'all', _urls = [], onSuccess) =>
  async (dispatch) => {
    try {
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: true } });
      const requestUri = `${config.apiUrl.dataServer}/api/v2/generate-sitemap`;
      const data = await internalApi('get', requestUri, null);
      dispatch(clearLastErrorCode());
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: false } });
      if (onSuccess) onSuccess(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: false } });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };

export const saveSitemap =
  (stateBucket = 'all', sitemap, onSuccess) =>
  async (dispatch) => {
    try {
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: true } });
      const requestUri = `${config.apiUrl.dataServer}/api/add-sitemap-urls`;
      const payload = { url: sitemap };
      await internalApi('post', requestUri, payload);
      dispatch(clearLastErrorCode());
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: false } });
      if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error);
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: false } });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };

// {"urls":[{"id":"4e525ea4-db69-4f07-af38-d04e2b710fec"},{"id":"fd7e9e7e-9bae-47d9-907c-5b339a161453"}]}
export const deleteCachedUrls =
  (stateBucket = 'all', urls, onSuccess) =>
  async (dispatch) => {
    try {
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: true } });
      const requestUri = `${config.apiUrl.dataServer}/api/remove-cached-urls`;
      const message = { ids: urls.urls.map((u) => u.id) };
      await internalApi('delete', requestUri, message, THROW_ON_ERROR_V1);
      dispatch(clearLastErrorCode());
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: false } });
      if (onSuccess) onSuccess();
    } catch (error) {
      console.log(error);
      dispatch({ type: CACHEDPAGES_IN_PROGRESS, payload: { stateBucket, value: false } });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };
