import React from 'react';
import { Alert, Typography, Button, theme } from 'antd';
import { Link } from 'react-router-dom';

const URLS_ADDED_MESSAGES = {
  singular: '1 new URL was added to the Manual/API Queue for caching.',
  plural: 'new URLs were added to the Manual/API Queue for caching.',
};

function Message({ text }: { text: string }) {
  return (
    <Typography.Text>
      {text}
      <Link to="/recache-queue">
        <Button type="link">View Queue</Button>
      </Link>
    </Typography.Text>
  );
}

type URLsAddedAlertProps = {
  amount: number;
  afterClose: () => void;
};

function URLsAddedAlert({ amount, afterClose }: URLsAddedAlertProps) {
  const { token } = theme.useToken();

  return (
    <Alert
      style={{ marginBottom: token.marginMD }}
      type="success"
      message={
        <Message text={amount === 1 ? URLS_ADDED_MESSAGES.singular : `${amount} ${URLS_ADDED_MESSAGES.plural}`} />
      }
      closable
      afterClose={afterClose}
    />
  );
}

export default URLsAddedAlert;
