import {
  EMAIL_VERIFICATION_SENT,
  EMAIL_VERIFICATION_DONE,
  EMAIL_VERIFICATION_ERROR,
  EMAIL_VERIFICATION_RESENDING,
  EMAIL_VERIFICATION_RESENT,
} from '../actions/types';

export const VERIFICATION_STATE_INIT = 'init';
export const VERIFICATION_STATE_SENT = 'sent';
export const VERIFICATION_STATE_VERIFIED = 'verified';

const initialState = {
  verificationState: VERIFICATION_STATE_INIT,
  resending: false,
};

export default function emailVerificationReducer(state = initialState, action) {
  switch (action.type) {
    case EMAIL_VERIFICATION_DONE:
      return { ...state, verificationState: VERIFICATION_STATE_VERIFIED };
    case EMAIL_VERIFICATION_SENT:
      return { ...state, verificationState: VERIFICATION_STATE_SENT };
    case EMAIL_VERIFICATION_ERROR:
      return { ...state, verificationState: VERIFICATION_STATE_INIT };
    case EMAIL_VERIFICATION_RESENDING:
      return { ...state, resending: true };
    case EMAIL_VERIFICATION_RESENT:
      return { ...state, resending: false };

    default:
      return state;
  }
}
