import { Navigate } from 'react-router-dom';

import BillingPage from './features/billing/BillingPage';
import DashboardPage from './features/dashboard/DashboardPage';
import Domain404CheckerPage from './features/domain-404-checker/Domain404CheckerPage';
import GoogleSearchConsolePage from './features/gsc-integrations/GoogleSearchConsolePage';
import IntegrationDetailsPage from './features/integration/IntegrationDetailsPage';
import IntegrationPage from './features/integration/IntegrationPage';
import IntegrationVerificationPage from './features/integration/IntegrationVerificationPage';
import IntegrationWizardPage from './features/integrationWizard/IntegrationWizardPage';
import RegistrationPage from './features/registration/RegistrationPage';
import SitemapRouter from './features/sitemaps/SitemapRouter';
import NewCacheManagerPage from './pages/NewCacheManagerPage.tsx';
import CacheManagerPage from './pages/CacheManagerPage';
import CachingActivityPage from './pages/CachingActivityPage';
import CrawlerActivityPage from './pages/CrawlerActivityPage';
import CustomDealTrackerPage from './pages/CustomDealTrackerPage';
import DashboardPageOld from './pages/DashboardPage';
import Error404Page from './pages/Error404Page';
import GettingStartedPage from './pages/GettingStartedPage';
import InsightPage from './pages/InsightPage';
import QualityAssurancePage from './pages/QualityAssurancePage';
import RawHtmlPage from './pages/RawHtmlPage';
import RecacheQueuePage from './pages/RecacheQueuePage';
import RedirectPage from './pages/RedirectPage';
import SettingsPage from './pages/SettingsPage';
import SignOutPage from './pages/SignOutPage';
import UncaughtError from './pages/UncaughtError';
import NewlyAddedDomainsPage from './features/added-domains/NewlyAddedDomainsPage';
import DomainsCountPage from './features/domains-count/DomainsCountPage';
import DomainManagerPage from './pages/DomainsManager';
import SecurityAndAccess from './pages/SecurityAndAccess';
import SeoReportPage from './features/SeoReport/SeoReportPage';
import OversightPage from './features/oversightSystem/OversightPage';
import Keywords from './pages/RankTracker';
import FeatureRequestsPage from './pages/FeatureRequestsPage';
import DemoSitemapPage from './pages/demo/DemoSitemapPage';
import { DevTestPage } from './pages/DevTestPage';
import AnalyticsPage from './features/Analytics/AnalyticsPage';
import DetailedView from './features/domain-manager/DetailedView';
import NotificationsPage from './pages/NotificationsPage';
import MyAccountPage from './features/my-account/MyAccountPage';

const routes = [
  { element: <RedirectPage />, path: '/login' },
  { element: <RedirectPage />, path: '/reset-password' },
  { element: <RedirectPage />, path: '/signup' },
  { element: <RedirectPage />, path: '/verify-email' },
  { element: <RedirectPage />, path: '/accept-invitation' },
  { element: <DashboardPage />, path: '/', event: 'Dashboard Page Viewed' },
  // { element: <DashboardPageOld />, path: '/dashboard', event: 'Dashboard Page Viewed' }, // to delete at some point
  { element: <CacheManagerPage />, path: '/old-cache', event: 'Cache Manager Viewed' },
  { element: <NewCacheManagerPage />, path: '/cache/*', event: 'Cache Manager Viewed' },
  { element: <CachingActivityPage />, path: '/recache-stats', event: 'Render History Viewed' },
  { element: <RecacheQueuePage />, path: '/recache-queue', event: 'Render Queue Viewed' },
  { element: <CrawlerActivityPage />, path: '/crawl-stats', event: 'CDN Analytics Viewed' },
  { element: <SettingsPage />, path: '/settings/*', event: 'Settings Viewed' },
  { element: <SecurityAndAccess />, path: '/security/*', event: 'Security and Access Viewed' },
  { element: <IntegrationPage />, path: '/integration' },
  { element: <IntegrationDetailsPage />, path: '/integration/:name' },
  { element: <IntegrationVerificationPage />, path: '/integration/:name/verification' },
  { element: <GettingStartedPage />, path: '/getting-started' },
  { element: <SignOutPage />, path: '/signout' }, // this is used by keycloak too
  { element: <RawHtmlPage />, path: '/raw-html' },
  { element: <BillingPage />, path: '/billing/*', event: 'Billing Page Viewed' },
  { element: <Error404Page />, path: '/error404' },
  { element: <UncaughtError />, path: '/error-uncaught' },
  { element: <RegistrationPage />, path: '/registration' },
  { element: <GoogleSearchConsolePage />, path: '/gsc', event: 'Google Search Colsole Viewed' },
  { element: <IntegrationWizardPage />, path: '/integration-wizard/*', event: 'Integration Started' },
  { element: <DomainManagerPage />, path: '/domain-manager/*', event: 'Domain Manager Viewed' },
  { element: <SeoReportPage />, path: '/seo-report/:id', event: 'SEO Report Viewed' },
  { element: <SitemapRouter />, path: '/sitemaps/*', event: 'Sitemap Viewed' },
  { element: <Domain404CheckerPage />, path: '/domain-404-checker', event: '404 Checker Page Viewed' },
  { element: <InsightPage />, path: '/insight', event: 'Deep Insight Viewed' },
  { element: <OversightPage />, path: '/oversight/*', event: 'Oversight Page Viewed' },
  { element: <CustomDealTrackerPage />, path: '/custom-deal' },
  { element: <NewlyAddedDomainsPage />, path: '/added-domains' },
  { element: <QualityAssurancePage />, path: '/qa' },
  { element: <DomainsCountPage />, path: '/page-count' },
  { element: <Keywords />, path: '/rank-tracker/*', event: 'Rank Tracker Viewed' },
  { element: <AnalyticsPage />, path: '/analytics/:domain?', event: 'Analytics Page Viewed' },
  { element: <DetailedView />, path: '/detailed-view', event: 'Detailed View Page Viewed' },
  { element: <FeatureRequestsPage />, path: '/feature-requests' },
  { element: <Navigate to="/feature-requests" />, path: '/feature-request' }, // without the "s", for backward compatibility
  { element: <NotificationsPage />, path: '/notifications/*', event: 'Notifications Center opened' },
  { element: <MyAccountPage />, path: '/account', event: 'My Account Page Viewed' },
  // { element: <DemoSitemapPage />, path: '/demo/sitemap' },
  // { element: <DevTestPage />, path: '/dev' },
  { element: <Error404Page />, path: '*' },
];

export default routes;
