import React from 'react';
import { Typography } from 'antd';

import { NOTIFICATION_TYPES } from '../Notification';
import NewUpdate from './NewUpdateNotification';
import RenderLimit75 from './RenderLimit75Notification';
import RenderLimit100 from './RenderLimit100Notification';
import PageReport from './PageReportNotification';
import BetterPlan from './BetterPlanNotification';

function NotificationBody({ notification = {} }) {
  switch (notification.type) {
    case NOTIFICATION_TYPES.update:
      return <NewUpdate notification={notification} />;

    case NOTIFICATION_TYPES.pageReport:
      return <PageReport notification={notification} />;

    case NOTIFICATION_TYPES.renderLimit75:
      return <RenderLimit75 notification={notification} />;

    case NOTIFICATION_TYPES.renderLimit100:
      return <RenderLimit100 notification={notification} />;

    case NOTIFICATION_TYPES.betterPlanFound:
      return <BetterPlan notification={notification} />;

    default:
      return <Typography.Paragraph>{notification.description}</Typography.Paragraph>;
  }
}

export default React.memo(NotificationBody);
