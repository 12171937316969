import { Flex, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import { USER_AGENT_NAMES } from '../../UserAgents';

const timeIntervalOptions = [
  {
    label: 'Last 1 day',
    value: '1',
  },
  {
    label: 'Last 7 days',
    value: '7',
  },
  {
    label: 'Last 30 days',
    value: '30',
  },
];

export const HeaderFilters = ({ onTimeIntervalChange, domainOptions, domain, onChangeUserAgent }) => {
  const navigate = useNavigate();
  return (
    <Flex justify="space-between" gap={16} align="baseline" wrap="wrap">
      <Select
        style={{ width: 140 }}
        placeholder="domain"
        options={domainOptions}
        onChange={(value) => navigate(`/analytics/${value}`)}
        value={domain}
        popupMatchSelectWidth={false}
      />
      <Select
        style={{ width: 140 }}
        placeholder="user agent"
        options={USER_AGENT_NAMES}
        popupMatchSelectWidth={false}
        onChange={onChangeUserAgent}
        allowClear
      />
      <Select
        style={{ width: 140 }}
        placeholder="time interval"
        options={timeIntervalOptions}
        onChange={onTimeIntervalChange}
        defaultValue={timeIntervalOptions[0].value}
      />
    </Flex>
  );
};
