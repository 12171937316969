import { useEffect } from 'react';
import { Checkbox, Tooltip } from 'antd';

export default function DeviceTypeSelector({ mobileAdaptive, setAdaptiveTypes }) {
  const defaultAdaptiveTypes = mobileAdaptive ? ['desktop', 'mobile'] : ['desktop'];
  useEffect(() => {
    setAdaptiveTypes(defaultAdaptiveTypes);
  }, []);
  return (
    <>
      <Tooltip title={!mobileAdaptive ? 'Please enable Mobile Optimized Rendering in Advanced Settings' : null}>
        <Checkbox.Group
          options={[
            { label: 'Desktop', value: 'desktop' },
            { label: 'Mobile', value: 'mobile' },
          ]}
          disabled={!mobileAdaptive}
          defaultValue={defaultAdaptiveTypes}
          onChange={(checkedValues) => {
            setAdaptiveTypes(checkedValues);
          }}
        />
      </Tooltip>
    </>
  );
}
