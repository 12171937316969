// imported by /reducers/index.js
// holds road search parameters and result

import { matchWith } from '../assets/lib';

import {
  CRAWLERACTIVITY_SET,
  CRAWLERACTIVITY_IN_PROGRESS,
  CRAWLERACTIVITY_RESET,
  CRAWLERACTIVITY_SET_PARAMS,
} from '../actions/types';
import { calculateStats, EMPTY_STATS } from '../assets/pagingFunctions';

const DEFAULT_PAGE_SIZE = 200;

const emptyState = {
  inProgress: false,
  params: {
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    query: '',
    statusCodeEq: null,
    statusCodeLow: null,
    statusCodeHigh: null,
    responseTimeLow: null,
    responseTimeHigh: null,
    timedout: null,
    renderedTimeLow: null,
    renderedTimeHigh: null,
    // timePeriod: null,
    userAgent: null,
    // cacheStatus: null
  },
  stats: EMPTY_STATS,
  requests: [],
};

const initialState = {
  dashboard: emptyState,
  all: emptyState,
};

export default function crawlerActivityReducer(state = initialState, action) {
  const { payload } = action;
  const bucket = payload && payload.stateBucket ? payload.stateBucket : 'dashboard';
  switch (action.type) {
    case CRAWLERACTIVITY_SET: {
      const newState = {
        inProgress: false,
        params: matchWith(emptyState.params, payload.params),
        stats: calculateStats(payload, DEFAULT_PAGE_SIZE),
        requests: payload.data,
      };
      return {
        ...state,
        dashboard: bucket === 'dashboard' ? newState : state.dasbboard,
        all: bucket === 'all' ? newState : state.all,
      };
    }
    case CRAWLERACTIVITY_SET_PARAMS:
      return {
        ...state,
        dashboard: bucket === 'dashboard' ? { ...state.dashboard, params: payload.params } : state.dashboard,
        all: bucket === 'all' ? { ...state.all, params: payload.params } : state.all,
      };

    case CRAWLERACTIVITY_IN_PROGRESS:
      return {
        ...state,
        dashboard: bucket === 'dashboard' ? { ...state.dashboard, inProgress: payload.value } : state.dashboard,
        all: bucket === 'all' ? { ...state.all, inProgress: payload.value } : state.all,
      };

    case CRAWLERACTIVITY_RESET:
      return initialState;
    default:
  }
  return state;
}
