import { Modal } from 'antd';

const ExportCSVModal = ({ onClose, onExport, showModal, text }) => {
  return (
    <Modal
      title="Export CSV"
      closable
      cancelText="Cancel"
      okText="Export"
      open={showModal}
      onCancel={onClose}
      onOk={onExport}
    >
      {text}
    </Modal>
  );
};

export default ExportCSVModal;
