import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';

import config from '../../../assets/config';
import internalApi from '../../../assets/internalApi';

import { useGetGscIntegrationsStatusQuery } from './../api/gscApiSlice';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import { useEvent } from '../../events/hooks/useEvent';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';

const PrerenderGoogleLogin = () => {
  const { data: gscIntegrationStatus } = useGetGscIntegrationsStatusQuery();
  const prerenderUser = usePrerenderUser();
  const navigate = useNavigate();
  const { track } = useEvent();

  const login = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (response) => {
      await internalApi('post', `${config.apiUrl.restServer}/v3/gsc-integrations`, { code: response.code });
      track('Google Search Colsole Integrated');
      navigate(0);
    },
    onError: (error) => console.log('Login Failed:', error),
    scope: 'email https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/webmasters.readonly',
  });

  const disconnect = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async () => {
      googleLogout();
      await internalApi('delete', `${config.apiUrl.restServer}/v3/gsc-integrations`, null);
      track('Google Search Colsole Removed');
      navigate(0);
    },
    onError: (error) => console.log('Login Failed:', error),
    scope: 'email https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/webmasters.readonly',
  });

  return (
    <div>
      {prerenderUser && gscIntegrationStatus?.data?.isConnected ? (
        <div>
          <ButtonWithRole disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]} onClick={disconnect} danger>
            Disconnect Search Console
          </ButtonWithRole>
        </div>
      ) : (
        <ButtonWithRole disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]} type="primary" onClick={login}>
          Connect Search Console 🚀
        </ButtonWithRole>
      )}
    </div>
  );
};

export default PrerenderGoogleLogin;
