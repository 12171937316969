import React, { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu, Divider } from 'antd';
import { isFree, isCustom } from '@prerender/billing-shared';

import { useCharbeePlanId, useHasTrackingCode, useIsPrerenderUser, useUserRole } from '../../hooks/usePrerenderUser';
import useFeatureFlag, { FeatureFlag } from '../../hooks/useFeatureFlag';
import menuItems, { PAGE_KEY, MenuItem, LeafMenuItem } from './menuItems';
import { Role } from '../../features/PrerenderUser';

const TARGET_TO_KEY = menuItems.reduce((acc, item) => {
  if (item.type === 'group') {
    item.children.forEach((child) => (acc[child.target] = child.key));
  } else {
    acc[item.target] = item.key;
  }
  return acc;
}, {} as Record<PAGE_KEY, string>);

function filterVisibleMenuItem(item: MenuItem, visibilityCheckerFn: (item: LeafMenuItem) => boolean) {
  if (item.type === 'group') {
    const children = item.children
      .filter((child) => visibilityCheckerFn(child))
      .map(({ key, label, icon }) => ({ key, label, icon }));

    if (children.length) {
      return { ...item, children };
    }
  } else if (visibilityCheckerFn(item)) {
    return { key: item.key, label: item.label, icon: item.icon };
  }
}

type SidebarProps = {
  isCollapsed: boolean;
};
const Sidebar = ({ isCollapsed }: SidebarProps) => {
  const location = useLocation();
  const oversightEnabled = useFeatureFlag([FeatureFlag.Oversight]);
  const customDealEnabled = useFeatureFlag([FeatureFlag.CustomDeal]);
  const hasTrackingCode = useHasTrackingCode();
  const isPrerenderUser = useIsPrerenderUser();
  const chargebeePlanId = useCharbeePlanId();
  const paidPlan = !isFree(chargebeePlanId);
  const role = useUserRole();

  const shouldBeVisible = useCallback(
    (item: LeafMenuItem) => {
      switch (item.key) {
        case PAGE_KEY.IntegrationWizard:
          return !hasTrackingCode && role !== Role.Guest;

        case PAGE_KEY.DomainAnalytics:
          return isPrerenderUser;

        case PAGE_KEY.Oversight:
          return oversightEnabled;

        case PAGE_KEY.FeatureRequests:
          return paidPlan;

        case PAGE_KEY.CustomDeal:
          return isCustom(chargebeePlanId) || customDealEnabled;

        default:
          return item.visible;
      }
    },
    [hasTrackingCode, isPrerenderUser, paidPlan, chargebeePlanId, role, customDealEnabled, oversightEnabled]
  );

  const items = useMemo(
    () =>
      menuItems.reduce((acc, item) => {
        const filteredItem = filterVisibleMenuItem(item, shouldBeVisible);
        if (filteredItem) {
          if (isCollapsed && item.type === 'group') filteredItem.label = <Divider style={{ margin: '10px 0' }} />;
          acc.push(filteredItem);
        }
        return acc;
      }, []),
    [shouldBeVisible, isCollapsed]
  );

  const selectedKey = TARGET_TO_KEY[`/${location.pathname.split('/')[1]}`];

  return <Menu selectedKeys={selectedKey} mode="inline" items={items} inlineIndent={12} />;
};

export default React.memo(Sidebar);
