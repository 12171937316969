import React, { PropsWithChildren } from 'react';
import { Button, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

type CustomTooltipProps = PropsWithChildren<Pick<ExportButtonProps, 'hasSelectedUrls' | 'isFreePlan'>>;

function CustomTooltip({ isFreePlan, hasSelectedUrls, children }: CustomTooltipProps) {
  if (isFreePlan)
    return (
      <Tooltip
        title={
          <p>
            The export is only available for our paid subscriptions. You can change your subscription in the{' '}
            <Link to="/billing/plans">Billing section</Link>
          </p>
        }
      >
        {children}
      </Tooltip>
    );

  if (hasSelectedUrls)
    return (
      <Tooltip title="The export action will run for all filtered URLs and not only a selected batch.">
        {children}
      </Tooltip>
    );

  return <>{children}</>;
}

type ExportButtonProps = {
  isFreePlan?: boolean;
  hasSelectedUrls?: boolean;
  onClick: () => void;
};

const ExportButton = ({ isFreePlan = false, hasSelectedUrls = false, onClick }: ExportButtonProps) => {
  return (
    <CustomTooltip isFreePlan={isFreePlan} hasSelectedUrls={hasSelectedUrls}>
      <Button className="ml-2" size="small" disabled={isFreePlan} onClick={onClick}>
        Export
      </Button>
    </CustomTooltip>
  );
};

export default ExportButton;
