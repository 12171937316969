import React from 'react';

const SpinningWheel = ({ matchParent = false }) => (
  <div
    className={`p-2 mt-3 mb-3 d-flex justify-content-center align-items-center ${matchParent ? '' : 'vw-100 vh-100'}`}
  >
    <div className={'fe fe-loader display-1 fa-spin'} />
  </div>
);

export default SpinningWheel;
