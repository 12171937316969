import DataPointCard from './DataPointCard';
import { isFreePlan } from '../chargebee/chargebee';
import { formatPagesCount } from '../assets/numberFormatter';

export function getPageLimit(prerenderUser) {
  const { customCachedPagesLimit, plan } = prerenderUser;

  return customCachedPagesLimit || plan.limit;
}

function getPageLimitCounter(prerenderUser) {
  const { isMeteredBilling, renderCounter, numPagesCached } = prerenderUser;

  return isMeteredBilling ? renderCounter : numPagesCached;
}

export function isLimitReached(prerenderUser) {
  const counter = getPageLimitCounter(prerenderUser);
  const pageLimit = getPageLimit(prerenderUser);

  return pageLimit ? counter / pageLimit >= 1 : false;
}

function isWarningReached(prerenderUser) {
  const counter = getPageLimitCounter(prerenderUser);
  const pageLimit = getPageLimit(prerenderUser);
  return pageLimit ? counter / pageLimit >= 0.85 : false;
}

function getToolTip(prerenderUser, cmData) {
  const { plan, isMeteredBilling, hasBillingInfo } = prerenderUser;
  const isFreeUser = isFreePlan(plan);
  const pageLimit = formatPagesCount(getPageLimit(prerenderUser));

  return isMeteredBilling
    ? isFreeUser
      ? cmData.toolTipFree.replace(/{{freeLimit}}/, pageLimit)
      : cmData.toolTip
    : !hasBillingInfo && pageLimit
    ? cmData.toolTip.replace(/{{freeLimit}}/, pageLimit)
    : null;
}

function getBadge(prerenderUser, cmData) {
  const { isMeteredBilling, plan } = prerenderUser;
  const reachedLimit = isLimitReached(prerenderUser);
  const reachedWarning = isWarningReached(prerenderUser);

  const isFreeUser = isFreePlan(plan);
  const limitText = isMeteredBilling && isFreeUser ? cmData.limitFree : cmData.limit;
  const limitTheme = isMeteredBilling && isFreeUser ? cmData.limitFreeTheme : cmData.limitTheme;

  const text = reachedLimit ? limitText : reachedWarning ? cmData.warning : null;

  const theme = reachedLimit ? limitTheme : reachedWarning ? cmData.warningTheme : null;

  return {
    text,
    theme,
  };
}

const PageLimitCard = ({ width = 'col-12', cm = null, prerenderUser = null }) => {
  if (!cm || !prerenderUser) return null;

  const { isMeteredBilling } = prerenderUser;
  const hasLimit = !!getPageLimit(prerenderUser);
  const counter = formatPagesCount(getPageLimitCounter(prerenderUser));
  const pageLimit = formatPagesCount(getPageLimit(prerenderUser));

  const cmData = isMeteredBilling ? cm.renderCounter : cm.cachedPages;
  const badge = getBadge(prerenderUser, cmData);
  const toolTip = getToolTip(prerenderUser, cmData);

  return (
    <DataPointCard
      width={width}
      title={cmData.title}
      value={hasLimit ? `${counter} of ${pageLimit}` : counter}
      badge={badge.text}
      badgeTheme={badge.theme}
      toolTip={toolTip}
      icon={cmData.icon}
      isLoading={prerenderUser.inProgress}
    />
  );
};

export default PageLimitCard;
