import React from 'react';
import { Modal, Typography, Alert } from 'antd';

function RegenerateTokenModal({ open = false, onCancel, onConfirm, error = '', isLoading = false }) {
  return (
    <Modal
      closable
      title="Invalidate and renew token"
      open={open}
      onCancel={onCancel}
      okText="Regenerate token"
      okType="danger"
      onOk={onConfirm}
      okButtonProps={{ loading: isLoading }}
    >
      <Typography.Paragraph>
        Obtaining a new Token invalidates your existing Token and requires you to update your Prerender Middleware with
        the new Token.
      </Typography.Paragraph>

      {!!error && <Alert type="error" closable message={error} />}
    </Modal>
  );
}

export default RegenerateTokenModal;
