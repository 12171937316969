import { Alert, Button, Space, Typography } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useEvent } from '../events/hooks/useEvent';
import { isFreePlan } from '../../chargebee/chargebee';
import './DashboardAlert.css';

const { Text } = Typography;

const InviteBillingManagerAlert = () => {
  const user = usePrerenderUser();
  const { track } = useEvent();
  const navigate = useNavigate();
  const location = useLocation();

  const onInviteClick = () => {
    track('Invite Billing Manager Alert Clicked');
    navigate('/security/team-members');
  };

  const showAlert = !isFreePlan(user.plan) && !user.hasBillingManager && location.pathname !== '/security/team-members';
  if (showAlert) {
    const message = <Text strong>Friendly Reminder: Invite Billing Managers To Your Team</Text>;
    return (
      <Space className="dashboard-alert" direction="vertical" style={{ width: '100%' }}>
        <Alert
          message={message}
          description={
            'Don’t forget to add Billing Managers to your Prerender account. ' +
            'You’ll help declutter your inbox and guarantee that invoicing emails ' +
            'go to the right users.'
          }
          banner={true}
          type="success"
          action={
            <Button type="primary" onClick={onInviteClick}>
              Add Billing Managers
            </Button>
          }
        />
      </Space>
    );
  }
  return <></>;
};

export default InviteBillingManagerAlert;
