import { restApi } from '../api/restApiSlice';

const analyticsApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnalytics: builder.query({
      query: (params) => ({ url: `/analytics/crawled-pages`, method: 'GET', params }),
      providesTags: ['analytics'],
    }),
    getStats: builder.query({
      query: (params) => ({ url: `/analytics/stats`, method: 'GET', params }),
      providesTags: ['analytics'],
    }),
    exportData: builder.mutation({
      query: (body) => ({ url: `/analytics/export`, method: 'POST', body }),
    }),
  }),
});

export const { useGetAnalyticsQuery, useGetStatsQuery, useExportDataMutation } = analyticsApiSlice;
