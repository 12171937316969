import { ConfigProvider as ConfigProvider5 } from 'antd';
import 'babel-polyfill';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './assets/vendor/font-awesome/css/font-awesome.min.css';
import FullPageLoading from './components/utils/FullPageLoading';
import { initKeycloak } from './features/auth/keycloakAuth';
import { enableMockingOnDevelopment } from './mock.api';
import store from './store';
import { themeConfig } from './theme';
import { initChartLibrary } from './assets/charts';

import App from './App';

initChartLibrary();
initKeycloak(store.dispatch);

// this is injected by this script https://assets.prerender.io/journey.root.rv7.js
declare global {
  interface Window {
    saveJourney: (token: string, userId?: number) => void;
  }
}

// Will make this toggleable later
enableMockingOnDevelopment().then(() => {
  const container = document.querySelector('#root');
  const root = createRoot(container);
  root.render(
    <Suspense fallback={<FullPageLoading />}>
      <ConfigProvider5 prefixCls="ant5" theme={themeConfig}>
        <Provider store={store}>
          <HelmetProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </HelmetProvider>
        </Provider>
      </ConfigProvider5>
    </Suspense>
  );
});
