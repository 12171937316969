import { Fragment } from 'react';
import { ModalHeader, ModalBodyScroller } from '../../components/ModalLib';
import { ControlButton } from '../../components/PageElementsLib';
import { Container } from '../../components/CssFrameworkComponents';
import { MODAL_CANCALLED_ACTION, INTEGRATION_SELECTED_ACTION } from './onboardingActions';

const ONBOARDING_IMAGE_PATH = '/assets/img/onboarding/';

const IntegrationSelectorModalContent = ({ modalCm, onEvent, isLoading }) => (<Fragment>
  <ModalHeader
    modalHeaderLeft={<ControlButton text={modalCm.header.btnLeft} disabled={isLoading} onclick={() => onEvent({ action: MODAL_CANCALLED_ACTION })}/>}
    modalHeaderRight={<div className="mx-4"/>}
    title={ modalCm.header.title }
  />
  <ModalBodyScroller>
    <Container fluid>
      <div className="row align-items-center m-4">
        <div className="col-auto">
          <h1>{modalCm.body.intro.title}</h1>
          <h4>{modalCm.body.intro.subtitle}</h4>
        </div>
        <div className="col-auto mt-2">
          <p>
            {modalCm.body.intro.content}
          </p>
        </div>
        <div className="col-auto mt-2">
          <p className="font-weight-bold">
            {modalCm.body.methods.title}
          </p>
        </div>
        <div className="container">
          <div className="row">
            {modalCm.body.methods.integrations.map((integration) => (<div key={integration} className="col-md-3 col-6 align-self-center p-2 h-100">
              <button type="button" className="btn btn-outline-info w-100" disabled={isLoading}
              onClick={() => { onEvent({ action: INTEGRATION_SELECTED_ACTION, payload: integration }); }}>
                  <img
                  src={`${ONBOARDING_IMAGE_PATH + integration.toLowerCase()}.svg`}
                  width='48px' height="48px"
                  alt={integration}
                  className="pb-3"/>
                  <h4 className="mb-0">{integration}</h4>
                </button>
              </div>))}
          </div>
        </div>
      </div>
    </Container>
  </ModalBodyScroller>
</Fragment>);

export default IntegrationSelectorModalContent;
