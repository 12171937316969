export function createExpensesDataFromInvoices(data, numberOfMonths) {
  const dateKeysForNumberOfMonths = Object.keys(data).slice(-numberOfMonths);

  const reservedComputationDataset = {
    backgroundColor: '#6BB5F0',
    label: 'Subscription Cost $',
    data: dateKeysForNumberOfMonths.map((dateKey) => data[dateKey].planCost / 100),
  };

  const onDemandComputationDataset = {
    backgroundColor: '#FFBA53',
    label: 'Extra Renders Cost $',
    data: dateKeysForNumberOfMonths.map((dateKey) => data[dateKey].onDemandCost / 100),
  };

  const labels = dateKeysForNumberOfMonths.map((dateKey) =>
    new Date(dateKey).toLocaleString('en-US', { month: 'long' })
  );

  return {
    datasets: [reservedComputationDataset, onDemandComputationDataset],
    labels,
  };
}
