import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';

const RedirectPage = () => {
  const location = useLocation();

  if (location.pathname === '/signup') {
    const urlParams = new URLSearchParams(location.search);
    const plan = urlParams.get('plan');
    const username = urlParams.get('username');

    if (plan) {
      upsertJourneyCookie();
      logJourney(window.location, 'signup.visit');
    }

    if (username) {
      sessionStorage.setItem('username', username);
    }
  }

  return <Navigate to="/" />;
};

export default RedirectPage;
