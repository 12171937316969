import { connect } from 'react-redux';
import { ModalWrapper } from '../../components/ModalLib';
import { TWrapper, TRow, TCell } from '../../components/TableLib';
import { useEvent } from '../events/hooks/useEvent';
import { useLazyGetURLsQuery, useClearUrlCacheMutation } from '../urls/api/urlsApiSlice';
import { useEffect } from 'react';
import { Button, Flex, Typography } from 'antd';

const ClearPartialCacheModal = ({ onEvent, wildcard, prerenderUser }) => {
  const { track } = useEvent();
  const limit = 10;
  const [clearUrlCache, clearUrlCacheResult] = useClearUrlCacheMutation();
  const [getUrls, urlsResult] = useLazyGetURLsQuery({ wildcard, limit });

  const urls = urlsResult.data?.urls || [];
  const urlsCount = urlsResult.data?.count || 0;

  useEffect(() => {
    getUrls({ wildcard, limit });
  }, []);

  const onSubmit = () => {
    clearUrlCache(wildcard)
      .unwrap()
      .finally(() => {
        track('Cache Partially Cleared', {
          subscription_plan: prerenderUser.chargebeePlanId,
        });
        onEvent({ action: 'closeClearPartialCacheModal' });
      });
  };

  const getTitle = () => {
    if (clearUrlCacheResult.isLoading) return 'Clearing cache for URLs, please wait...';
    if (urlsResult.isFetching) return 'Retrieving URLs, please wait...';

    return urlsCount === 0
      ? `No URLs found that matches: ${wildcard}`
      : `This will clear cache for ${urlsCount} URL${urlsCount == 1 ? '' : 's'} ${
          urlsCount > limit ? ', For example:' : ''
        }`;
  };

  return (
    <ModalWrapper maxWidth="750px">
      <Typography.Title level={5} style={{ margin: '0 auto', paddingTop: 16 }}>
        Clear Partial Cache - Confirmation
      </Typography.Title>
      <TWrapper
        tableId="urlsTable"
        parentType="modal"
        rootClassName="wrap-table-content"
        showTHead={false}
        cols={[{ size: 'flex' }]}
      >
        <div style={{ margin: '0 auto' }}>
          <Typography.Paragraph>{getTitle()}</Typography.Paragraph>
        </div>
        {urls.map((item, index) => {
          return (
            <TRow key={`url-${index}`}>
              <TCell addClass="pa-2">
                <span>{item.url}</span>
              </TCell>
            </TRow>
          );
        })}
      </TWrapper>
      <Flex justify="space-between" style={{ padding: 16 }}>
        <Button onClick={() => onEvent({ action: 'closeClearPartialCacheModal' })}>Cancel</Button>
        <Button
          type="primary"
          onClick={onSubmit}
          disabled={!urlsCount || clearUrlCacheResult.isLoading || urlsResult.isFetching}
          loading={clearUrlCacheResult.isLoading || urlsResult.isFetching}
        >
          Clear Cache
        </Button>
      </Flex>
    </ModalWrapper>
  );
};

const mapStateToProps = (state) => ({
  prerenderUser: state.prerenderUser,
});

export default connect(mapStateToProps)(ClearPartialCacheModal);
