const storage = localStorage;

export default class UserAgentState {
  #userAgentKey;

  #userAgents;

  constructor(userEmail, defaultUserAgents) {
    this.#userAgentKey = `${userEmail}-crawler-activity-table-user-agents-filter`;

    const state = storage.getItem(this.#userAgentKey);
    if (state === null) {
      storage.setItem(this.#userAgentKey, JSON.stringify(defaultUserAgents));
      this.#userAgents = defaultUserAgents;
    } else {
      this.#userAgents = JSON.parse(state);
    }
  }

  get userAgents() {
    return this.#userAgents;
  }

  update(newUserAgents) {
    if (
      !Array.isArray(newUserAgents) ||
      !newUserAgents.every((ua = {}) => typeof ua?.value === 'string' && typeof ua?.label === 'string')
    ) {
      console.warn('Tried to update UA store with invalid values:', newUserAgents);
      return;
    }

    storage.setItem(this.#userAgentKey, JSON.stringify(newUserAgents));
  }
}
