import React from 'react';
import { Tooltip } from 'antd';

import UALIST_NAMES from './userAgentNames';

const BASE_PATH = '/assets/img/user-agents-logo';
const DEFAULT_LOGO = `${BASE_PATH}/default.png`;

function UserAgentLogo({ userAgent }) {
  const src = React.useMemo(() => {
    const found = UALIST_NAMES.find((ua) => userAgent.toLowerCase().includes(ua.value));
    return found ? `${BASE_PATH}/${found.iconName}.png` : DEFAULT_LOGO;
  }, [userAgent]);

  return (
    <Tooltip placement="right" title={userAgent}>
      <img style={{ width: '20px', aspectRatio: 1, opacity: '.5' }} src={src} alt={userAgent} />
    </Tooltip>
  );
}

export default UserAgentLogo;
