import { restApi } from '../../api/restApiSlice';

export const packageApiSlice = restApi.injectEndpoints({
  tagTypes: ['packages'],
  endpoints: (builder) => ({
    getPackages: builder.query({
      query: () => '/packages',
    }),
    setPackageSubscription: builder.mutation({
      query: (body) => ({
        url: '/packages',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['packages'],
    }),
  }),
});

export const { useGetPackagesQuery, useSetPackageSubscriptionMutation } = packageApiSlice;
