const integrationNamesFormatting = {
  node: 'node',
  cloudflare: 'cloudflare',
  nginx: 'nginx',
  apache: 'apache',
  iis: 'iis',
  ruby: 'ruby',
  fastly: 'fastly',
  NextJS: 'NextJS',
  'magento-2': 'Magento 2',
  other: 'other',
};

export function formatIntegration(integration) {
  const formattedIntegrationName = integrationNamesFormatting[integration];
  if (!formattedIntegrationName) {
    throw new Error(`Missing formatting for integration: ${integration}`);
  }
  return formattedIntegrationName;
}
