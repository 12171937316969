import React from 'react';
import { Space, Tooltip, Popconfirm, Button } from 'antd';
import { DeleteOutlined, ReloadOutlined, SettingOutlined } from '@ant-design/icons';

function UserActions({
  onResendInviteClick,
  onUpdateUserClick,
  onRevokeInvitationClick,
  revokeInvitationLoading,
  onDeleteUserClick,
}) {
  return (
    <Space size="small">
      {onResendInviteClick && (
        <Tooltip placement="top" title="Resend Invite">
          <Button icon={<ReloadOutlined />} onClick={onResendInviteClick} />
        </Tooltip>
      )}
      {onUpdateUserClick && (
        <Tooltip placement="top" title="Settings">
          <Button icon={<SettingOutlined />} onClick={onUpdateUserClick} />
        </Tooltip>
      )}

      {onRevokeInvitationClick && (
        <Tooltip placement="top" title="Revoke Invitation">
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={onRevokeInvitationClick}
            loading={revokeInvitationLoading}
          />
        </Tooltip>
      )}
      {onDeleteUserClick && (
        <Popconfirm
          title="Are you sure you want to delete this user?"
          placement="left"
          onConfirm={onDeleteUserClick}
          okType="danger"
          okText="Delete"
          cancelText="Cancel"
        >
          <Tooltip placement="top" title="Delete User">
            <Button danger icon={<DeleteOutlined />} />
          </Tooltip>
        </Popconfirm>
      )}
    </Space>
  );
}

export default UserActions;
