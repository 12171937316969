import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSeoScore, clearSeoScore } from './redux/seoScoreModalActions';
import { closeModal } from '../../actions/pageActions';
import { Container } from '../../components/CssFrameworkComponents';
import { TWrapper, TRow, TCell } from '../../components/TableLib';
import { ModalWrapper, ModalHeader, ModalBodyScroller, ModalFooter } from '../../components/ModalLib';
import { LoadingTextBar, ControlButton } from '../../components/PageElementsLib';

export const SEO_SCORE_MODAL_NAME = 'SeoScoreModal';

const cid = 'SeoScoreModal';
const CLOSE_MODAL_ACTION = 'closeModal';
const SEND_FEEDBACK_ACTION = 'sendFeedbackAction';

const SeoScoreModal = ({
  cachedUrl,
  seoScoreModal,
  page,
  getSeoScore: doGetSeoScore,
  closeModal: doCloseModal,
  clearSeoScore: doClearSeoScore,
}) => {
  const isOpened = page.modalVisible === SEO_SCORE_MODAL_NAME;

  useEffect(() => {
    if (isOpened && !seoScoreModal.inProgress && !seoScoreModal.seoScoreResult && !page.lastErrorCode)
      doGetSeoScore(cachedUrl.id);
  });

  const onEvent = ({ action }) => {
    switch (action) {
      case CLOSE_MODAL_ACTION:
        doCloseModal();
        doClearSeoScore();
        break;
      default:
        break;
    }
  };

  const hasRecommendation = seoScoreModal.seoScoreResult?.result.recommendations.length > 0;
  const showTable = seoScoreModal.inProgress || (seoScoreModal.seoScoreResult && hasRecommendation);
  const showHead = showTable && !seoScoreModal.inProgress && hasRecommendation;

  return (
    <ModalWrapper minWidth="40%">
      <ModalHeader title="SEO score" />
      <ModalBodyScroller>
        <Container fluid>
          <div className="row align-items-center m-4">
            <div className="col-auto">
              <p>
                Don’t worry about the score value, it’s just our number!
                <br />
                The score value is calculated based on existing and missing commonly used elements and it is designed to
                help you easily find pages that may need improvement using simple SEO tips and tricks. Feel free to
                check our{' '}
                <a href="https://docs.prerender.io/docs/how-this-feature-works" target="_blank">
                  recommendations
                </a>{' '}
                and use them to optimize your pages.
              </p>
            </div>
          </div>
          {showTable && (
            <TWrapper
              tableId="seoScore"
              parentType="modal"
              cols={[
                { size: 'min', valign: 'middle', addClass: 'text-center', name: 'score delta' },
                { size: 'flex', valign: 'middle', addClass: 'text-center', name: 'description' },
                { size: 'min', valign: 'middle', addClass: 'text-center', name: 'documentation' },
              ]}
              showTHead={showHead}
              isLoading={seoScoreModal.inProgress}
              loadingCols={[<LoadingTextBar />, <LoadingTextBar />, <LoadingTextBar />]}
              maxLoadingRows={2}
            >
              {seoScoreModal.seoScoreResult &&
                seoScoreModal.seoScoreResult.result.recommendations.map((recommendation, index) => (
                  <TRow key={index}>
                    <TCell addClass={'text-center'}>
                      <div className="avatar avatar-sm">
                        <span className="avatar-title rounded-circle">
                          {recommendation.scoreDelta ? recommendation.scoreDelta : 'N/A'}
                        </span>
                      </div>
                    </TCell>
                    <TCell>{recommendation.description ? recommendation.description : 'N/A'}</TCell>
                    <TCell>
                      {recommendation.documentation && (
                        <a
                          type="button"
                          className="p-0 btn btn-normal btn-link link-blue"
                          href={recommendation.documentation}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Knowledge Base
                        </a>
                      )}
                    </TCell>
                  </TRow>
                ))}
            </TWrapper>
          )}
          {seoScoreModal.seoScoreResult && !hasRecommendation && (
            <div className="row align-items-center m-4">
              <div className="col-auto">
                <p>All is well; we have no recommendation for your content at this time.</p>
              </div>
            </div>
          )}
        </Container>
      </ModalBodyScroller>
      <ModalFooter
        modalFooterRight={<ControlButton text="Close" onclick={() => onEvent({ action: CLOSE_MODAL_ACTION })} />}
      />
    </ModalWrapper>
  );
};

function mapStateToProps(state) {
  return {
    seoScoreModal: state.seoScoreModal,
    page: state.page,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      closeModal,
      getSeoScore,
      clearSeoScore,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SeoScoreModal);
