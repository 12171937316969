import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IntegrationSelectorModalContent from './integrationSelectorModalContent';
import IntegrationModalContent from './integrationModalContent';
import { INTEGRATION_SELECTED_ACTION, PREVIOUS_STEP, INTEGRATION_ACKNOWLEDGED, INTEGRATION_VERIFICATION_DONE } from './onboardingActions';
import IntegrationVerificationModalContent from './integrationVerificationModalContent';
import IntegrationVerificationResult from './integrationVerificationResultModalContent';
import { clearIntegrationResult, fetchContent, clearContent, setStep } from './redux/onboardingActions';
import { ModalWrapper } from '../../components/ModalLib';

const MIN_STEP_NUMBER = 0;
const INTEGRATION_SELECTOR_STEP_NUMBER = 0;
const INTEGRATION_SELECTED_STEP_NUMBER = 1;
const INTEGRATION_VERIFICATION_STEP_NUMBER = 2;
const INTEGRATION_VERIFICATION_RESULT_STEP_NUMBER = 3;

const cid = 'Onboarding';

const OnboardingModal = ({ prerenderUser, onEvent, modalCm, onboarding,
  clearIntegrationResult: doClearIntegrationResult, fetchContent: doFetchContent, clearContent: doClearContent, setStep: doSetStep }) => {
  const { validationResult, integraionContent: content, integrationContentLoading: contentLoading, selectedIntegration, step } = onboarding;

  useEffect(() => {
    if (content) {
      doSetStep(INTEGRATION_SELECTED_STEP_NUMBER);
    }
  }, [content, doSetStep]);

  useEffect(() => {
    switch (step) {
      case INTEGRATION_SELECTOR_STEP_NUMBER:
        doClearContent();
        break;
      default:
        break;
    }
  }, [step, doClearContent]);

  useEffect(() => {
    if (validationResult) {
      doSetStep(INTEGRATION_VERIFICATION_RESULT_STEP_NUMBER);
    }
  }, [doSetStep, validationResult]);

  const integrationSelectorModalContentOnEvent = (event) => {
    switch (event.action) {
      case INTEGRATION_SELECTED_ACTION:
        doFetchContent(event.payload);
        break;

      case INTEGRATION_ACKNOWLEDGED:
        doSetStep(INTEGRATION_VERIFICATION_STEP_NUMBER);
        break;

      case PREVIOUS_STEP:
        doClearIntegrationResult();
        {
          const newStep = Math.max(step - 1, MIN_STEP_NUMBER);
          doSetStep(newStep);
        }
        break;

      case INTEGRATION_VERIFICATION_DONE:
        doClearIntegrationResult();
        onEvent(event);
        break;

      default:
        onEvent(event);
    }
  };

  const getModalContent = () => {
    switch (step) {
      case INTEGRATION_SELECTED_STEP_NUMBER:
        return (<IntegrationModalContent
          prerenderUser={prerenderUser}
          onEvent={integrationSelectorModalContentOnEvent}
          cm={modalCm.integration}
          content={content} />);
      case INTEGRATION_VERIFICATION_STEP_NUMBER:
        return (<IntegrationVerificationModalContent
          cm={modalCm.verification}
          onEvent={integrationSelectorModalContentOnEvent} />);
      case INTEGRATION_VERIFICATION_RESULT_STEP_NUMBER:
        return (<IntegrationVerificationResult
          cm={modalCm.result}
          prerenderUser={prerenderUser}
          integration={selectedIntegration}
          validationResult={validationResult}
          onEvent={integrationSelectorModalContentOnEvent} 
          showHeader={true} />);

      case INTEGRATION_SELECTOR_STEP_NUMBER:
      default:
        return (<IntegrationSelectorModalContent
          modalCm={modalCm.selector}
          onEvent={integrationSelectorModalContentOnEvent}
          isLoading={contentLoading} />);
    }
  };

  return <ModalWrapper maxWidth='50em' height='45em'>
    {getModalContent()}
  </ModalWrapper>;
};

function mapStateToProps(state) {
  return {
    modalCm: state.page.contentData[cid].modal,
    onboarding: state.onboarding,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    clearIntegrationResult,
    fetchContent,
    clearContent,
    setStep,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingModal);
