import React from 'react';

import { Bar } from 'react-chartjs-2';
import { createExpensesDataFromInvoices } from './createExpensesData';
import { useGetRenderExpensesQuery } from '../api/billingApiSlice';
import { useSelector } from 'react-redux';
import { useEvent } from '../../events/hooks/useEvent';

const BillingRenderExpenses = () => {
  const { data: expenses } = useGetRenderExpensesQuery();
  const [monthsFilterValueNumber, setMonthsFilterValueNumber] = React.useState(3);

  const userPlan = useSelector((state) => state.prerenderUser.planName);
  const { track } = useEvent();

  const handleTrackFilterEvent = React.useCallback(
    (timeInterval) => {
      track('Render Expenses Filtered', { subscription_plan: userPlan, time_interval: timeInterval });
    },
    [userPlan]
  );

  if (!expenses?.data) {
    return null;
  }

  const data = createExpensesDataFromInvoices(expenses?.data, monthsFilterValueNumber);

  return (
    <div style={{ position: 'relative' }}>
      {/* <div className="dropdown" style={{ position: 'absolute', top: '0', right: '0' }}>
        <Select
          value={monthsFilterValueNumber}
          onChange={(value) => {
            setMonthsFilterValueNumber(value);
            handleTrackFilterEvent(`${value} months`);
          }}
          options={[
            { value: 3, label: '3 months' },
            { value: 6, label: '6 months' },
            { value: 12, label: '12 months' },
          ]}
        />
      </div> */}
      <Bar
        title="Render Expenses"
        options={{
          animation: false,
          plugins: {
            title: { display: true, text: 'Render Expenses', position: 'top', align: 'start' },
            legend: {
              display: true,
              position: 'top',
              align: 'start',
              labels: {
                usePointStyle: true,
              },
              padding: { bottom: 6 },
            },
          },

          scales: {
            y: {
              stacked: true,
              ticks: {
                stepSize: 0.1,
                beginAtZero: true,
              },
              grid: {
                display: true,
                color: '#D3D3D3',
                borderDash: [5, 5], // Set the border dash pattern for dotted lines [dash length, gap length]
              },
            },
            x: {
              stacked: true,
              grid: {
                display: true,
                color: '#D3D3D3',
                borderDash: [5, 5], // Set the border dash pattern for dotted lines [dash length, gap length]
              },
            },
          },
        }}
        data={data}
      />
    </div>
  );
};

export default BillingRenderExpenses;
