import {
  GET_DOMAINS_IN_PROGRESS,
  GET_DOMAINS_SUCCESS
} from '../actions/types';

const initialState = {
  inProgress: false,
  domains: [],
  totalCount: 0,
};

export default function domainReducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case GET_DOMAINS_IN_PROGRESS:
      return { ...state, inProgress: payload };
    case GET_DOMAINS_SUCCESS:
      return {
        ...state,
        domains: payload.domains,
        totalCount: payload.totalCount,
      };
    default:
      return state;
  }
}
