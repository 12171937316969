import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Select } from 'antd';
import { createRenderUsageData } from './createRenderUsageData';
import { useGetRenderUsageQuery } from '../api/billingApiSlice';
import { useEvent } from '../../events/hooks/useEvent';
import { useSelector } from 'react-redux';

const BillingRenderUsage = () => {
  const [timeFilterValueInDays, setTimeFilterValueInDays] = React.useState(7);
  const { data: renderUsage } = useGetRenderUsageQuery(timeFilterValueInDays, 0);

  const data = createRenderUsageData(renderUsage?.data || []);

  const userPlan = useSelector((state) => state.prerenderUser.planName);
  const { track } = useEvent();

  const handleTrackFilterEvent = React.useCallback(
    (timeInterval) => {
      track('Render Usage Filtered', { subscription_plan: userPlan, time_interval: timeInterval });
    },
    [userPlan]
  );

  if (!renderUsage?.data) {
    return null;
  }

  return (
    <div style={{ position: 'relative' }}>
      <div className="dropdown" style={{ position: 'absolute', top: '0', right: '0' }}>
        <Select
          value={timeFilterValueInDays}
          onChange={(value) => {
            setTimeFilterValueInDays(value);
            handleTrackFilterEvent(`${value} days`);
          }}
          options={[
            { value: 7, label: 'last 7 days' },
            { value: 14, label: 'last 14 days' },
            { value: 30, label: 'last 30 days' },
          ]}
        />
      </div>
      <Bar
        title="Render usage"
        options={{
          animation: false,
          plugins: {
            title: { display: true, text: '', position: 'top', align: 'start' },
            legend: {
              display: true,
              position: 'top',
              align: 'start',
              labels: {
                usePointStyle: true,
              },
              padding: { bottom: 6 },
            },
          },

          scales: {
            y: {
              ticks: {
                stepSize: 0.1,
                beginAtZero: true,
              },
              grid: {
                display: true,
                color: '#D3D3D3',
                borderDash: [5, 5], // Set the border dash pattern for dotted lines [dash length, gap length]
              },
            },
            x: {
              grid: {
                display: true,
                color: '#D3D3D3',
                borderDash: [5, 5], // Set the border dash pattern for dotted lines [dash length, gap length]
              },
            },
          },
        }}
        data={data}
      />
    </div>
  );
};

export default BillingRenderUsage;
