import React from 'react';
import { useSelector } from 'react-redux';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';
import GeneralSettings from '../features/settings/generalSettings/generalSettings';
import EmailPreferencesTab from '../features/settings/emailPreferences/emailPreferences';
import { Alert, Tabs, Typography } from 'antd';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

const META_TAGS = {
  title: 'Settings - Prerender.io',
};

const KEY_LABEL_MAP = {
  general: 'General',
  emailPreferences: 'Email Preferences',
};

const tabs = [
  {
    key: 'general',
    label: KEY_LABEL_MAP['general'],
    component: GeneralSettings,
  },
  {
    key: 'emailPreferences',
    label: KEY_LABEL_MAP['emailPreferences'],
    component: EmailPreferencesTab,
  },
];

const SettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const apiErrTxt = useSelector((state) => state.page.contentData.apiError);
  const lastErrorCode = useSelector((state) => state.page.lastErrorCode);

  const activeTab = React.useMemo(() => location.pathname.split('/').pop(), [location.pathname]);

  return (
    <AdminTemplate metaTags={META_TAGS}>
      {lastErrorCode && <Alert type="error" message={apiErrTxt[lastErrorCode] && apiErrTxt[lastErrorCode]} closable />}
      <Typography.Title level={2}>Settings</Typography.Title>
      <Tabs items={tabs} activeKey={activeTab} onChange={(key) => navigate(key)} />

      <Routes>
        {tabs.map(({ key, component: Component }) => (
          <Route path={key} key={key} element={<Component />} />
        ))}
        <Route path="*" element={<Navigate to={tabs[0].key} />} />
      </Routes>
    </AdminTemplate>
  );
};

export default requireAuth(SettingsPage);
