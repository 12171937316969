import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Flex, Typography, theme } from 'antd';

import { useEvent } from '../../events/hooks/useEvent';

function CurrentToken({ showBanner = true, tokenStyle }) {
  const { token } = theme.useToken();
  const prerenderToken = useSelector((state) => state.prerenderUser.token);
  const chargebeePlanId = useSelector((state) => state.prerenderUser.chargebeePlanId);
  const { track } = useEvent();

  const onTokenCopied = () => {
    track('Token Copied', {
      location: window.location.pathname,
      subscription_plan: chargebeePlanId,
    });
  };

  return (
    <Flex vertical gap="large">
      {showBanner && (
        <Alert
          message="This token is used in your integration to authenticate requests belonging to your account."
          type="info"
          showIcon
        />
      )}
      <Typography.Text
        code
        copyable={{ text: prerenderToken, onCopy: onTokenCopied }}
        style={{ textAlign: 'center', fontSize: token.fontSizeXL, ...tokenStyle }}
      >
        {prerenderToken}
      </Typography.Text>
    </Flex>
  );
}

export default CurrentToken;
