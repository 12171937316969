import React, { useState } from 'react';
import { Flex, Input, Table, Button, Typography, Tooltip, message, ConfigProvider, Empty } from 'antd';
import { DesktopOutlined, MobileOutlined, SearchOutlined } from '@ant-design/icons';
import { getAgeString, getDateTimeTooltip } from '../../assets/time';
import { useDispatch } from 'react-redux';
import { requestCaching } from '../../actions/cachedPagesActions';
import { Link as RouteLink } from 'react-router-dom';
import { openModal } from '../../actions/pageActions';
import SeoScoreModal from '../seoScoreModal/seoScoreModal';
import SeoScoreStatusComponent from '../../components/pages/SeoScoreStatusComponent';
import SeoScoreDetailsModal from '../seoScoreDetailsModal/SeoScoreDetailsModal';
import { useShowNewSeoScore } from '../../hooks/useShowNewSeoScore';
import { isFreePlan } from '../../chargebee/chargebee';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import ExportButton from '../../components/ExportButton';
import SeoScoreTag from './components/SeoScoreTag';
import ButtonWithRole from '../../components/ButtonWithRole';
import USER_ROLES from '../../features/auth/userRoles';

const SHOW_NEVER = true;

const { Link } = Typography;

const seoScoreStatuses = [
  { value: 'improvement', text: 'Improvement' },
  { value: 'passed', text: 'Passed' },
  { value: 'unknown', text: 'Unknown' },
];

export const SeoReportUrlsTable = ({
  urls,
  isFetching,
  tableParams,
  handleTableChange,
  debouncedSetTableParams,
  totalUrls,
  exportModalOpen,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const showNewScore = useShowNewSeoScore();
  const prerenderUser = usePrerenderUser();

  const paidPlan = !isFreePlan(prerenderUser.plan);

  const onInputChange = (e) => {
    const newInput = e.target.value;
    setSearchInput(newInput);
    debouncedSetTableParams(newInput);
  };

  const handleRecache = (url) => {
    dispatch(
      requestCaching('all', [url], () => {
        messageApi.open({
          type: 'success',
          content: (
            <span>
              1 URL was added to the Priority Recache Queue. <RouteLink to="/recache-queue">View Queue</RouteLink>
            </span>
          ),
        });
      })
    );
  };

  const openSeoScoreModal = (url) => {
    if (url.seo_score !== null) {
      dispatch(openModal('SeoScoreModal', <SeoScoreModal cachedUrl={url} onEvent={{ action: 'closeModal' }} />));
    }
  };

  const openSeoScoreStatusModal = (url) => {
    if (url.seo_score_status !== 'unknown') {
      dispatch(openModal('SeoScoreDetailsModal', <SeoScoreDetailsModal url={url} />));
    }
  };

  return (
    <>
      {contextHolder}
      <Flex justify="space-between">
        <Input
          placeholder="search url"
          allowClear
          value={searchInput}
          onChange={onInputChange}
          prefix={<SearchOutlined style={{ color: '#D9D9D9' }} />}
          style={{ marginBottom: 16, width: '30%' }}
        />
        <ExportButton isFreePlan={!paidPlan} onClick={exportModalOpen} />
      </Flex>
      <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No URLs found" />}>
        <Table
          loading={isFetching}
          dataSource={urls}
          onChange={handleTableChange}
          bordered
          pagination={{
            current: tableParams.pagination.current,
            pageSize: tableParams.pagination.pageSize,
            total: totalUrls,
          }}
          rowKey="id"
        >
          <Table.Column
            title="URL"
            dataIndex="url"
            key="url"
            render={(url) => (
              <div className="d-inline-block text-truncate url-shortener">
                <Link href={url} target="_blank" rel="noreferrer">
                  {url}
                </Link>
              </div>
            )}
          />
          <Table.Column
            title="Device"
            dataIndex="adaptive_type"
            key="adaptive_type"
            align="center"
            width="5%"
            filters={[
              {
                text: 'Desktop',
                value: 'desktop',
              },
              {
                text: 'Mobile',
                value: 'mobile',
              },
            ]}
            render={(adaptiveType) => (
              <Tooltip
                title={`Rendering was optimized for ${adaptiveType === 'mobile' ? 'Mobile' : 'Desktop'} crawlers`}
                placement="top"
              >
                {adaptiveType === 'mobile' ? <MobileOutlined /> : <DesktopOutlined />}
              </Tooltip>
            )}
          />
          {showNewScore ? (
            <Table.Column
              title="SEO score"
              dataIndex="seo_score_status"
              key="seo_score_status"
              align="center"
              width="5%"
              filters={seoScoreStatuses}
              sorter
              render={(seo_score_status, record) => (
                <Button
                  type="ghost"
                  size="small"
                  onClick={() => {
                    openSeoScoreStatusModal(record);
                  }}
                >
                  <SeoScoreStatusComponent status={seo_score_status} />
                </Button>
              )}
            />
          ) : (
            <Table.Column
              title="SEO score"
              dataIndex="seo_score"
              key="seo_score"
              align="center"
              width="5%"
              sorter
              render={(score, record) => (
                <Button
                  type="ghost"
                  size="small"
                  onClick={() => {
                    openSeoScoreModal(record);
                  }}
                >
                  <SeoScoreTag score={score} />
                </Button>
              )}
            />
          )}

          <Table.Column
            title="Content Age"
            dataIndex="last_refresh"
            key="last_refresh"
            width="10%"
            align="center"
            sorter
            render={(lastRefresh) => (
              <span data-tip={lastRefresh && getDateTimeTooltip(lastRefresh)}>
                {getAgeString(new Date(lastRefresh), 'ago', 'long', SHOW_NEVER)}
              </span>
            )}
          />
          <Table.Column
            title=""
            key="recache"
            width="10%"
            align="center"
            render={(_, record) => (
              <ButtonWithRole
                disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                size="small"
                onClick={() => {
                  handleRecache(record);
                }}
              >
                Recache
              </ButtonWithRole>
            )}
          />
        </Table>
      </ConfigProvider>
    </>
  );
};
