import { Modal, Button, Table, Tag, Col, Row, Card, Alert, Typography } from 'antd';
const { Title, Text } = Typography;

// requests: { url: string; status?: number; statusText?: string; errorCode?: string }[];
const requestsColumns = [
  {
    title: 'URL',
    dataIndex: 'url',
  },
  {
    title: 'Error code',
    dataIndex: 'errorCode',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Status Text',
    dataIndex: 'statusText',
  },
];

const invalidUrlsColumns = [
  {
    title: 'Invalid URL',
    dataIndex: 'url',
  },
];

export function SitemapCrawlDetailsModal({ crawlResult, onClose }) {
  return (
    <Modal
      width={'70%'}
      style={{ top: 20 }}
      open={crawlResult !== null}
      title="Sitemap Crawl details"
      onCancel={onClose}
      onOk={onClose}
      transitionName=""
      maskTransitionName=""
      footer={[
        <Button key="back" onClick={onClose}>
          Ok
        </Button>,
      ]}
    >
      <p>We had difficulity crawling this sitemap. Here is a list of issues we faced.</p>

      <Row gutter={15}>
        <Col span={5}>
          <Card style={{ margin: 10 }}>
            {crawlResult?.result?.is_sitemap_found ? (
              <Alert message="We reached the sitemap URL" type="success" />
            ) : (
              <Alert message="We could not reach the sitemap URL" type="error" />
            )}
          </Card>
        </Col>
        <Col span={5}>
          <Card style={{ margin: 10 }}>
            {crawlResult?.result?.is_sitemap_valid === true ? (
              <Alert message="The sitemap seems to be valid XML/text file" type="success" />
            ) : null}
            {crawlResult?.result?.is_sitemap_valid === false ? (
              <Alert message="The sitemap is not valid XML/text file" type="error" />
            ) : null}
            {crawlResult?.result?.is_sitemap_valid === null ? (
              <Alert message="Could not determine if sitemap is valid XML/text file" type="info" />
            ) : null}
          </Card>
        </Col>
        <Col span={5}>
          <Card style={{ margin: 10 }}>
            {crawlResult?.result?.is_sitemap_valid !== null &&
            crawlResult?.result?.sub_sitemaps_depth_limit_reached === false ? (
              <Alert message="Sub-sitemap limit is not reached" type="success" />
            ) : null}
            {crawlResult?.result?.sub_sitemaps_depth_limit_reached === true ? (
              <Alert message="Sub-sitemap limit is reached. We allow maximum of 3 nested sitemap dept" type="error" />
            ) : null}
            {crawlResult?.result?.is_sitemap_valid === null ||
            crawlResult?.result?.sub_sitemaps_depth_limit_reached === null ? (
              <Alert message="Could not determine if sitemap nesting depth is reached" type="info" />
            ) : null}
          </Card>
        </Col>
      </Row>

      <Title level={2}>Outbound requests</Title>
      <Table dataSource={crawlResult?.result?.requests} columns={requestsColumns} />

      <Title level={2}>Invalid URLs found</Title>
      <Text>Does not include all invalid URLs, just examples.</Text>
      <Table
        dataSource={crawlResult?.result?.invalid_url_examples?.map((url) => ({ url }))}
        columns={invalidUrlsColumns}
      />
    </Modal>
  );
}
