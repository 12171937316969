import { useState } from 'react';
import { Button, Flex, Select } from 'antd';
import { ModalWrapper, ModalHeader } from '../../components/ModalLib';
import { TWrapper, TRow, TCell } from '../../components/TableLib';

const selectIntervalOptions = [
  { value: '1day', label: 'Past 24 hours' },
  { value: '7days', label: 'Past 7 days' },
  { value: '30days', label: 'Past 30 days' },
];

const selectFileTypeOptions = [
  { value: 'csv', label: 'CSV file' },
  // { value: 'excel', label: 'Spreadsheet (XLSX - Max 65536 rows of data allowed)' },
];

export default function ExportRenderHistoryModal({ onEvent }) {
  const [userInput, setUserInput] = useState({
    interval: selectIntervalOptions[0].value,
    fileType: selectFileTypeOptions[0].value,
  });

  const appendUserInput = (newInput) => {
    setUserInput((prevInput) => ({ ...prevInput, ...newInput }));
  };

  return (
    <ModalWrapper maxHeight={'380px'}>
      <ModalHeader title="Export Render History" />
      <TWrapper
        tableId="renderHistoryExportModalWrapper"
        parentType="modal"
        showTHead={false}
        cols={[{ size: 'flex', valign: 'middle' }]}
      >
        <TRow>
          <TCell addStyle={{ border: 'none' }}>
            <p>Select Report Interval</p>
            <Select
              options={selectIntervalOptions}
              defaultValue={selectIntervalOptions[0].value}
              className="w-100 no-border"
              onChange={(value) => appendUserInput({ interval: value })}
            ></Select>
          </TCell>
        </TRow>
        <TRow>
          <TCell addStyle={{ border: 'none' }}>
            <p>Select Report Type</p>
            <Select
              options={selectFileTypeOptions}
              defaultValue={selectFileTypeOptions[0].value}
              className="w-100 no-border"
              onChange={(value) => appendUserInput({ fileType: value })}
            ></Select>
          </TCell>
        </TRow>
        <TRow>
          <TCell colspan="3">
            <Flex justify="space-between">
              <Button onClick={() => onEvent({ action: 'modalCancelled' })}>Cancel</Button>
              <Button type="primary" onClick={() => onEvent({ action: 'exportAnalytics', payload: userInput })}>
                Export
              </Button>
            </Flex>
          </TCell>
        </TRow>
      </TWrapper>
    </ModalWrapper>
  );
}
