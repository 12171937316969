export function createRenderUsageData(data) {
  // For the moment the BE does not support the differentiation between reserverd and on-demand renders.

  // const reservedComputationDataset = {
  //   backgroundColor: '#6FC49D',
  //   label: 'Reserved Computation',
  //   data: data.map((entry) => entry.scheduled),
  // };

  // const onDemandComputationDataset = {
  //   backgroundColor: '#FFBA53',
  //   label: 'On-Demand Computation',
  //   data: data.map((entry) => entry.onDemand),
  // };

  const totalComputationDataset = {
    backgroundColor: '#6FC49D',
    label: 'Total Renders',
    data: data.map((entry) => parseFloat(entry.scheduled) + parseFloat(entry.onDemand)),
  };

  const labels = data.map((entry) => new Date(entry.day).toLocaleString('en-US', { day: 'numeric', month: 'short' }));

  return {
    datasets: [totalComputationDataset],
    labels,
  };
}
