import Cookies from 'universal-cookie';

const KEY_NPS_SURVEY_COMPLETED = 'npssurveycompleted';

// ----- NPS survey

export const setNpsSurveyCompletedDate = (date) => {
  try {
    const cookies = new Cookies();
    return date
      ? cookies.set(KEY_NPS_SURVEY_COMPLETED, `${date.getTime()}`)
      : cookies.remove(KEY_NPS_SURVEY_COMPLETED);
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getNpsSurveyCompletedDate = () => {
  try {
    const cookies = new Cookies();
    const timestamp = parseInt(cookies.get(KEY_NPS_SURVEY_COMPLETED), 10);
    return Number.isNaN(timestamp) ? null : new Date(timestamp);
  } catch (e) {
    console.error(e);
    return null;
  }
};
