import React from 'react';
import { Table, ConfigProvider, Empty } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';
import { useUserRole } from '../../../hooks/usePrerenderUser';
import URLParameter from '../URLParametersTab/URLParameter';

const getColumns = (onEditClicked: (rowIndex: number) => void) => [
  {
    title: 'Parameter',
    key: 'param',
    dataIndex: 'param',
    width: '99%',
  },
  {
    title: 'Rule',
    key: 'action',
    dataIndex: 'action',
    className: 'col-min',
  },
  {
    title: 'Edit',
    key: 'edit',
    width: '1%',
    render: (_text, _record, index) => (
      <ButtonWithRole
        disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
        type="text"
        onClick={() => onEditClicked(index)}
        icon={<EditOutlined />}
      >
        {''}
      </ButtonWithRole>
    ),
  },
];

type UrlParametersSettingsTableProps = {
  isLoading?: boolean;
  items?: Array<URLParameter>;
  hideIgnoreWhenCachingParams?: boolean;
  hideUseForCachingParams?: boolean;
  onRowEditClick: (item: URLParameter) => void;
  selectedRows: URLParameter[];
  onSelectedRowsChange: (selectedRows: URLParameter[]) => void;
};

function UrlParametersSettingsTable({
  isLoading = false,
  items = [],
  hideIgnoreWhenCachingParams,
  hideUseForCachingParams,
  onRowEditClick,
  selectedRows,
  onSelectedRowsChange,
}: UrlParametersSettingsTableProps) {
  const userRole = useUserRole();

  const rows = React.useMemo(
    () =>
      items.map((item) => ({
        id: item.id,
        param: item.value,
        ignore: item.ignore,
        action: item.ignore ? 'Ignore when caching' : 'Use for caching',
      })),
    [items]
  );

  const getRowClassName = (row: URLParameter) => {
    if (row.ignore && hideIgnoreWhenCachingParams) return 'text-muted';

    if (!row.ignore && hideUseForCachingParams) return 'text-muted';

    return '';
  };

  const onEditClick = (index: number) => onRowEditClick(items[index]);

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Help Prerender cache your site more efficiently by indicating how we should handle query parameters after the ? in your URLs"
        />
      )}
    >
      <Table
        bordered={!!rows.length}
        rowKey="id"
        loading={isLoading}
        rowSelection={{
          type: 'checkbox',
          onChange: (_ids, rows) => onSelectedRowsChange(rows),
          selectedRowKeys: selectedRows.map((row) => row.id),
          getCheckboxProps: (_record) => ({
            disabled: userRole === USER_ROLES.BILLING_MANAGER || userRole === USER_ROLES.GUEST,
          }),
        }}
        columns={getColumns(onEditClick)}
        dataSource={rows}
        pagination={false}
        rowClassName={getRowClassName}
        showHeader={!!rows.length}
        scroll={{ x: true }}
      />
    </ConfigProvider>
  );
}

export default UrlParametersSettingsTable;
