import React from 'react';
import { message } from 'antd';
import { useDispatch } from 'react-redux';

import { usePrerenderUser } from '../../../hooks/usePrerenderUser';
import { formatCacheRefreshInterval } from '../../../assets/dateFormatter';
import { useGetDomainsQuery } from '../../domain-manager/api/domainManagerApiSlice';
import useQueryStringParameters from '../../../utils/useQueryStringParameters';
import useQueryStringPagination from '../../../utils/useQueryStringPagination';
import { useEvent } from '../../events/hooks/useEvent';
import { prerenderUserPatch } from '../../../actions/prerenderUserActions';

import CachedPagesAdd from '../components/CachedPagesAdd';
import {
  useGetCachedPagesListQuery,
  useUpdateCacheFreshnessMutation,
  useRecacheURLsMutation,
  useDeleteURLsMutation,
} from '../CacheManagerApi';
import Heading from './Heading';
import URLsAddedAlert from './URLsAddedAlert';
import CacheFreshnessUpdatedAlert from './CacheFreshnessUpdatedAlert';
import URLsCachedAlert from './URLsCachedAlert';
import CacheManagerTable from './CacheManagerTable';
import { buildCachedPagesQueryParameters, CachedPagesQueryParameters, QueryParamNames } from './QueryParameters';

const DEFAULT_PAGE_SIZE = 50;

function CacheManagerTab() {
  const [messageApi, messageContext] = message.useMessage();
  const [addPagesModalVisible, setAddPagesModalVisible] = React.useState(false);
  const [urlsAddedAmount, setUrlsAddedAmount] = React.useState(0);
  const [newCacheFreshnessValue, setNewCacheFreshnessValue] = React.useState(0);
  const [urlsCachedAmount, setUrlsCachedAmount] = React.useState(0);

  const { pagination, updatePagination } = useQueryStringPagination(DEFAULT_PAGE_SIZE);
  const { queryStrings, updateParameter, updateMultipleParameters, clearAllParameters } =
    useQueryStringParameters<CachedPagesQueryParameters>(buildCachedPagesQueryParameters);
  const domainsResult = useGetDomainsQuery('');
  const cachedPagesResult = useGetCachedPagesListQuery({ ...queryStrings, ...pagination });

  const [updateCacheFreshnessReq, updateCacheFreshnessResult] = useUpdateCacheFreshnessMutation();
  const [requestCaching, requestCachingResult] = useRecacheURLsMutation();
  const [deleteURLsReq, deleteURLsResult] = useDeleteURLsMutation();

  const dispatch = useDispatch();
  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();

  React.useEffect(() => {
    if (cachedPagesResult.isError)
      messageApi.error('An error occurred while fetching cached pages, please try again later');

    if (domainsResult.isError) messageApi.error('An error occurred while fetching domains, please try again later');

    if (updateCacheFreshnessResult.isError)
      messageApi.error('An error occurred while updating cache freshness, please try again later');

    if (requestCachingResult.isError)
      messageApi.error('An error occurred while requesting caching, please try again later');

    if (deleteURLsResult.isError) messageApi.error('An error occurred while deleting URLs, please try again later');
  }, [
    cachedPagesResult.isError,
    domainsResult.isError,
    updateCacheFreshnessResult.isError,
    requestCachingResult.isError,
    deleteURLsResult.isError,
  ]);

  const cacheSettingsToolTip = React.useMemo(() => {
    const isTooltip = true;
    if (prerenderUser.chargebeePlanId === 'basic-001') {
      return 'The Basic plan only allows a fixed cache expiration of 3 days';
    } else {
      return `Your pricing plan allows a cache freshness between ${formatCacheRefreshInterval(
        prerenderUser.plan.current.cacheRefreshInterval,
        isTooltip
      )}`;
    }
  }, [prerenderUser.chargebeePlanId, prerenderUser.plan.current.cacheRefreshInterval]);

  const setLastCrawledFilterActive = (value: boolean) => {
    const dateOfAmonthAgo = new Date();
    dateOfAmonthAgo.setMonth(dateOfAmonthAgo.getMonth() - 1);

    updateParameter('last_delivery_at', value ? dateOfAmonthAgo.toISOString() : '');
  };

  const updateCacheFreshness = async (days: number) => {
    try {
      await updateCacheFreshnessReq({ value: days }).unwrap();
      dispatch(prerenderUserPatch({ cacheFreshness: days }));
      setNewCacheFreshnessValue(days);
      track('Cache Expiration Set', {
        subscription_plan: prerenderUser.chargebeePlanId,
        cache_expiration_time: days,
      });
    } catch {}
  };

  const updateDomain = (domain: string) => {
    updateParameter('domain', domain);
    track('Cache URL list serched: Domain', {
      subscription_plan: prerenderUser.chargebeePlanId,
      domain,
    });
  };

  const updateSearchCondition = (condition: string) => {
    updateParameter('qCondition', condition);
  };

  const updateSearchQuery = (query: string) => {
    if (!query || query.length > 2) {
      updateParameter('q', query);
      track('Cache URL List Searched', {
        search_string: query,
        subscription_plan: prerenderUser.chargebeePlanId,
      });
    }
  };

  const clearFilters = () => {
    clearAllParameters();
  };

  const sortAndFilter = (filters: {
    sortField: CachedPagesQueryParameters['sort'];
    sortDirection: CachedPagesQueryParameters['sortDirection'];
    filters: Pick<
      CachedPagesQueryParameters,
      QueryParamNames.AdaptiveType | QueryParamNames.Source | QueryParamNames.CacheState
    >;
  }) => {
    updateMultipleParameters({
      sort: filters.sortField,
      sortDirection: filters.sortDirection,
      ...filters.filters,
    });
  };

  const requestRecache = async (urls: { id: string }[]) => {
    const ids = urls.map((url) => url.id);
    try {
      await requestCaching({ ids }).unwrap();
      setUrlsCachedAmount(ids.length);
      track('URL Added To Recache Queue', {
        subscription_plan: prerenderUser.chargebeePlanId,
        is_bulk_action: ids.length > 1,
      });
      return true;
    } catch {
      return false;
    }
  };

  const deleteURLs = async (urls: { id: string }[]) => {
    const ids = urls.map((url) => url.id);
    try {
      await deleteURLsReq({ ids }).unwrap();
      track('URL Removed From Cache', {
        subscription_plan: prerenderUser.chargebeePlanId,
        is_bulk_action: ids.length > 1,
      });
      return true;
    } catch {
      return false;
    }
  };

  const goToPreviousPage = () => {
    updatePagination(pagination.page - 1);
  };
  const goToNextPage = () => {
    updatePagination(pagination.page + 1);
  };

  const paginationInfos = {
    page: pagination.page,
    pageSize: cachedPagesResult.data?.length,
    goToPreviousPage: pagination.page > 1 ? goToPreviousPage : undefined,
    goToNextPage: cachedPagesResult.data?.length === pagination.pageSize ? goToNextPage : undefined,
  };

  return (
    <>
      {messageContext}
      <CachedPagesAdd
        onSuccess={setUrlsAddedAmount}
        mobileAdaptive={prerenderUser.mobileAdaptive}
        showModal={addPagesModalVisible}
        onModalClose={() => setAddPagesModalVisible(false)}
      />

      {!!urlsAddedAmount && <URLsAddedAlert amount={urlsAddedAmount} afterClose={() => setUrlsAddedAmount(0)} />}

      {!!newCacheFreshnessValue && (
        <CacheFreshnessUpdatedAlert days={newCacheFreshnessValue} afterClose={() => setNewCacheFreshnessValue(0)} />
      )}

      {!!urlsCachedAmount && <URLsCachedAlert amount={urlsCachedAmount} afterClose={() => setUrlsCachedAmount(0)} />}

      <Heading
        cachedPagesAmount={prerenderUser.numPagesCached}
        domainsAmount={domainsResult.data?.totalCount}
        lastCrawledFilterActive={!!queryStrings.last_delivery_at}
        setLastCrawledFilterActive={setLastCrawledFilterActive}
        cacheSettingsToolTip={cacheSettingsToolTip}
        onAddUrlClick={() => setAddPagesModalVisible(true)}
        onUpdateCacheFreshness={updateCacheFreshness}
      />

      <CacheManagerTable
        domains={domainsResult.data?.domains}
        onDomainSelected={updateDomain}
        selectedDomain={queryStrings.domain}
        onSearchConditionChanged={updateSearchCondition}
        onSearch={updateSearchQuery}
        currentSearchQuery={queryStrings.q}
        currentQCondition={queryStrings.qCondition}
        loading={cachedPagesResult.isFetching || requestCachingResult.isLoading}
        onReloadData={cachedPagesResult.refetch}
        onClearFilters={clearFilters}
        cachedPages={cachedPagesResult.data}
        filteredInfo={{
          source: queryStrings.source,
          adaptive_type: queryStrings.adaptive_type,
          cache_state: queryStrings.cache_state,
          seo_score_status: queryStrings.seo_score_status,
        }}
        onSortAndFilter={sortAndFilter}
        onRequestRecache={requestRecache}
        onDeleteURLs={deleteURLs}
        pagination={paginationInfos}
      />
    </>
  );
}

export default CacheManagerTab;
