import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getContent } from '../actions/pageActions';

class ErrorLoader extends Component {
  componentDidMount() {
    this.loader();
  }

  componentDidUpdate() {
    const { code, apiError, custom, scrollTop } = this.props;
    if (((code && apiError[code]) || (!code && custom)) && scrollTop && document.getElementById('modal-body-scroller'))
      document.getElementById('modal-body-scroller').scrollTop = 0;
    if (code && !apiError[code]) this.loader();
  }

  loader() {
    const { code, apiError, language, getContent: doGetContent, custom, cmsLoading } = this.props;
    if (!cmsLoading && !custom && code && !apiError[code] && code !== 'ECONNREFUSED') {
      doGetContent({ component: 'apiError', lang: language });
    }
  }

  render() {
    const { code, apiError, custom, tableId = null, rowIndex = null, rowCount = null, cols = null } = this.props;
    let childIndex = 0;
    if ((code && !apiError[code]) || (!code && !custom)) return null;
    const children = tableId
      ? React.Children.map(this.props.children, (child) => {
          if (React.isValidElement(child)) {
            // eslint-disable-next-line no-plusplus
            const clone = React.cloneElement(child, { tableId, rowIndex, rowCount, cols, colIndex: childIndex++ });
            return clone;
          }

          return undefined;
        })
      : this.props.children;
    return <>{children}</>;
  }
}

function mapStateToProps(state) {
  return {
    language: state.page.language,
    apiError: state.page.contentData.apiError,
    lastErrorCode: state.page.lastErrorCode,
    cmsLoading: state.page.cmsLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getContent }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorLoader);
