import { restApi } from '../api/restApiSlice';

export const oversightApiSlice = restApi.injectEndpoints({
  endpoints: (builder) => ({
    tagTypes: ['eventsHistory', 'events'],
    getEventsHistory: builder.query({
      query: (params) => ({ url: `/events-history`, method: 'GET', params }),
      providesTags: ['eventsHistory'],
    }),
    getEvents: builder.query({
      query: () => ({ url: `/events`, method: 'GET' }),
      providesTags: ['events'],
    }),
  }),
});

export const { useGetEventsHistoryQuery, useGetEventsQuery } = oversightApiSlice;
