import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card } from '../../../components/CssFrameworkComponents';
import { setDesktopRenderingSetting, setMobileRenderingSetting } from '../redux/settingsActions';
import { defaultDesktopUserAgent, defaultMobileUserAgent } from '../redux/settingsReducer';
import RenderingDeviceCard from './renderingDeviceCard';

const getResolutionOptions = (resolutions, viewport, customText) => {
  const data = [...resolutions];
  const current = data.find((option) => option.width === viewport.width && option.height === viewport.height);

  if (!current) {
    data.push({
      text: `${customText} (${viewport.width}x${viewport.height})`,
      width: viewport.width,
      height: viewport.height,
      selected: true,
    });
  } else {
    current.selected = true;
  }

  return data;
};

const RenderingDevicesCard = ({
  cm,
  settings,
  setMobileRenderingSetting: doSetMobileRenderingSetting,
  setDesktopRenderingSetting: doSetDesktopRenderingSetting,
}) => {
  const desktopResolutionOptions = [
    { text: `Default - (1440x718)`, width: 1440, height: 718 },
    { text: 'Full HD (1920x1080)', width: 1920, height: 1080 },
    { text: 'Full HD Lazy Loaded (1920x12000)', width: 1920, height: 12000 },
    { text: '4k (3840x2160)', width: 3840, height: 2160 },
    { text: '4k Lazy Loaded (3840x24000)', width: 3840, height: 24000 },
    { text: 'HD (1024x768)', width: 1024, height: 768 },
    { text: 'HD Lazy Loaded (1024x8140)', width: 1024, height: 8140 },
  ];

  const mobileResolutionOptions = [
    { text: `Default - iPhone SE (375x667)`, width: 375, height: 667 },
    { text: 'iPhone SE Lazy Loaded (375x6700)', width: 375, height: 6700 },
    { text: 'iPhone 12 Pro (390x844)', width: 390, height: 844 },
    { text: 'iPhone 12 Pro Lazy Loaded (390x9000)', width: 390, height: 9000 },
    { text: 'iPad Air (820x1180)', width: 820, height: 1180 },
    { text: 'iPad Air Lazy Loaded (820x12000)', width: 820, height: 12000 },
  ];

  const desktopResolution = {
    title: 'Screen Resolution',
    options: getResolutionOptions(
      desktopResolutionOptions,
      settings.renderingSettings.desktop.viewPort,
      cm.renderDevices.desktop.resolution.customText
    ),
    disabled: settings.inProgress,
  };

  const mobileResolution = {
    title: 'Screen Resolution',
    options: getResolutionOptions(
      mobileResolutionOptions,
      settings.renderingSettings.mobile.viewPort,
      cm.renderDevices.mobile.resolution.customText
    ),
    disabled: settings.inProgress || false,
  };

  const desktopUserAgentData = {
    title: 'User Agent',
    value: settings.renderingSettings.desktop.userAgent,
    disabled: settings.inProgress || false,
    default: defaultDesktopUserAgent,
  };

  const mobileUserAgentData = {
    title: 'User Agent',
    value: settings.renderingSettings.mobile.userAgent,
    disabled: settings.inProgress || false,
    default: defaultMobileUserAgent,
  };

  const desktopCheckboxState = {
    state: true,
    disabled: true,
    label: 'Status',
  };

  const mobileCheckboxState = {
    state: settings.renderingSettings.mobile.enabled,
    disabled: settings.inProgress || false,
    label: 'Status',
  };

  const onDesktopStateChanged = (state) => {
    doSetDesktopRenderingSetting(state);
  };

  const onMobileStateChanged = (state) => {
    doSetMobileRenderingSetting(state);
  };

  return (
    <Card title={cm.title}>
      <div className="col">
        <div className="row">
          <p>
            You can optimize your website's rendering based on the crawling bot that requests your page. For example,
            Google's mobile rendering bot can receive a mobile-optimized version of your site, while the desktop bot
            will get a desktop rendering.
          </p>
          <p>
            With Mobile Optimized Rendering activated, we will start caching mobile pages when mobile crawlers request
            them. Alternatively, you can use our re-cache API with adaptive type set to “Mobile” to pre-cache mobile
            versions of your cached pages. You can also prompt your sitemaps to be crawled for mobile page caching
            directly in the Sitemaps section.
          </p>
          <p>
            Note: turning on both rendering devices will cause you to render each page for each device, effectively
            multiplying your number of renders.
          </p>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <RenderingDeviceCard
              title="Desktop Optimized Rendering"
              icon="fe-monitor"
              resolution={desktopResolution}
              userAgent={desktopUserAgentData}
              checkbox={desktopCheckboxState}
              onChangedAction={onDesktopStateChanged}
            />
          </div>

          <div className="col-md-12 col-lg-6">
            <RenderingDeviceCard
              title="Mobile Optimized Rendering"
              icon="fe-smartphone"
              resolution={mobileResolution}
              userAgent={mobileUserAgentData}
              checkbox={mobileCheckboxState}
              onChangedAction={onMobileStateChanged}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    cm: {
      title: 'Rendering Devices',
      default: 'Default',
      renderDevices: {
        desktop: {
          title: 'Desktop Optimized Render',
          resolution: {
            label: 'Resolution',
            customText: 'Custom',
          },
          userAgent: {
            label: 'User Agent',
          },
          checkbox: {
            labelActive: 'Active',
            labelEnable: 'Enable',
          },
        },
        mobile: {
          title: 'Mobile Optimized Render',
          resolution: {
            label: 'Resolution',
            customText: 'Custom',
          },
          userAgent: {
            label: 'User Agent',
          },
          checkbox: {
            labelActive: 'Active',
            labelEnable: 'Enable',
          },
        },
      },
    },
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setMobileRenderingSetting,
      setDesktopRenderingSetting,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderingDevicesCard);
