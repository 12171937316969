import React from 'react';
import { Routes, Route, useNavigate, useLocation, useMatch, Navigate } from 'react-router-dom';
import { Typography, Tabs } from 'antd';

import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';
import { useEvent } from '../features/events/hooks/useEvent';
import { PreRenderTokenPage, TeamMembersPage, AuditLogPage } from '../features/SecurityAndAccess';
import { isSSOSite } from '../features/auth/keycloakAuth';

const META_TAGS = {
  title: 'Security & Access - Prerender.io',
};

const KEY_LABEL_MAP = {
  'prerender-token': 'Prerender Token',
  'team-members': 'Team Members',
  'audit-log': 'Audit Log',
};

const TABS = [
  {
    key: 'prerender-token',
    label: KEY_LABEL_MAP['prerender-token'],
    component: PreRenderTokenPage,
  },
  {
    key: 'team-members',
    label: KEY_LABEL_MAP['team-members'],
    component: TeamMembersPage,
    hide: isSSOSite(),
  },
  {
    key: 'audit-log',
    label: KEY_LABEL_MAP['audit-log'],
    component: AuditLogPage,
  },
].filter((tab) => !tab.hide);

function SecurityAndAccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const { track } = useEvent();

  const activeTab = React.useMemo(() => location.pathname.split('/').pop(), [location.pathname]);

  const onTabClick = (tabKey) => {
    track(`${KEY_LABEL_MAP[tabKey]} tab viewed`);
    navigate(tabKey);
  };

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Typography.Title level={2}>Security and Access</Typography.Title>
      <Typography.Paragraph type="secondary">
        This section allows you to manage security related configurations, generate access token, invite team members
        and more.
      </Typography.Paragraph>

      <Tabs activeKey={activeTab} items={TABS} onTabClick={onTabClick} />

      <Routes>
        {TABS.map(({ key, component: Component }) => (
          <Route path={key} key={key} element={<Component />} />
        ))}
        <Route path="" element={<Navigate to={TABS[0].key} />}></Route>
      </Routes>
    </AdminTemplate>
  );
}

export default requireAuth(SecurityAndAccess);
