import React from 'react';
import { message, Alert, Flex } from 'antd';
import { useSelector } from 'react-redux';
import { isEnterprise } from '@prerender/billing-shared';

import { Container } from '../../../components/CssFrameworkComponents';
import { useEvent } from '../../events/hooks/useEvent';
import { useGetInvoicesQuery, useLazyGetInvoiceDownloadLinkQuery } from '../api/billingApiSlice';
import { initChargebee, openChargebeePortalBillingHistory } from '../../../chargebee/chargebee';
import BillingHistoryTable from './BillingHistoryTable';
import USER_ROLES from '../../auth/userRoles';
import ButtonWithRole from '../../../components/ButtonWithRole';

const BillingHistory = () => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const { data: invoicesList, isLoading: isLoadingInvoices, isError: isErrorLoadingInvoices } = useGetInvoicesQuery();
  const [getDownloadLink] = useLazyGetInvoiceDownloadLinkQuery();
  const billingInfoContentData = useSelector((state) => state.page.contentData.BillingInfo);

  const { track } = useEvent();
  const userPlanName = useSelector((state) => state.prerenderUser.planName);
  const charbeePlanId = useSelector((state) => state.prerenderUser.chargebeePlanId);

  const [messageApi, contextHolder] = message.useMessage();

  const filteredInvoicesList = (invoicesList?.data || []).filter((invoice) => {
    return (
      invoice.id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      invoice.planName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      invoice.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const hideRendersColumns = isEnterprise(charbeePlanId);

  const onDownloadInvoiceError = (error) => {
    messageApi.open({
      type: 'error',
      content: 'Unable to download PDF. Please try again.',
    });
    console.error('Error fetching invoice download url.', error);
  };

  const handleOpenInvoicePdf = React.useCallback(
    (invoiceId) => {
      track(`PDF Invoice Opened`);

      getDownloadLink(invoiceId)
        .then((response) => {
          if (response.isError) {
            onDownloadInvoiceError(response.error.data);
            return;
          }

          const url = response?.data?.data?.url;
          if (url) {
            const link = document.createElement('a');
            link.href = url;
            link.click();

            track('Invoice Downloaded', { subscription_plan: userPlanName });
          }
        })
        .catch(onDownloadInvoiceError);
    },
    [userPlanName]
  );

  React.useEffect(() => {
    const url = window.location.href;
    initChargebee(billingInfoContentData.chargeBee.site, billingInfoContentData.chargeBee.domain, url);
  }, [billingInfoContentData.chargeBee]);

  return (
    <Container fluid addClass="pb-4 p-0">
      {contextHolder}
      <div className="d-flex justify-content-between">
        <h3 className="">Billing History</h3>
        {isLoadingInvoices && <div className="ml-3 spinner-border spinner-border-sm text-primary" role="status"></div>}
      </div>
      <p className="text-base text-grey">Here you will find all your invoices including due and overdue payments.</p>
      {/* <form className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Search invoices"
          aria-label="Search"
          onChange={(event) => setSearchTerm(event.target.value)}
          value={searchTerm}
        />
        {searchTerm.length > 0 && (
          <div className="input-group-append">
            <Button type='secondary' className="ml-2" onClick={() => setSearchTerm('')}>
              Clear
            </Button>
          </div>
        )}
      </form> */}
      <Flex gap="large" vertical justify="center">
        {isErrorLoadingInvoices ? (
          <Alert
            message="An unexpected error occured when retrieving your invoices. Please try again be reloading the page."
            type="error"
          />
        ) : (
          <BillingHistoryTable
            isLoading={isLoadingInvoices}
            items={filteredInvoicesList}
            handleOpenInvoicePdf={handleOpenInvoicePdf}
            hideRendersColumns={hideRendersColumns}
          />
        )}
        <ButtonWithRole
          disabledFor={[USER_ROLES.GUEST, USER_ROLES.TEAM_MEMBER]}
          type="link"
          onClick={() => openChargebeePortalBillingHistory({ onClosed: null })}
        >
          View Full History
        </ButtonWithRole>
      </Flex>
    </Container>
  );
};

export default BillingHistory;
