import React, { useState } from 'react';
import { Button, Card, Col, Flex, Row, Spin, Table, Tooltip } from 'antd';
import { getAgeString } from '../../assets/time';
import { statusCodeBadgeColor } from '../../assets/activityPageLib';
import { LoadingOutlined } from '@ant-design/icons';
import { StatusDropdownFilter } from '../../components/dropdownFilters/StatusDropdownFilter';
import { ResponseDropdownFilter } from '../../components/dropdownFilters/ResponseDropdownFilter';
import Column from 'antd/lib/table/Column';
import { RenderedTimeDropdown } from '../../components/dropdownFilters/RenderedTimeDropdown';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useEvent } from '../events/hooks/useEvent';
import { isNull, omitBy } from 'lodash';
import { createFilterEventProperties } from '../../utils/createFilterEventProperties';
import SearchBarV2 from '../../components/SearchBarV2';
import ExportButton from '../../components/ExportButton';
import { isFreePlan } from '../../chargebee/chargebee';

const deviceOptions = [
  {
    text: 'Desktop',
    value: 'desktop',
  },
  {
    text: 'Mobile',
    value: 'mobile',
  },
];

const dataIndexToSortQueryMapping = {
  renderedAt: 'time',
  httpStatus: 'status_code',
  renderTime: 'render_time',
};

const mapDataIndexToSortQueryParam = (dataIndex) => {
  if (dataIndexToSortQueryMapping[dataIndex]) {
    return dataIndexToSortQueryMapping[dataIndex];
  }
  throw new Error(`Mapping is missing for dataIndex: ${dataIndex}`);
};

const mapToApiPayload = (filters, sorter) => {
  const payload = {
    statusCodeEq: filters.httpStatus?.[0]?.statusCodeEq || null,
    statusCodeLow: filters.httpStatus?.length && filters.httpStatus[0].statusCodeLow,
    statusCodeHigh: filters.httpStatus?.length && filters.httpStatus[0].statusCodeHigh,
    responseTimeLow: filters.renderTime?.length && filters.renderTime[0].responseTimeLow,
    responseTimeHigh: filters.renderTime?.length && filters.renderTime[0].responseTimeHigh,
    timedout: filters.renderTime?.length && filters.renderTime[0].timedout,
    renderedTimeLow: filters.renderedAt?.length && filters.renderedAt[0].renderedTimeLow,
    renderedTimeHigh: filters.renderedAt?.length && filters.renderedAt[0].renderedTimeHigh,
    adaptive_type: filters.device?.join(',') || null,
  };
  if (sorter?.column?.dataIndex) {
    payload.sort = mapDataIndexToSortQueryParam(sorter.column.dataIndex);
    payload.sortDirection = sorter.order === 'ascend' ? 'ASC' : 'DESC';
  }
  return payload;
};

const mapToTrackingProperties = (filterResult) => {
  const { renderedAt, device, httpStatus, renderTime } = omitBy(filterResult, isNull);

  return {
    rendered_at: renderedAt?.[0] && `${renderedAt[0].renderedTimeLow}-${renderedAt[0].renderedTimeHigh}`,
    selected_device: device,
    selected_status:
      httpStatus?.[0] &&
      (httpStatus[0].statusCodeEq || `${httpStatus[0].statusCodeLow}-${httpStatus[0]?.statusCodeHigh}`),
    response_time: renderTime?.[0] && `${renderTime[0].responseTimeLow}-${renderTime[0].responseTimeHigh}`,
    timedout: renderTime?.[0]?.timedout,
  };
};

export const RenderHistoryTable = ({
  dataSource,
  onChangeTable,
  onUpdateTable,
  clearFilters,
  performQuerySearch,
  performQuerySearchAnyway,
  onSearchConditionChanged,
  state,
}) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const { track } = useEvent();
  const user = usePrerenderUser();
  const isFreeUser = isFreePlan(user.plan);

  const clearTableFilters = () => {
    setFilteredInfo({});
    clearFilters();
  };

  const mapToTableData = (data) => {
    return data.map((item) => {
      return {
        renderedAt: getAgeString(new Date(item.time), 'ago', 'long'),
        httpStatus: (
          <span className={`badge ${statusCodeBadgeColor(item)}`} style={{ fontSize: '90%', fontWeight: '500' }}>
            {item.status_code}
          </span>
        ),
        renderTime: item.timedout ? (
          <div className="badge bg-danger" style={{ fontSize: '90%', fontWeight: '500' }}>
            <div>{Math.round(item.render_time)} ms</div>
          </div>
        ) : (
          <span>{Math.round(item.render_time)} ms</span>
        ),
        url: (
          <div className="text-truncate url-shortener-lg">
            <span style={{ fontFamily: 'monospace' }}>{item.url}</span>
          </div>
        ),
        device: (
          <Tooltip
            title={`Rendering was optimized for [${item?.adaptive_type === 'mobile' ? 'Mobile' : 'Desktop'}] crawlers`}
            placement="left"
          >
            <i
              data-tip={item?.adaptive_type === 'mobile' ? 'Mobile' : 'Desktop'}
              className={item?.adaptive_type === 'mobile' ? 'fe fe-smartphone' : 'fe fe-monitor'}
            />
          </Tooltip>
        ),
      };
    });
  };

  const onChange = (pagination, filters, sorter) => {
    const selectedFiltersToTrack = createFilterEventProperties(filters, filteredInfo, mapToTrackingProperties);
    setFilteredInfo(filters);
    onChangeTable({
      action: 'updateSearchParams',
      payload: mapToApiPayload(filters, sorter),
    });

    if (Object.keys(selectedFiltersToTrack).length !== 0) {
      track(`Render History Filtered: ${Object.keys(selectedFiltersToTrack)}`, {
        subscription_plan: user.chargebeePlanId,
        ...selectedFiltersToTrack,
      });
    }
  };

  if (!dataSource) return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;

  return (
    <Card styles={{ body: { padding: 12 } }}>
      <Row className="pb-3 align-items-baseline">
        <Col flex="auto">
          <SearchBarV2
            id={state.searchBarId}
            initialValue={state.initialSearchQuery}
            onTypingEnd={(query) => performQuerySearch(query)}
            onEnterPressed={(query) => performQuerySearchAnyway(query)}
            onSearchConditionChanged={(condition) => onSearchConditionChanged(condition)}
            placeholder="Please enter at least 3 characters to start your search"
          />
        </Col>
        <Col flex="210px">
          <Flex justify="end">
            <Button className="ml-2" disabled={dataSource.inProgress} onClick={onUpdateTable} size="small">
              <i className="fe fe-rotate-cw"></i>
            </Button>
            <Button className="ml-2" disabled={dataSource.inProgress} onClick={() => clearTableFilters()} size="small">
              Clear filters
            </Button>
            <ExportButton isFreePlan={isFreeUser} onClick={() => onChangeTable({ action: 'openExportModal' })} />
          </Flex>
        </Col>
      </Row>
      <Table
        dataSource={mapToTableData(dataSource.requests)}
        onChange={onChange}
        className="table card-table table-sm table-hover table-nowrap"
        scroll={{ x: 400 }}
        pagination={false}
      >
        <Column
          title="RENDERED AT"
          dataIndex="renderedAt"
          align="center"
          sorter="true"
          defaultSortOrder="descend"
          filteredValue={filteredInfo.renderedAt || null}
          filterDropdown={({ confirm, setSelectedKeys, selectedKeys, close, clearFilters }) => {
            return (
              <RenderedTimeDropdown
                close={close}
                confirm={confirm}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                clearFilters={clearFilters}
                label="Rendered at (in hours)"
              />
            );
          }}
        />
        <Column
          title="HTTP STATUS"
          dataIndex="httpStatus"
          align="center"
          sorter="true"
          filteredValue={filteredInfo.httpStatus || null}
          filterDropdown={({ confirm, setSelectedKeys, selectedKeys, close, clearFilters }) => {
            return (
              <StatusDropdownFilter
                confirm={confirm}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                clearFilters={clearFilters}
              />
            );
          }}
        />
        <Column
          title="RENDER TIME"
          dataIndex="renderTime"
          align="center"
          sorter="true"
          filteredValue={filteredInfo.renderTime || null}
          filterDropdown={({ confirm, setSelectedKeys, selectedKeys, close, clearFilters }) => {
            return (
              <ResponseDropdownFilter
                close={close}
                confirm={confirm}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                clearFilters={clearFilters}
              />
            );
          }}
        />
        <Column title="URL" dataIndex="url" />
        <Column
          title="DEVICE"
          dataIndex="device"
          align="center"
          filters={deviceOptions}
          filteredValue={filteredInfo.device || null}
        />
      </Table>
    </Card>
  );
};
