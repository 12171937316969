import { Button, Card, Flex, Space, Typography, message } from 'antd';
import AdminTemplate from '../../layout/AdminTemplate';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { useGetDomainStatisticsQuery } from '../domain-manager/api/domainManagerApiSlice';
import { AnalyticsTable } from './components/AnalyticsTable';
import { AnalyticsStatisticCards } from './components/AnalyticsStatisticCards';
import { useEffect, useMemo, useState } from 'react';
import SpinningWheel from '../../components/SpinningWheel';
import requireAuth from '../../components/hocs/requireAuth';
import useTableFilters from '../domain-manager/hooks/useTableFilters';
import { useGetAnalyticsQuery, useGetStatsQuery } from './AnalyticsApiSlice';
import dayjs from 'dayjs';
import { HeaderFilters } from './components/HeaderFilters';
import { PRERENDER_EMAIL } from '../../hooks/usePrerenderUser';

const META_TAGS = {
  title: 'Analytics - Prerender.io',
};

const PAGE_SIZE = 50;

const initialDateInterval = {
  from: dayjs().subtract(1, 'day').toISOString(),
  to: new Date().toISOString(),
};

const mapToApiPayload = (params, domain, dateInterval, userAgent) => ({
  domain,
  page: params.pagination.current,
  pageSize: params.pagination.pageSize,
  sort: params.sorter?.field,
  sortDirection: params.sorter?.order === 'ascend' ? 'ASC' : 'DESC',
  url: params.filters?.url ? params.filters?.url[0] : '',
  from: dateInterval.from,
  to: dateInterval.to,
  adaptiveType: params.filters?.adaptive_type?.join(','),
  userAgent: userAgent || '',
});

const AnalyticsPage = () => {
  const initialParams = {
    pagination: {
      current: 1,
      pageSize: PAGE_SIZE,
    },
    sorter: {
      field: 'last_requested_at',
      order: 'descend',
    },
  };
  const { domain } = useParams();
  const navigate = useNavigate();
  const [dateInterval, setDateInterval] = useState(initialDateInterval);
  const [userAgent, setUserAgent] = useState('');
  const { data: domains, error, isFetching } = useGetDomainStatisticsQuery();
  const { tableParams, handleTableChange, onInputChange } = useTableFilters('url', initialParams);
  const statsResult = useGetStatsQuery({ ...dateInterval, domain, userAgent });

  const serializedQuery = useMemo(
    () => mapToApiPayload(tableParams, domain, dateInterval, userAgent),
    [tableParams, domain, dateInterval, userAgent]
  );

  const {
    data,
    error: analyticsError,
    isFetching: isFetchingAnalytics,
  } = useGetAnalyticsQuery(serializedQuery, {
    refetchOnMountOrArgChange: true,
  });

  const domainOptions = useMemo(
    () => domains?.data?.map((domain) => ({ label: domain.domain, value: domain.domain })),
    [domains?.data]
  );

  const analyticsData = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.data.map((item, index) => {
      return {
        ...item,
        key: `${index}-${item.url}`,
      };
    });
  }, [data]);

  useEffect(() => {
    if (domains?.data?.length > 0 && !domain) {
      const firstDomain = domains.data[0].domain;
      navigate(`/analytics/${firstDomain}`);
    }
  }, [domains, domain]);

  useEffect(() => {
    if (statsResult.isError) {
      message.error('An error occurred while fetching analytics data');
    }
    if (analyticsError) {
      message.error('An error occurred while fetching analytics data');
    }
  }, [statsResult.isError, analyticsError]);

  const onTimeIntervalChange = (value) => {
    const dateStart = dayjs().subtract(value, 'day');
    setDateInterval({
      from: dateStart.toISOString(),
      to: new Date().toISOString(),
    });
  };

  if (isFetching) {
    return <SpinningWheel />;
  }

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Space direction="vertical" size="large">
        <Flex gap="large" justify="space-between">
          <Space align="baseline">
            <Link to="/domain-manager">
              <Button icon={<LeftOutlined />} type="link">
                Back
              </Button>
            </Link>
            <Typography.Title level={3}>Analytics</Typography.Title>
          </Space>
          <HeaderFilters
            domainOptions={domainOptions}
            domain={domain}
            onChangeUserAgent={(value) => setUserAgent(value)}
            onTimeIntervalChange={onTimeIntervalChange}
          />
        </Flex>

        <AnalyticsStatisticCards
          crawlerRequests={statsResult.data?.crawlerRequests}
          notCrawledPages={statsResult.data?.notCrawledPages}
          isLoading={statsResult.isLoading}
          filters={{ domain, userAgent }}
        />

        <Card>
          <AnalyticsTable
            pagination={{ ...tableParams.pagination, total: data?.totalCount }}
            exportQuery={serializedQuery}
            dataSource={analyticsData}
            handleTableChange={handleTableChange}
            onSearch={onInputChange}
            loading={isFetchingAnalytics}
          />
        </Card>
      </Space>
    </AdminTemplate>
  );
};

function isAuthorized({ email }) {
  return email.endsWith(PRERENDER_EMAIL);
}

export default requireAuth(AnalyticsPage, isAuthorized);
