import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Card, Flex, Table, DatePicker, message, Typography, ConfigProvider, Empty, Button } from 'antd';
import dayjs from 'dayjs';

import { useGetEventsHistoryQuery } from '../oversightApiSlice';
import { getDisplayRange } from '../../../utils/getDisplayRange';
import { useGetDomainsQuery } from '../../domain-manager/api/domainManagerApiSlice';
import { debounce } from 'lodash';
import { DomainSearchDropdown } from '../../../components/DomainSearchDropdown';

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;

const PAGE_SIZE = 50;

const mapToApiPayload = (params) => {
  return {
    page: params.pagination.current,
    pageSize: params.pagination.pageSize,
    sort: params.sorter?.field,
    sortDirection: params.sorter?.order === 'ascend' ? 'ASC' : 'DESC',
    from: params.from,
    to: params.to,
    events: params.eventType,
    domain: params.domain ? params.domain : undefined,
  };
};

const EventHistoryTab = ({ eventTypes, eventTypesError, loadingEventTypes }) => {
  const initialParams = {
    pagination: {
      current: 1,
      pageSize: PAGE_SIZE,
    },
    sorter: {
      field: 'created_at',
      order: 'descend',
    },
  };
  const [messageApi, messageContext] = message.useMessage();
  const [tableParams, setTableParams] = useState(initialParams);
  const {
    data: events,
    error: eventsError,
    isFetching: isFetchingEvents,
  } = useGetEventsHistoryQuery(mapToApiPayload(tableParams), {
    refetchOnMountOrArgChange: true,
  });
  const totalCount = events?.totalCount || 0;
  const [disabledReset, setDisabledReset] = useState(true);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState({});
  const {
    data: domains,
    error: domainsError,
    isFetching: isFetchingDomains,
  } = useGetDomainsQuery(searchValue, { refetchOnMountOrArgChange: true });

  const domainSelectRef = useRef(null);

  const tableData = useMemo(() => {
    return events?.data?.map(
      (event, index) =>
        ({
          key: index,
          eventType: event.type,
          created_at: dayjs(event.created_at).format('YYYY-MM-DD HH:mm:ss'),
          details: event.details,
        } || [])
    );
  }, [events]);

  const domainsList = useMemo(() => {
    return domains?.domains;
  }, [domains]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchValue(value);
    }, 500),
    []
  );

  useEffect(() => {
    if (eventsError) {
      messageApi.error(eventsError.data.error);
    }
    if (eventTypesError) {
      messageApi.error(eventTypesError.data.error);
    }
    if (domainsError) {
      messageApi.error(domainsError.data.error);
    }
  }, [eventsError?.data?.error, eventTypesError?.data?.error, domainsError?.data?.error]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      sorter,
      domain: selectedDomain,
      ...filters,
    });
  };

  const handleDomainSearch = (domain) => {
    setSelectedDomain(domain);
    setTableParams((oldParams) => ({
      ...oldParams,
      pagination: { ...oldParams.pagination, current: 1 },
      domain: domain,
    }));
  };

  const handleRangeChange = (value) => {
    setDisabledReset(false);
    const [start, end] = value ?? [];
    const from = start ? dayjs(start).toISOString() : undefined;
    const to = end ? dayjs(end).toISOString() : undefined;
    setDateRange({ from, to });
  };

  const displayRange = getDisplayRange(tableParams.pagination, totalCount);

  return (
    <div>
      {messageContext}
      <Card title="Alerts">
        <Flex justify="space-between" style={{ marginBottom: 20 }}>
          <DomainSearchDropdown
            ref={domainSelectRef}
            domains={domainsList}
            selectedDomain={selectedDomain}
            performDomainSearch={handleDomainSearch}
            loading={isFetchingDomains}
            onSearch={debouncedSearch}
            onClear={() => {
              setSearchValue('');
            }}
          />
          <Paragraph>
            Displaying {displayRange} events from total of {totalCount} records
          </Paragraph>
        </Flex>
        <ConfigProvider
          renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No events found" />}
        >
          <Table
            loading={isFetchingEvents || loadingEventTypes}
            dataSource={tableData}
            onChange={handleTableChange}
            pagination={{
              current: tableParams.pagination.current,
              pageSize: tableParams.pagination.pageSize,
              total: totalCount,
            }}
            bordered
          >
            <Table.Column
              title="Recorded At"
              dataIndex="created_at"
              key="created_at"
              sorter
              width="25%"
              filterDropdown={({ close, confirm }) => (
                <Card styles={{ body: { padding: 12 } }}>
                  <Flex vertical style={{ marginBottom: 12 }}>
                    <Paragraph>Timeframe</Paragraph>
                    <RangePicker
                      onChange={handleRangeChange}
                      showTime
                      value={[dateRange.from ? dayjs(dateRange.from) : null, dateRange.to ? dayjs(dateRange.to) : null]}
                    />
                  </Flex>
                  <Flex justify="space-between" style={{ borderTop: '1px solid #f0f0f0', paddingTop: 8 }}>
                    <Button
                      size="small"
                      type="link"
                      disabled={disabledReset}
                      onClick={() => {
                        close();
                        setDisabledReset(true);
                        setDateRange({});
                        setTableParams((oldParams) => ({
                          ...oldParams,
                          pagination: { ...oldParams.pagination, current: 1 },
                          from: undefined,
                          to: undefined,
                        }));
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        setTableParams((oldParams) => ({
                          ...oldParams,
                          pagination: { ...oldParams.pagination, current: 1 },
                          from: dateRange.from,
                          to: dateRange.to,
                        }));
                        confirm({ closeDropdown: true });
                      }}
                    >
                      OK
                    </Button>
                  </Flex>
                </Card>
              )}
            />
            <Table.Column
              title="Event Type"
              dataIndex="eventType"
              key="eventType"
              width="25%"
              filters={eventTypes?.data?.map((event) => ({ text: event.name, value: event.id }))}
            />
            <Table.Column title="Details" dataIndex="details" key="details" width="50%" />
          </Table>
        </ConfigProvider>
      </Card>
    </div>
  );
};

export default EventHistoryTab;
