import React from 'react';
import { Table, ConfigProvider, Empty, Tooltip, Popconfirm, Space } from 'antd';
import {
  CheckCircleFilled,
  DeleteOutlined,
  SearchOutlined,
  UserAddOutlined,
  WarningOutlined,
  QuestionCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';

import ButtonWithRole, { SwitchWithRole } from '../../../components/ButtonWithRole';
import USER_ROLES from '../../../features/auth/userRoles';

function IconWithTooltip({ value, labels = { success: 'Success', failure: 'Failure', notChecked: 'Not checked' } }) {
  if (value === null)
    return (
      <Tooltip title={labels.notChecked}>
        <QuestionCircleOutlined />
      </Tooltip>
    );

  if (value === true)
    return (
      <Tooltip title={labels.success}>
        <CheckCircleFilled style={{ color: '#4CC59A' }} />
      </Tooltip>
    );

  if (value === false)
    return (
      <Tooltip title={labels.failure}>
        <WarningOutlined style={{ color: '#FF4D4E' }} />
      </Tooltip>
    );
}

function DomainsTable({ isLoading, rows = [], onEnabledToggle, onEditClick, onDeleteClick }) {
  const tableColumns = React.useMemo(
    () => [
      {
        title: 'Enabled',
        key: 'enabled',
        dataIndex: 'enabled',
        className: 'col-min',
        render: (_text, item) => {
          return (
            <Tooltip
              title={item.isEnabled ? 'Enabling the domain will prompt Prerender bot to check it periodically.' : ''}
            >
              <SwitchWithRole
                disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                checked={item.isEnabled}
                onClick={() => onEnabledToggle(item)}
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'Domain',
        key: 'domain',
        width: '99%',
        dataIndex: 'domain',
        className: 'text-truncate url-shortener',
        render: (text) => <span style={{ fontFamily: 'monospace' }}>{text}</span>,
      },
      {
        title: 'Created at',
        key: 'createdAt',
        dataIndex: 'createdAt',
        className: 'col-min',
      },
      {
        title: 'Check interval',
        key: 'checkInterval',
        dataIndex: 'revisitInterval',
        className: 'col-min',
      },
      {
        title: 'Last checked at',
        key: 'lastCheckedAt',
        dataIndex: 'lastCheckedAt',
        className: 'col-min',
      },
      {
        title: 'Next check',
        key: 'nextCheck',
        dataIndex: 'nextVisit',
        className: 'col-min',
      },
      {
        title: 'Origin',
        key: 'origin',
        dataIndex: 'origin',
        className: 'col-min',
        align: 'center',
        render: (_text, item) => {
          const type = item.type.toLowerCase();
          if (type.includes('user')) {
            return (
              <Tooltip title="Created by you">
                <UserAddOutlined />
              </Tooltip>
            );
          }

          if (type.includes('auto')) {
            return (
              <Tooltip title="Automaticaly discovered">
                <SearchOutlined />
              </Tooltip>
            );
          }

          return '-';
        },
      },
      {
        title: 'Domain reachable',
        key: 'domainReachable',
        dataIndex: 'domainReachable',
        className: 'col-min',
        align: 'center',
        render: (_text, item) => {
          const labels = {
            notChecked: 'Not checked yet',
            success: 'Reached',
            failure: 'Failed to reach domain',
          };
          return <IconWithTooltip value={item.domainCheckSuccess} labels={labels} />;
        },
      },
      {
        title: '404 status returned',
        key: 'status404',
        dataIndex: 'status404',
        className: 'col-min',
        align: 'center',
        render: (_text, item) => {
          const labels = {
            notChecked: 'Not checked yet',
            success: 'Success',
            failure: 'Failed, 404 not returned',
          };
          return <IconWithTooltip value={item.randomUrlCheckSuccess} labels={labels} />;
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        dataIndex: 'actions',
        className: 'col-min',
        render: (_text, item) => {
          return (
            <Space>
              <Tooltip title="Edit">
                <ButtonWithRole
                  disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                  onClick={() => onEditClick(item)}
                  disabled={isLoading}
                  icon={<EditOutlined />}
                />
              </Tooltip>
              <Popconfirm
                title="Delete this check?"
                okText="Yes, delete"
                cancelText="Cancel"
                placement="left"
                okType="danger"
                onConfirm={() => onDeleteClick(item.id)}
              >
                <ButtonWithRole
                  disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                  disabled={isLoading}
                  danger
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Space>
          );
        },
      },
    ],
    [isLoading, onEnabledToggle, onEditClick, onDeleteClick]
  );

  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="You don't have any domain checks added. Start by adding one."
        />
      )}
    >
      <Table
        bordered={!isLoading}
        rowKey="id"
        loading={isLoading}
        columns={tableColumns}
        dataSource={rows}
        pagination={false}
        showHeader={!!rows.length}
        scroll={{ x: true }}
      />
    </ConfigProvider>
  );
}

export default DomainsTable;
