import { useEffect } from 'react';
import { Card, message } from 'antd';

const EventDescriptionTab = ({ eventTypes, eventTypesError, loadingEventTypes }) => {
  useEffect(() => {
    if (eventTypesError) {
      message.error('Error fetching events');
    }
  }, [eventTypesError?.data?.error]);

  return (
    <div>
      {eventTypes?.data?.map((event) => {
        return (
          <Card
            loading={loadingEventTypes}
            title={event.name}
            key={event.id}
            style={{ marginBottom: 16 }}
            styles={{ body: { padding: 12 }, header: { padding: 12, fontSize: 14 } }}
          >
            {event.description}
          </Card>
        );
      })}
    </div>
  );
};

export default EventDescriptionTab;
