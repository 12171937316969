import {
  ChargeBeePlan,
  PlanName,
  ChargeBeeAddonId,
  ChargeBeePlanId,
  CacheLocation,
  Support,
  BillingPeriod,
  PlanTier,
} from './types';

const tier2024ChargeBeePlans: Array<ChargeBeePlan> = [
  {
    name: PlanName.Lite,
    chargeBeePlanId: ChargeBeePlanId.Lite2024,
    index: 2,
    legacy: false,
    costInCents: 3000,
    addonCostInCents: 185,
    currencyCode: 'USD',
    includedRenderedPages: 15000,
    chargeBeeAddonId: ChargeBeeAddonId.lite2024Overages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [1, 7],
    cacheLocation: CacheLocation.shared,
    available: false,
    support: Support.normal,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.P2024,
    apiAccess: false,
  },
  {
    name: PlanName.Essential,
    chargeBeePlanId: ChargeBeePlanId.Essential2024,
    index: 3,
    legacy: false,
    costInCents: 9000,
    addonCostInCents: 150,
    currencyCode: 'USD',
    includedRenderedPages: 50000,
    chargeBeeAddonId: ChargeBeeAddonId.essential2024Overages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [1, 7],
    cacheLocation: CacheLocation.shared,
    available: false,
    support: Support.normal,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.P2024,
    apiAccess: false,
  },
  {
    name: PlanName.Advanced,
    chargeBeePlanId: ChargeBeePlanId.Advanced2024,
    index: 4,
    legacy: false,
    costInCents: 29000,
    addonCostInCents: 100,
    currencyCode: 'USD',
    includedRenderedPages: 250000,
    chargeBeeAddonId: ChargeBeeAddonId.advanced2024Overages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [0.5, 14],
    cacheLocation: CacheLocation.both,
    available: false,
    support: Support.normal,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.P2024,
    apiAccess: true,
  },
  {
    name: PlanName.Custom,
    chargeBeePlanId: ChargeBeePlanId.Custom2024,
    index: 5,
    legacy: false,
    costInCents: 990000,
    addonCostInCents: 75,
    currencyCode: 'USD',
    includedRenderedPages: 1000000,
    chargeBeeAddonId: ChargeBeeAddonId.custom2024Overages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [0.25, 30],
    cacheLocation: CacheLocation.both,
    available: false,
    support: Support.high,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: true,
    tier: PlanTier.P2024,
    apiAccess: true,
  },
];

export const chargeBeePlans: Array<ChargeBeePlan> = [
  {
    name: PlanName.Legacy,
    chargeBeePlanId: ChargeBeePlanId.Legacy,
    index: 0,
    legacy: true,
    costInCents: 0,
    addonCostInCents: 0,
    currencyCode: 'USD',
    includedRenderedPages: 0,
    suggested: false,
    current: false,
    cacheRefreshInterval: [1, 90],
    cacheLocation: CacheLocation.shared,
    available: false,
    support: Support.none,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.Legacy,
    apiAccess: false,
  },
  {
    name: PlanName.LegacyYearly,
    chargeBeePlanId: ChargeBeePlanId.LegacyYearly,
    index: 1,
    legacy: true,
    costInCents: 0,
    addonCostInCents: 0,
    currencyCode: 'USD',
    includedRenderedPages: 0,
    suggested: false,
    current: false,
    cacheRefreshInterval: [1, 90],
    cacheLocation: CacheLocation.shared,
    available: false,
    support: Support.none,
    billingPeriod: BillingPeriod.yearly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.Legacy,
    apiAccess: false,
  },
  {
    name: PlanName.Basic,
    chargeBeePlanId: ChargeBeePlanId.Basic,
    index: 0,
    legacy: false,
    costInCents: 0,
    addonCostInCents: 0,
    currencyCode: 'USD',
    includedRenderedPages: 1000,
    suggested: false,
    current: false,
    cacheRefreshInterval: [3, 3],
    cacheLocation: CacheLocation.shared,
    available: false,
    support: Support.none,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.Free,
    apiAccess: false,
  },
  {
    name: PlanName.Professional,
    chargeBeePlanId: ChargeBeePlanId.Professional,
    index: 1,
    legacy: false,
    costInCents: 2500,
    addonCostInCents: 200,
    includedRenderedPages: 50000,
    currencyCode: 'USD',
    chargeBeeAddonId: ChargeBeeAddonId.professionalOverages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [3, 90],
    cacheLocation: CacheLocation.shared,
    available: false,
    support: Support.none,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.P2020,
    apiAccess: false,
  },
  {
    name: PlanName.Startup,
    chargeBeePlanId: ChargeBeePlanId.Startup,
    index: 2,
    legacy: false,
    costInCents: 9900,
    addonCostInCents: 100,
    currencyCode: 'USD',
    includedRenderedPages: 250000,
    chargeBeeAddonId: ChargeBeeAddonId.startupOverages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [1, 90],
    cacheLocation: CacheLocation.shared,
    available: false,
    support: Support.low,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.P2020,
    apiAccess: false,
  },
  {
    name: PlanName.Business,
    chargeBeePlanId: ChargeBeePlanId.Business,
    index: 3,
    legacy: false,
    costInCents: 29900,
    addonCostInCents: 75,
    currencyCode: 'USD',
    includedRenderedPages: 3000000,
    chargeBeeAddonId: ChargeBeeAddonId.businessOverages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [1, 90],
    cacheLocation: CacheLocation.both,
    available: false,
    support: Support.normal,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.P2020,
    apiAccess: false,
  },
  {
    name: PlanName.Enterprise,
    chargeBeePlanId: ChargeBeePlanId.Enterprise,
    index: 4,
    legacy: false,
    costInCents: 199000,
    addonCostInCents: 20,
    currencyCode: 'USD',
    includedRenderedPages: 10000000,
    chargeBeeAddonId: ChargeBeeAddonId.enterpriseOverages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [1, 90],
    cacheLocation: CacheLocation.both,
    available: false,
    support: Support.high,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: true,
    tier: PlanTier.P2020,
    apiAccess: true,
  },
  {
    name: PlanName.Startup,
    chargeBeePlanId: ChargeBeePlanId.Startup2022,
    index: 2,
    legacy: false,
    costInCents: 9000,
    addonCostInCents: 200,
    currencyCode: 'USD',
    includedRenderedPages: 100000,
    chargeBeeAddonId: ChargeBeeAddonId.startup2022Overages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [1, 7],
    cacheLocation: CacheLocation.shared,
    available: false,
    support: Support.low,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.P2022,
    apiAccess: false,
  },
  {
    name: PlanName.Scaleup,
    chargeBeePlanId: ChargeBeePlanId.Scaleup2022,
    index: 3,
    legacy: false,
    costInCents: 29000,
    addonCostInCents: 100,
    currencyCode: 'USD',
    includedRenderedPages: 1000000,
    chargeBeeAddonId: ChargeBeeAddonId.scaleup2022Overages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [0.5, 14],
    cacheLocation: CacheLocation.both,
    available: false,
    support: Support.normal,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: false,
    tier: PlanTier.P2022,
    apiAccess: true,
  },
  {
    name: PlanName.Enterprise,
    chargeBeePlanId: ChargeBeePlanId.Enterprise2022,
    index: 4,
    legacy: false,
    costInCents: 99000,
    addonCostInCents: 50,
    currencyCode: 'USD',
    includedRenderedPages: 5000000,
    chargeBeeAddonId: ChargeBeeAddonId.enterprise2022Overages,
    suggested: false,
    current: false,
    cacheRefreshInterval: [0.25, 30],
    cacheLocation: CacheLocation.both,
    available: false,
    support: Support.high,
    billingPeriod: BillingPeriod.monthly,
    customPlan: false,
    contactUsToUse: true,
    tier: PlanTier.P2022,
    apiAccess: true,
  },
  {
    name: PlanName.CustomEnterprise,
    chargeBeePlanId: ChargeBeePlanId.CustomEnterprise,
    index: 5,
    legacy: false,
    costInCents: 0,
    addonCostInCents: 0,
    currencyCode: 'USD',
    includedRenderedPages: 0,
    chargeBeeAddonId: undefined,
    suggested: false,
    current: false,
    cacheRefreshInterval: [0.25, 30],
    cacheLocation: CacheLocation.both,
    available: false,
    support: Support.high,
    billingPeriod: BillingPeriod.monthly,
    customPlan: true,
    contactUsToUse: false,
    tier: PlanTier.Custom,
    apiAccess: true,
  },
  {
    name: PlanName.CustomEnterpriseYearly,
    chargeBeePlanId: ChargeBeePlanId.CustomEnterpriseYearly,
    index: 5,
    legacy: false,
    costInCents: 0,
    addonCostInCents: 0,
    currencyCode: 'USD',
    includedRenderedPages: 0,
    chargeBeeAddonId: undefined,
    suggested: false,
    current: false,
    cacheRefreshInterval: [0.25, 30],
    cacheLocation: CacheLocation.both,
    available: false,
    support: Support.high,
    billingPeriod: BillingPeriod.yearly,
    customPlan: true,
    contactUsToUse: false,
    tier: PlanTier.Custom,
    apiAccess: true,
  },
  ...tier2024ChargeBeePlans,
];

export const PlanIdToNameMap = {
  [ChargeBeePlanId.Basic]: PlanName.Basic,
  [ChargeBeePlanId.Professional]: PlanName.Professional,
  [ChargeBeePlanId.Startup]: PlanName.Startup,
  [ChargeBeePlanId.Business]: PlanName.Business,
  [ChargeBeePlanId.Enterprise]: PlanName.Enterprise,
  [ChargeBeePlanId.Startup2022]: PlanName.Startup,
  [ChargeBeePlanId.Scaleup2022]: PlanName.Scaleup,
  [ChargeBeePlanId.Enterprise2022]: PlanName.Enterprise,
  [ChargeBeePlanId.CustomEnterprise]: PlanName.CustomEnterprise,
  [ChargeBeePlanId.CustomEnterpriseYearly]: PlanName.CustomEnterpriseYearly,
  [ChargeBeePlanId.Legacy]: PlanName.Legacy,
  [ChargeBeePlanId.LegacyYearly]: PlanName.LegacyYearly,
  [ChargeBeePlanId.Adhoc]: PlanName.Adhoc,
  [ChargeBeePlanId.Lite2024]: PlanName.Lite,
  [ChargeBeePlanId.Essential2024]: PlanName.Essential,
  [ChargeBeePlanId.Advanced2024]: PlanName.Advanced,
  [ChargeBeePlanId.Custom2024]: PlanName.Custom,
};
