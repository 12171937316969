import { useState, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Input, Form, Modal, Radio, Space, Button, Flex, Typography, Tag, Divider } from 'antd';

import { getContent, setLastErrorCode } from '../../../actions/pageActions';
import { useLazyGetURLsCountQuery } from '../../urls/api/urlsApiSlice';

const wildcardToRegex = (rule) => {
  const escapeRegex = (u) => u.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'); // eslint-disable-line
  return new RegExp(`^${rule.split('*').map(escapeRegex).join('.*')}$`);
};

function exactWildcardMatch(text = '', pattern = '') {
  const regExp = wildcardToRegex(pattern);
  return regExp.test(text);
}

function MatchTag({ text, pattern }) {
  const isEmpty = !text || !pattern;
  if (isEmpty) return '';

  const match = exactWildcardMatch(text, pattern);

  if (match) return <Tag color="success">Match</Tag>;
  return <Tag color="error">No match</Tag>;
}

function UrlAffected({ count, isLoading, isEmpty }) {
  if (isEmpty) return <Typography.Paragraph>Number of URLs affected: start typing to find out</Typography.Paragraph>;
  if (isLoading) return <Typography.Paragraph>Number of URLs affected: calculating...</Typography.Paragraph>;
  return <Typography.Paragraph>Number of URLs affected: {count}</Typography.Paragraph>;
}

const ALLOWED_PARAM_NAME_PATTERN = /^[a-zA-Z0-9-_*]*$/;
const SPECIAL_CHARS_ERROR = 'Only letters, numbers, hyphens, and asterisks are allowed';
const DEFAULT_ERROR = "Please only enter the name of the query parameter that needs to be ignored e.g. 'page'";

const UrlParamEditor = ({
  lastErrorCode,
  apiErrTxt,
  urlParam,
  urlParams,
  setLastErrorCode,
  onEvent,
  showModal,
  onModalClose,
}) => {
  const [form] = Form.useForm();
  const [testParamInput, setTestParamInput] = useState('');
  const [getURLsCount, urlsCountResult] = useLazyGetURLsCountQuery();
  const latestRequestRef = useRef(null);

  const urlParamName = Form.useWatch('param', { form, preserve: true });
  const viewMode = urlParam && urlParam.id ? 'edit' : 'add';

  const onClose = () => {
    if (lastErrorCode) setLastErrorCode('');
    setTestParamInput('');
    form.resetFields();
    onModalClose();
  };

  const saveForm = ({ param = '', rule }) => {
    const { id } = urlParam;
    if (viewMode === 'edit') {
      onEvent({
        action: 'updateRule',
        payload: [{ id, value: param, ignore: rule === 'ignore' }],
      });
    } else {
      onEvent({
        action: 'setUrlParam',
        payload: { id, value: param, ignore: rule === 'ignore' },
      });
    }
  };

  const onModalSubmit = () => {
    saveForm(form.getFieldsValue());
    onClose();
  };

  const debouncedUrlCountQuery = useCallback(
    debounce((paramName) => {
      if (latestRequestRef.current) {
        latestRequestRef.current.abort();
        latestRequestRef.current = null;
      }
      if (paramName) latestRequestRef.current = getURLsCount({ wildcard: paramName, wildcard_type: 'query' });
    }, 500),
    []
  );

  async function triggerUrlCountQuery(urlParamName) {
    try {
      await form.validateFields();
      debouncedUrlCountQuery(urlParamName);
    } catch (error) {
      debouncedUrlCountQuery(null);
    }
  }

  const onTestParamInputChange = (e) => setTestParamInput(e.target.value);

  useEffect(() => {
    if (urlParam) {
      const activeRule = urlParam.id ? (urlParam.ignore ? 'ignore' : 'allow') : 'ignore';

      form.setFieldsValue({ rule: activeRule, param: urlParam.value });
    }
  }, [urlParam, form]);

  useEffect(() => {
    if (showModal) triggerUrlCountQuery(urlParamName);
  }, [urlParamName, form, showModal]);

  return (
    <Modal
      destroyOnClose
      closable
      open={showModal}
      title="Add URL Parameter"
      okText="Save"
      cancelText="Cancel"
      onCancel={onClose}
      okButtonProps={{ htmlType: 'submit', form: 'addUrlParam' }}
      styles={{ footer: { display: 'flex', justifyContent: 'space-between' } }}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <Flex gap="small">
            {viewMode === 'edit' && (
              <Button
                onClick={() => {
                  onEvent({ action: 'deleteParams', payload: [urlParam.id] });
                  onModalClose();
                }}
                disabled={urlParams.inProgress}
                danger
              >
                Delete from list
              </Button>
            )}
            <OkBtn />
          </Flex>
        </>
      )}
    >
      {apiErrTxt[lastErrorCode] && <Alert showIcon type="error" message={apiErrTxt[lastErrorCode]} />}
      {showModal && (
        <Form name="addUrlParam" form={form} style={{ marginTop: 12 }} layout="vertical" onFinish={onModalSubmit}>
          <Form.Item
            style={{ marginTop: 4, marginBottom: 6 }}
            name="param"
            label="Parameter string"
            rules={[
              {
                required: true,
                message: DEFAULT_ERROR,
              },
              {
                max: 50,
                message: DEFAULT_ERROR,
              },
              {
                pattern: ALLOWED_PARAM_NAME_PATTERN,
                message: SPECIAL_CHARS_ERROR,
              },
            ]}
          >
            <Input id="param" placeholder="Enter a URL query parameter" autoFocus value={urlParamName} showCount />
          </Form.Item>
          <div>
            <UrlAffected
              count={urlsCountResult.data?.count}
              isLoading={urlsCountResult.isFetching}
              isEmpty={!urlParamName}
            />
          </div>
          <div style={{ marginTop: 4, marginBottom: 24 }}>
            <Typography.Paragraph type="secondary" style={{ marginBottom: '4px' }}>
              If you are using a wildcard, you can try it here
            </Typography.Paragraph>
            <Input
              addonBefore={
                <div style={{ width: '70px' }}>
                  <MatchTag pattern={urlParamName} text={testParamInput} />
                </div>
              }
              placeholder="test-query-param"
              value={testParamInput}
              onChange={onTestParamInputChange}
            />
          </div>

          <Divider />

          <Form.Item name="rule" label="Caching rule">
            <Radio.Group>
              <Space direction="vertical">
                <Radio value="ignore">Ignore when Caching</Radio>
                <Radio value="allow">Use when Caching</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.page.language,
    apiErrTxt: state.page.contentData.apiError,
    lastErrorCode: state.page.lastErrorCode,
    urlParams: state.urlParams,
    prerenderUser: state.prerenderUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getContent, setLastErrorCode }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UrlParamEditor);
