import React from 'react';
import { Table } from 'antd';

const columns = [
  {
    title: 'Time Bucket',
    dataIndex: 'timeBucket',
    key: 'timeBucket',
  },
  {
    title: 'Pages',
    dataIndex: 'pages',
    key: 'pages',
  },
  {
    title: 'Total %',
    dataIndex: 'percentage',
    key: 'percentage',
  },
];

function getFormattedPercentage(amount, total) {
  const percentage = (amount / total) * 100;
  if (percentage) {
    return `${percentage.toFixed(1)}%`;
  }
  return '-';
}

function CacheAgeTable({ lessOneDay, gOneLSevenDays, gSevenLThirtyDays, gThirtyDays }) {
  const total = lessOneDay + gOneLSevenDays + gSevenLThirtyDays + gThirtyDays;

  const data = React.useMemo(
    () => [
      {
        id: 'lessOneDay',
        timeBucket: 'Less than 1 day',
        pages: lessOneDay,
        percentage: getFormattedPercentage(lessOneDay, total),
      },
      {
        id: 'gOneLSevenDays',
        timeBucket: '1-7 days',
        pages: gOneLSevenDays,
        percentage: getFormattedPercentage(gOneLSevenDays, total),
      },
      {
        id: 'gSevenLThirtyDays',
        timeBucket: '7-30 days',
        pages: gSevenLThirtyDays,
        percentage: getFormattedPercentage(gSevenLThirtyDays, total),
      },
      {
        id: 'gThirtyDays',
        timeBucket: 'More than 30 days',
        pages: gThirtyDays,
        percentage: getFormattedPercentage(gThirtyDays, total),
      },
    ],
    [lessOneDay, gOneLSevenDays, gSevenLThirtyDays, gThirtyDays]
  );

  return <Table size="small" rowKey="id" columns={columns} dataSource={data} pagination={false} bordered />;
}

export default CacheAgeTable;
