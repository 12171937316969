// Define action creators that dispatch actions:
import config from '../assets/config';
import internalApi from '../assets/internalApi';
import { getLocalErrorCode } from '../assets/lib';
import { setLastErrorCode, clearLastErrorCode } from './pageActions';

import { RECACHEQUEUE_SET, RECACHEQUEUE_IN_PROGRESS, CUSTOM_RECACHE_DELAY_SET, RENDERING_SPEED_SET } from './types';

export const recacheQueueSearch =
  (stateBucket = 'priority', params) =>
  async (dispatch) => {
    try {
      dispatch({ type: RECACHEQUEUE_IN_PROGRESS, payload: { stateBucket, value: true } });
      let filter = '';
      filter += params.query ? `&query=${params.query}` : '';
      filter += params.page ? `&page=${params.page}` : '';
      filter += params.pageSize ? `&pageSize=${params.pageSize}` : '';
      filter += params.sort ? `&sort=${params.sort}` : '';
      const requestUri =
        stateBucket === 'priority'
          ? `${config.apiUrl.dataServer}/api/priority-recache-queue?${filter}`
          : `${config.apiUrl.dataServer}/api/recache-queue?${filter}`;
      const data = await internalApi('get', requestUri, null);
      dispatch({ type: RECACHEQUEUE_SET, payload: { stateBucket, data, params } });
      dispatch(clearLastErrorCode());
    } catch (error) {
      console.log(error);
      dispatch({ type: RECACHEQUEUE_IN_PROGRESS, payload: { stateBucket, value: false } });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };
