import { Steps } from 'antd';
import './IntegrationSteps.css';

const IntegrationSteps = ({ current = 0 }) => (
  <Steps
    current={current}
    items={[
      { title: 'Choose Integration' },
      { title: 'Get Instructions', description: 'To integrate Prerender' },
      { title: 'Verify the integration' },
    ]}
  />
);

export { IntegrationSteps };
