// define action creators that dispatch actions:
import config from '../assets/config';
import internalApi from '../assets/internalApi';
import { getLocalErrorCode, targetAfterSigninCookie } from '../assets/lib';

import {
  SET_TARGET_AFTER_SIGNIN,
  SET_LASTERRORCODE,
  CLEAR_LASTERRORCODE,
  OPEN_MODAL,
  CLOSE_MODAL,
  ERASE_PAGE_REDUCER,
  SET_GROWL_VISIBLE,
  CONTENT_LOADING_IN_PROGRESS,
  CONTENT_SET,
  SET_CONTACT_FORM_STATUS,
  SET_MODAL_HEIGHT,
  PRERENDER_USER_UNAUTHORIZED,
  SET_COOKIE_CONSENT,
} from './types';

const authErrors = ['E0270', 'E0006', 'E0005'];

export function setLastErrorCode(errorCode) {
  return (dispatch) => {
    dispatch({ type: SET_LASTERRORCODE, payload: errorCode });
    if (authErrors.indexOf(errorCode) !== -1) {
      dispatch({ type: PRERENDER_USER_UNAUTHORIZED, payload: false });
    }
  };
}

export function clearLastErrorCode() {
  return { type: CLEAR_LASTERRORCODE };
}

export function setTargetAfterSignin(target) {
  targetAfterSigninCookie({ method: 'set', target }); // set cookie
  return { type: SET_TARGET_AFTER_SIGNIN, payload: { target } };
}

export function openModal(id, content) {
  return { type: OPEN_MODAL, payload: { id, content } };
}

export function reportModalHeight(modalHeight) {
  return { type: SET_MODAL_HEIGHT, payload: modalHeight };
}

export const closeModal =
  (browserHistory = null, target = null) =>
  (dispatch) => {
    dispatch({ type: CLOSE_MODAL });
    if (browserHistory && target) browserHistory.push(target);
  };

export function erasePageReducer() {
  // performed at user logout:
  return {
    type: ERASE_PAGE_REDUCER,
    payload: {},
  };
}

export function setGrowlVisible(bool) {
  return { type: SET_GROWL_VISIBLE, payload: bool };
}

export const getContent =
  (params, callback = null) =>
  async (dispatch) => {
    // options:
    // params:  ids, page
    try {
      dispatch({ type: CONTENT_LOADING_IN_PROGRESS, payload: true });
      let filter = '';
      filter += params.component ? `&component=${params.component}` : '';
      filter += params.lang ? `&lang=${params.lang}` : '';
      filter += params.group ? `&group=${params.group}` : '';
      filter += params.groups ? `&groups=${params.groups.join()}` : '';
      const requestUri = `${config.apiUrl.cmsServer}/api/v2/cms/getcontent?${filter}`;
      const data = await internalApi('get', requestUri, null);
      dispatch({ type: CONTENT_SET, payload: data });
      // dispatch(clearLastErrorCode()); if enabled 404 error is not triggered anymore
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch({ type: CONTENT_LOADING_IN_PROGRESS, payload: false });
      dispatch(setLastErrorCode(getLocalErrorCode(error)));
    }
  };

export const submitContactForm = (content) => async (dispatch) => {
  try {
    dispatch({ type: SET_CONTACT_FORM_STATUS, payload: { status: 'processing' } });
    await internalApi('post', `${config.apiUrl.authServer}/contactform`, content);
    dispatch({ type: SET_CONTACT_FORM_STATUS, payload: { status: 'success' } });
  } catch (error) {
    dispatch({ type: SET_CONTACT_FORM_STATUS, payload: { status: 'error' } });
    dispatch(setLastErrorCode(getLocalErrorCode(error)));
  }
};

export function setContactFormStatus(status) {
  return { type: SET_CONTACT_FORM_STATUS, payload: { status } };
}

export function setCookieConsent(enabled) {
  return { type: SET_COOKIE_CONSENT, payload: enabled };
}
