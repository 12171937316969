import { Typography, Space, Button } from 'antd';
import { RocketOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import RenderedHtml from './RenderedHtml';

/**
 * @param {Object} props
 * @param {Object} props.notification
 * @param {String} props.notification.description
 * @param {String} props.notification.createdAt - ISO date string
 * @param {Object} props.notification.metadata
 * @param {String=} props.notification.metadata.htmlContent - HTML content
 * @param {String} props.notification.metadata.suggestedChargebeePlanId - Chargebee plan ID
 */
function BetterPlanNotification({ notification }) {
  const { metadata = {} } = notification;
  return (
    <Space direction="vertical" size="large">
      <Typography.Text strong>{new Date(notification.createdAt).toLocaleDateString()}</Typography.Text>
      <Typography.Title type="warning" level={4}>
        <RocketOutlined /> We found a better plan for you!
      </Typography.Title>
      <Typography.Title level={3}>{notification.description}</Typography.Title>

      <RenderedHtml html={metadata.htmlContent} />

      <Link to={`/billing/plans`} state={{ suggestedChargebeePlanId: metadata.suggestedChargebeePlanId }}>
        <Button type="primary">Go to Subscription Plans</Button>
      </Link>
    </Space>
  );
}

export default BetterPlanNotification;
