// Functions of

export const calculateStats = ({ data, params }, defaultPageSize) => {
  const downloadedCount = data.length;
  const amtpp = params.pageSize || defaultPageSize;
  const total = (downloadedCount === amtpp) ? downloadedCount + 1 : downloadedCount; // assume we have more than one page

  const stats = {
    total,
    amt: downloadedCount,
    pages: downloadedCount > 0 ? parseInt(total / amtpp, 10) + params.page : params.page,
  };
  return stats;
};

export const EMPTY_STATS = {
  total: 0,
  pages: 0,
  amt: 0,
};
