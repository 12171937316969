import internalApi from '../assets/internalApi';
import config from '../assets/config';

const { Chargebee } = window;
let chargebeeInstance = null;
let lastPortalClosed = new Date();
const portalsOpenTimeDiff = 500;

export const BILLING_PORTAL_WINDOW = 'billing';
export const PAYMENT_PORTAL_WINDOW = 'payment';
export const INVOICES_PORTAL_WINDOW = 'invoices';

const sleepIfNeeded = async () => {
  // opening a second portal immediately after another portal just closed not working...
  if (Math.abs(new Date() - lastPortalClosed) < portalsOpenTimeDiff) {
    await new Promise((r) => setTimeout(r, portalsOpenTimeDiff));
  }
};

export const logout = () => {
  if (chargebeeInstance) {
    chargebeeInstance.logout();
  }
};

export const initChargebee = (site, domain, redirectUrl) => {
  logout();

  if (chargebeeInstance === null) {
    Chargebee.init({
      site,
      domain,
    });
    chargebeeInstance = Chargebee.getInstance();

    chargebeeInstance.setPortalSession(async () => {
      const result = await internalApi('post', `${config.apiUrl.dataServer}/api/create-portal-session`, {
        redirectUrl,
      });
      return result;
    });
  }
};

export const openChargebeePortalPaymentSources = ({ onPaymentSourceAdded, onClosed, paymentSourceUpdate }) => {
  if (chargebeeInstance) {
    (async () => {
      await sleepIfNeeded();
      chargebeeInstance.createChargebeePortal().openSection(
        {
          sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES,
        },
        {
          paymentSourceAdd: () => {
            if (onPaymentSourceAdded) onPaymentSourceAdded();
          },
          paymentSourceUpdate: () => {
            if (paymentSourceUpdate) paymentSourceUpdate();
          },
          close: () => {
            lastPortalClosed = new Date();
            if (onClosed) onClosed(PAYMENT_PORTAL_WINDOW);
          },
        }
      );
    })();
  }
};

export const openChargebeePortalBillingAddress = ({ onClosed }) => {
  if (chargebeeInstance) {
    (async () => {
      await sleepIfNeeded();
      chargebeeInstance.createChargebeePortal().openSection(
        {
          sectionType: Chargebee.getPortalSections().ADDRESS,
        },
        {
          close: (cbData) => {
            lastPortalClosed = new Date();
            if (onClosed) onClosed(BILLING_PORTAL_WINDOW);
          },
        }
      );
    })();
  }
};

export const openChargebeePortalBillingHistory = ({ onClosed }) => {
  if (chargebeeInstance) {
    (async () => {
      await sleepIfNeeded();
      chargebeeInstance.createChargebeePortal().openSection(
        {
          sectionType: Chargebee.getPortalSections().BILLING_HISTORY,
        },
        {
          close: () => {
            lastPortalClosed = new Date();
            if (onClosed) onClosed(INVOICES_PORTAL_WINDOW);
          },
        }
      );
    })();
  }
};

export const isFreePlan = (plan) => plan?.name === 'Free' || plan?.name === 'Basic';
export const isEnterprisePlan = (plan) => plan?.name === 'Enterprise';
export const FREE_PLAN_LIMIT = 1000;
export const RENDERS_PER_UNIT = 1000;
