//
//
// This file contains pieces from
// prerender-private-cache/middleware/ignore-urls.js
//
//

const NEVER_MATCH = { test: () => false, responseFn: () => {} };

// Q: Why not a package?
// A: All glob/wildcard packages are made for file matching
//    So for example:
//    duck*goose won't match for https://httpbin.org/anything/duckasdgoose only **/duck*goose would
const wildcardToRegex = (rule) => {
  const escapeRegex = (u) => u.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1'); // eslint-disable-line
  return new RegExp(`^${rule.split('*').map(escapeRegex).join('.*')}$`);
};

const validateResponseConfig = (responseConfig) => !!responseConfig.status;

// eslint-disable-next-line import/prefer-default-export
export const ruleConfigIntoRule = (ruleConfig) => {
  const { type, pattern, response } = ruleConfig;
  if (!validateResponseConfig(response)) return NEVER_MATCH;

  // On match this will send the response to the crawler
  const responseFn = (res) => {
    if (response.status) res.status(response.status);
    if (response.headers) res.set(response.headers);
    return res.send(response.data);
  };

  switch (type) {
    case 'REGEX': {
      const regex = new RegExp(pattern);
      return { test: (url) => regex.test(url), responseFn };
    }
    case 'CONTAINS': return { test: (url) => url.includes(pattern), responseFn };
    case 'WILDCARD': {
      const regex = wildcardToRegex(pattern);
      return { test: (url) => regex.test(url), responseFn };
    }

    default: return NEVER_MATCH;
  }
};
