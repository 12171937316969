import { ChargeBeePlanId } from './types';

export enum ChargeBeePackageId {
  Kickstart = 'package-2024-kickstart-100',
  Advantage = 'package-2024-advantage-100',
  ProPay = 'package-2024-propay-100',
  Security = 'package-2024-security-100',
  Keyword200 = 'package-2024-keyword-200',
  Keyword500 = 'package-2024-keyword-500',
  Keyword1000 = 'package-2024-keyword-1000',
}

enum PackageName {
  Kickstart = 'Kickstart',
  Advantage = 'Advantage+',
  ProPay = 'ProPay',
  Security = 'Security+',
  Keyword200 = 'Keyword 200',
  Keyword500 = 'Keyword 500',
  Keyword1000 = 'Keyword 1000',
}

export const PLAN_TO_FREE_KEYWORD_MAPPING: Record<ChargeBeePlanId, number> = {
  [ChargeBeePlanId.Basic]: 0,
  [ChargeBeePlanId.Professional]: 5,
  [ChargeBeePlanId.Startup]: 10,
  [ChargeBeePlanId.Business]: 25,
  [ChargeBeePlanId.Enterprise]: 50,
  [ChargeBeePlanId.Startup2022]: 10,
  [ChargeBeePlanId.Scaleup2022]: 25,
  [ChargeBeePlanId.Enterprise2022]: 50,
  [ChargeBeePlanId.CustomEnterprise]: 50,
  [ChargeBeePlanId.CustomEnterpriseYearly]: 50,
  [ChargeBeePlanId.Legacy]: 50,
  [ChargeBeePlanId.LegacyYearly]: 0,
  [ChargeBeePlanId.Adhoc]: 0,
  [ChargeBeePlanId.Lite2024]: 5,
  [ChargeBeePlanId.Essential2024]: 10,
  [ChargeBeePlanId.Advanced2024]: 25,
  [ChargeBeePlanId.Custom2024]: 50,
};

export const KEYWORD_PACKAGE_TO_NUMBER_OF_KEYWORDS = {
  [ChargeBeePackageId.Keyword200]: 200,
  [ChargeBeePackageId.Keyword500]: 500,
  [ChargeBeePackageId.Keyword1000]: 1000,
};

export enum AddonBillingType {
  Subscription = 'subscription',
  OneTime = 'one_time',
}

export interface ChargeBeePackage {
  readonly name: PackageName;
  readonly chargeBeeAddonId: ChargeBeePackageId;
  readonly index: number;
  readonly isActive: boolean;
  readonly costInCents: number;
  readonly currencyCode: string;
  readonly billingType: AddonBillingType;
  readonly description?: string;
  readonly features?: Array<{ title: string; description?: string }>;
}

const tier2024ChargeBeePackages: Array<ChargeBeePackage> = [
  {
    name: PackageName.Kickstart,
    chargeBeeAddonId: ChargeBeePackageId.Kickstart,
    index: 0,
    isActive: false,
    costInCents: 80000,
    currencyCode: 'USD',
    billingType: AddonBillingType.OneTime,
    description:
      'This one-time package empowers you with expert setup and configuration, ensuring a smooth and secure launch.',
    features: [
      {
        title: '3 setup and configuration sessions',
        description: 'with our experts',
      },
      {
        title: 'Onboarding',
        description: 'assistance',
      },
      {
        title: 'Security assessment',
      },
    ],
  },
  {
    name: PackageName.Advantage,
    chargeBeeAddonId: ChargeBeePackageId.Advantage,
    index: 1,
    isActive: false,
    costInCents: 17000,
    currencyCode: 'USD',
    billingType: AddonBillingType.Subscription,
    description: `Enjoy an elevated level of support and insight. The Advantage+ package allows you to have more strategic control over your operations.`,
    features: [
      {
        title: 'Dedicated account manager',
        description: '2 monthly consultation sessions',
      },
      {
        title: 'Custom reports',
        description: 'tailored to your specific needs',
      },
      {
        title: 'Ongoing support',
        description: 'with guaranteed response times',
      },
    ],
  },
  {
    name: PackageName.ProPay,
    chargeBeeAddonId: ChargeBeePackageId.ProPay,
    index: 2,
    isActive: false,
    costInCents: 2500,
    currencyCode: 'USD',
    billingType: AddonBillingType.Subscription,
    description: `Simplify your financial operations. Minimize administrative burdens and ensure smooth payments.`,
    features: [
      {
        title: 'ACH handling',
      },
      {
        title: 'PO management',
      },
    ],
  },
  {
    name: PackageName.Security,
    chargeBeeAddonId: ChargeBeePackageId.Security,
    index: 3,
    isActive: false,
    costInCents: 12000,
    currencyCode: 'USD',
    billingType: AddonBillingType.Subscription,
    description: `Protect your pre-rendering infrastructure with comprehensive security solutions.`,
    features: [
      {
        title: 'Secure Single Sign-On (SSO)',
        description: 'Setup and ongoing maintenance',
      },
      {
        title: 'Regular security assessment',
        description: 'completion',
      },
      {
        title: 'Dedicated security',
        description: 'expertise and support',
      },
    ],
  },
  {
    name: PackageName.Keyword200,
    chargeBeeAddonId: ChargeBeePackageId.Keyword200,
    index: 4,
    isActive: false,
    costInCents: 4400,
    currencyCode: 'USD',
    billingType: AddonBillingType.Subscription,
    description: `Track 200 keywords with daily updates. Perfect for small businesses or focused campaigns.`,
    features: [
      {
        title: '200 keywords',
      },
      {
        title: 'Daily updates',
      },
    ],
  },
  {
    name: PackageName.Keyword500,
    chargeBeeAddonId: ChargeBeePackageId.Keyword500,
    index: 5,
    isActive: false,
    costInCents: 8900,
    currencyCode: 'USD',
    billingType: AddonBillingType.Subscription,
    description: `Monitor 500 keywords daily. Ideal for growing businesses or managing multiple websites.`,
    features: [
      {
        title: '500 keywords',
      },
      {
        title: 'Daily updates',
      },
    ],
  },
  {
    name: PackageName.Keyword1000,
    chargeBeeAddonId: ChargeBeePackageId.Keyword1000,
    index: 6,
    isActive: false,
    costInCents: 13500,
    currencyCode: 'USD',
    billingType: AddonBillingType.Subscription,
    description: `Track 1000 keywords with daily updates. Comprehensive coverage for larger businesses or agencies.`,
    features: [
      {
        title: '1000 keywords',
      },
      {
        title: 'Daily updates',
      },
    ],
  },
];

export const getPackageById = (chargebeePackageId: string): ChargeBeePackage => {
  const cbPackage = tier2024ChargeBeePackages.find((p) => p.chargeBeeAddonId === chargebeePackageId);

  if (!cbPackage) throw new Error(`PackageId not found: ${chargebeePackageId}`);

  return cbPackage;
};

const PackageIdToNameMap: Record<ChargeBeePackageId, PackageName> = {
  [ChargeBeePackageId.Kickstart]: PackageName.Kickstart,
  [ChargeBeePackageId.Advantage]: PackageName.Advantage,
  [ChargeBeePackageId.ProPay]: PackageName.ProPay,
  [ChargeBeePackageId.Security]: PackageName.Security,
  [ChargeBeePackageId.Keyword200]: PackageName.Keyword200,
  [ChargeBeePackageId.Keyword500]: PackageName.Keyword500,
  [ChargeBeePackageId.Keyword1000]: PackageName.Keyword1000,
};

export function chargeBeePackageIdToPackageName(packageId: ChargeBeePackageId): string | undefined {
  return PackageIdToNameMap[packageId];
}

export function getAllPackages(): Array<ChargeBeePackage> {
  return tier2024ChargeBeePackages;
}
