import React from 'react';
import { Modal, Select, Form } from 'antd';

import { getLowerTierRoles } from './appUserRoles';

function UpdateRoleModal({ open, onClose, onSubmit, isLoading = false, userBeingUpdated = {}, userRole }) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSubmit({ loginUserId: userBeingUpdated.loginUserId, role: values.role });
  };

  const lowerTierRoles = React.useMemo(() => getLowerTierRoles(userRole), [userRole]);

  React.useEffect(() => {
    form.setFieldValue('role', userBeingUpdated?.role);
  }, [userBeingUpdated, form]);

  return (
    <Modal
      title="User settings"
      open={open}
      onCancel={onClose}
      okText="Update"
      okButtonProps={{ htmlType: 'submit', form: 'userSettings', loading: isLoading }}
    >
      <Form
        form={form}
        layout="vertical"
        name="userSettings"
        onFinish={onFinish}
        initialValues={{ role: userBeingUpdated?.role }}
      >
        <Form.Item label="User role" name="role">
          <Select id="role" options={lowerTierRoles} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default UpdateRoleModal;
