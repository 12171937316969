import { useSelector } from 'react-redux';

const ALLOWED_USER_IDS_NEW_SCORE = [
  278603, 2800, 624119, 257720, 276430, 619523, 619330, 623058, 272579, 2228, 133183, 279551, 257544,
];

export function useShowNewSeoScore() {
  const companyId = useSelector((state) => state.prerenderUser.companyId);
  return ALLOWED_USER_IDS_NEW_SCORE.includes(companyId);
}
