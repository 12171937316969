import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Space, Button, Skeleton, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import AdminTemplate from '../../layout/AdminTemplate';
import NotificationComponent from './Notifications';
import { useEvent } from '../events/hooks/useEvent';
import { decreaseNotificationCount } from '../../actions/prerenderUserActions';
import { useMarkAsReadMutation, useGetNotificationQuery } from './NotificationsApiSlice';

const META_TAGS = {
  title: 'Notifications - Prerender.io',
};

function NotificationDetailsPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { track } = useEvent();
  const dispatch = useDispatch();
  const notificationQueryResult = useGetNotificationQuery(id);
  const [markAsReadMutation] = useMarkAsReadMutation();

  const markAsRead = (notificationId) => {
    dispatch(decreaseNotificationCount(1));
    markAsReadMutation([notificationId]);
  };

  React.useEffect(() => {
    if (notificationQueryResult.isSuccess) {
      track('Notification viewed', { notificationId: notificationQueryResult.data.id });
      if (!notificationQueryResult.data.seenAt) markAsRead(notificationQueryResult.data.id);
    } else if (notificationQueryResult.isError) {
      message.error('Failed to fetch notification');
    }
  }, [notificationQueryResult.isSuccess, notificationQueryResult.isError]);

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Space direction="vertical" size="large">
        <Button icon={<CloseOutlined />} onClick={() => navigate('..')}>
          Close
        </Button>
        <Typography.Title level={2}>Notifications Center</Typography.Title>
        <Skeleton loading={notificationQueryResult.isFetching} active>
          <NotificationComponent notification={notificationQueryResult.data} />
        </Skeleton>
      </Space>
    </AdminTemplate>
  );
}

export default NotificationDetailsPage;
