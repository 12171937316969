import { Button, Checkbox, Form, Input } from 'antd';
import '../RegistrationPage.css';
import { useEffect } from 'react';
import { useEvent } from '../../events/hooks/useEvent';

const goalOptions = [
  { value: 'Index more pages', label: 'Index more pages' },
  { value: 'Faster indexing', label: 'Faster indexing' },
  { value: 'Crawl budget optimization', label: 'Crawl budget optimization' },
  { value: 'Fix incomplete rendering of pages', label: 'Fix incomplete rendering of pages' },
  { value: 'Better server response times', label: 'Better server response times' },
  { value: 'Better SEO scores (PageSpeed Insights)', label: 'Better SEO scores (PageSpeed Insights)' },
  { value: 'Open graph rendering for social sharing', label: 'Open graph rendering for social sharing' },
  { value: 'Other', label: 'Other (please specify)' },
];

const mapFieldsData = (data) => {
  return {
    goals: [...data.goals, data.otherGoal].filter((el) => el !== 'Other' && el !== undefined),
  };
};

const mapValuesToFields = (values, options) => {
  const result = { goals: [], otherGoal: '' };
  values?.goals?.forEach((goal) => {
    if (options.find((option) => option.value === goal)) {
      result.goals.push(goal);
    } else {
      result.otherGoal = goal;
      result.goals.push('Other');
    }
  });
  return result;
};

export const GoalsForm = ({ onFinish, onGoBack, values }) => {
  const [form] = Form.useForm();
  const goalsWatch = Form.useWatch('goals', form);
  const { track, updateUserProfile } = useEvent();

  useEffect(() => {
    if (goalsWatch && !goalsWatch.includes('Other')) {
      form.setFieldValue('otherGoal', undefined);
    }
  }, [goalsWatch]);

  const onFinishForm = (data) => {
    const mappedData = mapFieldsData(data);
    onFinish(mappedData);
    track('Signup Form: Goal Entered', { goal: mappedData.goals });
    updateUserProfile({ goal: mappedData.goals });
  };

  return (
    <>
      <Form
        onFinish={(data) => {
          onFinishForm(data);
        }}
        layout="vertical"
        form={form}
        initialValues={mapValuesToFields(values, goalOptions)}
      >
        <div className="my-5">
          <h1 className="text-center">What are you trying to achieve?</h1>
          <h3 className="text-center">Help us provide you and your team with a better experience.</h3>
        </div>
        <Form.Item name={'goals'} rules={[{ required: true, message: 'Please select at least one' }]}>
          <Checkbox.Group options={goalOptions} />
        </Form.Item>
        <Form.Item name={'otherGoal'} noStyle={goalsWatch && !goalsWatch.includes('Other')}>
          <Input type={goalsWatch && goalsWatch.includes('Other') ? 'text' : 'hidden'} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          Continue
        </Button>
        <Button type="link" onClick={onGoBack}>
          Go back
        </Button>
      </Form>
    </>
  );
};
