import SiteControl from './SiteControl';
import SitemapControl from './SitemapControl';

import { useGetGscIntegrationsStatusQuery } from '../api/gscApiSlice';
import { usePrerenderUser } from '../../../hooks/usePrerenderUser';

const SearchConsoleActions = () => {
  const { data: gscIntegrationStatus } = useGetGscIntegrationsStatusQuery();
  const prerenderUser = usePrerenderUser();

  return (
    <div className="w-full">
      {prerenderUser && gscIntegrationStatus?.data?.isConnected ? (
        <div className="items-center">
          <SitemapControl />
        </div>
      ) : (
        <h2 className="text-lg3 dark:text-white">
          Your Search Console account isn't connected with Prerender yet. Connect it to see results.
        </h2>
      )}
    </div>
  );
};

export default SearchConsoleActions;
