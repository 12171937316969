import { KEYWORD_PACKAGE_TO_NUMBER_OF_KEYWORDS } from '@prerender/billing-shared';

export const REGEX_CHARGEBEE_ADDON_ID_FOR_KEYWORD_PACKAGE = /^package-(\d{4})+-keyword-\d+$/;

export function setDisableKeywordPackages(packages) {
  const clonedPackages = structuredClone(packages);
  const keywordPackages = clonedPackages.filter((p) =>
    REGEX_CHARGEBEE_ADDON_ID_FOR_KEYWORD_PACKAGE.test(p.chargeBeeAddonId)
  );
  const activeKeywordPackage = keywordPackages.find((p) => p.isActive);
  if (activeKeywordPackage) {
    const otherKeywordPackages = keywordPackages.filter(
      (p) => !p.chargeBeeAddonId !== activeKeywordPackage.chargeBeeAddonId
    );
    const numberOfKeywordsFromActivePackage =
      KEYWORD_PACKAGE_TO_NUMBER_OF_KEYWORDS[activeKeywordPackage.chargeBeeAddonId];
    otherKeywordPackages.forEach((keywordPackage) => {
      const numberOfKeywordsFromPackage = KEYWORD_PACKAGE_TO_NUMBER_OF_KEYWORDS[keywordPackage.chargeBeeAddonId];
      keywordPackage.isDisableKeywordPackageCard = numberOfKeywordsFromPackage < numberOfKeywordsFromActivePackage;
    });
  }
  return clonedPackages;
}
