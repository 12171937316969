import { Form, Input, Modal, Slider, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { expirationIndexToReadable } from '../utils/expirationIndexToReadable';

const { Paragraph } = Typography;

export const NewRuleModal = ({ showModal, isAddSettingLoading, onSubmit, onCancel }) => {
  const [cacheExpirationLabel, setCacheExpirationLabel] = useState(() => expirationIndexToReadable(1));
  const [form] = Form.useForm();

  const onCacheExpirationChange = (value) => setCacheExpirationLabel(expirationIndexToReadable(value));

  useEffect(() => {
    form.setFieldsValue({ cacheExpiration: 1, rule: '' });
    setCacheExpirationLabel(expirationIndexToReadable(1));
  }, [showModal]);

  return (
    <Modal
      title="Add rule"
      open={showModal}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
      confirmLoading={isAddSettingLoading}
    >
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item
          label="Rule"
          name="rule"
          rules={[
            {
              required: true,
              message: 'Please enter a URL pattern',
            },
          ]}
        >
          <Input placeholder="Add URL pattern" autoFocus />
        </Form.Item>
        <Form.Item name="cacheExpiration" label={`Cache Expiration (${cacheExpirationLabel})`}>
          <Slider
            min={1}
            max={23 + 30} // 23 hours + 30 days
            marks={{ 1: '1h', 53: '30d' }}
            tooltip={{ formatter: expirationIndexToReadable }}
            onChangeComplete={onCacheExpirationChange}
          />
        </Form.Item>
      </Form>
      <Paragraph>Please note that this change may lead to an increase in the monthly render counter</Paragraph>
    </Modal>
  );
};
