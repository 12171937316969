import { Flex, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

const NavigationButtons = ({
  onGoBack,
  onGoNext,
  disabledNext,
  disabledBack,
  nextBtnText = 'Continue',
  showIcon,
  showExtraButton = false,
  extraButtonText,
  onExtraButtonClick,
}) => {
  return (
    <Flex justify="space-between" style={{ marginTop: 'auto', width: '100%', paddingRight: 70 }}>
      {onGoBack ? (
        <Button type="default" onClick={onGoBack} disabled={disabledBack}>
          Back
        </Button>
      ) : (
        <div></div>
      )}
      <Flex gap="small">
        {showExtraButton && (
          <Button type="text" onClick={onExtraButtonClick}>
            {extraButtonText}
          </Button>
        )}
        {onGoNext ? (
          <Button type="primary" onClick={onGoNext} disabled={disabledNext}>
            {showIcon && <CheckOutlined style={{ paddingRight: 8 }} />}
            {nextBtnText}
          </Button>
        ) : (
          <div></div>
        )}
      </Flex>
    </Flex>
  );
};

export default NavigationButtons;
