import { GET_SEO_SCORE_IN_PROGRESS, SET_SEO_SCORE } from './actionTypes';

const initialState = {
  inProgress: false,
  seoScoreResult: null,
};

const seoScoreModalReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case GET_SEO_SCORE_IN_PROGRESS:
      return {
        ...state,
        inProgress: payload,
      };

    case SET_SEO_SCORE:
      return {
        ...state,
        seoScoreResult: payload,
      };

    default: return state;
  }
};

export default seoScoreModalReducer;
