import { Routes, Route } from 'react-router-dom';

import requireAuth from '../components/hocs/requireAuth';
import { NotificationsListPage, NotificationDetailsPage } from '../features/NotificationsCenter';

function NotificationsPage() {
  return (
    <Routes>
      <Route path="" element={<NotificationsListPage />} />
      <Route path=":id" element={<NotificationDetailsPage />} />
    </Routes>
  );
}

export default requireAuth(NotificationsPage);
