import { restApi } from '../../api/restApiSlice';

export const renderingQueueApi = restApi.injectEndpoints({
  endpoints: (builder) => ({
    clearManualQueue: builder.mutation({
      query: () => ({ url: '/render-queue/manual', method: 'DELETE' }),
      providesTags: ['ManualQueue'],
    }),
  }),
});

export const { useClearManualQueueMutation } = renderingQueueApi;
