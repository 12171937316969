import React from 'react';
import { Alert, theme } from 'antd';

import { formatDays } from '../../../assets/dateFormatter';

type CacheFreshnessUpdatedAlertProps = {
  days: number;
  afterClose: () => void;
};

function CacheFreshnessUpdatedAlert({ days, afterClose }: CacheFreshnessUpdatedAlertProps) {
  const { token } = theme.useToken();

  return (
    <Alert
      style={{ marginBottom: token.marginMD }}
      type="success"
      message={`The recaching interval for all URLs has been set to ${formatDays(days)}.`}
      closable
      afterClose={afterClose}
    />
  );
}

export default CacheFreshnessUpdatedAlert;
