import { matchWith } from '../assets/lib';
import {
  URLPARAMS_SET,
  URLPARAMS_IN_PROGRESS,
  URLPARAMS_RESET,
} from '../actions/types';

const emptyState = {
  inProgress: false,
  params: {
    page: 0,
    pageSize: 200,
    query: '',
  },
  stats: {
    total: 0,
    pages: 0,
    amt: 0,
  },
  items: [],
};

const initialState = emptyState;

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case URLPARAMS_SET:
    {
      const dl = payload.data.length;
      const amtpp = payload.params.pageSize || state.params.pageSize;
      const total = (dl === amtpp) ? dl + 1 : dl; // assume we have more than one page
      return { ...state,
        inProgress: false,
        params: matchWith(state.params, payload.params),
        stats: { total, amt: dl, pages: dl > 0 ? parseInt(total / amtpp, 10) + payload.params.page : 0 },
        items: payload.data,
      };
    }
    case URLPARAMS_IN_PROGRESS:
      return { ...state, inProgress: payload };
    case URLPARAMS_RESET:
      return initialState;
    default:
      break;
  }
  return state;
};
