import React from 'react';

export default function FullPageLoading() {
  return (
    <div
      style={{
        width: '100dvw',
        height: '100dvh',
        backgroundColor: '#f9fbfd',
        color: '#222',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '3rem',
        fontWeight: 100,
        fontFamily: 'system-ui',
      }}
    >
      Loading
    </div>
  );
}
