import { Slider } from 'antd';

const RecacheIntervalSlider = ({ value, max, currentFreshness, onChange }) => {
  const getPossibleValues = () => {
    // 6h, 12h, 1, 2, 3, ... 29, 30
    const normalValues = [0.25, 0.5, ...Array.from({ length: 30 }, (_, i) => i + 1)];
    if ((max === 60 && currentFreshness <= 60) || (max < 60 && currentFreshness === 60)) {
      return [...normalValues, 60];
    }
    if (max !== 90 && currentFreshness === 90) {
      return [...normalValues, 90];
    }
    if (max === 90 || (max === 60 && currentFreshness === 90) || (max === 90 && currentFreshness === 60)) {
      return [...normalValues, 60, 90];
    }
    return normalValues;
  };

  const possibleValues = getPossibleValues();
  const sliderChanged = (daysIndex) => {
    const val = possibleValues[daysIndex];
    if (onChange) onChange(val);
  };

  const daysToSliderValue = (v) => {
    const result = possibleValues.indexOf(v);
    return result === -1 ? Math.floor(possibleValues.length / 2) : result;
  };

  return (
    <div>
      <Slider
        tooltip={{ formatter: null }}
        max={possibleValues.length - 1}
        min={0}
        value={daysToSliderValue(value)}
        onChange={sliderChanged}
      />
    </div>
  );
};

export default RecacheIntervalSlider;
