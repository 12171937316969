import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

import { Container } from '../../components/CssFrameworkComponents';
import { closeModal } from '../../actions/pageActions';
import { TWrapper, TRow, TCell } from '../../components/TableLib';
import { ModalWrapper, ModalHeader, ModalBodyScroller, ModalFooter } from '../../components/ModalLib';
import { LoadingTextBar, ControlButton } from '../../components/PageElementsLib';
import { useGetSeoScoreDetailsQuery } from '../api/seoApiSlice';

export const SEO_SCORE_DETAILS_MODAL_NAME = 'SeoScoreDetailsModal';

const CLOSE_MODAL_ACTION = 'closeModal';

const SeoScoreDetailsModal = ({ url }) => {
  const dispatch = useDispatch();

  const { data, isFetching, error } = useGetSeoScoreDetailsQuery(url, { refetchOnMountOrArgChange: true });

  return (
    <ModalWrapper minWidth="40%">
      <ModalHeader title="SEO Score" />
      <ModalBodyScroller>
        <Container fluid>
          <div className="row align-items-center m-4">
            <div className="col-auto">
              <p>
                HTML elements play a crucial role in helping search engines understand and rank your website’s content.
                Here are the most important HTML elements to optimize:
              </p>
            </div>
          </div>
          {error && (
            <div
              style={{
                padding: '12px 24px',
                color: 'red',
              }}
            >
              An unexpected error has occured while retrieving details. Please try again by reoping modal.
            </div>
          )}
          {!error && (
            <TWrapper
              tableId="seoScoreDetails"
              parentType="modal"
              cols={[
                { size: 'min', valign: 'middle', addClass: 'text-center', name: 'Passed' },
                { size: 'flex', valign: 'middle', addClass: 'text-center', name: 'Description' },
                { size: 'min', valign: 'middle', addClass: 'text-center', name: 'Documentation' },
              ]}
              showTHead
              isLoading={isFetching}
              loadingCols={[<LoadingTextBar />, <LoadingTextBar />, <LoadingTextBar />]}
              maxLoadingRows={2}
            >
              {!isFetching &&
                data?.details?.map((it, index) => (
                  <TRow key={index}>
                    <TCell addClass={'text-center'}>
                      {it.passed ? (
                        <CheckCircleFilled style={{ fontSize: '32px', color: 'green' }} />
                      ) : (
                        <CloseCircleFilled style={{ fontSize: '32px', color: 'red' }} />
                      )}
                    </TCell>
                    <TCell>{it.description ? it.description : cm.notAvailable}</TCell>
                    <TCell>
                      {it.documentation && (
                        <a
                          type="button"
                          className="p-0 btn btn-normal btn-link link-blue"
                          href={it.documentation}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Why and How to Fix it?
                        </a>
                      )}
                    </TCell>
                  </TRow>
                ))}
            </TWrapper>
          )}
        </Container>
      </ModalBodyScroller>
      <ModalFooter
        modalFooterRight={
          <ControlButton
            text="Close"
            onclick={() => {
              dispatch(closeModal());
            }}
          />
        }
      />
    </ModalWrapper>
  );
};

export default SeoScoreDetailsModal;
