import React, { useEffect } from 'react';
import { Button, Typography, Flex, Popover, theme, message } from 'antd';
import { BugOutlined, MessageOutlined, StarOutlined, LinkOutlined, CalendarOutlined } from '@ant-design/icons';

import { useEvent } from '../events/hooks/useEvent';
import { openWebWidget } from './Zendesk';
import { useUserSnap } from './UserSnap';
import BookMeetingModal from './BookMeetingModal';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useGetCustomerSupportQuery } from './customerSupportApiSlice';
import { isEnterprise } from '@prerender/billing-shared';

const HELP_CENTER_URL = 'https://docs.prerender.io/';
const EVENTS_TRACKED = {
  sectionOpened: 'Help section opened',
  helpCenterClicked: 'Help Center clicked',
  reportBugClicked: 'Report bug clicked',
  contactSupportClicked: 'Contact support clicked',
  rateExperienceClicked: 'Rate experience clicked',
  contactCSMClicked: 'Contact CSM clicked',
  contactSalesClicked: 'Contact sales clicked',
};

function HelpSection({ contactCSMVisible = false, eventsTrackingData = {} }) {
  const [messageApi, contextHolder] = message.useMessage();
  const [isHelpPopoverOpen, setIsHelpPopoverOpen] = React.useState(false);
  const [openContactModalType, setOpenContactModalType] = React.useState('');
  const { openUserSatisfactionWidget, openIssueTrackingWidget } = useUserSnap();
  const { track } = useEvent();
  const { token } = theme.useToken();
  const user = usePrerenderUser();
  const { data, error, isLoading } = useGetCustomerSupportQuery(user.csmId);
  const isEnterpriseUser = isEnterprise(user?.chargebeePlanId);

  const actionWrapper = (callback, eventName) => () => {
    track(eventName, eventsTrackingData);
    callback();
    setIsHelpPopoverOpen(false);
  };

  const onHelpClick = () => {
    track(EVENTS_TRACKED.sectionOpened, eventsTrackingData);
    setIsHelpPopoverOpen(true);
  };

  useEffect(() => {
    if (error) {
      messageApi.error('Failed to load customer support data');
    }
  }, [error]);

  return (
    <>
      {contextHolder}
      <Popover
        title="How can we help you?"
        trigger="click"
        open={isHelpPopoverOpen}
        onOpenChange={(value) => setIsHelpPopoverOpen(value)}
        content={
          <div>
            <Typography.Paragraph>
              <p>Please choose an option below.</p>
            </Typography.Paragraph>
            <Flex vertical gap="large" style={{ padding: '8px' }}>
              <Button
                icon={<LinkOutlined />}
                type="primary"
                href={HELP_CENTER_URL}
                target="_blank"
                onClick={actionWrapper(() => {}, EVENTS_TRACKED.helpCenterClicked)}
              >
                Visit our Help Center
              </Button>
              <Button
                icon={<BugOutlined />}
                type="primary"
                onClick={actionWrapper(openIssueTrackingWidget, EVENTS_TRACKED.reportBugClicked)}
              >
                Report a bug
              </Button>
              {!isEnterpriseUser && (
                <Button
                  icon={<MessageOutlined />}
                  type="primary"
                  onClick={actionWrapper(openWebWidget, EVENTS_TRACKED.contactSupportClicked)}
                >
                  Contact support
                </Button>
              )}
              <Button
                icon={<StarOutlined />}
                type="primary"
                onClick={actionWrapper(openUserSatisfactionWidget, EVENTS_TRACKED.rateExperienceClicked)}
              >
                Rate your experience
              </Button>
              {contactCSMVisible && (
                <Button
                  icon={<CalendarOutlined />}
                  type="primary"
                  onClick={actionWrapper(() => setOpenContactModalType('csm'), EVENTS_TRACKED.contactCSMClicked)}
                >
                  Contact {data?.title}
                </Button>
              )}
              <Button
                icon={<CalendarOutlined />}
                type="primary"
                onClick={actionWrapper(() => setOpenContactModalType('sales'), EVENTS_TRACKED.contactSalesClicked)}
              >
                Contact our Sales Team
              </Button>
            </Flex>
          </div>
        }
      >
        <Button
          icon={<MessageOutlined />}
          type="default"
          size="large"
          onClick={onHelpClick}
          style={{ color: token.colorPrimary, borderColor: token.colorPrimary }}
        >
          Help
        </Button>
      </Popover>
      <BookMeetingModal
        contactType={openContactModalType}
        onClose={() => setOpenContactModalType('')}
        eventsTrackingData={eventsTrackingData}
        csmData={data}
        isLoading={isLoading}
      />
    </>
  );
}

export default HelpSection;
