import React from 'react';
import { Modal, Typography, Button, Flex } from 'antd';

type CachedPagesActionsModalProps = {
  open: boolean;
  onCancel: () => void;
  urls: { id: string; url: string }[];
  onRecache: (urls: { id: string }[]) => void;
  onDelete: (urls: { id: string }[]) => void;
};

function URLData({ url }: { url: string }) {
  return (
    <div>
      <Typography.Text type="secondary">URL</Typography.Text>
      <Typography.Paragraph code>{url}</Typography.Paragraph>
    </div>
  );
}

function CachedPagesActionsModal({ open, onCancel, urls, onDelete, onRecache }: CachedPagesActionsModalProps) {
  const hasMultipleUrls = urls.length > 1;

  const modalTitle = hasMultipleUrls ? `Edit ${urls.length} URLs` : 'Edit URL';
  const removeFromCacheText = hasMultipleUrls ? `Remove ${urls.length} URLs from cache` : 'Remove from cache';

  return (
    <Modal
      title={modalTitle}
      open={open}
      onCancel={onCancel}
      closable
      footer={[<Button onClick={onCancel}>Close</Button>]}
    >
      {!!urls.length && !hasMultipleUrls && <URLData url={urls[0].url} />}
      {!!urls.length && (
        <div>
          <Typography.Paragraph type="secondary">Options</Typography.Paragraph>
          <Flex vertical gap="middle">
            {!hasMultipleUrls && (
              <Button href={`/raw-html?id=${urls[0].id}`} target="_blank" rel="noreferrer">
                View Cached HTML
              </Button>
            )}
            <Button onClick={() => onRecache(urls)}>Add To Recache Queue</Button>
            <Button onClick={() => onDelete(urls)} danger>
              {removeFromCacheText}
            </Button>
          </Flex>
        </div>
      )}
    </Modal>
  );
}

export default CachedPagesActionsModal;
