// '123 days'
// '1 day'
// '12 hours'

export const formatDays = (days) => {
  const HOURS = 24;
  if (days < 1) {
    const daysInHours = Math.round(days * HOURS);
    const hoursText = daysInHours === 1 ? 'hour' : 'hours';
    return `${daysInHours} ${hoursText}`;
  }
  const daysText = days === 1 ? 'day' : 'days';
  return `${days} ${daysText}`;
};

export const formatCacheRefreshInterval = (cacheRefreshInterval, isTooltip) => {
  {
    const intervalPreposition = isTooltip ? 'and' : 'to';
    if (!cacheRefreshInterval || cacheRefreshInterval.length !== 2) {
      return '';
    }
    const formattedFrom =
      cacheRefreshInterval[0] < 1 && cacheRefreshInterval[1] >= 1
        ? formatDays(cacheRefreshInterval[0])
        : `${cacheRefreshInterval[0]}`;
    return cacheRefreshInterval[0] === cacheRefreshInterval[1]
      ? `${formatDays(cacheRefreshInterval[1])}`
      : `${formattedFrom} ${intervalPreposition} ${formatDays(cacheRefreshInterval[1])}`;
  }
};

export const stringToDate = (strRep) => {
  if (!strRep) return null;
  const timestamp = Date.parse(strRep);
  if (Number.isNaN(timestamp)) return null;
  return new Date(timestamp);
};
