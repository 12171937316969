import React, { useState } from 'react';
import { Flex, Typography, Card, Button, Skeleton, Space } from 'antd';
// import { SettingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';

const { Paragraph, Text } = Typography;

import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';

import { useGetPagesDeliveredDailyQuery } from '../api/dashboardApiSlice';

import { BlurringOverlay } from '../../../components/BlurringOverlay/BlurringOverlay';

const colors = {
  'Cache Misses': `#9EA2AE`,
  'Cache Hits': `#0771B4`,
};

export function CrawlerVisitsCharts({ days, trackingCodeInstalled, domain }) {
  const { data: dataChartRaw, isFetching: isLoadingChart } = useGetPagesDeliveredDailyQuery(
    { days, domain },
    { refetchOnMountOrArgChange: true }
  );

  if (isLoadingChart) {
    return (
      <Card bordered={false} style={{ width: '100%' }}>
        <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px' }}>
          <Skeleton />
        </Flex>
      </Card>
    );
  }

  const dataChart =
    dataChartRaw?.data?.map(({ day, ...fields }) => {
      const name = dayjs(day).format('MMM DD');

      const filteredFields = {};

      for (const [key, value] of Object.entries(fields)) {
        if (value > 0) {
          filteredFields[key] = value;
        }
      }

      return {
        name,
        'Cache Hits': filteredFields['cacheHit'],
        'Cache Misses': filteredFields['cacheMiss'],
      };
    }) || [];

  const barNames = dataChart.reduce((acc, curr) => {
    const keys = Object.keys(curr).filter((d) => d !== 'name' && !acc.includes(d));
    return [...acc, ...keys];
  }, []);

  return (
    <Flex style={{ width: '100%', position: 'relative' }}>
      <BlurringOverlay
        enabled={!trackingCodeInstalled}
        location="Dashboard Number of Crawler Visits"
        content="Complete your Prerender integration to unlock its full potential."
      />
      <Card bordered={false} style={{ width: '100%', filter: !trackingCodeInstalled ? 'blur(3px)' : null }}>
        <Flex justify={'space-between'} align={'flex-start'} style={{ marginBottom: '15px' }}>
          <Space direction="vertical">
            <Paragraph strong style={{ marginBottom: '0' }}>
              Number of Crawler Visits
            </Paragraph>
            <Text type="secondary">
              A HIT represents a requested page that was served from our cache. A MISS represents a requested page that
              was rendered on the fly, served, and only cached if it responded with a 200 status code.
            </Text>
          </Space>
          <Flex gap="small" wrap="wrap" align={'center'}>
            <Link to="/crawl-stats">
              <Button size={'small'}>Show more</Button>
            </Link>
            {/* <SettingOutlined /> */}
          </Flex>
        </Flex>

        <Flex style={{ height: '100%', width: '100%' }}>
          <ResponsiveContainer width="95%" height={400}>
            <ComposedChart
              data={dataChart}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              barSize={40}
            >
              <Legend layout="horizontal" verticalAlign="top" align="left" wrapperStyle={{ top: 0, left: 25 }} />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />

              {barNames.map((name, index) => {
                const fill = colors[name];
                return <Bar key={name} dataKey={name} stackId="a" fill={fill} />;
              })}

              {/*
            <Line
              name="Trendline"
              dot={false}
              type="monotone"
              dataKey="pv"
              id="trendline0"
              stroke="#FFB353"
              strokeWidth={2}
            /> */}
            </ComposedChart>
          </ResponsiveContainer>
        </Flex>
      </Card>
    </Flex>
  );
}
