import { Dropdown, Tooltip, Switch, Flex, Space, Typography, theme } from 'antd';
import { useMemo, useState } from 'react';

import ClearCacheComponent from '../../clearCache/clearCacheCard';
import RecacheIntervalCard from '../../recache-interval-settings/recacheIntervalCard';
import { formatCacheRefreshInterval } from '../../../assets/dateFormatter';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../../features/auth/userRoles';

const searchResultLabelPlural = 'These cached pages are matching your search:';
const searchResultLabel = 'This cached page is matching your search:';

const CacheManagerHeader = ({
  prerenderUser,
  cachedPages,
  domainsCount,
  onAddUrlClick,
  notCrawledPagesFilter,
  setNotCrawledPagesFilter,
  onEvent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { token } = theme.useToken();

  const tooltipTitle = useMemo(() => {
    const isTooltip = true;
    if (prerenderUser.chargebeePlanId === 'basic-001') {
      return 'The Basic plan only allows a fixed cache expiration of 3 days';
    } else {
      return `Your pricing plan allows a cache freshness between ${formatCacheRefreshInterval(
        prerenderUser.plan.current.cacheRefreshInterval,
        isTooltip
      )}`;
    }
  }, [prerenderUser.chargebeePlanId, prerenderUser.plan.current.cacheRefreshInterval]);

  const onRecacheIntervalEvent = (e) => {
    if (e.action === 'closeCacheFreshnessCard') {
      setIsOpen(false);
      return;
    }
    if (e.action === 'updateCacheFreshnessSlider') {
      setIsOpen(false);
      onEvent(e);
      return;
    }

    onEvent(e);
  };

  return (
    <Flex align="flex-start" justify="space-between">
      <Space direction="vertical" style={{ marginBottom: '16px' }}>
        <div>
          {cachedPages.params.query
            ? cachedPages.stats.amt === 1
              ? searchResultLabel
              : searchResultLabelPlural
            : domainsCount > 1
            ? `You have ${prerenderUser.numPagesCached.toLocaleString('en-US')} URLs from ${domainsCount.toLocaleString(
                'en-US'
              )} domains cached in total`
            : `You have ${prerenderUser.numPagesCached.toLocaleString('en-US')} URLs cached in total`}

          <p>{cachedPages.params.query && <b className="ml-2">{cachedPages.params.query}</b>}</p>
        </div>

        <Flex gap="small">
          <Switch title="Not crawled pages" value={notCrawledPagesFilter} onChange={setNotCrawledPagesFilter} />
          <Typography.Text style={{ fontSize: token.fontSizeSM }} strong>
            Pages that have not been requested in the past 30 days
          </Typography.Text>
        </Flex>
      </Space>
      <div className="col">
        <div className="float-right">
          <ButtonWithRole
            disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
            type="primary"
            className="ml-2"
            onClick={() => onAddUrlClick()}
          >
            Add URLs
          </ButtonWithRole>
          <Dropdown
            dropdownRender={() => (
              <div style={{ maxWidth: 500, background: '#ffffff' }}>
                <RecacheIntervalCard onEvent={onRecacheIntervalEvent} />
              </div>
            )}
            trigger={['click']}
            placement="bottomRight"
            onOpenChange={setIsOpen}
            open={isOpen}
          >
            <Tooltip title={tooltipTitle}>
              <ButtonWithRole
                disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                className="ml-2"
                type="primary"
              >
                Cache Settings
              </ButtonWithRole>
            </Tooltip>
          </Dropdown>
          <ClearCacheComponent />
        </div>
      </div>
    </Flex>
  );
};

export default CacheManagerHeader;
