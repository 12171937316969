import { SET_SHOW_HAS_BILLING_ADDRESS_BADGE, SET_SHOW_HAS_BILLING_INFO_BADGE } from '../actions/types';

const initialState = {
  showHasBillingAddressBadge: true,
  showHasBillingInfoBadge: true,
};

const billingInfoReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_SHOW_HAS_BILLING_ADDRESS_BADGE:
      return { ...state, showHasBillingAddressBadge: payload };
    case SET_SHOW_HAS_BILLING_INFO_BADGE:
      return { ...state, showHasBillingInfoBadge: payload };
    default:
      return state;
  }
};

export default billingInfoReducer;
